import React from "react";
import Loading from "../../share/components/loading";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import "./styles.css";
import { currencyFormat } from "../../../utils";
import { useTranslation } from "react-i18next";

const AllCardsPharmacy = ({ dataPharmacy, loading, detailPharmacy }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="contain-all-clinics">
      {loading === true ? (
        <Loading size={100} />
      ) : dataPharmacy.length === 0 ? (
        <h2>{t("clinics.no_result")}</h2>
      ) : (
        dataPharmacy.map((item, key) => {
          return (
            <div
              onClick={() => {
                detailPharmacy(item);
              }}
              key={key}
              className="card-clinics"
            >
              <img
                src={`${process.env.REACT_APP_URL_API_AIOP}storage/${item.images[0].url}`}
                alt=""
                className="img-pharmacy"
              />
              <div className="card-clinic-info">
                <div className="layout-clinics-a">
                  <div>
                    <h3>{item.name}</h3>
                    <p>{item.quantity}</p>
                  </div>
                  <div>
                    <span>
                      {4.1}{" "}
                      <SvgIcon
                        name={iconList.IconStar}
                        color={"#ffff00"}
                        size={15}
                      />
                    </span>
                  </div>
                </div>
                <div className="layout-clinics-b lyt-pharmacy">
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "13px",
                    }}
                    className="p-pharmacy"
                  >
                    {t("pharmacy.shipping")}:
                    <span
                      style={{
                        color: "rgb(134, 134, 134)",
                        fontSize: "12px",
                        fontWeight: "200",
                      }}
                    >
                      {t("pharmacy.all_country")}
                    </span>
                  </p>
                  <span className="pharmacy-price">
                    {currencyFormat(item.price).total}
                  </span>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default AllCardsPharmacy;
