import React from "react";
import "./styles.css";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useRef, useState } from "react";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { widgetsList } from "../../../../../infra/constants/app";
import { useTranslation } from "react-i18next";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import DisabledModal from "../../../share/components/disabledModal/disabledModal";
import TextComponent from "../../../share/components/fonts/TextComponent";

function 
CardRegisterNow({
  icons,
  backgroundColor,
  colorButton,
  type,
  active,
  onClick,
  current = "",
  url,
  backgroundColorDesactive = "#104172CC",
  width = "",
  disable,
  subtitle = false,
}) {
  const [t] = useTranslation("global");
  const activeClass = active ? "cardRegisterNowActive" : "cardRegisterNow";
  const [showOnlyThis, setShowOnlyThis] = useState([]);
  const navigate = useNavigate();
  const MotionBox = styled(motion.div)`
    width: 100vw;
  `;
  const constraintsRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const redirectTo = (url) => {
    if (active) {
      navigate(url);
    }
  };
  function addWidgetURL(url) {
    const isWidget = widgetsList.find(
      (w) => w.path === window.location.pathname
    );
    return isWidget ? `/widget${url}` : url;
  }
  const servicesTravel = [
    {
      id: "fligths",
      title: t("carousel_services.fligth"),
      img: iconList.IconPlane,
      route: addWidgetURL("/fligths"),
    },
    {
      id: "hotel",
      title: t("carousel_services.hotel"),
      img: iconList.IconHotel,
      route: addWidgetURL("/hotel"),
    },
    {
      id: "tours",
      title: t("carousel_services.tours"),
      img: iconList.IconBag,
      route: addWidgetURL("/tours"),
    },
    {
      id: "transport",
      title: t("carousel_services.transfers"),
      img: iconList.IconTaxi,
      route: addWidgetURL("/transport"),
    },
    {
      id: "carRentals",
      title: t("carousel_services.car_rental"),
      img: iconList.IconRentaCar,
      route: addWidgetURL("/carRentals"),
    },
    {
      id: "packages",
      title: t("carousel_services.packages"),
      img: iconList.IconTravel,
      route: addWidgetURL("/packages"),
    },
  ];

  const item = {
    route: url,
    disable: disable,
  };

  const servicesTravelList =
    showOnlyThis && showOnlyThis.length > 0
      ? servicesTravel.filter((route) => showOnlyThis.includes(route.id))
      : servicesTravel;

  return (
    <div
      onMouseEnter={onClick}
      className={`cardRegisterNow ${activeClass}`}
      style={{
        backgroundColor: !active ? "transparent" : backgroundColor,
        width: `${width}`,
      }}
      onClick={onClick}
      ref={constraintsRef}
    >
      <div
        className={`${
          active ? "cardRegisterNowContainerActive" : "cardRegisterNowContainer"
        }`}
        style={{
          backgroundColor: active ? "transparent" : backgroundColorDesactive,
        }}
      ></div>
      <div
        className={`${
          active ? "cardRegisterNowImgActive" : "cardRegisterNowImg"
        }`}
      >
        <img src={type.img} alt={type.title} />
      </div>
      <div
        className={`${
          active ? "cardRegisterContentActive" : "cardRegisterContent"
        }`}
      >
        <h2
          className={`${
            active ? "cardRegisterNowTitleActive" : "cardRegisterNowTitle"
          }`}
        >
          {
            subtitle &&
            <span>
              Hoteles con
            </span>
          }
          {type.title}
        </h2>
        <p
          className={`${
            active
              ? "cardRegisterNowDescriptionActive"
              : "cardRegisterNowDescription"
          }`}
        >
          {type.description}
        </p>
      </div>

      <div className="cardRegisterNowButtonContain">
        <button
          className="cardRegisterNowButton"
          style={{ backgroundColor: colorButton, display: "none" }}
        >
          {icons ? (
            <MotionBox
              className="slider_"
              style={{ width: "100%", gap: "0px" }}
              drag="x"
              dragConstraints={constraintsRef}
            >
              {servicesTravelList.map((item, key) => {
                return (
                  <motion.div className="div_homeTurism" key={key}>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={2}
                      className="grid_cardHomeTurism"
                    >
                      <Link to={item.route} className="iconLink">
                        <SvgIcon color="#fff" size={40} name={item?.img} />
                      </Link>
                    </Grid>
                  </motion.div>
                );
              })}
            </MotionBox>
          ) : (
            type.more_info
          )}
        </button>
        <DisabledModal item={item} active={active}>
          <button className="cardRegisterNowButtonLink">
            <div
              className="cardRegisterNowClip"
              style={{ backgroundColor: colorButton }}
            ></div>
            <div
              className="cardRegisterNowClipBorder"
              style={{
                backgroundColor: colorButton
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span class="underline-effect-card">{type.button}</span>
            </div>
          </button>
        </DisabledModal>
      </div>
    </div>
  );
}

export default CardRegisterNow;
