import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";

const Tooltip = ({
  children,
  content,
  position = "top",
  mobilePosition = "bottom",
  trigger = "hover",
  customStyle = {},
}) => {
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1111);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1111);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = () => {
    if (trigger === "hover") setVisible(true);
  };

  const handleMouseLeave = () => {
    if (trigger === "hover") setVisible(false);
  };

  const handleClick = () => {
    if (trigger === "click") setVisible(!visible);
  };

  return (
    <div
      className={styles.tooltipContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={customStyle}
    >
      {children}
      <div
        className={`${styles.tooltip} ${styles[isMobile ? mobilePosition : position]} ${visible ? styles.tooltipVisible : ""
          }`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default Tooltip;