import React from "react";
import Specialists from "../../ui/views/Specialist/Specialists";

const SpecialistRoute = () => {
  return (
    <div>
      <Specialists />
    </div>
  );
};

export default SpecialistRoute;
