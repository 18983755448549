import * as React from 'react';
import styles from "./index.module.css";

const MaxWidthContainer = ({ children }) => {
    return (
        <div className={styles.containerMaxWidth}>
            {children}
        </div>
    );
};

export default MaxWidthContainer;