import React from "react";
import styles from "./styles.module.css";
import { FaPlaneDeparture } from "react-icons/fa6";
import { MdFlight, MdLuggage } from "react-icons/md";
import { BsFillLuggageFill } from "react-icons/bs";
import { FaLuggageCart } from "react-icons/fa";
import { useSelector } from "react-redux";

const Flights = ({ flights, title }) => {
  const formatTime = (timeString) => {
    return timeString.split(":").slice(0, 2).join(":");
  };

  const isRoundTripData = useSelector((state) => state.dataSearchEngine.isRoundTripData);
  const originSelectedFlightData = useSelector((state) => state.dataSearchEngine.originSelectedFlightData).split(" - ")[0];
  const destinySelectedFlightData = useSelector((state) => state.dataSearchEngine.destinySelectedFlightData).split(" - ")[0];

  const dateDepartureSelectedData = flights.itinerary[0].dateArrival.split("T")[0];
  const dateReturnSelectedData = flights.itinerary[1] ? flights.itinerary[1].dateDeparture.split("T")[0] : null;

  const timeDepartureSelectedDataGoing = formatTime(flights.itinerary[0].dateArrival.split("T")[1].split("+")[0]);
  const timeReturnSelectedDataGoint = formatTime(flights.itinerary[0].dateDeparture.split("T")[1].split("+")[0]);
  
  const timeDepartureSelectedDataReturn = flights.itinerary[1] ? formatTime(flights.itinerary[1].dateArrival.split("T")[1].split("+")[0]) : null;
  const timeReturnSelectedDataReturn = flights.itinerary[1] ? formatTime(flights.itinerary[1].dateDeparture.split("T")[1].split("+")[0]) : null;  
  
  const getBaggageIcons = (luggage) => {
    return luggage.map((bag, index) => {
      switch (bag.type) {
        case "Carry-on":
          return (
            <div key={index} className={styles.luggageIcon}>
              {bag.count === 1 && <MdLuggage size={20} />}
              {bag.count === 2 && <BsFillLuggageFill size={20} />}
              {bag.count > 2 && <FaLuggageCart size={20} />}
              <span>{bag.count}</span>
            </div>
          );
        case "Checked":
          return (
            <div key={index} className={styles.luggageIcon}>
              {bag.count === 1 && <MdLuggage size={20} />}
              {bag.count === 2 && <BsFillLuggageFill size={20} />}
              {bag.count > 2 && <FaLuggageCart size={20} />}
              <span>{bag.count}</span>
            </div>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className={styles.cartItemsItem}>
      {!title ? null : <h3>3. Vuelos</h3>}

      <div className={styles.cartItemsItemOne}>
          <div className={styles.itemFlight}>
            <div className={styles.itemHeader}>
              <div className={styles.itemHeaderIconText}>
                <div className={styles.icon}>
                  <FaPlaneDeparture />
                </div>
                <div className={styles.itemHeaderText}>
                  <h4 className={styles.title}>{ originSelectedFlightData } - { destinySelectedFlightData }</h4>
                  <p className={styles.shortDescription}>
                    {isRoundTripData ? "Ida y vuelta" : "Solo ida"}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.itemDetailsFlights}>
              {/* Sección de IDA */}
              <div className={styles.itemDetailsFlights1}>
                <div className={styles.itemDetailsFlightsColumn1}>
                  <p className={styles.typeFlight}>
                    <strong>IDA</strong> {dateDepartureSelectedData}
                  </p>

                  {/* <div className={styles.itemDetailsFlightsColumn2}>
                    {getBaggageIcons(flight.luggage)}
                  </div> */}
                </div>

                <div className={styles.airlineLogo}>
                  <img
                    src={`https://pics.avs.io/60/60/${flights.itinerary[0].company}.png`}
                    alt={`${flights.itinerary[0].company} logo`}
                  />
                </div>

                <div className={styles.itemDetailsFlightsColumn1Footer}>
                  <div className={styles.itemDetailsFlightsColumn1FooterPlace}>
                    <p>{flights.itinerary[0].from} </p>
                    <b>{timeDepartureSelectedDataGoing}</b>
                  </div>
                  <p>Directo</p>
                  <div className={styles.itemDetailsFlightsColumn1FooterPlace}>
                    <p>{flights.itinerary[0].to} </p>
                    <b>{timeReturnSelectedDataGoint}</b>
                  </div>
                </div>
              </div>

              {/* Sección de VUELTA (solo si existe) */}
              {isRoundTripData && (
                <div className={styles.itemDetailsFlights2}>
                  <div className={styles.itemDetailsFlightsColumn1}>
                    <p className={styles.typeFlight}>
                      <strong>VUELTA</strong> {dateReturnSelectedData}
                    </p>

                    {/* <div className={styles.itemDetailsFlightsColumn2}>
                      {getBaggageIcons(flight.luggage)}
                    </div> */}
                  </div>

                  <div className={styles.airlineLogo}>
                    <img
                    src={`https://pics.avs.io/60/60/${flights.itinerary[1].company}.png`}
                    alt={`${flights.itinerary[1].company} logo`}
                    />
                  </div>

                  <div className={styles.itemDetailsFlightsColumn1Footer}>
                    <div
                      className={styles.itemDetailsFlightsColumn1FooterPlace}
                    >
                      <p>{flights.itinerary[0].from} </p>
                      <b>{timeDepartureSelectedDataReturn}</b>
                    </div>
                    <p>Directo</p>
                    <div
                      className={styles.itemDetailsFlightsColumn1FooterPlace}
                    >
                      <p>{flights.itinerary[0].to} </p>
                      <b>{timeReturnSelectedDataReturn}</b>
                    </div>
                  </div>
                </div>
              )}

              {!isRoundTripData && (
                <div className={styles.itemDetailsFlights2Any}>
                  No hay vuelo de vuelta
                  <MdFlight size={20} />
                </div>
              )}
            </div>
          </div>

        <div className={styles.totalPrice}>
          <p className={styles.totalPriceText}>
            Precio:
            <span>
              {flights.priceTotal
                .toLocaleString("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 0,
                })}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Flights;
