import React, { useEffect, useState } from "react";
import DisabledModal from "../disabledModal/disabledModal";
import { MdCameraAlt } from "react-icons/md";
import style from "./styles.module.css";

const CardLastSearch = ({
  img,
  description1,
  title,
  description2,
  url = "/",
  active = false,
  disable = false,
  type,
}) => {
  const item = {
    route: url,
    disable: disable,
  };

  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={style.cardLastSearch}>
      <div className={style.cardLastImg}>
        {!imageLoaded && (
          <div className={style.imgSearchSkeleton}>
            <MdCameraAlt size={40} color="var(--greySecundary)" />
          </div>
        )}
        <img
          src={img}
          width={60}
          onLoad={() => setImageLoaded(true)}
          height={60}
          alt={title + " image"}
        />
      </div>

      <div className={style.cardContentLast}>
        <div>
          <h4>{type}</h4>
          <h3>{title}</h3>
          <p style={{ color: "var(--greySecundary)" }}>{description2}</p>
          <p>{description1}</p>
        </div>

        <DisabledModal
          active={active}
          item={item}
          width="fit content"
          center={true}
        >
          <span
            className={style.underlineEffect}
            style={{
              color: "var(--bluePrimary)",
              textAlign: "start",
              width: "fit-content",
            }}
          >
            Seguir buscando
          </span>
        </DisabledModal>
      </div>
    </div>
  );
};

export default CardLastSearch;
