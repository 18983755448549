const notificationsData = [
  {
    id: 1,
    message: "Notificación #1",
    details: "Detalles de la notificación #1.",
    time: "10:34 am",
    status: "leída",
    important: true,
    date: "2024-09-01",
  },
  {
    id: 2,
    message: "Notificación #2",
    details: "Detalles de la notificación #2.",
    time: "5:15 pm",
    status: "no leída",
    important: false,
    date: "2024-09-01",
  },
  {
    id: 3,
    message: "Notificación #3",
    details: "Detalles de la notificación #3.",
    time: "11:20 am",
    status: "archivada",
    important: false,
    date: "2024-09-01",
  },
  {
    id: 4,
    message: "Notificación #4",
    details: "Detalles de la notificación #4.",
    time: "2:47 pm",
    status: "leída",
    important: true,
    date: "2024-09-01",
  },
  {
    id: 5,
    message: "Notificación #5",
    details: "Detalles de la notificación #5.",
    time: "8:10 am",
    status: "no leída",
    important: true,
    date: "2024-09-01",
  },
  {
    id: 6,
    message: "Notificación #6",
    details: "Detalles de la notificación #6.",
    time: "4:12 pm",
    status: "archivada",
    important: false,
    date: "2024-09-06",
  },
  {
    id: 7,
    message: "Notificación #7",
    details: "Detalles de la notificación #7.",
    time: "7:23 am",
    status: "leída",
    important: true,
    date: "2024-09-07",
  },
  {
    id: 8,
    message: "Notificación #8",
    details: "Detalles de la notificación #8.",
    time: "9:45 pm",
    status: "no leída",
    important: false,
    date: "2024-09-08",
  },
  {
    id: 9,
    message: "Notificación #9",
    details: "Detalles de la notificación #9.",
    time: "3:33 pm",
    status: "archivada",
    important: false,
    date: "2024-09-09",
  },
  {
    id: 10,
    message: "Notificación #10",
    details: "Detalles de la notificación #10.",
    time: "6:02 am",
    status: "leída",
    important: true,
    date: "2024-09-10",
  },
  {
    id: 11,
    message: "Notificación #11",
    details: "Detalles de la notificación #11.",
    time: "1:54 pm",
    status: "no leída",
    important: true,
    date: "2024-09-11",
  },
  {
    id: 12,
    message: "Notificación #12",
    details: "Detalles de la notificación #12.",
    time: "12:38 pm",
    status: "archivada",
    important: false,
    date: "2024-09-12",
  },
  {
    id: 13,
    message: "Notificación #13",
    details: "Detalles de la notificación #13.",
    time: "8:45 am",
    status: "leída",
    important: true,
    date: "2024-09-13",
  },
  {
    id: 14,
    message: "Notificación #14",
    details: "Detalles de la notificación #14.",
    time: "11:30 pm",
    status: "no leída",
    important: false,
    date: "2024-09-14",
  },
  {
    id: 15,
    message: "Notificación #15",
    details: "Detalles de la notificación #15.",
    time: "10:12 am",
    status: "archivada",
    important: false,
    date: "2024-09-15",
  },
  {
    id: 16,
    message: "Notificación #16",
    details: "Detalles de la notificación #16.",
    time: "4:10 pm",
    status: "leída",
    important: true,
    date: "2024-09-16",
  },
  {
    id: 17,
    message: "Notificación #17",
    details: "Detalles de la notificación #17.",
    time: "9:25 am",
    status: "no leída",
    important: true,
    date: "2024-09-17",
  },
  {
    id: 18,
    message: "Notificación #18",
    details: "Detalles de la notificación #18.",
    time: "6:05 pm",
    status: "archivada",
    important: false,
    date: "2024-09-18",
  },
  {
    id: 19,
    message: "Notificación #19",
    details: "Detalles de la notificación #19.",
    time: "1:15 pm",
    status: "leída",
    important: true,
    date: "2024-09-19",
  },
  {
    id: 20,
    message: "Notificación #20",
    details: "Detalles de la notificación #20.",
    time: "3:45 am",
    status: "no leída",
    important: false,
    date: "2024-09-20",
  },
  {
    id: 21,
    message: "Notificación #21",
    details: "Detalles de la notificación #21.",
    time: "8:12 pm",
    status: "archivada",
    important: false,
    date: "2024-09-21",
  },
  {
    id: 22,
    message: "Notificación #22",
    details: "Detalles de la notificación #22.",
    time: "5:34 am",
    status: "leída",
    important: true,
    date: "2024-09-22",
  },
  {
    id: 23,
    message: "Notificación #23",
    details: "Detalles de la notificación #23.",
    time: "11:40 am",
    status: "no leída",
    important: true,
    date: "2024-09-23",
  },
  {
    id: 24,
    message: "Notificación #24",
    details: "Detalles de la notificación #24.",
    time: "7:58 pm",
    status: "archivada",
    important: false,
    date: "2024-09-24",
  },
  {
    id: 25,
    message: "Notificación #25",
    details: "Detalles de la notificación #25.",
    time: "6:22 pm",
    status: "leída",
    important: true,
    date: "2024-09-25",
  },
  {
    id: 26,
    message: "Notificación #26",
    details: "Detalles de la notificación #26.",
    time: "9:13 am",
    status: "no leída",
    important: false,
    date: "2024-09-26",
  },
  {
    id: 27,
    message: "Notificación #27",
    details: "Detalles de la notificación #27.",
    time: "4:45 am",
    status: "archivada",
    important: false,
    date: "2024-09-27",
  },
  {
    id: 28,
    message: "Notificación #28",
    details: "Detalles de la notificación #28.",
    time: "3:20 pm",
    status: "leída",
    important: true,
    date: "2024-09-28",
  },
  {
    id: 29,
    message: "Notificación #29",
    details: "Detalles de la notificación #29.",
    time: "1:30 am",
    status: "no leída",
    important: true,
    date: "2024-09-29",
  },
  {
    id: 30,
    message: "Notificación #30",
    details: "Detalles de la notificación #30.",
    time: "12:05 pm",
    status: "archivada",
    important: false,
    date: "2024-09-30",
  },
  {
    id: 31,
    message: "Notificación #31",
    details: "Detalles de la notificación #31.",
    time: "7:17 am",
    status: "leída",
    important: true,
    date: "2024-10-01",
  },
  {
    id: 32,
    message: "Notificación #32",
    details: "Detalles de la notificación #32.",
    time: "5:25 pm",
    status: "no leída",
    important: false,
    date: "2024-10-02",
  },
  {
    id: 33,
    message: "Notificación #33",
    details: "Detalles de la notificación #33.",
    time: "8:40 am",
    status: "archivada",
    important: false,
    date: "2024-10-03",
  },
  {
    id: 34,
    message: "Notificación #34",
    details: "Detalles de la notificación #34.",
    time: "6:55 pm",
    status: "leída",
    important: true,
    date: "2024-10-04",
  },
  {
    id: 35,
    message: "Notificación #35",
    details: "Detalles de la notificación #35.",
    time: "3:10 am",
    status: "no leída",
    important: true,
    date: "2024-10-05",
  },
  {
    id: 36,
    message: "Notificación #36",
    details: "Detalles de la notificación #36.",
    time: "11:02 pm",
    status: "archivada",
    important: false,
    date: "2024-10-06",
  },
  {
    id: 37,
    message: "Notificación #37",
    details: "Detalles de la notificación #37.",
    time: "9:10 am",
    status: "leída",
    important: true,
    date: "2024-10-07",
  },
  {
    id: 38,
    message: "Notificación #38",
    details: "Detalles de la notificación #38.",
    time: "2:23 pm",
    status: "no leída",
    important: false,
    date: "2024-10-08",
  },
  {
    id: 39,
    message: "Notificación #39",
    details: "Detalles de la notificación #39.",
    time: "4:12 pm",
    status: "archivada",
    important: false,
    date: "2024-10-09",
  },
  {
    id: 40,
    message: "Notificación #40",
    details: "Detalles de la notificación #40.",
    time: "7:30 am",
    status: "leída",
    important: true,
    date: "2024-10-10",
  },
  {
    id: 41,
    message: "Notificación #41",
    details: "Detalles de la notificación #41.",
    time: "12:10 pm",
    status: "no leída",
    important: true,
    date: "2024-10-11",
  },
  {
    id: 42,
    message: "Notificación #42",
    details: "Detalles de la notificación #42.",
    time: "10:05 am",
    status: "archivada",
    important: false,
    date: "2024-10-12",
  },
  {
    id: 43,
    message: "Notificación #43",
    details: "Detalles de la notificación #43.",
    time: "5:05 pm",
    status: "leída",
    important: true,
    date: "2024-10-13",
  },
  {
    id: 44,
    message: "Notificación #44",
    details: "Detalles de la notificación #44.",
    time: "1:15 am",
    status: "no leída",
    important: false,
    date: "2024-10-14",
  },
  {
    id: 45,
    message: "Notificación #45",
    details: "Detalles de la notificación #45.",
    time: "8:45 am",
    status: "archivada",
    important: false,
    date: "2024-10-15",
  },
  {
    id: 46,
    message: "Notificación #46",
    details: "Detalles de la notificación #46.",
    time: "6:15 pm",
    status: "leída",
    important: true,
    date: "2024-10-16",
  },
  {
    id: 47,
    message: "Notificación #47",
    details: "Detalles de la notificación #47.",
    time: "3:15 pm",
    status: "no leída",
    important: true,
    date: "2024-10-16",
  },
  {
    id: 48,
    message: "Notificación #48",
    details: "Detalles de la notificación #48.",
    time: "7:55 am",
    status: "archivada",
    important: false,
    date: "2024-10-16",
  },
  {
    id: 49,
    message: "Notificación #49",
    details: "Detalles de la notificación #49.",
    time: "2:35 pm",
    status: "leída",
    important: true,
    date: "2024-10-16",
  },
  {
    id: 50,
    message: "Notificación #50",
    details: "Detalles de la notificación #50.",
    time: "5:55 am",
    status: "no leída",
    important: false,
    date: "2024-10-16",
  },
];

export default notificationsData;
