import React, { useEffect, useState } from 'react';

import DesinitySearcher from "@/ui/views/flights/components/desinitySearcher";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import { useTranslation } from "react-i18next";
import { setDestinySelectedFlightData } from '@/store/slices/dataEnginePackage/dataEnginePackageSlice';
import { useDispatch, useSelector } from 'react-redux';

const InputDestinyFlight = ({
    getSelectedDestination,
    isCardRefDestination,
    selectedOrigin,
    selectedDestination,
    ellipsisText,
    validationSearchDestiny,
}) => {
    // Variables
    const dispatch = useDispatch();

    const [t] = useTranslation("global");
    const [focusDestination, setFocusDestination] = useState(false);

    const destinySelectedFlightData = useSelector((state) => state.dataSearchEngine.destinySelectedFlightData);

    // Functions
    const desinitySearcherTypes = {
        destination: {
            text: t("fligth.search_fligth.destination"),
            errorText: t("fligth.search_fligth.errorMessageDestination"),
            placeholder: t("fligth.search_fligth.placeholderDestination"),
        }
    };

    //UseEffects
    useEffect(() => {
        selectedDestination = destinySelectedFlightData;
    }, []);

    useEffect(() => {
        dispatch(setDestinySelectedFlightData(selectedDestination));
    }, [selectedDestination]);

    return(
        <div className="content-inputs content-inputs-destiny-origin-build-package"  style={{
            borderColor:  selectedDestination != "d" || validationSearchDestiny
            ? ""
            : "var(--redPrimary)"
        }}>
            <div className="first-section">
                <TextComponent
                    className={"label-destiny"}
                    children={"Destino de vuelo"}
                    size={"md2"}
                    fontWeight={
                    selectedDestination != "d" || validationSearchDestiny
                        ? "light"
                        : "400"
                    }
                    color={
                    selectedDestination != "d" || validationSearchDestiny
                        ? ""
                        : "var(--redPrimary)"
                    }
                />
                {focusDestination ? (
                    <DesinitySearcher
                    type={desinitySearcherTypes.destination}
                    change={getSelectedDestination}
                    inputFocused={isCardRefDestination}
                    setFocus={setFocusDestination}
                    condition={{build:true}}
                    />
                ) : selectedOrigin === selectedDestination ? (
                    <TextComponent
                    className={"value-origin-destiny"}
                    color={"var(--redPrimary)"}
                    children={ellipsisText("Cambia tu destino", 17)}
                    size={"md2"}
                    fontWeight={"bold"}
                    onclick={{
                        voidP: (pa) => getSelectedDestination(pa),
                        parameters: "d",
                    }}
                    />
                ) : (
                    <p
                    style={
                        selectedDestination != "d" || validationSearchDestiny
                        ? { 
                            fontWeight: "500", 
                        }
                        : {
                            color: "var(--greySecundary)",
                            fontWeight: "400",
                            }
                    }
                    className="value-origin-destiny"
                    onClick={() => {
                        setFocusDestination(true);
                    }}
                    >
                    {selectedDestination === "d"
                        ? "Selecciona un destino"
                        : selectedDestination}
                    </p>
                )}
            </div>
            {focusDestination === true ? (
                ""
            ) : (
                <div>
                    <SvgIcon
                        name={iconList.IconPinMap2}
                        color={"var(--bluePrimary)"}
                        size={30}
                    />
                </div>
            )}
        </div>
    );
};

export default InputDestinyFlight;