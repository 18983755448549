import React, { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import styles from "./styles.module.css";
import image from "../assets/1.jpg";
import { IoClose } from "react-icons/io5";

const CardsBuys = ({
    cards
}) => {
  

  const [selectedCard, setSelectedCard] = useState(null);
  const [activeModal, setActiveModal] = useState(false);

  const handleAlertClick = (card) => {
    setActiveModal(true);
    setSelectedCard(card);
  };

  return (
    <div className={styles.cardsBuys}>
      {cards.map((card) => (
        <div key={card.id} className={styles.cardBuy}>
          <div className={styles.cardBuyImage}>
            <img src={card.image} alt="Imagen" />
          </div>

          <div className={styles.contentPadding}>
            <div className={styles.cardBuyContent}>
              <div className={styles.cardBuyContentText}>
                <h3>{card.title}</h3>
                <div className={styles.cardBuyContentDescrip}>
                  {card.description}
                  <h4>{card.price}</h4>
                </div>
              </div>

              <button
                className={styles.cardIconAlert}
                onClick={() => handleAlertClick(card)}
              >
                <FiAlertCircle />
              </button>
            </div>

            <button className={styles.addBuy}>Agregar</button>
          </div>
        </div>
      ))}

      <div className={`${styles.modal} ${!activeModal ? styles.close : ""}`}>
        <div
          className={`${styles.modalContent} ${
            !activeModal ? styles.close : ""
          }`}
        >
          <button
            className={styles.closeButton}
            onClick={() => setActiveModal(false)}
          >
            <IoClose size={20} />
          </button>

          <div className={styles.contentModal}>
            <div className={styles.contentModalText}>
              <div className={styles.cardBuyContent}>
                <div className={styles.cardBuyContentText}>
                  <h3>{selectedCard?.title}</h3>
                  <div className={styles.cardBuyContentDescrip}>
                    {selectedCard?.additionalInfo}
                    <h4>{selectedCard?.price}</h4>
                  </div>
                </div>
              </div>

              <div className={styles.contentModalForm}>
                <div className={styles.contentInput}>
                  <label htmlFor="input1">Fecha</label>

                  <input type="date" id="input1" />
                </div>

                <div className={styles.contentInput}>
                  <label htmlFor="input1">Hora</label>

                  <input type="time" id="input1" />
                </div>

                <div className={styles.contentInput}>
                  <label htmlFor="input1">Cantidad</label>

                  <input type="number" id="input1" />
                </div>
              </div>

              <button className={styles.addBuy}>Agregar</button>
            </div>

            <div className={styles.modalImage}>
              <img src={selectedCard?.image} alt="Imagen" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsBuys;
