import React, { useEffect, useRef, useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setOriginSelectedTransladeData } from '@/store/slices/dataEnginePackage/dataEnginePackageSlice';

const InputOriginTranslate = ({
    citySelected,
    findOrigin,
    setCityVerify,
    loadingOrigin,
    originList,
    setOriginSelected,
    setOriginList,
    setOriginVerify,
    originSelected
}) => {
    // Variables
    const dispatch = useDispatch();

    const originRef = useRef(null);

    const [originFocus, setOriginFocus] = useState(false);
    const [showCardOrigin, setShowCardOrigin] = useState(false);

    const originSelectedTransladeData = useSelector((state) => state.dataSearchEngine.originSelectedTransladeData);

    // UseEffects
    useEffect(() => {
        setOriginSelected(originSelectedTransladeData);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (originRef.current && !originRef.current.contains(event.target)) {
                setOriginFocus(false);
                setShowCardOrigin(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [originRef]);

    useEffect(() => {
        if (originList.length > 0) {
            setShowCardOrigin(true);
        } else {
            setShowCardOrigin(false);
        }
    }, [originList]);

    useEffect(() => {
        dispatch(setOriginSelectedTransladeData(originSelected));
    }, [originSelected]);

    return (
        <div
            className="searchBuildPackageContentInput"
            ref={originRef}
            onClick={() => {
                if (
                    citySelected === "" ||
                    citySelected === "Selecciona la ciudad"
                ) {
                    setCityVerify(true);
                    setOriginFocus(true);
                } else {
                    findOrigin();
                    setOriginFocus(true);
                }
            }}
        >
            <div className="searchBuildPackageContentInputContent">
                <p>Origen de traslado</p>
                <input type="text" placeholder="Elige un origen" value={originSelected?.name || ''} readOnly />
            </div>
            {
                loadingOrigin ? (
                    <CircularProgress
                        size={30}
                        style={{
                            right: "5px",
                            position: "absolute",
                            top: "11px",
                        }}
                    />
                ) : (
                    <TiArrowSortedDown size={20} color="var(--bluePrimary)" />
                )
            }
            {
                showCardOrigin && originFocus ? (
                    <div className="dropdown-ciudad shadow">
                        <ul className="contentElemetsSearchInputBuildPackage">
                            {
                                originList.map((origin) => (
                                    <li
                                        key={origin.id}
                                        onClick={(e) => {
                                            setOriginSelected(origin);
                                            setOriginFocus(false);
                                            setOriginList([]);
                                            setOriginVerify(false);
                                            e.stopPropagation();
                                            localStorage.setItem(
                                                "originTransport",
                                                JSON.stringify(origin)
                                            );
                                        }}
                                    >
                                        {origin.name}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                ) : null
            }
        </div>
    );
};

export default InputOriginTranslate;