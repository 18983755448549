import React from 'react';
import InputTypeTravelTranslate from './SingleInputs/InputTypeTravelTranslate';
import InputSearchCityTranslate from './SingleInputs/InputSearchCityTranslate';
import InputOriginTranslate from './SingleInputs/InputOriginTranslate';
import InputDestinyTranslate from './SingleInputs/InputDestinyTranslate';
import InputTimeTranslate from './SingleInputs/InputTimeTranslate';

const InputsTrasnlates = ({
    typeT,
    setTypeT,
    city,
    setCity,
    loadingCity,
    cityVerify,
    citySelected,
    setOriginSelected,
    setDestinySelected,
    cityList,
    setCityList,
    setCityVerify,
    setcitySelected,
    originSelected,
    findOrigin,
    loadingOrigin,
    originList,
    setOriginList,
    setOriginVerify,
    destinyList,
    findDestiny,
    destinySelected,
    loadingDestiny,
    setDestinyList,
    setDestinyVerify,
    hoursSe,
    setHoursSe,
    setCalendarVerify,
    dateGoingSelected
}) => {

    return(
        <>
            <InputTypeTravelTranslate 
                typeT={typeT}
                setTypeT={setTypeT}
            />

            <InputSearchCityTranslate
                city={city}
                setCity={setCity}
                loadingCity={loadingCity}
                cityVerify={cityVerify}
                citySelected={citySelected}
                setOriginSelected={setOriginSelected}
                setDestinySelected={setDestinySelected}
                cityList={cityList}
                setCityList={setCityList}
                setCityVerify={setCityVerify}
                setcitySelected={setcitySelected}
            />

            <InputOriginTranslate 
                citySelected={citySelected}
                findOrigin={findOrigin}
                setCityVerify={setCityVerify}
                loadingOrigin={loadingOrigin}
                originList={originList}
                setOriginSelected={setOriginSelected}
                setOriginList={setOriginList}
                setOriginVerify={setOriginVerify}
                originSelected={originSelected}
            />

            <InputDestinyTranslate 
                originSelected={originSelected}
                destinyList={destinyList}
                findDestiny={findDestiny}
                destinySelected={destinySelected}
                setOriginVerify={setOriginVerify}
                loadingDestiny={loadingDestiny}
                setDestinySelected={setDestinySelected}
                setDestinyList={setDestinyList}
                setDestinyVerify={setDestinyVerify}
            />
            
            <InputTimeTranslate 
                hoursSe={hoursSe}
                setHoursSe={setHoursSe}
                setCalendarVerify={setCalendarVerify}
                dateGoingSelected={dateGoingSelected}
            />
        </>
    );
};

export default InputsTrasnlates;