import { Box } from "@mui/material";
import React, { useEffect } from "react";
import AvisoDePrivacidadPDF from "./pdf/AVISO-DE-PRIVACIDAD-Y-AUTOTIZACION-EXPRESA-PARA-EL-TRATAMIENTO-DE-DATOS-PERSONALES-WELLEZY.pdf";
import PoliticaTratamientoPDF from "./pdf/POLITICA-DE-TRATAMIENTO-DE-DATOS-PERSONALES-WELLEZY.pdf";
import FormatoReclamacionesPDF from "./pdf/FORMATO-DE-RECLAMACIONES-PARA-TRATAMIENTO-DE-DATOS-PERSONALES-WELLEZY.pdf";
import axios from "axios";

export const PrivacyPolicies = () => {
  const openPdf = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://ipinfo.io/json?token=7f8c5f5af0abfe"
        );

        const { city, region, country, loc, ip, postal, timezone } =
          response.data;

        let data = JSON.stringify({
          ip: ip,
          city: city,
          region: region,
          country: country,
          loc: loc,
          postal: postal,
          timezone: timezone,
          policies: true,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://staging.aiop.com.co/api/localization",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
      } catch (error) {
        console.error(
          "Error al obtener la información de ubicación:",
          error.message
        );
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "start",
          width: "80%",
          margin: "auto",
          padding: "70px 0px",
        }}
      >
        <Box
          style={{
            marginBottom: "50px",
          }}
        >
          <h1
            style={{
              fontSize: "60px",
              color: "#181818",
              textAlign: "start",
            }}
          >
            Política de privacidad
          </h1>
        </Box>
        <Box>
          <p>
            <strong>WELLEZY</strong> es responsable del uso de la información de
            nuestro público, por lo anterior, en cumplimiento de la Ley 1581 de
            2012, ponemos a su disposición, las Políticas de Tratamiento de
            Datos Personales.
          </p>
          <br />
          <p>
            Para WELLEZY es de vital importancia el adecuado tratamiento de los
            datos personales de sus públicos de interés. Velamos por la
            confidencialidad de la información de nuestros públicos, gracias a
            que contamos con el apoyo de las herramientas idóneas tanto técnicas
            como humanas para el almacenamiento y buen uso de ésta en función de
            las actividades para las cuales esta información ha sido entregada.
          </p>
          <br />
          <p>
            Usted puede ejercer su derecho a la protección y tratamiento de sus
            datos personales, tales como a conocerlos o suprimirlos
            diligenciando el formato de autorización o reclamo de tratamiento de
            datos personales y entregándolo en nuestra sede.
          </p>
          <br />
          <p>
            <a href="#" onClick={() => openPdf(PoliticaTratamientoPDF)}>
              POLITICA DE TRATAMIENTO DE DATOS PERSONALES WELLEZY
            </a>
          </p>
          <br />
          <p>
            <a href="#" onClick={() => openPdf(AvisoDePrivacidadPDF)}>
              AVISO DE PRIVACIDAD Y AUTOTIZACIÓN EXPRESA PARA EL TRATAMIENTO DE
              DATOS PERSONALES WELLEZY
            </a>
          </p>
          <br />
          <p>
            <a href="#" onClick={() => openPdf(FormatoReclamacionesPDF)}>
              FORMATO DE RECLAMACIONES PARA TRATAMIENTO DE DATOS PERSONALES
              WELLEZY
            </a>
          </p>
        </Box>
      </Box>
    </>
  );
};
