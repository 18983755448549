import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setDataPackage } from "@/store/slices/dataStepz/dataStepzSlice";

const UseClinics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const url = window.location.search;
  const params = new URLSearchParams(url);
  const slug = params.get('slug');

  const condition = location.pathname;
  let counter = 0;
  const isBuildPack = condition.includes("/build-package/clinic")

  const [loading, setLoading] = useState(false);
  const [dataClinics, setDataClinics] = useState([]);
  const [urlImage, setUrlImage] = useState("");
  const [services, setServices] = useState([]);
  const itemsForPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext, setMostrarNext] = useState(true);
  const [city, setCity] = useState("");
  const [name, setName] = useState("");

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
  const setUrlHotel = useSelector((state) => state.dataSearchEngine.urlS);
  const setUrlFlight = useSelector((state) => state.dataSearchEngine.urlS);

  function nextStep (slug, data){
    dispatch(setDataPackage({ ...dataPackage, clinic: data }));

    navigate(`/cart-checkout`);
  }

  function findClinics(page = 1, name = "", city = "") {
    const url = `${process.env.REACT_APP_URL_API_FINANMED}/v2/search/docs?page=${page}&type_affiliate=Clinica&name=${name}&city=${city}&wellezy=true`;
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        setLoading(false);
        setServices(res.data);
        setDataClinics(res.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function getClinicDoc() {
    const url = `${process.env.REACT_APP_URL_API_FINANMED}/v2/get/doctor/${slug}`
    setLoading(true);
    axios.get(url)
      .then((res) => {
        setLoading(false)
        const filtrados = res.data?.clinics.filter((item)=> {
          return item.wellezy === 1 ? item : null
        })
        setServices(res.data?.clinics);
        setDataClinics(filtrados);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  }

  useEffect(() => {
    if (isBuildPack) {
      getClinicDoc()
    } else {
      findClinics();
    }

  }, []);

  const nextHandler = (condition, page) => {
    findClinics(
      condition ? page : Number(services?.current_page) + 1,
      name,
      city
    );
  };

  const prevHandler = () => {
    findClinics(Number(services?.current_page) - 1, name, city);
  };

  const personalizeFind = (keyWord) => {
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/clinics`;
    setLoading(true);
    axios
      .get(url, {
        params: {
          search: keyWord,
        },
      })
      .then((res) => {
        counter += 1;
        setLoading(false);
        setServices(res.data.cinics);
        setUrlImage(res.data.url);
        setDataClinics([...res.data.cinics].splice(0, itemsForPage));
      })
      .catch((error) => {
        counter += 1;
        setLoading(false);
        console.log(error);
      });
  };

  const cityFind = (keyWord) => {
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/clinics`;
    setLoading(true);
    axios
      .get(url, {
        params: {
          search: keyWord,
        },
      })
      .then((res) => {
        counter += 1;
        setLoading(false);
        setServices(res.data.cinics);
        setUrlImage(res.data.url);
        setDataClinics([...res.data.cinics].splice(0, itemsForPage));
      })
      .catch((error) => {
        counter += 1;
        setLoading(false);
        console.log(error);
      });
  };

  const filterClinics = (word) => {
    if (word === "All") {
      setDataClinics([...services].splice(0, itemsForPage));
    } else {
      const keyWord = word.toLowerCase();
      const newData = [];

      for (let index = 0; index < services.length; index++) {
        for (
          let index2 = 0;
          index2 < services[index].subcategories.length;
          index2++
        ) {
          let toLowerCaseW =
            services[index].subcategories[index2].name.toLowerCase();
          if (toLowerCaseW.includes(keyWord)) {
            newData.push(services[index]);
          }
        }
      }

      setDataClinics(newData);
    }
  };

  const detailClinics = (clinic, id) => {
    navigate(`/Clinics/${id}`);
  };

  return {
    dataClinics,
    urlImage,
    currentPage,
    nextHandler,
    prevHandler,
    mostrarNext,
    services,
    loading,
    personalizeFind,
    cityFind,
    filterClinics,
    detailClinics,
    setCurrentPage,
    findClinics,
    setCity,
    setName,
    city,
    name,
    isBuildPack,
    nextStep,
    dataPackage
  };
};

export default UseClinics;
