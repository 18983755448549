import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

const UsePayHotel = () => {

  const { state } = useLocation()
  const [paymentCardSelected, setPaymentCardSelected] = useState(null);
  const [responseApi, setResponseApi] = useState(false)
  const [dataPay, setDataPay] = useState({})
  const [loadinPay, setLoadinPay] = useState(false)
  const [incompleteInformation, setIncompleteInformation] = useState("")
  const [idUser, setIdUser] = useState(null)
  const [email, setEmail] = useState(null)
  const navigate = useNavigate()
  const [urlPay, setUrlPay] = useState("")
  const requestId = localStorage.getItem("request-id-pay")
  const [statusTransaction, setStatusTransaction] = useState({})
  const [stateValue, setStateValue] = useState(!state ? JSON.parse(localStorage.getItem('data-pay-hotel')) : state)
  const [dataEmitter, setDataEmitter] = useState({})
  const goToLinkPay = () => {
    window.location.href = urlPay;
  }
  const emitterReservation = () => {
    const url = `${process.env.REACT_APP_URL_API_EMITTER_HOTEL}restel/confirmation`
    axios.post(url, {
      "locator": stateValue?.nLocalizador,
      "confirmation": "AE"
    })
      .then(res => {
        setDataEmitter(res.data)
        setResponseApi(false)
        setLoadinPay(false)
      })
      .catch(err => {
        console.log(err)
        setResponseApi(false)
        setLoadinPay(false)
      })
  }

  const getStatePay = () => {
    const url = `${process.env.REACT_APP_URL_STATE_PAY}restel`;
    axios.post(url, {
      request_id: Number(requestId)
    })
      .then(res => {
        setStatusTransaction(res.data)
        if (res.data?.pay?.status === "APPROVED") {
          setDataEmitter(res.data)
          setResponseApi(false)
          setLoadinPay(false)
        } else {
          setResponseApi(false)
          setLoadinPay(false)
        }
      })
      .catch(err => {
        console.log(err)
        setResponseApi(false)
        setLoadinPay(false)
      })

  }

  function getBaseUrl(url) {
    const parsedUrl = new URL(url);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
    return baseUrl;
}

  const getLinkPayReservation = () => {
    const dataNew = {
      "localizator": state?.nLocalizador,
      "type": "Restel",
      "price": Number(state?.price),
      "url": `${getBaseUrl(window.location.href)}/hotels/PayHotel`,
      "currency":"COP"
    }
    // const dataNew = {
    //   "cel": state?.tel.toString(),
    //   "name": state?.name,
    //   "lastname": state?.lastName,
    //   "localizator": state?.nLocalizador,
    //   "document": (state?.document),
    //   "typeDocument": state?.typeDoc,
    //   "email": state?.email,
    //   "type": "Restel",
    //   "price": Number(state?.price),
    //   "url": `${process.env.REACT_APP_URL_API_WELLEZY}hotels/PayHotel`
    // }

    const url = process.env.REACT_APP_URL_CREATE_LINK
    if (state?.tel === undefined || state?.name === undefined || state?.lastName === undefined || state?.nLocalizador === undefined || state?.email === undefined || state?.price === undefined || state?.document === undefined || state?.typeDoc === undefined) {
      setIncompleteInformation("Datos incompletos para obterner el link de pago")
      setTimeout(() => {
        setIncompleteInformation("")
      }, 4000);
    } else {
      setResponseApi(true)
      setLoadinPay(true)
      axios.post(url, dataNew)
        .then(res => {
          setUrlPay(res.data)
          localStorage.setItem('data-pay-hotel', JSON.stringify(state))
          localStorage.setItem("request-id-pay", res.data?.requestId)
          window.location.href = res.data?.processUrl
        })
        .catch(err => {
          console.log(err)
          setResponseApi(false)
          setIncompleteInformation("No se obtuvo el link de pago")
          setTimeout(() => {
            setIncompleteInformation("")
          }, 4000);
        })
    }

  }


  useEffect(() => {
    setResponseApi(true)
    setLoadinPay(true)
    if (!state) {
      getStatePay()
    } else {
      getLinkPayReservation()
    }

  }, [])



  return {
    state,
    paymentCardSelected,
    setPaymentCardSelected,
    responseApi,
    setResponseApi,
    dataPay,
    loadinPay,
    dataPay,
    incompleteInformation,
    setIdUser,
    setEmail,
    goToLinkPay,
    statusTransaction,
    stateValue,
    dataEmitter,
    getStatePay,
  }
}

export default UsePayHotel