import { createSlice } from "@reduxjs/toolkit";

export const searchEngineSlice = createSlice({
  name: 'dataSearchEngine',
  initialState: {
    spetVariableData: 0,
    espcialtieData: "",
    idEspecialtieData: null,
    procedureData: "",
    dateGoingSelectedData: "",
    dateReturnSelectedData: "",
    cityHotelData: "",
    nationalityData: "",
    isRoundTripData: false,
    originSelectedFlightData: "",
    destinySelectedFlightData: "",
    typeTravelTranslateData: "ONLY_TO_AIRPORT",
    cityTranslateData: '',
    originSelectedTransladeData: '',
    destinySelectedTransladeData: '',
    timeSelectedTransladeData: "",
    urlS: {}
  },
  reducers: {
    setUrls: (state,action)=>{
        state.urlS = action.payload
    },
    setSpetVariableData: (state, action) => {
        state.spetVariableData = action.payload;
    },
    setEspcialtieData: (state, action) => {
        state.espcialtieData = action.payload;
    },
    setIdSpecialtyData: (state, action) => {
        state.idEspecialtieData = action.payload;
    },
    setProcedureData: (state, action) => {
        state.procedureData = action.payload;
    },
    setDateGoingSelectedData: (state, action) => {
        state.dateGoingSelectedData = action.payload;
    },
    setDateReturnSelectedData: (state, action) => {
        state.dateReturnSelectedData = action.payload;
    },
    setCityHotelData: (state, action) => {
        state.cityHotelData = action.payload;
    },
    setNationalityData: (state, action) => {
        state.nationalityData = action.payload;
    },
    setIsRoundTripData: (state, action) => {
        state.isRoundTripData = action.payload
    },
    setOriginSelectedFlightData: (state, action) => {
        state.originSelectedFlightData = action.payload
    },
    setDestinySelectedFlightData: (state, action) => {
        state.destinySelectedFlightData = action.payload
    },
    setTypeTravelTranslateData: (state, action) => {
        state.typeTravelTranslateData = action.payload
    },
    setOriginSelectedTransladeData: (state, action) => {
        state.originSelectedTransladeData = action.payload
    },
    setDestinySelectedTransladeData: (state, action) => {
        state.destinySelectedTransladeData = action.payload
    },
    setCityTranslateData: (state, action) => {
        state.cityTranslateData = action.payload
    },
    setTimeSelectedTransladeData: (state, action) => {
        state.timeSelectedTransladeData = action.payload
    }
  }
});

export const { 
    setSpetVariableData,
    setEspcialtieData,
    setIdSpecialtyData,
    setProcedureData,
    setDateGoingSelectedData, 
    setDateReturnSelectedData,
    setCityHotelData,
    setNationalityData,
    setIsRoundTripData,
    setOriginSelectedFlightData,
    setDestinySelectedFlightData,
    setTypeTravelTranslateData,
    setCityTranslateData,
    setOriginSelectedTransladeData,
    setDestinySelectedTransladeData,
    setTimeSelectedTransladeData,
    setUrls
} = searchEngineSlice.actions;

export default searchEngineSlice.reducer;