import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { IoIosArrowDown } from "react-icons/io";

function FrequentQuestions({ data }) {
  const [isShowingContentKey, setIsShowingContentKey] = useState(false);
  const [listFaq, setListFaq] = useState([]);
  const [animationClass, setAnimationClass] = useState("fade-in");

  useEffect(() => {
    if (listFaq.length > 0) {
      // Aplicar fade-out antes de actualizar los datos
      setAnimationClass("fade-out");

      // Después de la duración del fade-out, actualizar los datos y aplicar fade-in
      setTimeout(() => {
        setListFaq(data?.es || []);
        setAnimationClass("fade-in");
      }, 400); // Duración del fade-out (2s)
    } else {
      // Inicialmente, simplemente mostrar los datos
      setListFaq(data?.es || []);
    }
  }, [data]);

  const [contentHeight, setContentHeight] = useState({});
  const contentRefs = useRef([]);

  useEffect(() => {
    listFaq.forEach((_, key) => {
      if (contentRefs.current[key]) {
        setContentHeight((prev) => ({
          ...prev,
          [key]: contentRefs.current[key].scrollHeight,
        }));
      }
    });
  }, [listFaq]);

  return (
    <div className={`pqrsFrequentQuestions ${animationClass}`}>
      <p className="pqrsFrequentQuestionsTitle">Preguntas frecuentes</p>

      <div className="pqrsFrequentQuestionsWrapper">
        {listFaq.map((faq, key) => {
          const isOpen = isShowingContentKey === key;
          return (
            <div
              key={key}
              className="pqrsFaq"
              onClick={() =>
                isOpen
                  ? setIsShowingContentKey(null)
                  : setIsShowingContentKey(key)
              }
            >
              <div className="pqrsFaqRow">
                <div className="pqrsTitle">{faq.title}</div>
                <IoIosArrowDown
                  style={{
                    transition: 'all .3s ease',
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </div>
              <div
                ref={(el) => (contentRefs.current[key] = el)}
                className={`pqrsContent ${isOpen ? 'show' : ''}`}
                style={{
                  maxHeight: isOpen ? `${contentHeight[key]}px` : '0px',
                }}
              >
                <p>{faq.content}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FrequentQuestions;
