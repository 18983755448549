import React, { useEffect, useState } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import { useTranslation } from "react-i18next";
import axios from "axios";

const SelectProcedures = ({ procedure, setProcedure }) => {
  const [modalProcedures, setModalProcedures] = useState(false);

  const [procedures, setProcedures] = useState(["cargando..."]);

  const [selectProcedures, setSelectProcedures] = useState("");

  const [t] = useTranslation("global");

  const getProcedures = () => {
    const urlSpecialist = `${process.env.REACT_APP_URL_API_AIOP}api/speciality`;
    axios
      .get(urlSpecialist)
      .then((response) => {
        setProcedures(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProcedures();
  }, []);

  return (
    <div className="content-select-procedures-main">
      <div className="content-select-proceduresT">
        <h3 className="title-select-procedures">
          {t("specialist.booking_flow.tratement_your_like")}
        </h3>
        <section className="contain-camp-select-procedures">
          <label htmlFor="">{t("specialist.booking_flow.tratement")}</label>
          <div
            className="camp-select-procedures"
            onClick={() => {
              setModalProcedures(!modalProcedures);
            }}
          >
            <span>
              {procedure === ""
                ? t("specialist.booking_flow.select_tratement")
                : procedure}
            </span>
            <SvgIcon
              name={iconList.IconArrowDown2}
              size={20}
              color={"#004274"}
            />
            {modalProcedures && (
              <div className="modal-procedures-select">
                <ul>
                  {procedures.map((index) => {
                    return (
                      <li
                        onClick={() => {
                          setProcedure(index?.name);
                          localStorage.setItem("BooProcedure", index?.name);
                        }}
                      >
                        {index?.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </section>
        <section className="contain-procedures-popular">
          <h3>{t("specialist.booking_flow.tratement_popular")}</h3>
          <div className="content-procedures-popular">
            <span
              onClick={() => {
                setProcedure("Lipoabdominoplastia");
                localStorage.setItem("BooProcedure", "Lipoabdominoplastia");
              }}
              style={{
                borderColor:
                  procedure === "Lipoabdominoplastia" ? "#004274" : "",
                color: procedure === "Lipoabdominoplastia" ? "#004274" : "",
              }}
            >
              Lipoabdominoplastia
            </span>
            <span
              onClick={() => {
                setProcedure("Mamoplastia");
                localStorage.setItem("BooProcedure", "Mamoplastia");
              }}
              style={{
                borderColor: procedure === "Mamoplastia" ? "#004274" : "",
                color: procedure === "Mamoplastia" ? "#004274" : "",
              }}
            >
              Mamoplastia
            </span>
            <span
              onClick={() => {
                setProcedure("Lipotransferencia");
                localStorage.setItem("BooProcedure", "Lipotransferencia");
              }}
              style={{
                borderColor: procedure === "Lipotransferencia" ? "#004274" : "",
                color: procedure === "Lipotransferencia" ? "#004274" : "",
              }}
            >
              Lipotransferencia
            </span>
            <span
              onClick={() => {
                setProcedure("Rinoplastia");
                localStorage.setItem("BooProcedure", "Rinoplastia");
              }}
              style={{
                borderColor: procedure === "Rinoplastia" ? "#004274" : "",
                color: procedure === "Rinoplastia" ? "#004274" : "",
              }}
            >
              Rinoplastia
            </span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SelectProcedures;
