// SearchBuildPackage.jsx
import React from "react";
import InputsFlights from "./components/InputsFlights";
import InputsHotel from "./components/InputsHotel";
import InputsTrasnlates from "./components/InputsTrasnlates";
import InputCalendar from "./components/SingleInputs/InputCalendar";
import InputSearchProcedures from "./components/SingleInputs/InputSearchProcedures";
import InputSearchCityHotel from "./components/SingleInputs/InputSearchCityHotel";
import InputSearchEspecialties from "./components/SingleInputs/InputSearchEspecialties";
import styles from "./styles.module.css";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import ModalContainer from "@/ui/views/share/components/modalContainer";
import useSearchBuildPackage from "@/Hooks/BuildPackage/useBuildPackageSearchEngine/UseBuildPackageSearchEngine";
import InputTypeTravelFlight from "./components/SingleInputs/InputTypeTravelFlight";

import { FaRegCircle } from "react-icons/fa6";
import { FaRegDotCircle } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdErrorOutline } from "react-icons/md";

import "./styles.css";

const SearchBuildPackage = () => {
  const {
    activeIndex,
    setActiveIndex,
    showCalendar,
    setShowCalendar,
    checkDate,
    setCheckDate,
    showSearchCityHotel,
    setShowSearchCityHotel,
    especialtie,
    setEspecialtie,
    showSpecialties,
    setShowSpecialties,
    checkSpeciality,
    setCheckSpeciality,
    idSpecialty,
    setIdSpecialty,
    procedure,
    setProcedure,
    showProcedures,
    setShowProcedures,
    checkProcedure,
    setCheckProcedure,
    daysProcedure,
    setDaysProcedure,
    modalQuestion,
    setModalQuestion,
    continuePackage,
    setContinuePackage,
    isIframe,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    searcherFlight,
    isCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,
    isCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    setDateGoingSelectedApi,
    setDateReturnSelectedApi,
    setIsManagingPassengers,
    ellipsisText,
    validationSearchDestiny,
    validationSearchDate,
    builUrlFlight,
    cardRef,
    openHotelSearch,
    paceholders,
    search,
    setSearch,
    destiny,
    openHotelSearchFuct,
    destinationSelectionFunc,
    findCountryClient,
    nacionalityDropdown,
    setNacionalityDropdown,
    dataNacionality,
    selectedNacionality,
    nacionality,
    codeNacionality,
    loading,
    validationForm,
    buildUrlHotel,
    datesSelected,
    setDatesSelected,
    setTypeT,
    typeT,
    cityList,
    citySelected,
    setcitySelected,
    findOrigin,
    originList,
    originSelected,
    setOriginSelected,
    findDestiny,
    destinyList,
    destinySelected,
    setDestinySelected,
    setCityList,
    loadingCity,
    loadingDestiny,
    loadingOrigin,
    city,
    setCity,
    setOriginList,
    setDestinyList,
    cityVerify,
    setCityVerify,
    setDestinyVerify,
    setOriginVerify,
    hoursSe,
    setHoursSe,
    setCalendarVerify,
    findSpecialist,
    modalNoFound,
    setModalNoFound,
    isEmptyObject,
    newSearch,
    continueBuy,
    findBuildPack,
    dataPackage,
    loadingLastSearch
  } = useSearchBuildPackage();

  const packages = [
    "Especialista + Clínica + Hotel",
    "Especialista + Clínica + Vuelo",
    "Especialista + Clínica + Vuelo + Hotel",
  ];

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <ModalContainer
        hiddsetActiveChangeServiceen={setModalNoFound}
        show={modalNoFound}
        sizeHeight={52}
        width={450}
      >
        <div className={styles.modalNoFound}>
          <div className={styles.modalNoFoundIcon}>
            <MdErrorOutline />
          </div>
          <h3>Lo sentimos</h3>
          <p>
            No hemos encontrado especialistas para el procedimiento que buscas.
            Te invitamos a probar con otra búsqueda o explorar otras opciones.
          </p>
        </div>
      </ModalContainer>

      {
        !loadingLastSearch ? (
          <ModalContainer
            show={modalQuestion}
            hiddsetActiveChangeServiceen={setModalQuestion}
            sizeHeight={52}
            width={500}
          >
            <div>
              <div className={styles.modalQuestion}>
                <p>¿Deseas continuar con tu compra?</p>

                <div className='content-all-info-detail-transport' style={{ margin: "1rem 0" }}>
                  <h2>Detalle de la compra</h2>
                  <div className='line-separation-transport'></div>
                  <div className='info-detail-transport'>
                    {
                      dataPackage.specialist ? (
                        <div className="camp-detail">
                          <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconDoctor} size={20} />Doctor</h3>
                          <span>{dataPackage.specialist.name}</span>
                        </div>
                      ) : null
                    }
                    {
                      dataPackage.clinic ? (
                        <div className="camp-detail">
                          <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconHospital} size={20} />Clinica</h3>
                          <span>{dataPackage.clinic.name}</span>
                        </div>
                      ) : null
                    }
                    {
                      dataPackage.flight ? (
                        <div className="camp-detail">
                          <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconPlane2} size={20} />Vuelo</h3>
                          <span>{dataPackage.flight.itinerary[0].from} - {dataPackage.flight.itinerary[0].to}</span>
                        </div>
                      ) : null
                    }
                    {
                      dataPackage.hotel ? (
                        <div className="camp-detail">
                          <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconHotel} size={20} />Hotel</h3>
                          <span>{dataPackage.hotel.dataHot.nombre_h}</span>
                        </div>
                      ) : null
                    }
                  </div>
                  <div className='line-separation-transport'></div>
                  <div className='note-trans'>
                    <p className={styles.note}>
                      Podría haber cargos adicionales al pagar tu paquete. De ser así, te los informaremos en detalle antes de finalizar tu pedido.
                    </p>
                  </div>
                </div>
                <div className="container-btns-continue-buy">
                  <button onClick={() => {
                    continueBuy();
                  }}>Continuar paquete</button>
                  <button onClick={() => newSearch()}>Nueva busqueda</button>
                </div>
              </div>
            </div>

          </ModalContainer>
        ) : null
      }

      <div className="searchBuildPackage">
        <div className="searchBuildPackageContent">
          <div className="searchBuildPackageContentOptions">
            {packages.map((packageOption, index) => (
              <button
                key={index}
                className={`searchBuildPackageContentOption ${activeIndex === index ? "active" : ""
                  }`}
                onClick={() => handleButtonClick(index)}
              >
                {activeIndex === index ? (
                  <FaRegDotCircle size={15} />
                ) : (
                  <FaRegCircle size={15} />
                )}
                {packageOption}
              </button>
            ))}
          </div>

          <div className="searchBuildPackageContentInputsButton">
            <div className="searchBuildPackageContentSections">
              <div className="searchBuildPackageContentInputs">
                <InputSearchEspecialties
                  showSpecialties={showSpecialties}
                  setShowSpecialties={setShowSpecialties}
                  especialtie={especialtie}
                  setEspecialtie={setEspecialtie}
                  checkSpeciality={checkSpeciality}
                  setIdSpecialty={setIdSpecialty}
                  setProcedure={setProcedure}
                />

                <InputSearchProcedures
                  showProcedures={showProcedures}
                  setShowProcedures={setShowProcedures}
                  procedure={procedure}
                  setProcedure={setProcedure}
                  checkProcedure={checkProcedure}
                  idSpecialty={idSpecialty}
                  setDaysProcedure={setDaysProcedure}
                  setDateGoingSelected={setDateGoingSelected}
                  setDateReturnSelected={setDateReturnSelected}
                />
                {
                  activeIndex == 0 &&
                  <InputCalendar
                    showCalendar={showCalendar}
                    setShowCalendar={setShowCalendar}
                    oneWay={isRoundTrip}
                    setOneWay={setIsRoundTrip}
                    isRoundTrip={
                      activeIndex === 0 
                        ? true
                        : isRoundTrip
                    }
                    setIsRoundTrip={setIsRoundTrip}
                    dateSelectedHotel={datesSelected}
                    setDateSelectedHotel={setDatesSelected}
                    cardRefDate={cardRefDate}
                    setDateGoingSelected={setDateGoingSelected}
                    setDateGoingSelectedApi={setDateGoingSelectedApi}
                    setDateReturnSelected={setDateReturnSelected}
                    setDateReturnSelectedApi={setDateReturnSelectedApi}
                    searcherFlight={searcherFlight}
                    checkDate={checkDate}
                    setCheckDate={setCheckDate}
                    dateGoingSelected={dateGoingSelected}
                    dateSreturnSelected={dateReturnSelected}
                    accompanied={false}
                    setIsManagingPassengers={setIsManagingPassengers}
                    validationForm={validationForm}
                    activeIndex={activeIndex}
                    daysProcedure={daysProcedure}
                  />
                }

                {/* {
                  activeIndex == 1 &&

                  <InputTypeTravelFlight
                    isRoundTrip={isRoundTrip}
                    setIsRoundTrip={setIsRoundTrip}
                    checkDate={checkDate}
                    setCheckDate={setCheckDate}
                  />
                } */}
               
                <InputSearchCityHotel
                  showSearchCityHotel={showSearchCityHotel}
                  setShowSearchCityHotel={setShowSearchCityHotel}
                  paceholders={paceholders}
                  validationForm={validationForm}
                  cardRef={cardRef}
                  search={search}
                  setSearch={setSearch}
                  destiny={destiny}
                  destinationSelectionFunc={destinationSelectionFunc}
                  openHotelSearchFuct={openHotelSearchFuct}
                  openHotelSearch={openHotelSearch}
                  activeIndex={activeIndex}
                  getSelectDestination={getSelectedDestination}
                />

                {activeIndex === 0 || activeIndex === 2 || activeIndex === 3 ? (
                  <div className="w-full">
                    <InputsHotel
                      paceholders={paceholders}
                      validationForm={validationForm}
                      cardRef={cardRef}
                      search={search}
                      setSearch={setSearch}
                      destiny={destiny}
                      destinationSelectionFunc={destinationSelectionFunc}
                      openHotelSearchFuct={openHotelSearchFuct}
                      openHotelSearch={openHotelSearch}
                      nacionalityDropdown={nacionalityDropdown}
                      setNacionalityDropdown={setNacionalityDropdown}
                      nacionality={nacionality}
                      codeNacionality={codeNacionality}
                      findCountryClient={findCountryClient}
                      loading={loading}
                      dataNacionality={dataNacionality}
                      selectedNacionality={selectedNacionality}
                    />
                  </div>
                ) : null}
              </div>
              {activeIndex === 1 || activeIndex === 2 || activeIndex === 3 ? (
                <div className="searchBuildPackageContentInputs">
                  <InputsFlights
                    isRoundTrip={isRoundTrip}
                    setIsRoundTrip={setIsRoundTrip}
                    checkDate={checkDate}
                    setCheckDate={setCheckDate}
                    getSelectedOrigin={getSelectedOrigin}
                    isCardRefOrigin={isCardRefOrigin}
                    selectedOrigin={selectedOrigin}
                    selectedDestination={selectedDestination}
                    ellipsisText={ellipsisText}
                    validationSearchDestiny={validationSearchDestiny}
                    isCardRefDestination={isCardRefDestination}
                    getSelectedDestination={getSelectedDestination}
                    activeIndex={activeIndex}
                    inputCalendarComponent={
                      <InputCalendar
                        showCalendar={showCalendar}
                        setShowCalendar={setShowCalendar}
                        oneWay={isRoundTrip}
                        setOneWay={setIsRoundTrip}
                        isRoundTrip={ isRoundTrip}
                        setIsRoundTrip={setIsRoundTrip}
                        dateSelectedHotel={datesSelected}
                        setDateSelectedHotel={setDatesSelected}
                        cardRefDate={cardRefDate}
                        setDateGoingSelected={setDateGoingSelected}
                        setDateGoingSelectedApi={setDateGoingSelectedApi}
                        setDateReturnSelected={setDateReturnSelected}
                        setDateReturnSelectedApi={setDateReturnSelectedApi}
                        searcherFlight={searcherFlight}
                        checkDate={checkDate}
                        setCheckDate={setCheckDate}
                        dateGoingSelected={dateGoingSelected}
                        dateSreturnSelected={dateReturnSelected}
                        accompanied={false}
                        setIsManagingPassengers={setIsManagingPassengers}
                        validationForm={validationForm}
                        activeIndex={activeIndex}
                        daysProcedure={daysProcedure}
                      />
                    }
                  />
                </div>
              ) : null}
              {activeIndex === 3 ? (
                <div className="searchBuildPackageContentInputs">
                  <InputsTrasnlates
                    typeT={typeT}
                    setTypeT={setTypeT}
                    city={city}
                    setCity={setCity}
                    loadingCity={loadingCity}
                    cityVerify={cityVerify}
                    citySelected={citySelected}
                    setOriginSelected={setOriginSelected}
                    setDestinySelected={setDestinySelected}
                    cityList={cityList}
                    setCityList={setCityList}
                    setCityVerify={setCityVerify}
                    setcitySelected={setcitySelected}
                    findOrigin={findOrigin}
                    loadingOrigin={loadingOrigin}
                    originList={originList}
                    setOriginList={setOriginList}
                    setOriginVerify={setOriginVerify}
                    originSelected={originSelected}
                    destinyList={destinyList}
                    findDestiny={findDestiny}
                    destinySelected={destinySelected}
                    loadingDestiny={loadingDestiny}
                    setDestinyList={setDestinyList}
                    setDestinyVerify={setDestinyVerify}
                    hoursSe={hoursSe}
                    setHoursSe={setHoursSe}
                    setCalendarVerify={setCalendarVerify}
                    dateGoingSelected={dateGoingSelected}
                  />
                </div>
              ) : null}
            </div>
            <button
              className="searchBuildPackageContentButtonSearch"
              onClick={findBuildPack}
            >
              <FiSearch size={30} />
              Buscar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBuildPackage;
