const shoppingCart = [
    // Vuelos
    {
      type: 'vuelo',
      icon: '✈️',
      title: 'Vuelo a Nueva York',
      description: 'Vuelo directo a Nueva York desde Los Ángeles',
      longDescription: 'Disfruta de un vuelo cómodo y rápido a la Gran Manzana con nuestra aerolínea premium. Incluye servicios a bordo y entretenimiento.',
      baggage: ['1', '2'],
      price: 350000,
      date: '2024-09-10',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'vuelo',
      icon: '✈️',
      title: 'Vuelo a Tokio',
      description: 'Vuelo económico a Tokio desde San Francisco',
      longDescription: 'Vuela a Tokio con tarifas accesibles y servicios a bordo. Incluye opciones de comidas asiáticas y entretenimiento en tu idioma.',
      baggage: ['1', '2', '3'],
      price: 800000,
      date: '2024-09-18',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'vuelo',
      icon: '✈️',
      title: 'Vuelo a Sídney',
      description: 'Vuelo a Sídney con escala en Los Ángeles',
      longDescription: 'Viaja a Sídney con una cómoda escala en Los Ángeles. Aprovecha los servicios de a bordo y las opciones de entretenimiento durante el vuelo.',
      baggage: ['1', '2', '3'],
      price: 1200000,
      date: '2024-09-25',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'vuelo',
      icon: '✈️',
      title: 'Vuelo a Madrid',
      description: 'Vuelo directo a Madrid desde Nueva York',
      longDescription: 'Disfruta de un vuelo directo a Madrid con nuestra aerolínea premium. Incluye comida y bebidas durante el vuelo.',
      baggage: ['1', '2', '3'],
      price: 7000000,
      date: '2024-09-30',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'vuelo',
      icon: '✈️',
      title: 'Vuelo a Londres',
      description: 'Vuelo directo a Londres desde Toronto',
      longDescription: 'Vuela a Londres cómodamente con nuestra aerolínea y disfruta de entretenimiento a bordo y comidas incluidas.',
      baggage: ['1', '2', '3'],
      price: 650000,
      date: '2024-10-05',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'vuelo',
      icon: '✈️',
      title: 'Vuelo a Buenos Aires',
      description: 'Vuelo directo a Buenos Aires desde Miami',
      longDescription: 'Disfruta de un vuelo sin escalas a Buenos Aires con servicios completos a bordo, incluyendo comidas y entretenimiento.',
      baggage: ['1', '2', '3'],
      price: 550000,
      date: '2024-10-10',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    // Hoteles
    {
      type: 'hotel',
      icon: '🏨',
      title: 'Hotel en París',
      description: 'Hotel de lujo en el centro de París',
      longDescription: 'Hospédate en un hotel de 5 estrellas con todas las comodidades en el corazón de París. Incluye desayuno y acceso al spa.',
      price: 1200000,
      date: '2024-09-15',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'hotel',
      icon: '🏨',
      title: 'Hotel en Roma',
      description: 'Alojamiento en el centro histórico de Roma',
      longDescription: 'Disfruta de una estancia en un hotel encantador con vista al Coliseo y acceso a los principales monumentos de Roma.',
      price: 700000,
      date: '2024-09-20',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'hotel',
      icon: '🏨',
      title: 'Hotel en Londres',
      description: 'Alojamiento cerca del Big Ben en Londres',
      longDescription: 'Hospédate cerca del Big Ben y el Palacio de Westminster en un hotel moderno con desayuno incluido y acceso a wifi.',
      price: 9500000,
      date: '2024-09-28',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'hotel',
      icon: '🏨',
      title: 'Hotel en Berlín',
      description: 'Estancia en un hotel boutique en Berlín',
      longDescription: 'Disfruta de una estancia en un hotel boutique con un diseño único y servicios exclusivos en Berlín.',
      price: 600000,
      date: '2024-10-02',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'hotel',
      icon: '🏨',
      title: 'Hotel en Barcelona',
      description: 'Hotel cerca de la Sagrada Familia en Barcelona',
      longDescription: 'Hospédate en un moderno hotel con vista a la Sagrada Familia y disfruta de la vibrante vida de la ciudad condal.',
      price: 850000,
      date: '2024-10-08',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'hotel',
      icon: '🏨',
      title: 'Hotel en Ámsterdam',
      description: 'Alojamiento en un canal en Ámsterdam',
      longDescription: 'Disfruta de una estancia en un pintoresco hotel a lo largo de uno de los icónicos canales de Ámsterdam.',
      price: 780000,
      date: '2024-10-12',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'hotel',
      icon: '🏨',
      title: 'Hotel en Dubái',
      description: 'Hotel con vistas al Burj Khalifa en Dubái',
      longDescription: 'Hospédate en un lujoso hotel con impresionantes vistas al Burj Khalifa y disfruta del lujo que Dubái ofrece.',
      price: 1500000,
      date: '2024-10-15',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'hotel',
      icon: '🏨',
      title: 'Hotel en Tokio',
      description: 'Alojamiento moderno en Shibuya, Tokio',
      longDescription: 'Disfruta de una estancia en un hotel moderno y bien ubicado en el vibrante distrito de Shibuya en Tokio.',
      price: 1000000,
      date: '2024-10-20',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    // Traslados
    {
      type: 'traslado',
      icon: '🚗',
      title: 'Traslado aeropuerto - hotel',
      description: 'Traslado privado del aeropuerto al hotel en SUV',
      longDescription: 'Viaja con comodidad y estilo en un traslado privado desde el aeropuerto hasta tu hotel en un vehículo SUV de lujo.',
      price: 80000,
      date: '2024-09-10',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'traslado',
      icon: '🚗',
      title: 'Traslado aeropuerto - centro',
      description: 'Traslado en minivan desde el aeropuerto al centro de la ciudad',
      longDescription: 'Llega al centro de la ciudad de manera rápida y cómoda en una minivan con aire acondicionado y espacio para equipaje.',
      price: 600000,
      date: '2024-09-20',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'traslado',
      icon: '🚗',
      title: 'Traslado hotel - puerto',
      description: 'Traslado privado desde tu hotel al puerto',
      longDescription: 'Llega al puerto con comodidad en un traslado privado desde tu hotel, ideal para viajeros con crucero reservado.',
      price: 500000,
      date: '2024-09-28',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'traslado',
      icon: '🚗',
      title: 'Traslado aeropuerto - casa',
      description: 'Traslado compartido desde el aeropuerto a tu casa',
      longDescription: 'Disfruta de un traslado compartido con otros pasajeros desde el aeropuerto hasta la puerta de tu casa.',
      price: 400000,
      date: '2024-10-02',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'traslado',
      icon: '🚗',
      title: 'Traslado a atracción',
      description: 'Traslado privado a una atracción turística',
      longDescription: 'Llega con estilo y comodidad a tu atracción turística favorita en un vehículo privado con aire acondicionado.',
      price: 7000000,
      date: '2024-10-08',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'traslado',
      icon: '🚗',
      title: 'Traslado a estación de tren',
      description: 'Traslado rápido y cómodo a la estación de tren',
      longDescription: 'Evita las molestias del transporte público con un traslado directo y rápido a la estación de tren.',
      price: 5500000,
      date: '2024-10-12',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'traslado',
      icon: '🚗',
      title: 'Traslado desde la estación de tren',
      description: 'Traslado privado desde la estación de tren a tu destino',
      longDescription: 'Relájate con un traslado privado desde la estación de tren hasta tu destino final en la ciudad.',
      price: 65000000,
      date: '2024-10-15',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'traslado',
      icon: '🚗',
      title: 'Traslado de ciudad a ciudad',
      description: 'Traslado interurbano privado',
      longDescription: 'Viaja cómodamente de una ciudad a otra con un traslado privado en un vehículo de lujo con aire acondicionado.',
      price: 1500000,
      date: '2024-10-20',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    // Más compras adicionales
    {
      type: 'vuelo',
      icon: '✈️',
      title: 'Vuelo a París',
      description: 'Vuelo directo a París desde Los Ángeles',
      longDescription: 'Vuela a París sin escalas y disfruta de los mejores servicios a bordo con comida y entretenimiento.',
      baggage: ['1', '2', '3'],
      price: 1000000,
      date: '2024-11-01',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'hotel',
      icon: '🏨',
      title: 'Hotel en Las Vegas',
      description: 'Alojamiento en el Strip de Las Vegas',
      longDescription: 'Hospédate en el corazón de Las Vegas con todas las comodidades y servicios exclusivos para una experiencia inolvidable.',
      price: 9000000,
      date: '2024-11-05',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    },
    {
      type: 'traslado',
      icon: '🚗',
      title: 'Traslado VIP al aeropuerto',
      description: 'Servicio de traslado VIP al aeropuerto en limusina',
      longDescription: 'Llega al aeropuerto con estilo en un traslado VIP en limusina, con bebidas incluidas y un conductor profesional.',
      price: 20000000,
      date: '2024-11-10',
      viewDetailsButton: 'Ver más detalles',
      payButton: 'Ir a pagar',
    }
  ];
  

export default shoppingCart;
