import * as React from "react";
import styles from "./index.module.css";
import { Typography } from "@mui/material";

export default function Home() {
  return (
    <div className={styles.documentationContainer}>
      <Typography variant="h1" gutterBottom fontWeight="bold">
        Documentación de Componentes
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{ textAlign: "justify" }}
      >
        ¡Bienvenido a la documentación de componentes de Wellezy! Aquí encontrarás toda la información que necesitas para utilizar nuestros componentes web de manera efectiva. Cada sección está diseñada para guiarte en la integración de los elementos, con ejemplos prácticos y recomendaciones detalladas. Navega por las guías para comprender mejor cómo implementar cada componente dentro de la página de Wellezy.
      </Typography>
    </div>
  );
}
