import { useState } from 'react';
function useTeleConsultation(code) {
  const [codeValue, setCodeValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  if (code && code !== '' && codeValue === '' && codeValue !== code) {
    setCodeValue(code)
  }


  function getNewCode(event) {
    const value = event.target.value;
    setCodeValue(value)
  }

  async function initTeleConsultation({ token }) {
    try {
    } catch (error) {
      console.log("Error in initTeleConsultation(): ", error)
    }
    
    }
    
    return {
    codeValue,
    getNewCode,
    initTeleConsultation,
    isLoading,
    }
    }
    
    export default useTeleConsultation;
