import React, { useEffect, useState } from "react";

import { IoMdArrowDropdown } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.css";
import Tooltip from "@/Components/Tooltip/Tooltip";

const StepStep = ({ id, title, onClick, step }) => {
  const navigate = useNavigate();

  const [active, setActive] = useState(false);

  const currentStep = useSelector((state) => state.dataStepz.currentStep);
  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const routes = useSelector((state) => state.dataStepz.routes);
  const setSpetVariableData = useSelector(
    (state) => state.dataSearchEngine.spetVariableData
  );
  
  const changeStep = (step) => {
    if (step === 1 && routes.flights) {
      navigate(`${routes.flights.route}`);

    } else if (step === 1 && routes.hotels) {
      navigate(`${routes.hotels.route}`);

    } else if (step === 2 && routes.hotels && setSpetVariableData === 2) {
      navigate(`${routes.hotels.route}`);

    } else if ( step === 2 && routes.specialist) {
      navigate(`${routes.specialist.route}${routes.specialist.search}`);

    } else if (step === 3 && routes.specialist && setSpetVariableData === 2) {
      navigate(`${routes.specialist.route}${routes.specialist.search}`);

    } else if (step === 3 && routes.clinics) {
      navigate(`${routes.clinics.route}${routes.clinics.search}`);

    } else if(step === 4 && routes.clinics && setSpetVariableData === 2) {
      navigate(`${routes.clinics.route}${routes.clinics.search}`);

    } else if (step === 4 && routes.summary) {
      navigate(`${routes.summary.route}`);

    } else if (step === 5 && routes.summary && setSpetVariableData === 2) {
      navigate(`${routes.summary.route}`);

    } else if ((step === 5 || step === 6) && routes.std) {
      navigate(`${routes.std.route}`);
      
    }
  };

  useEffect(() => {
    if (currentStep === step) {
      setActive(true);
    }
  }, []);

  const isCompleted = currentStep > step;

  return (
    <div className={styles.stepToStep}>
      <div
        className={`${styles.itemSection} ${active ? styles.active : ""} ${
          isCompleted ? styles.completed : ""
        }`}
        onClick={() => changeStep(step)}
      >
        <h2 className={styles.itemText}>{step}</h2>
        <div className={styles.itemNumberTitle}>
          <h3 className={styles.itemText}>{title}</h3>
        </div>
      </div>
    </div>
  );
};

export default StepStep;
