import React, { useEffect, useRef, useState } from 'react';
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setCityTranslateData } from '@/store/slices/dataEnginePackage/dataEnginePackageSlice';

const InputSearchCityTranslate = ({
    setCity,
    loadingCity,
    cityVerify,
    citySelected,
    setOriginSelected,
    setDestinySelected,
    cityList,
    setCityList,
    setCityVerify,
    setcitySelected
}) => {
    // Variables
    const dispatch = useDispatch();

    const inputCityRef = useRef(null);
    const [t] = useTranslation("global");

    const [focusCity, setFocusCity] = useState(false);
    const [showCardCities, setShowCardCities] = useState(false);

    const cityTranslateData = useSelector(state => state.dataSearchEngine.cityTranslateData);

    //UseEffects
    useEffect(() => {
        setcitySelected(cityTranslateData);
    }, []);

    useEffect(() => {
        if (focusCity) {
            inputCityRef.current.focus();
        }
    }, [focusCity]);

    useEffect(() => {
        if (cityList.length > 0) {
            setShowCardCities(true);
        }else{
            setShowCardCities(false);
        }
    }, [cityList]);

    useEffect(() => {
        dispatch(setCityTranslateData(citySelected));
    }, [citySelected]);

    return(
        <div className="campo-t">
            <div
                className="input-pass campo-translate-search-build-package"
                style={{
                borderColor: cityVerify && "var(--redPrimary)",
                }}
            >
                <div style={{ width: "100%" }}>
                <p className='font-style-p-search-build-package'>
                    {t("transfers.city")}:
                </p>
                <div
                    className="input-cat"
                    style={{ background: "none" }}
                >
                    {focusCity === false ? (
                    <p
                        onClick={() => {
                        setFocusCity(true);
                        setOriginSelected("");
                        setDestinySelected("");
                        localStorage.removeItem("originTransport");
                        localStorage.removeItem("destinyTransport");
                        }}
                        style={{
                            textWrap: "nowrap",
                            overflow: "hidden",
                            marginRight: "28px",
                            color: cityVerify && "var(--greySecundary)",
                            fontWeight: 500,
                        }}
                    >
                        {citySelected.name
                        ? citySelected?.name
                        : citySelected}
                    </p>
                    ) : (
                    <div className="contend-input-loader">
                        <input
                        ref={inputCityRef}
                        className="input-dropdown"
                        onFocus={() => setFocusCity(true)}
                        onBlur={() => setFocusCity(false)}
                        onChange={(e) => {
                            setCity(e.target.value);
                        }}
                        type="text"
                        placeholder={t("transfers.city")}
                        />
                        {loadingCity && (
                        <CircularProgress
                            size={30}
                            style={{
                            right: "0",
                            position: "absolute",
                            top: "-17px",
                            }}
                        />
                        )}
                    </div>
                    )}
                </div>
                {!focusCity && (
                    <div
                    style={{
                        position: "absolute",
                        right: "8px",
                        top: "13px",
                    }}
                    >
                    <SvgIcon
                        name={iconList.IconPinMap2}
                        color={"var(--bluePrimary)"}
                        size={30}
                    />
                    </div>
                )}
                </div>
            </div>
            {
                cityList?.length > 0 && (
                    <div className="dropdown-ciudad shadow">
                        <ul className="contentElemetsSearchInputBuildPackage">
                            {cityList?.map((item) => (
                                <li
                                    onClick={() => {
                                    setcitySelected(item);
                                    localStorage.setItem(
                                        "cityTransport",
                                        JSON.stringify(item)
                                    );
                                    setCityList([]);
                                    setCityVerify(false);
                                    }}
                                    key={item.id}
                                >
                                    {item.name}
                                </li>
                                )
                            )}
                        </ul>
                    </div>
                )
            }
        </div>
    );
};

export default InputSearchCityTranslate;