import "../../styles.css";
import "./stylesStep3.css";
import { currencyFormat } from "../../../../../utils";
import { useTranslation } from "react-i18next";
import TermAndConditions from "../../../../share/components/term&conditions/TermAndConditions";
import connectMiles from "../../../../../assets/logos/connectmiles.png";
import Loading from "../../../../share/components/loading/index";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import CarouselSevices from "../../../../share/components/CarouselServices/CarouselSevices";
import ReturnPage from "../../../../share/components/return/ReturnPage";
import AnyQuestions from "../../../../home/components/anyQuestion";

function Step3() {
  // const {
  //   step,
  //   setStep,
  //   stepList,
  //   paymentCardSelected,
  //   setPaymentCardSelected,
  //   generateTicketData,
  //   showModalTicket,
  //   reset,
  //   message
  // } = usePassengerInformationFormStep3(
  //   userDetail,
  //   userToken,
  //   location,
  //   PNR,
  //   dataSavePNR
  // );
  const { state } = useLocation();
  const porcentaje = 0.05;
  const subTotal = state?.priceWithOutTaxes + state?.priceTaxes;
  const otrosCargos = subTotal * porcentaje;
  const total = subTotal + otrosCargos; // location?.priceTotal
  const [t] = useTranslation("global");
  const [loadingLink, setLoadingLink] = useState(false);

  function getBaseUrl(url) {
    const parsedUrl = new URL(url);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
    return baseUrl;
  }
const typeOfCoin = { name: state?.currency, abbreviation: state?.currency , simbol: '$' };


  const createLinkPay = () => {
    const dataNew = {
      localizator: state?.controlNumberPNR,
      type: "Amadeus",
      price: total,
      url: `${getBaseUrl(window.location.href)}/flight/flightsPay`,
      currency:state?.currency
    };
    const url = process.env.REACT_APP_URL_CREATE_LINK;
    setLoadingLink(true);
    axios
      .post(url, dataNew)
      .then((res) => {
        localStorage.setItem("data-pay-flight", JSON.stringify(state));
        localStorage.setItem("request-id-pay", res.data?.requestId);
        window.location.href = res.data?.processUrl;
      })
      .catch((err) => {
        console.log(err);
        setLoadingLink(false);
      });
  };

  const questtions = [
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
  ];

  const [confirm, setConfirm] = useState(false);

  return (
    <>
      <section className="section-method-pay">
        {loadingLink && <Loading size={80} />}

        <CarouselSevices />
        <ReturnPage />

        <div className="section-method-one">
          <div className="method-one">
            <div className="group-pay-method">
              <div className="pay-method">
                <img
                  src="https://static.placetopay.com/placetopay-logo.svg"
                  alt="Logo"
                />
              </div>
              <TermAndConditions questions={questtions}>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    color: "#3e3e3e",
                  }}
                >
                  <h3
                    style={{ color: "#104172", fontSize: 18, fontWeight: 600 }}
                  >
                    1. Introducción
                  </h3>
                  content: "Bienvenido a nuestro servicio. Estos términos y
                  condiciones describen las reglas y regulaciones para el uso
                  del sitio web. Al acceder a este sitio web, asumimos que
                  aceptas estos términos y condiciones en su totalidad. No
                  continúes usando nuestro sitio web si no aceptas todos los
                  términos y condiciones establecidos en esta página."
                  <h3
                    style={{ color: "#104172", fontSize: 18, fontWeight: 600 }}
                  >
                    2. Licencia
                  </h3>
                  A menos que se indique lo contrario, los propietarios de este
                  sitio web y sus licenciantes son propietarios de los derechos
                  de propiedad intelectual de todo el contenido en el sitio web.
                  Todos los derechos de propiedad intelectual están reservados.
                  <h3
                    style={{ color: "#104172", fontSize: 18, fontWeight: 600 }}
                  >
                    3. Restricciones
                  </h3>
                  Estás específicamente restringido de todo lo siguiente:
                  Publicar cualquier material del sitio web en cualquier otro
                  medio; vender, sublicenciar y / o comercializar cualquier
                  material del sitio web; usar este sitio web de cualquier
                  manera que tenga o pueda tener un efecto negativo en el
                  rendimiento del sitio web; utilizar este sitio web en contra
                  de las leyes y regulaciones aplicables, o de cualquier manera
                  que pueda causar daño al sitio web o a cualquier persona o
                  entidad.
                </p>
              </TermAndConditions>
            </div>
          </div>

          <div className="method-two">
            <div className="method-two-title">
              <h3>Información de pago</h3>
            </div>

            <div className="method-two-info">
              <div className="method-two-info-item">
                <h4>{t("fligth.form_passenger.step3.sub_total")}:</h4>
                <span>$ {currencyFormat(subTotal, typeOfCoin)?.total}</span>
              </div>

              <div className="method-two-info-item">
                <h4> {t("fligth.form_passenger.step3.other_taxes")}:</h4>
                <span>$ {currencyFormat(otrosCargos, typeOfCoin)?.total}</span>
              </div>

              <div className="method-two-info-item">
                <h4>{t("fligth.form_passenger.step3.total")}:</h4>
                <span>$ {currencyFormat(total, typeOfCoin)?.total}</span>
              </div>
            </div>

            <div className="method-two-buttons">
              <div className="method-two-confirm-label">
                <input
                  type="checkbox"
                  class="ui-checkbox"
                  name="confirm"
                  id="confirm"
                  onChange={() => setConfirm(!confirm)}
                />
                <label htmlFor="confirm">Confirmar compra</label>
              </div>

              <button
                className="method-two-pay"
                style={{
                  backgroundColor: !confirm ? "#10417286" : null,
                  pointerEvents: !confirm ? "none" : null,
                }}
                onClick={() => createLinkPay()}
              >
                Pagar ahora
              </button>
            </div>
          </div>
        </div>

        <div className="connect-miles">
          <div className="connect-miles-img">
            <img src={connectMiles} alt="connectMiles" />
          </div>
          <p>
            By paying with your ConnectMiles credit card, you will earn more
            miles on you purchase
          </p>
        </div>
      </section>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </>
  );
}

export default Step3;
