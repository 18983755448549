import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import useMyMovements from "../../../../../viewModels/profile/MyMovements/useMyMovements.viewModel";
import styles from "./index.module.css";
import { useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

function MyMovements({ code }) {
  const { codeValue, getNewCode, initMyMovements, isLoading } =
    useMyMovements(code);

  const initRoomAvailable = true;

  const movements = Array.from({ length: 100 }, (_, index) => ({
    date: `0${(index % 30) + 1} de Abril 2024`,
    description: `Movimiento ${index + 1}, Descripción del movimiento ${
      index + 1
    }`,
    amount: Math.floor(Math.random() * 1000000),
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calcula el índice de inicio y fin para la paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMovements = movements.slice(indexOfFirstItem, indexOfLastItem);

  const nextHandler = () => {
    if (currentPage < Math.ceil(movements.length / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  return (
    <div className={styles.myMovements}>
      <h1>Movimientos</h1>

      <div className={styles.movementItems}>
        {currentMovements.length > 0 ? (
          currentMovements.map((movement, index) => (
            <div key={index} className={styles.movementItem}>
              <div className={styles.details}>
                {movement.date}
                <p>{movement.description}</p>
              </div>
              <h3 className={styles.amount}>
                {formatPriceCOP(movement.amount)}
              </h3>
            </div>
          ))
        ) : (
          <div className={styles.noItems}>
            <div className={styles.message}>
              No hay movimientos
              <AiOutlineExclamationCircle
                size={20}
                color="var(--bluePrimary)"
              />
            </div>

            <Link to="/" className={styles.buy}>
              Ir a comprar
            </Link>
          </div>
        )}
      </div>

      {currentMovements.length > 0 && (
        <Paginado
          nextHandler={nextHandler}
          prevHandler={prevHandler}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={movements}
          itemsPerPage={itemsPerPage}
        />
      )}
    </div>
  );
}

export default MyMovements;
