import banner from "../../../assets/pictures/bannerTourismHealth.png";
import "./styles.css";
import { useTranslation } from "react-i18next";
import ActivityIndicator from "../../share/components/activityIndicator";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import { ArrowDown2, ArrowUp2, Heart } from "iconsax-react";
import useBrowseHome from "../../../viewModels/home/browse.home.viewModel";
import { CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import arrowDown from "./arrowDown.svg";
import { IoIosArrowDown } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import React, { useEffect, useRef, useState } from "react";

const SearchTourismHealth = () => {
  const {
    disableBtn,
    loading,
    tabOpen,
    cardRefProcedure,
    cardRefDestination,
    proceduresSelected,
    isOpenproceduresList,
    setIsOpenproceduresList,
    proceduresList,
    chooseProcedure,
    destinationsSelected,
    isOpenDestinationsList,
    setIsOpenDestinationsList,
    destinationsList,
    chooseDestination,
    searchForProceduresOnSite,
    goToWelezyShop,
    loadingSearch,
  } = useBrowseHome();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("global");

  const gotoOption = () => {
    navigate(`/TourismHealthPack/result?procedure=${proceduresSelected ? proceduresSelected : ""}&city=${destinationsSelected}`, {
      state: {
        procedure:proceduresSelected,
        city: destinationsSelected
      },
    });
  };

  const [procedureFilterText, setProcedureFilterText] = useState("");
  const [destinationFilterText, setDestinationFilterText] = useState("");
  const [isProcedureInputActive, setIsProcedureInputActive] = useState(false);
  const [isDestinationInputActive, setIsDestinationInputActive] =
    useState(false);

  const procedureInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const containerRef = useRef(null); // Para manejar clics fuera del componente

  // Filtra la lista de procedimientos en función del texto de entrada
  const filteredProcedures = proceduresList.filter((procedure) =>
    procedure?.name.toLowerCase().includes(procedureFilterText.toLowerCase())
  );

  // Filtra la lista de destinos en función del texto de entrada
  const filteredDestinations = destinationsList.filter((destination) =>
    destination?.name
      .toLowerCase()
      .includes(destinationFilterText.toLowerCase())
  );

  // Maneja la selección de un procedimiento
  const handleProcedureSelect = (procedure) => {
    chooseProcedure(procedure);
    setIsProcedureInputActive(false); // Oculta el campo de entrada
    setProcedureFilterText(""); // Restablece el texto del filtro
    setIsOpenproceduresList(false); // Cierra la lista de procedimientos
  };

  // Maneja la selección de un destino
  const handleDestinationSelect = (destination) => {
    chooseDestination(destination);
    setIsDestinationInputActive(false); // Oculta el campo de entrada
    setDestinationFilterText(""); // Restablece el texto del filtro
    setIsOpenDestinationsList(false); // Cierra la lista de destinos
  };

  // Maneja el clic en el texto para activar el campo de entrada
  const handleProcedureTextClick = (e) => {
    e.stopPropagation();
    setIsProcedureInputActive(true);
    setIsOpenproceduresList(true);
    setIsOpenDestinationsList(false);
  };

  // Maneja el clic en el texto para activar el campo de entrada de destinos
  const handleDestinationTextClick = (e) => {
    e.stopPropagation();
    setIsDestinationInputActive(true);
    setIsOpenDestinationsList(true);
    setIsOpenproceduresList(false);
  };

  // Maneja el clic fuera del componente y el foco del input
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpenproceduresList(false);
        setIsOpenDestinationsList(false);
        setIsProcedureInputActive(false);
        setIsDestinationInputActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Establece el foco en el campo de entrada cuando está activo
  useEffect(() => {
    if (isProcedureInputActive && procedureInputRef.current) {
      procedureInputRef.current.focus();
    }
    if (isDestinationInputActive && destinationInputRef.current) {
      destinationInputRef.current.focus();
    }
  }, [isProcedureInputActive, isDestinationInputActive]);

  return (
    <div className="browseHomeColumnSideLeftForm">
      <h2 className="browseHomeColumnSideLeftTitle">
        {t("browse_home.need_procedures")}
      </h2>
      <div className="browseHomeColumnSideLeftFormColumns" ref={containerRef}>
        {/* Primer bloque: Procedimientos */}
        <div
          className={`browseHomeColumnSideLeftFormColumnsGroup`}
          onClick={() => {
            setIsOpenDestinationsList(false);
            setIsOpenproceduresList(!isOpenproceduresList);
          }}
        >
          <div className="browseHomeColumnSideLeftFormColumnsText">
            <p className="browseHomeColumnSideLeftFormColumnsTextTitle">
              {t("browse_home.what_procedures_looking")}
            </p>
            {isProcedureInputActive ? (
              <input
                type="text"
                placeholder="Buscar procedimiento..."
                value={procedureFilterText}
                onChange={(e) => setProcedureFilterText(e.target.value)}
                className="filterInput"
                ref={procedureInputRef}
                onBlur={() => setIsProcedureInputActive(false)}
              />
            ) : (
              <p
                className="browseHomeColumnSideLeftFormColumnsTextValue"
                onClick={handleProcedureTextClick}
              >
                {proceduresSelected || t("browse_home.procedures")}
              </p>
            )}
          </div>
          {loading ? (
            <CircularProgress size={15} />
          ) : (
            <div className="browseHomeColumnSideLeftFormColumnsBtn">
              <div
                style={{
                  transition: "all 0.3s ease",
                  rotate: isOpenproceduresList ? "180deg" : "",
                }}
              >
                <IoIosArrowDown />
              </div>
            </div>
          )}

          {isOpenproceduresList && (
            <div className="browseHomeColumnSideLeftFormListContainer">
              {/* Lista de procedimientos filtrados */}
              {filteredProcedures.length > 0 ? (
                filteredProcedures.map((procedure, key) => (
                  <div
                    className="browseHomeColumnSideLeftFormListOption"
                    onClick={() => handleProcedureSelect(procedure)}
                    key={key}
                  >
                    <p className="browseHomeColumnSideLeftFormListOptionText">
                      {procedure?.name}
                    </p>
                  </div>
                ))
              ) : (
                <p className="noResults">No hay procedimientos que coincidan</p>
              )}
            </div>
          )}
        </div>

        {/* Segundo bloque: Destinos */}
        <div
          className={`browseHomeColumnSideLeftFormColumnsGroup`}
          onClick={() => {
            setIsOpenproceduresList(false);
            setIsOpenDestinationsList(!isOpenDestinationsList);
          }}
        >
          <div className="browseHomeColumnSideLeftFormColumnsText">
            <p className="browseHomeColumnSideLeftFormColumnsTextTitle">
              {t("browse_home.where")}
            </p>
            {isDestinationInputActive ? (
              <input
                type="text"
                placeholder="Buscar destino..."
                value={destinationFilterText}
                onChange={(e) => setDestinationFilterText(e.target.value)}
                className="filterInput"
                ref={destinationInputRef}
                onBlur={() => setIsDestinationInputActive(false)}
              />
            ) : (
              <p
                className="browseHomeColumnSideLeftFormColumnsTextValue"
                onClick={handleDestinationTextClick}
              >
                {destinationsSelected || "Cartagena, Barranquilla..."}
              </p>
            )}
          </div>
          {loading ? (
            <CircularProgress size={15} />
          ) : (
            <div className="browseHomeColumnSideLeftFormColumnsBtn">
              <div
                style={{
                  transition: "all 0.3s ease",
                  rotate: isOpenDestinationsList ? "180deg" : "",
                }}
              >
                <IoIosArrowDown />
              </div>
            </div>
          )}

          {isOpenDestinationsList && (
            <div
              className="browseHomeColumnSideLeftFormListContainer"
              style={{ top: 50 }}
            >
              {filteredDestinations.length > 0 ? (
                filteredDestinations.map((destination, key) => (
                  <div
                    className="browseHomeColumnSideLeftFormListOption"
                    onClick={() => handleDestinationSelect(destination)}
                    key={key}
                  >
                    <p className="browseHomeColumnSideLeftFormListOptionText">
                      {destination?.name}
                    </p>
                  </div>
                ))
              ) : (
                <p className="noResults">No hay destinos que coincidan</p>
              )}
            </div>
          )}
        </div>
      </div>

      {!(isOpenDestinationsList || isOpenproceduresList) && pathname !== "/" ? (
        <button
          style={{ opacity: loadingSearch ? 0.5 : 1 }}
          className={`${
            disableBtn
              ? "browseHomeColumnSideLeftFormBtnDisabled"
              : "browseHomeColumnSideLeftFormBtn"
          }`}
          onClick={() => !loadingSearch && gotoOption()}
          disabled={disableBtn}
        >
          {!loadingSearch && (
            <p className="browseHomeColumnSideLeftFormBtnText">
              {t("browse_home.search")}
            </p>
          )}
          <div className="browseHomeColumnSideLeftFormBtnIcon">
            {loadingSearch ? (
              <ActivityIndicator size={25} colorFill="#ffffff" />
            ) : (
              <CiSearch size={23} color="#fff" />
            )}
          </div>
        </button>
      ) : (
        <button
          style={{ opacity: loadingSearch ? 0.5 : 1 }}
          className={`${
            disableBtn
              ? "browseHomeColumnSideLeftFormBtnDisabled"
              : "browseHomeColumnSideLeftFormBtn"
          }`}
          onClick={() => !loadingSearch && searchForProceduresOnSite()}
          disabled={disableBtn}
        >
          {!loadingSearch && (
            <p className="browseHomeColumnSideLeftFormBtnText">
              {t("browse_home.search")}
            </p>
          )}
          <div className="browseHomeColumnSideLeftFormBtnIcon">
            {loadingSearch ? (
              <ActivityIndicator size={25} colorFill="#ffffff" />
            ) : (
              <SvgIcon name={iconList.IconSearch} size={25} color={"#FFFFFF"} />
            )}
          </div>
        </button>
      )}
    </div>
  );
};

export default SearchTourismHealth;
