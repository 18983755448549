import React from 'react'
import styles from "./styles.module.css"

const QuestionMamoplastia = ({
    dataMamoplastia,
    setDataMamoplastia,
    validation
}) => {

    return (
        <div className={styles.cont_inputs}>
            <div className={styles.campTwoInputs}>
            <div className={styles['camp-two-check-middle']}>
                <div className={styles.checkboxCont}>
                    <input
                        type="text"
                        value={dataMamoplastia?.size_brazier}
                        placeholder='PJ 32'
                        className={!dataMamoplastia?.size_brazier && validation === true ? styles.obligatory : ""}
                        onChange={(e) => {
                            setDataMamoplastia((prevState) => ({
                                ...prevState,
                                size_brazier: e.target.value
                            }));
                        }}
                    />
                    <label className={styles.subTitleQuestions}>¿Cual es tu talla de brazier actual (aproximadamente)? *</label>
                </div>
            </div>
            <div className={styles['camp-two-check-middle']}>
                <div className={styles.checkboxCont}>
                    <input
                        type="text"
                        value={dataMamoplastia?.want_size}
                        placeholder='PJ 36'
                        className={!dataMamoplastia?.want_size && validation === true ? styles.obligatory : ""}
                        onChange={(e) => {
                            setDataMamoplastia((prevState) => ({
                                ...prevState,
                                want_size: e.target.value
                            }));
                        }}
                    />
                    <label className={styles.subTitleQuestions}>¿Que talla de brazier deseas alcanzar? *</label>
                </div>
            </div>
            </div>
            <div className={styles.campTwoInputs}>
            <div className={styles['camp-two-check-middle']}>
                <div className={styles.checkboxCont}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Aumentar Talla</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.hope_to_achieve?.add_size}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        hope_to_achieve: {
                                            ...prevState.hope_to_achieve,
                                            add_size: !prevState.hope_to_achieve.add_size
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Reducir Talla</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.hope_to_achieve?.reduce_size}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        hope_to_achieve: {
                                            ...prevState.hope_to_achieve,
                                            reduce_size: !prevState.hope_to_achieve.reduce_size
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Levantar</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.hope_to_achieve?.get_up}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        hope_to_achieve: {
                                            ...prevState.hope_to_achieve,
                                            get_up: !prevState?.hope_to_achieve?.get_up
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Misma talla pero con más firmeza</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.hope_to_achieve?.same_size_firmer}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        hope_to_achieve: {
                                            ...prevState.hope_to_achieve,
                                            same_size_firmer: !prevState?.hope_to_achieve?.same_size_firmer
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Corregir Asimetría</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.hope_to_achieve?.correct_asymmetry}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        hope_to_achieve: {
                                            ...prevState.hope_to_achieve,
                                            correct_asymmetry: !prevState?.hope_to_achieve?.correct_asymmetry
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Reducir el pezón</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.hope_to_achieve?.reduce_nipple}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        hope_to_achieve: {
                                            ...prevState.hope_to_achieve,
                                            reduce_nipple: !prevState?.hope_to_achieve?.reduce_nipple
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Otros</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.hope_to_achieve?.others}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        hope_to_achieve: {
                                            ...prevState.hope_to_achieve,
                                            others: !prevState?.hope_to_achieve?.others
                                        }
                                    }));
                                }}
                            />
                        </div>
                        {

                            dataMamoplastia?.hope_to_achieve?.others &&
                            <div style={{ display: "flex", flexDirection: "column", gap: ".5rem", }}>
                                <label htmlFor="">Describe que resultado quieres lograr con tu cirugía *</label>
                                <input
                                    type="text"
                                    placeholder='PJ quiero verme mas atractica'
                                    className={!dataMamoplastia?.hope_to_achieve?.description && validation === true ? styles.obligatory : ""}
                                    value={dataMamoplastia?.hope_to_achieve?.description}
                                    onChange={(e) => {
                                        setDataMamoplastia((prevState) => ({
                                            ...prevState,
                                            hope_to_achieve: {
                                                ...prevState.hope_to_achieve,
                                                description: e.target.value
                                            }
                                        }));
                                    }}
                                />
                            </div>

                        }

                    </div>

                    <label className={styles.subTitleQuestions}>¿Que esperas esperas lograr con tu cirugía mamaria?</label>
                </div>
            </div>
            <div className={styles['camp-two-check-middle']}>
                <div className={styles.checkboxCont}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">¿Te has realizado alguna cirugía mamaria previamente?</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.performed_breast_surgery}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        performed_breast_surgery: !dataMamoplastia?.performed_breast_surgery
                                    }));
                                }}
                            />
                        </div>
                        {

                            dataMamoplastia?.performed_breast_surgery &&
                            <>
                                <div style={{ display: "flex", flexDirection: "column", gap: ".5rem", }}>
                                    <label htmlFor="">Por favor detalla el tipo de cirugía y fecha *</label>
                                    <input
                                        type="text"
                                        placeholder='PJ mamoplastia 18/08/2019'
                                        className={!dataMamoplastia?.performed_breast_surgery_description && validation === true ? styles.obligatory : ""}
                                        value={dataMamoplastia?.performed_breast_surgery_description}
                                        onChange={(e) => {
                                            setDataMamoplastia((prevState) => ({
                                                ...prevState,
                                                performed_breast_surgery_description: e.target.value
                                            }));
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", gap: ".5rem", }}>
                                    <label htmlFor="">¿Sabes en qué lugar esta puesto tu implante? *</label>
                                    <select name="" id=""
                                        className={!dataMamoplastia?.know_implant_place && validation === true ? styles.obligatory : ""}
                                        value={dataMamoplastia?.know_implant_place}
                                        onChange={(e) => {
                                            setDataMamoplastia((prevState) => ({
                                                ...prevState,
                                                know_implant_place: e.target.value
                                            }));
                                        }}>
                                        <option value="">Seleccione</option>
                                        <option value="Glandular">
                                            Glandular
                                        </option>
                                        <option value="Submascular">
                                            Submascular
                                        </option>
                                        <option value="Muscular">
                                            Muscular
                                        </option>
                                    </select>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                                    <label htmlFor="">¿Te gustaría considerar una reducción o levantamiento sin implantes?</label>
                                    <input
                                        type='checkbox'
                                        checked={dataMamoplastia?.consider_reduction_lift}
                                        onChange={(e) => {
                                            setDataMamoplastia((prevState) => ({
                                                ...prevState,
                                                consider_reduction_lift: !dataMamoplastia?.consider_reduction_lift
                                            }));
                                        }} />
                                </div>
                            </>
                        }
                    </div>
                    <label className={styles.subTitleQuestions}>Historial Quirurgico</label>
                </div>

            </div>
            </div>
            <div className={styles['camp-two-check-middle']}>
                <div className={styles.checkboxCont}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Cicatrices</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.biggest_concern_doubt?.scars}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        biggest_concern_doubt: {
                                            ...prevState.biggest_concern_doubt,
                                            scars: !prevState.biggest_concern_doubt.scars
                                        }

                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Idoneidad del especialista</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.biggest_concern_doubt?.specialist_suitability}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        biggest_concern_doubt: {
                                            ...prevState.biggest_concern_doubt,
                                            specialist_suitability: !prevState.biggest_concern_doubt.specialist_suitability
                                        }

                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Habilitación y seguridad de las clínicas</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.biggest_concern_doubt?.security_clearance}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        biggest_concern_doubt: {
                                            ...prevState.biggest_concern_doubt,
                                            security_clearance: !prevState.biggest_concern_doubt.security_clearance
                                        }

                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Resultados esperados</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.biggest_concern_doubt?.expected_results}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        biggest_concern_doubt: {
                                            ...prevState.biggest_concern_doubt,
                                            expected_results: !prevState.biggest_concern_doubt.expected_results
                                        }

                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Recuperación y tiempo de inactividad</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.biggest_concern_doubt?.recovery_time}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        biggest_concern_doubt: {
                                            ...prevState.biggest_concern_doubt,
                                            recovery_time: !prevState.biggest_concern_doubt.recovery_time
                                        }

                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Informacion sobre los Implantes</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.biggest_concern_doubt?.info_about_implants}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        biggest_concern_doubt: {
                                            ...prevState.biggest_concern_doubt,
                                            info_about_implants: !prevState.biggest_concern_doubt.info_about_implants
                                        }

                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">Informacion sobre las tecnicas</label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.biggest_concern_doubt?.info_about_techniques}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        biggest_concern_doubt: {
                                            ...prevState.biggest_concern_doubt,
                                            info_about_techniques: !prevState.biggest_concern_doubt.info_about_techniques
                                        }

                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: ".5rem", }}>
                            <label className={styles.subTitleQuestions}>¿Hay algo más que consideras importante para que evaluemos tu caso? *</label>
                            <input
                                type="text"
                                placeholder='PJ operaciones, condiciones'
                                className={!dataMamoplastia?.something_you_consider_important && validation === true ? styles.obligatory : ""}
                                value={dataMamoplastia?.something_you_consider_important}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,
                                        something_you_consider_important: e.target.value
                                    }));
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: ".5rem", alignItems: "center", justifyContent: "flex-end" }}>
                            <label htmlFor="">¿Alguien te recomendó nuestros servicios? </label>
                            <input
                                type="checkbox"
                                checked={dataMamoplastia?.someone_recommended_us}
                                onChange={(e) => {
                                    setDataMamoplastia((prevState) => ({
                                        ...prevState,

                                        someone_recommended_us: !prevState.someone_recommended_us


                                    }));
                                }}
                            />
                        </div>
                        {
                            dataMamoplastia?.someone_recommended_us &&
                            <div style={{ display: "flex", flexDirection: "column", gap: ".5rem", }}>
                                <label htmlFor="">¿Quien te recomendo? *</label>
                                <input
                                    type="text"
                                    placeholder='PJ amigo, redes ...'
                                    className={!dataMamoplastia?.recommend_description && validation === true ? styles.obligatory : ""}
                                    value={dataMamoplastia?.recommend_description}
                                    onChange={(e) => {
                                        setDataMamoplastia((prevState) => ({
                                            ...prevState,
                                            recommend_description: e.target.value
                                        }));
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <label className={styles.subTitleQuestions}>¿Cuál es tu mayor inquietud o duda respecto al procedimiento? (selecciona las que apliquen)</label>
                </div>

            </div>
        </div>
    )
}

export default QuestionMamoplastia