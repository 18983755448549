import React, { useEffect, useState } from "react";
import useHeaderSession from "@/ui/viewModels/session/header.session.viewModel";
import SessionModal from "../session/components/SessionModal/SessionModal";
import ModalContainer from "../share/components/modalContainer";
import SvgIcon, { iconList } from "../share/components/iconSVG";
import StepStep from "../BuildPackage/components/Step/StepStep";
import StepHome from "../BuildPackage/components/Step/StepHome";
import {
  setCurrentStep,
  setRoutes,
} from "@/store/slices/dataStepz/dataStepzSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import SpecialistClinic from "./components/specialist-clinic";
import Flights from "./components/flights";
import Hotel from "./components/hotel";
import Traslados from "./components/traslados";
import CarouselSevices from "../share/components/CarouselServices/CarouselSevices";
import AnyQuestions from "../home/components/anyQuestion";
import { useTranslation } from "react-i18next";import DataSearch from "../BuildPackage/components/DataSearch/DataSearch";
;



const CartCheckout = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate()

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  const { userDetail } = useHeaderSession();

  const dispatch = useDispatch();
  const location = useLocation();

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const valueFlight = dataPackage.flight ? dataPackage.flight.priceTotal : 0;
  const valueHotel = dataPackage.hotel ? dataPackage.hotel.price : 0;

  const [activeSessionModal, setActiveSessionModal] = useState(false);
  const [modalQuestion, setModalQuestion] = useState(false);

  const totalPrice =
    valueFlight +
    valueHotel;

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsConfirmed(event.target.checked);
  };


  useEffect(() => {
    setActiveSessionModal(false)
  }, [userDetail])



  const navigateStd = () => {
    if (!userDetail) {
      setActiveSessionModal(true)
    } else {
      navigate("/build-package/std")
    }

  }

  const setSpetVariableData = useSelector(
    (state) => state.dataSearchEngine.spetVariableData
  );

  const routes = useSelector((state) => state.dataStepz.routes);

  const [dataStepzTitles, setDataStepzTitles] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {
    if (setSpetVariableData === 2) {
      dispatch(setCurrentStep(5));
    } else {
      dispatch(setCurrentStep(4));
    }
    dispatch(
      setRoutes({
        ...routes,
        summary: {
          route: location.pathname,
          search: location.search,
        },
      })
    );
  }, []);

  useEffect(() => {
    let titles = [];
    if (setSpetVariableData === 0) {
      titles = ["Hotel", "Especialista", "Clínica", "Resumen", "STD"];
    } else if (setSpetVariableData === 1) {
      titles = ["Vuelo", "Especialista", "Clínica", "Resumen", "STD"];
    } else if (setSpetVariableData === 2) {
      titles = [ "Vuelo", "Hotel", "Especialista", "Clínica", "Resumen", "STD"];
    } else if (setSpetVariableData === 3) {
      titles = ["Especialista", "Clínica", "Vuelo", "Hotel", "Traslado","Resumen", "STD"];
    }
    setDataStepzTitles(titles);
  }, [setSpetVariableData]); 

  const handleNavigation = () => {
    if (isConfirmed) {
      navigate("/build-package/std");
    }
  };



  return (
    <div className={styles.contentCartCheckoutInfo}>
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>

      <SessionModal
        activeSessionModal={activeSessionModal}
        setActiveSessionModal={setActiveSessionModal}
        url={"/build-package/std"}
      />

      <ModalContainer
        show={modalQuestion}
        hiddsetActiveChangeServiceen={setModalQuestion}
      >

          <div className={styles.modalQuestion}>
            <img src="https://cdn-icons-png.freepik.com/512/5471/5471074.png" alt="question" />
            <p>¿Deseas continuar con tu compra?</p>

            <div className='content-all-info-detail-transport' style={{ margin: "1rem 0" }}>
              <h2>Detalle de la compra</h2>
              <div className='line-separation-transport'></div>
              <div className='info-detail-transport'>
                <div className="camp-detail">
                  <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconDoctor} size={20} />Doctor</h3>
                  <span>Benito Perez</span>
                </div>
                <div className="camp-detail">
                  <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconHospital} size={20} />Clinica</h3>
                  <span>Clinica laser</span>
                </div>
                <div className="camp-detail">
                  <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconPlane2} size={20} />Vuelo</h3>
                  <span>MDE - BOG</span>
                </div>
                <div className="camp-detail">
                  <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconHotel} size={20} />Hotel</h3>
                  <span>Low Tower</span>
                </div>
              </div>
              <div className='line-separation-transport'></div>
              <div className='note-trans'>
                <p className={styles.note}>
                  Podría haber cargos adicionales al pagar tu paquete. De ser así, te los informaremos en detalle antes de finalizar tu pedido.
                </p>
              </div>
            </div>
            <div>
              <button onClick={() => navigateStd()}>Continuar</button>
              <button onClick={() => {
                navigate("/build-package")
              }}>Salir</button>
            </div>
          </div>

      </ModalContainer>

      <div className={styles.cartCheckoutSection}>
        <div className={styles.itemSectionAll}>
          <StepHome />
          {dataStepzTitles.map((item, index) => (
            <StepStep
              key={index}
              title={item}
              step={index + 1}
              onClick={handleStepClick}
              dataPackage={dataPackage}
            />
          ))}
        </div>
        <div className={styles.containerSearch}>
          <DataSearch />
        </div>
        <div className={styles.cartCheckout}>
          <div className={styles.cart}>
            <h2>Tu compra</h2>
            <div className={styles.cartItems}>
              <SpecialistClinic title={true} appointments={dataPackage} />

              {dataPackage.flight && (
                <Flights title={true} flights={dataPackage.flight} />
              )}

              {dataPackage.hotel && (
                <Hotel title={true} hotels={dataPackage.hotel} />
              )}
            </div>
          </div>
          <div className={styles.checkout}>
            <div className={styles.checkoutContent}>
              <h4 className={styles.checkoutTitle}>
                Información de Cotización
              </h4>

                <ul className={styles.checkoutList}>
                  <li className={styles.checkoutListItem}>
                    Procedimiento
                    <p className={styles.checkoutListPriceTicket}>
                      Sujeto a valoración
                    </p>
                  </li>

                  {dataPackage.flight && (
                    <li className={styles.checkoutListItem}>
                      Vuelo
                      <p className={styles.checkoutListPrice}>
                        {formatPriceCOP(dataPackage.flight.priceTotal)}
                      </p>
                    </li>
                  )}

                  {dataPackage.hotel && (
                    <li className={styles.checkoutListItem}>
                      Hotel
                      <p className={styles.checkoutListPrice}>
                        {formatPriceCOP(dataPackage.hotel.price)}
                      </p>
                    </li>
                  )}

                  <li className={styles.checkoutListItem}>
                    Total
                    <p className={styles.checkoutListPrice}>
                      {formatPriceCOP(totalPrice)}
                    </p>
                  </li>
                </ul>
             

              <div className={styles.checkoutActions}>
                <div className={styles.checkoutCheckboxWrapper}>
                  <input
                    type="checkbox"
                    id="confirm"
                    checked={isConfirmed}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className={styles.checkoutCheckboxLabel}
                    htmlFor="confirm"
                  >
                    Confirmar cotización
                  </label>
                </div>

                <button
                  className={`${styles.checkoutButton} ${!isConfirmed ? styles.checkoutButtonDisabled : ""
                    }`}
                  disabled={!isConfirmed}

                  onClick={navigateStd}
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default CartCheckout;
