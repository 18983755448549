import React from 'react'
import "./styles.css"
import { useTranslation } from 'react-i18next'
import { faBedPulse } from '@fortawesome/free-solid-svg-icons'

const TreatmentTime = ({setTreatmentTime,setBudget,setOtherReviews,otherReviews,budget,treatmentTime}) => {

    const [t] = useTranslation("global")

    const selectTreament = (option)=>{
        if(option == "a"){
            setTreatmentTime("6 meses o mas")
            localStorage.setItem("BooTreatmen","6 meses o mas")
        }else if(option == "b"){
            setTreatmentTime("3 meses")
            localStorage.setItem("BooTreatmen","3 meses")
        }else if(option == "c"){
            setTreatmentTime("2 meses")
            localStorage.setItem("BooTreatmen","2 meses")
        }else if(option == "d"){
            setTreatmentTime("1 mes")
            localStorage.setItem("BooTreatmen","1 mes")

        }else if(option == "e"){
            setTreatmentTime("Me interesa saber más sobre el tratamiento, pero no tengo intención de realizarlo este año")
            localStorage.setItem("BooTreatmen","Me interesa saber más sobre el tratamiento, pero no tengo intención de realizarlo este año")
        }
    }
    
    const selectBudget = (option)=>{
        if(option == "a"){
            setBudget(true)
            localStorage.setItem("BooBudget",true)
        }else if(option == "b"){
            localStorage.setItem("BooBudget",false)
            setBudget(false)
        }
    }

    const selectOtherReviews = (option)=>{
        if(option == "a"){
            setOtherReviews(true)
            localStorage.setItem("BooOtherReviews",true)
        }else if(option == "b"){
            localStorage.setItem("BooOtherReviews",false)
            setOtherReviews(false)
        }
    }
    
    return (
        <div className='content-treatment-timeT'>
            <h2>{t("specialist.booking_flow.time_tratement")}</h2>
            <section className='contain-select-motive'>
                <div className='camp-checkbox-motive'>
                    <input type="radio" name="time" checked={treatmentTime === "6 meses o mas"} id="motive" onClick={()=>{
                        selectTreament("a")
                    }}/>
                    <label htmlFor="motive">{t("specialist.booking_flow.option1")}</label>
                </div>
                <div className='camp-checkbox-motive'>
                    <input type="radio" name="time" checked={treatmentTime === "3 meses"} id="motive" onClick={()=>{
                        selectTreament("b")
                    }}/>
                    <label htmlFor="motive">{t("specialist.booking_flow.option2")}</label>
                </div>
                <div className='camp-checkbox-motive'>
                    <input type="radio" name="time" checked={treatmentTime === "2 meses"} id="motive" onClick={()=>{
                        selectTreament("c")
                    }}/>
                    <label htmlFor="motive">{t("specialist.booking_flow.option3")}</label>
                </div>
                <div className='camp-checkbox-motive'>
                    <input type="radio" name="time" checked={treatmentTime === "1 mes"} id="motive" onClick={()=>{
                        selectTreament("d")
                    }}/>
                    <label htmlFor="motive">{t("specialist.booking_flow.option4")}</label>
                </div>
                <div className='camp-checkbox-motive'>
                    <input type="radio" name="time" checked={treatmentTime === "Me interesa saber más sobre el tratamiento, pero no tengo intención de realizarlo este año"} id="motive" onClick={()=>{
                        selectTreament("e")
                    }}/>
                    <label htmlFor="motive">{t("specialist.booking_flow.option5")}</label>
                </div>
            </section>
            <section className='contain-describe-consultation'>
                <h3>{t("specialist.booking_flow.have_budget")}</h3>
                <div className='contain-checkbox-treatment'>
                    <div className='camp-checkbox-motive'>
                        <input type="radio" name="presupuesto" checked={budget == true} id="motive" onClick={()=>{
                        selectBudget("a")
                    }}/>
                        <label htmlFor="">{t("specialist.booking_flow.yes")}</label>
                    </div>
                    <div className='camp-checkbox-motive'>
                        <input type="radio" name="presupuesto" checked={budget == false} id="motive" onClick={()=>{
                        selectBudget("b")
                    }}/>
                        <label htmlFor="">{t("specialist.booking_flow.no")}</label>
                    </div>
                </div>
            </section>
            <section className='contain-describe-consultation'>
                <h3>{t("specialist.booking_flow.professional_appraisals")}</h3>
                <div className='contain-checkbox-treatment'>
                    <div className='camp-checkbox-motive'>
                        <input type="radio" name="valoraciones" checked={otherReviews == true} id="motive" onClick={()=>{
                        selectOtherReviews("a")
                    }}/>
                        <label htmlFor="">{t("specialist.booking_flow.yes")}</label>
                    </div>
                    <div className='camp-checkbox-motive'>
                        <input type="radio" name="valoraciones" checked={otherReviews == false} id="motive" onClick={()=>{
                       selectOtherReviews("b")
                    }}/>
                        <label htmlFor="">{t("specialist.booking_flow.no")}</label>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default TreatmentTime