import React, { useState, useRef, useEffect } from "react";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";

import FlightsDateGoing from "@/Components/FlightsDateGoing/FlightsDateGoing";
import TooltipContainer from "@/ui/views/share/components/tooltipContainer";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";

import { useDispatch, useSelector } from "react-redux";
import { setDateGoingSelectedData, setDateReturnSelectedData, } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";


const InputCalendar = ({
  showCalendar,
  setShowCalendar,
  isRoundTrip,
  setIsRoundTrip,
  cardRefDate,
  setDateGoingSelected,
  setDateGoingSelectedApi,
  setDateReturnSelected,
  setDateReturnSelectedApi,
  searcherFlight,
  checkDate,
  setCheckDate,
  dateGoingSelected,
  dateSreturnSelected,
  accompanied,
  setIsManagingPassengers,
  validationForm,
  dateSelectedHotel,
  setDateSelectedHotel,
  activeIndex = false,
  daysProcedure = false
}) => {
  //Variables
  const dispatch = useDispatch();
  const location = useLocation();

  const flightDefault = false;
  const [calendarSize, setCalendarSize] = useState({ width: 100, height: 80 });
  const [dateOneWay, setDateOneWay] = useState(new Date());
  const [dateReturn, setDateReturn] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  });

  const routeStartsWithBuildPackageDates = location.pathname === "/build-package";

  const dateGoingSelectedData = useSelector((state) => state.dataSearchEngine.dateGoingSelectedData);
  const dateReturnSelectedData = useSelector((state) => state.dataSearchEngine.dateReturnSelectedData);

  const calendarRef = useRef(null);

  //Functions

  function convertDate(dateStr) {
    const [month, day, year] = dateStr.split("/");

    const fullYear = `20${year}`;

    const date = new Date(`${fullYear}-${month}-${day}T05:00:00.000Z`);

    return date.toISOString();
  }

  //UseEffects

  useEffect(() => {
    if (routeStartsWithBuildPackageDates) {
      setDateGoingSelected("");
      setDateGoingSelectedApi("");
      dispatch(setDateGoingSelectedData(""));
    } else if (dateGoingSelectedData) {
      setDateGoingSelected(dateGoingSelectedData);
      setDateGoingSelectedApi(convertDate(dateGoingSelectedData));
    }

    if (routeStartsWithBuildPackageDates) {
      setDateReturnSelected("");
      setDateReturnSelectedApi("");
      dispatch(setDateReturnSelectedData(""));
    } else if (dateReturnSelectedData) {
      setDateReturnSelected(dateReturnSelectedData);
      setDateReturnSelectedApi(convertDate(dateReturnSelectedData));
    }

    const handleResize = () => {
      if (window.innerWidth < 850) {
        setCalendarSize({ width: 100, height: 80 });
      } else {
        setCalendarSize({ width: 200, height: 80 });
      }
    };

    window.addEventListener("resize", handleResize);

    // Llamar a handleResize para establecer el tamaño inicial
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {


      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        const differenceInTime = new Date(dateReturn) - new Date(dateOneWay);
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        if (isRoundTrip) {
          if (daysProcedure.days) {
            if (differenceInDays < daysProcedure.days) {
              setDateReturnSelected("")
              setDateGoingSelected("")
              notify(`El procedimiento ${daysProcedure.name} tiene un minimo de ${daysProcedure.days} dias`, "❌");
            } else {
              setShowCalendar(false);
            }
          } else {
            setShowCalendar(false);

          }
        } else {
          setShowCalendar(false);
        }



      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateOneWay, dateReturn]);

  useEffect(() => {
    setDateSelectedHotel({
      entryDate: dateOneWay.toLocaleDateString("en-us", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }),
      exitDate: dateReturn.toLocaleDateString("en-us", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }),
    });

    dispatch(setDateGoingSelectedData(dateGoingSelected));

    dispatch(setDateReturnSelectedData(dateSreturnSelected));
  }, [dateGoingSelected, dateSreturnSelected]);


  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });

  return (
    <>
      <div
        className="searchBuildPackageContentInput"
        ref={calendarRef}
        style={{
          borderColor:
            isRoundTrip && ((!dateGoingSelected || !dateSreturnSelected) && validationForm) || !isRoundTrip && (!dateGoingSelected && validationForm)
              ? "var(--redPrimary)"
              : "",
          opacity: daysProcedure === 0 || !daysProcedure.days ? 0.5 : 1,
          pointerEvents: daysProcedure === 0 || !daysProcedure.days ? "none" : ""
        }}
      >
        <div
          className="searchBuildPackageContentInputContent"
          onClick={() => setShowCalendar(true)}
        >
          <TextComponent
            children={"Fecha"}
            size={"md2"}
            tag={"p"}
            color={
              isRoundTrip && ((!dateGoingSelected || !dateSreturnSelected) && validationForm) || !isRoundTrip && (!dateGoingSelected && validationForm)
                ? "var(--redPrimary)"
                : "var(--greySecundary)"
            }
          />
          <input
            type="text"
            placeholder={!isRoundTrip && activeIndex == 1 ? "Salida" : "Salida - Llegada" }
            readOnly
            value={
              dateGoingSelected || dateSreturnSelected
                ? `${dateGoingSelected} ${ !isRoundTrip && activeIndex == 1 ? "" : "- " + dateSreturnSelected 
                }`
                : ""
            }
            className={`${(!dateGoingSelected || !dateSreturnSelected) && validationForm
              ? "inputColorBlueGray"
              : "inputColorBlue"
              }`}
          />
        </div>

        <SvgIcon
          name={iconList.IconCalendar}
          color={"var(--bluePrimary)"}
          size={30}
        />
        {
          showCalendar ? (
            <TooltipContainer
              show={showCalendar}
              hidden={setShowCalendar}
              sizeWidth={calendarSize.width}
              sizeHeight={calendarSize.height}
            >
              <div className="searchFlightsContainerDate">
                <FlightsDateGoing
                  oneWay={!isRoundTrip}
                  setOneWay={setIsRoundTrip}
                  roundTrip={isRoundTrip}
                  setRoundTrip={setIsRoundTrip}
                  setDateGoing={setShowCalendar}
                  dateGoing={showCalendar}
                  cardRef={cardRefDate}
                  setDateGoingSelected={setDateGoingSelected}
                  setDateGoingSelectedApi={setDateGoingSelectedApi}
                  setDateSreturnSelected={setDateReturnSelected}
                  setDateSreturnSelectedApi={setDateReturnSelectedApi}
                  searcherFlight={searcherFlight}
                  checkDate={checkDate}
                  setCheckDate={setCheckDate}
                  dateGoingSelected={dateGoingSelected}
                  dateSreturnSelected={dateSreturnSelected}
                  accompanied={accompanied}
                  setDateOneWay={setDateOneWay}
                  setDateReturn={setDateReturn}
                  dateOneWay={dateOneWay}
                  dateReturn={dateReturn}
                  setIsManagingPassengers={setIsManagingPassengers}
                  flightDefault={flightDefault}
                  daysProcedure={daysProcedure}
                  activeIndex={activeIndex}
                />
              </div>
            </TooltipContainer>
          ) : null
        }
      </div>
    </>

  );
};

export default InputCalendar;
