import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../Context/user/userProvider';
import { useTranslation } from 'react-i18next';

function usePassengerInformationForm() {
  const { userDetail, userToken, loadingContext } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation().state;
  const [step, setStep] = useState(1);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [needLogin, setNeedLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [controlNumberPNR, setControlNumberPNR] = useState('');
  const [dataSavePNR, setDataSavePNR] = useState(null);
  const [globalPNR, setGlobalPNR] = useState(null);
  const [modalListPassenger, setModalListPassenger] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [loadingLink, setLoadingLink] = useState(false)
  const [t] = useTranslation("global")
  const stepsList = [
    { id: 1, name: t("fligth.form_passenger.step.person_data"), available: false },
    { id: 2, name: t("fligth.form_passenger.step.check_reservation"), available: false },
    { id: 3, name: t("fligth.form_passenger.step.pay"), available: false },
  ]
 
  function hiddenModalSesion() {
    setNeedLogin(false)
    setTimeout(() => {
      window.location.href = '/';
    }, 1500);
  }

  const sobreCargo =()=>{
    return location?.priceTotal + (location?.priceTotal * 0.05)
  }

  function getBaseUrl(url) {
    const parsedUrl = new URL(url);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
    return baseUrl;
}

  const createLinkPay = ()=>{
    const dataNew = {
      "name":dataSavePNR?.travellers[0]?.name,
      "lastname":dataSavePNR?.travellers[0]?.lastName,
      "document":dataSavePNR?.travellers[0]?.documentNumber,
      "email":dataSavePNR?.emailTwo,
      "cel":dataSavePNR?.tel,
      "localizator":controlNumberPNR,
      "type":"Amadeus",
      "price":sobreCargo().toString(),
      "url":`${getBaseUrl(window.location.href)}/flight/flightsPay`
    }
    const url = process.env.REACT_APP_URL_CREATE_LINK
    setLoadingLink(true)
    axios.post(url, dataNew)
        .then(res => {
          localStorage.setItem('data-pay-flight', JSON.stringify(location))
          localStorage.setItem("request-id-pay",res.data?.requestId)
          window.location.href = res.data?.processUrl
        })
        .catch(err => {
          console.log(err)
          setLoadingLink(false)
        })
  }

  function goToScreen(screen) {
    setNeedLogin(false)
    const stateProps = {
      routeMemory: window.location.pathname,
      dataMemory: { ...location }
    }
    navigate(screen, { state: stateProps })
  }

  async function savePNR() {
    if (isLoading) return;
    try {
      setIsLoading(true)
      setShowModal(true)
      setModalListPassenger(false)
      const url = `${process.env.REACT_APP_URL_API_FLY}savepnr`;
      const result = await axios.post(url, dataSavePNR);
      if (result) {
        const newMessage = {
          text: t("fligth.form_passenger.step.exit_reservation"),
          code: result?.data?.reserve?.control_number,
        }
        setMessage(newMessage)
        setControlNumberPNR(result?.data?.reserve?.control_number);
      }
    } catch (error) {
      console.log("Error in savePNR()", error)


      if (error?.response?.data?.status === 400 || error?.response?.status === 500) {
        console.log(" error: ", error?.response?.data)
        setMessage({
          text: t("fligth.form_passenger.step.error_reservation"), code: 'ERROR',
        })
      }
    }
    finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (showModal && !isLoading) {
      setTimeout(() => {
        setShowModal(false);
        setMessage(null);
        if (message && message?.code !== "ERROR") {
          setStep(2);
        }
      }, 5000);
    }
  }, [isLoading, showModal]);


  function getSession() {
    if (!loadingContext) {
      if (!userToken && !userDetail) {
        setNeedLogin(true)
      }
      else {
      }
      setLoadingScreen(false)
    }
  }


  function setShowModalClick() {
    setShowModal(false)
    setShowModal(false);
    setMessage(null);
    setStep(2);
  }


  const smoothScrollToTop = () => {
    const currentPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    if (currentPosition > 0) {
      window.requestAnimationFrame(smoothScrollToTop);
      window.scrollTo(0, currentPosition - currentPosition / 8);
    }
  };


  function getDataToEdit(data) {

    setDataToEdit(data)
    setModalListPassenger(false);
  }


  function showListPassenger(PNR) {
    setDataSavePNR(PNR)
    setModalListPassenger(true);
  }

  useEffect(() => {
    smoothScrollToTop();
    if (!location?.refresh) {

      getSession()
    }
  }, [])

  return {
    step,
    setStep,
    loadingScreen,
    userDetail,

    userToken,
    needLogin,
    setNeedLogin,
    stepsList,

    hiddenModalSesion,
    goToScreen,
    savePNR,
    isLoading,

    showModal,
    message,
    location,
    setShowModalClick,

    controlNumberPNR,
    globalPNR,
    setGlobalPNR,
    dataSavePNR,
    showListPassenger,
    modalListPassenger,
    getDataToEdit,
    dataToEdit,
    setModalListPassenger,

    createLinkPay,
    loadingLink
  }
}

export default usePassengerInformationForm;
