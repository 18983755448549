import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";

const StepHome = () => {
  const navigate = useNavigate();

  const changeHomePackage = () => {
    navigate("/build-package");
  };
  return (
    <div className={styles.stepToStepHome}>
      <div className={styles.itemSectionHome} onClick={() => changeHomePackage()}>
        <h2 className={styles.itemText}>
          <AiFillHome />
        </h2>
      </div>
    </div>
  );
};

export default StepHome;
