import { setEspcialtieData, setIdSpecialtyData } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";
import TooltipContainer from "@/ui/views/share/components/tooltipContainer";
import { Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";

const InputSearchEspecialties = ({
    showSpecialties,
    setShowSpecialties,
    especialtie,
    setEspecialtie,
    checkSpeciality,
    setIdSpecialty,
    setProcedure
}) => {

    // Variables
    const dispatch = useDispatch();

    const specialtiesRef = useRef(null);
    const inputRef = useRef(null);

    const [cardEspecialties, setCardEspecialties] = useState(false);
    const [especialties, setEspecialties] = useState([]);
    const [especialtiesSearch, setEspecialtiesSearch] = useState([]);
    
    const especialtieData = useSelector((state) => state.dataSearchEngine.espcialtieData);
    const idEspecialtieData = useSelector((state) => state.dataSearchEngine.idEspecialtieData);

    // Functions
    const handleClick = () => {
        setProcedure("")
        setEspecialtie("");
        setIdSpecialty(null);
        setShowSpecialties(true);
        setCardEspecialties(true);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const updateEspecialtieData = (especialtieSelect, idEspecialtieSelect) => {
        dispatch(setEspcialtieData(especialtieSelect));
        dispatch(setIdSpecialtyData(idEspecialtieSelect));
    }

    const getEspecialties = () => {
        let response = axios.get("https://pdtclientsolutions.com/crm-public/api/category");

        response.then((res) => {
            setEspecialties(res.data);
        })
        .catch((err) => {
            console.error(err);
        });
    };

    // UseEffects
    useEffect(() => {
        getEspecialties();
        setEspecialtie(especialtieData);
        setIdSpecialty(idEspecialtieData);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(specialtiesRef.current && !specialtiesRef.current.contains(event.target)){
                setShowSpecialties(false);
                setCardEspecialties(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [specialtiesRef, setShowSpecialties]);

    useEffect(() => {
        const sortedEspecialties = [...especialties].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        if (especialtie === "") {
            setEspecialtiesSearch(sortedEspecialties.filter((especialty) => 
                especialty.name
            ));
        } else {
            setEspecialtiesSearch(sortedEspecialties.filter((especialty) => 
                especialty.name && especialty.name.toLowerCase().includes(especialtie.toLowerCase())
            ));
        }
    }, [especialtie, especialties]);

    return (
        <div className="searchBuildPackageContentInput" onClick={handleClick} style={{
            borderColor: especialtie == "" && checkSpeciality
            ? "var(--redPrimary)"
            : ""
        }} ref={specialtiesRef}>
            <div className="searchBuildPackageContentInputContent">
                <TextComponent
                    children={"Especialidad"}
                    size={"md2"}
                    tag={"p"}
                    color={
                        especialtie == "" && checkSpeciality
                            ? "var(--redPrimary)"
                            : "var(--greySecundary)"
                    }
                />
                <input 
                    type="text" 
                    placeholder={ showSpecialties ? '' : 'Elige una especialidad' } 
                    ref={inputRef} 
                    value={especialtie}
                    onChange={(e) => setEspecialtie(e.target.value)}
                />
            </div>
            <TiArrowSortedDown size={20} color="var(--bluePrimary)" />
            {
                cardEspecialties && (
                    <TooltipContainer
                        show={showSpecialties}
                        hidden={setShowSpecialties}
                        sizeWidth={100}
                        sizeHeight={80}
                    >
                        <ul className="contentElemetsSearchInputBuildPackage">
                            {
                                especialtiesSearch.length === 0 ? (
                                    <li className="list-not-results">
                                        No se encontraron resultados
                                    </li>
                                ) : (
                                    especialtiesSearch.map((especialtieItem, index) => (
                                        <Tooltip title={especialtieItem.name} arrow placement="right">
                                            <li key={index} onClick={(e) => {
                                                e.stopPropagation();
                                                setEspecialtie(especialtieItem.name);
                                                setShowSpecialties(false);
                                                setCardEspecialties(false);
                                                updateEspecialtieData(especialtieItem.name, especialtieItem.id);
                                                setIdSpecialty(especialtieItem.id);
                                            }}>
                                                <span>{especialtieItem.name}</span>
                                            </li>
                                        </Tooltip>
                                    ))
                                )
                            }
                        </ul>
                    </TooltipContainer>
                )
            }
        </div>
    );
}

export default InputSearchEspecialties;