import React, { useEffect, useState } from "react";
import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";
import StepStep from "../../components/Step/StepStep";
import Clinics from "@/ui/views/clinics/Clinics";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";
import HomeButtonBuildPackage from "../../components/HomeButtonBuildPackage/HomeButtonBuildPackage";

import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentStep,
  setRoutes,
} from "@/store/slices/dataStepz/dataStepzSlice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import "./styles.css";
import StepHome from "../../components/Step/StepHome";
import DataSearch from "../../components/DataSearch/DataSearch";

const StepClinic = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [dataStepzTitles, setDataStepzTitles] = useState([]);
  const [t] = useTranslation("global");

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector(
    (state) => state.dataSearchEngine.spetVariableData
  );
  const routes = useSelector((state) => state.dataStepz.routes);

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {
    if (setSpetVariableData === 2 ) {
      dispatch(setCurrentStep(4));
    }
    else{
      dispatch(setCurrentStep(3));
    }

    dispatch(
      setRoutes({
        ...routes,
        clinics: {
          route: location.pathname,
          search: location.search,
        },
      })
    );
  }, []);

  useEffect(() => {
    let titles = [];
    if (setSpetVariableData === 0) {
      titles = ["Hotel", "Especialista", "Clínica", "Resumen", "STD"];
    } else if (setSpetVariableData === 1) {
      titles = ["Vuelo", "Especialista", "Clínica", "Resumen", "STD"];
    } else if (setSpetVariableData === 2) {
      titles = [ "Vuelo", "Hotel", "Especialista", "Clínica", "Resumen", "STD"];
    }
    setDataStepzTitles(titles);
  }, [setSpetVariableData]); 

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>

      <div className="maxWidthBuildPackage">
        <div className="itemSectionAll">
          <StepHome />
          {dataStepzTitles.map((item, index) => (
            <StepStep
              key={index}
              title={item}
              step={index + 1}
              onClick={handleStepClick}
              dataPackage={dataPackage}
            />
          ))}
        </div>
        <DataSearch />

        <Clinics />
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default StepClinic;
