import React, { useState } from "react";
import styles from "./styles.module.css";
import appointmentsData from "./appointments";
import { FaUserDoctor } from "react-icons/fa6";
import { AiOutlineExclamationCircle, AiOutlinePushpin } from "react-icons/ai";
import { HiOutlineMail, HiOutlineMailOpen } from "react-icons/hi";
import { GoFileDirectory } from "react-icons/go";
import { MdDateRange, MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";

function AppointmentRequests() {
  const [filterCriteria, setFilterCriteria] = useState({
    status: null,
    important: null,
    date: null,
  });

  const [dateInputVisible, setDateInputVisible] = useState(false);

  const filters = [
    {
      key: "status",
      value: "leída",
      label: "Leídas",
      icon: <HiOutlineMailOpen size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "status",
      value: "no leída",
      label: "No Leídas",
      icon: <HiOutlineMail size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "status",
      value: "archivada",
      label: "Archivadas",
      icon: <GoFileDirectory size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "important",
      value: true,
      label: "Importantes",
      icon: <AiOutlinePushpin size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "date",
      label: "Por Fecha",
      icon: <MdDateRange size={20} color="var(--bluePrimary)" />,
      renderInput: dateInputVisible && (
        <input
          type="date"
          id="filter-date"
          className={styles.dateInput}
          value={filterCriteria.date || ""}
          onChange={(e) => handleFilterChange("date", e.target.value)}
        />
      ),
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilterCriteria((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleButtonClick = (key, value) => {
    if (key === "date") {
      setDateInputVisible(!dateInputVisible);
      if (filterCriteria.date) {
        setFilterCriteria((prev) => ({
          ...prev,
          date: null,
        }));
      }
    } else {
      setFilterCriteria((prev) => ({
        ...prev,
        [key]: filterCriteria[key] === value ? null : value,
      }));
    }
  };

  const isFilterActive = (key, value) => filterCriteria[key] === value;

  const filterAppointments = (appointments, criteria) => {
    return appointments.filter((appointment) => {
      if (criteria.status && appointment.status !== criteria.status) {
        return false;
      }
      if (
        criteria.important !== null &&
        appointment.important !== criteria.important
      ) {
        return false;
      }
      if (criteria.date && appointment.date !== criteria.date) {
        return false;
      }
      return true;
    });
  };

  const filteredAppointments = filterAppointments(
    appointmentsData,
    filterCriteria
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAppointments = filteredAppointments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const nextHandler = () => {
    if (currentPage < Math.ceil(filteredAppointments.length / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  
  return (
    <div className={styles.appointmentSection}>
      <div className={styles.appointment}>
        <h3>Citas</h3>
        <div className={styles.appointmentItems}>
          {currentAppointments.length > 0 ? (
            currentAppointments.map((item, index) => (
              <div key={index} className={styles.itemAppointment}>
                <div className={styles.itemHeader}>
                  <div className={styles.itemHeaderIconText}>
                    <div className={styles.icon}>
                      <FaUserDoctor />
                    </div>
                    <div className={styles.itemHeaderText}>
                      <h4 className={styles.title}>{item.doctorName}</h4>
                      <p className={styles.shortDescription}>
                        {item.doctorSpecialty}
                      </p>
                    </div>
                  </div>

                  <p className={styles.itemDate}>
                    {formattedToday === item.date
                      ? `Hoy a las ${ item.time}`
                      : item.date}
                  </p>
                </div>
                <div className={styles.itemDetails}>
                  <p className={styles.longDescription}>
                    {item.appointmentDescription}
                  </p>
                </div>

                <a href={item.detailsLink} className={styles.detailsButton}>
                  Ver más detalles
                </a>

                <div className={styles.itemLinks}>
                  <p>
                    Código para ingresar: <span>{item.meetCode}</span>
                  </p>
                  <p>
                    Hora: <span>{item.time}</span>
                  </p>
                  <p>
                    Fecha: <span>{item.date}</span>
                  </p>
                  <p>
                    Especialidad: <span>{item.specialty}</span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noItems}>
              <div className={styles.message}>
                No hay citas recientemente
                <AiOutlineExclamationCircle
                  size={20}
                  color="var(--bluePrimary)"
                />
              </div>
              <Link to="/" className={styles.link}>
                Ir a Home
              </Link>
            </div>
          )}
        </div>
        {currentAppointments.length > 0 && (
          <Paginado
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={filteredAppointments}
            itemsPerPage={itemsPerPage}
          />
        )}
      </div>

      <div className={styles.filters}>
        <h3>Filtros</h3>
        <div className={styles.filterButtons}>
          {filters.map(({ key, value, label, icon, renderInput }) => (
            <div key={label} className={styles.filterContainer}>
              <button
                className={`${styles.buttonFilter} ${
                  isFilterActive(key, value) ? styles.activeFilter : ""
                }`}
                onClick={() => handleButtonClick(key, value)}
              >
                {icon} {label}
              </button>
              {renderInput}
            </div>
          ))}
          <button
            className={styles.buttonFilterDelete}
            onClick={() =>
              setFilterCriteria({ status: null, important: null, date: null })
            }
          >
            <MdDelete size={20} color="var(--bluePrimary)" /> Restablecer
            Filtros
          </button>
        </div>
      </div>
    </div>
  );
}

export default AppointmentRequests;
