import { useState, createContext } from "react";

export const TransportContext = createContext();

const TransportProvider = ({ children }) => {
  const [resultados, setResultados] = useState([0]);

  const changeValue = (e) => {
    setResultados(e);
  };
  return (
    <TransportContext.Provider value={{ resultados, changeValue }}>
      {children}
    </TransportContext.Provider>
  );
};

export { TransportProvider };

export default TransportContext;
