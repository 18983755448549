import React, { useEffect, useRef, useState } from "react";
import styles from "./CityCarousel.module.css";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

const CityCarousel = ({
  cities,
  smoothScrollTo,
  selectedNacionality,
  destinationSelectionFunc,
  setDefaultHotel,
  setDateEntry,
}) => {
  const [currentIndex, setCurrentIndex] = useState(cities.length); // Start at the first duplicated item
  const [visibleCards, setVisibleCards] = useState(4); // Default is 3 cards visible on desktop
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const startXRef = useRef(null);
  const isSwipingRef = useRef(false);

  // Ref to track touch position
  const touchStartX = useRef(null);

  // Duplicate the cities array for infinite scrolling
  const duplicatedCities = [...cities, ...cities, ...cities];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setVisibleCards(1);
        setIsMobile(true);
      } else if (window.innerWidth < 767) {
        setVisibleCards(2);
        setIsMobile(true);
      } else if (window.innerWidth < 1024) {
        setVisibleCards(3);
        setIsMobile(true);
      } else {
        setVisibleCards(4);
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Reset index when changing visibleCards
    setCurrentIndex(cities.length);
  }, [visibleCards, cities.length]);

  useEffect(() => {
    // Update button states
    setIsPrevDisabled(currentIndex <= cities.length);
    setIsNextDisabled(currentIndex >= duplicatedCities.length - visibleCards);
  }, [currentIndex, duplicatedCities.length, visibleCards, cities.length]);

  const prevCard = () => {
    if (!isPrevDisabled) {
      setCurrentIndex((prevIndex) =>
        prevIndex <= cities.length
          ? duplicatedCities.length - visibleCards
          : prevIndex - 1
      );
    }
  };

  const nextCard = () => {
    if (!isNextDisabled) {
      setCurrentIndex((prevIndex) =>
        prevIndex >= duplicatedCities.length - visibleCards
          ? cities.length
          : prevIndex + 1
      );
    }
  };

  const handleTouchStart = (e) => {
    if (isMobile) {
      startXRef.current = e.touches[0].clientX;
      isSwipingRef.current = true;
    }
  };

  const handleTouchMove = (e) => {
    if (isMobile && isSwipingRef.current && startXRef.current) {
      const diffX = startXRef.current - e.touches[0].clientX;

      if (diffX > 50) {
        nextCard(); // Swipe left (next card)
        isSwipingRef.current = false;
      } else if (diffX < -50) {
        prevCard(); // Swipe right (previous card)
        isSwipingRef.current = false;
      }
    }
  };

  const handleTouchEnd = () => {
    if (isMobile) {
      startXRef.current = null;
      isSwipingRef.current = false;
    }
  };

  return (
    <div
      className={styles.carouselContainerHotel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {isMobile && (
      <button
        onClick={prevCard}
        className={`${styles.carouselButton} ${styles.carouselButtonLeft} ${
          isPrevDisabled && styles.disabled
        }`}
        disabled={isPrevDisabled}
      >
        <MdOutlineKeyboardArrowLeft />
      </button>
      )}
      <div className={styles.carouselWrapper}>
        <div
          className={styles.carouselContentHotel}
          style={{
            transform: `translateX(-${(100 / visibleCards) * currentIndex}%)`,
          }}
        >
          {duplicatedCities.map((city, index) => (
            <div key={index} className={styles.cardHotelDestac}>
              <div className={styles.cardHotelDestacImg}>
                <img src={city.img} alt={city.name} />
              </div>
              <div className={styles.cardContentDestac}>
                <div>
                  <p>Disfruta en</p>
                  <h3>{city.name}</h3>
                </div>
                <p className={styles.cardContentDestacDescription}>
                  Hoteles{" "}
                  <span className={styles.cardContentDestacDescriptionStars}>
                    5 estrellas
                  </span>
                </p>
                <div className={styles.cardContentInfo}>
                  <div className={styles.cardContentInfoDescription}>
                    <p>
                      Descubre una experiencia inolvidable en nuestro hotel en{" "}
                      {city.name}. Disfruta de una estancia que combina confort
                      y elegancia con un servicio excepcional. Te ofrecemos una
                      experiencia única, pensada para hacer de tu visita a
                      Cartagena algo especial.
                    </p>
                  </div>
                  <div className={styles.cardContentInfoButton}>
                    <button
                      onClick={() => {
                        smoothScrollTo(200, 400);
                        selectedNacionality("CO", "COLOMBIA");
                        destinationSelectionFunc(city);
                        setDefaultHotel(true);
                        setDateEntry(true);
                      }}
                      className={styles.underlineEffect}
                    >
                      Ver más
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isMobile && (
      <button
        onClick={nextCard}
        disabled={isNextDisabled}
        className={`${styles.carouselButton} ${styles.carouselButtonRight} ${
          isNextDisabled && styles.disabled
        }`}
      >
        <MdOutlineKeyboardArrowRight />
      </button>
       )}
    </div>
  );
};

export default CityCarousel;
