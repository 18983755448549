import React, { useEffect, useState } from 'react'
import CarouselSevices from '../../share/components/CarouselServices/CarouselSevices'
import packageAside from "../../../assets/images/packages1.jpg";
import { useTranslation } from 'react-i18next';
import packImg from "./pack.png"
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CardPackages from '../../home/components/PackagesSection/CardPackages';
import SliderOferts from '../../home/components/SliderOferts/SliderOferts';
import "./styles.css"
import Recomendation from './Recomendation/Recomendation';
import Loading from '../../share/components/loading';
import { CircularProgress, Grid, Pagination } from '@mui/material'
import paperFligth from "./paperFlight.svg"
import AnyQuestions from "../../home/components/anyQuestion/index"
import ReturnPage from '../../share/components/return/ReturnPage';

const TypePackages = () => {
  const [t] = useTranslation("global");
  const urlParams = new URLSearchParams(window.location.search)
  const type = urlParams.get('type');
  let navigate = useNavigate()
  const [packs, setPacks] = useState([])
  const [packsRecomendation, setPacksRecomendation] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingImage, setLoadingImage] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [width, setWidth] = useState("32%")
  //Variables de paginación

  let counter = 0;
  let hasUpdated = false;
  const [itemsPerPage, setItemsPerPage] = useState(6);
  
  // Variables de paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = packs.slice(indexOfFirstItem, indexOfLastItem);


  //Función para cambiar de página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const redirectTo = () => {
    navigate(type === "colombia" ? "/packagesType?type=internacional" : "/packagesType?type=colombia")
  }

  const updatedPagination = (item) => {
    setItemsPerPage(item);
  };
  const getPacks = () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_URL_API_AIOP}api/packages`
    axios.get(url, {
      params: {
        "destination_type": type === "colombia" ? "national" : "international"
      }
    })
      .then(response => {
        setTimeout(() => {
          setLoading(false)
        }, 1000);
        setPacks(response.data.packs)
      })
      .catch(err => {
        console.log(err)
      })
  }


  const getPacksRecomendation = () => {
    const url = `${process.env.REACT_APP_URL_API_AIOP}api/packages`
    axios.get(url)
      .then(response => {
        setPacksRecomendation(response.data.packs)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getPacks()
    setCurrentPage(1)
  }, [type])

  useEffect(() => {
    getPacksRecomendation()
  }, [])


  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 1270){
        updatedPagination(6);
        setWidth("32%")
        setCurrentPage(currentPage); 
      }else if (window.innerWidth <= 1270 && window.innerWidth > 600 && !hasUpdated) {
        updatedPagination(4);
        setWidth("48%")
        setCurrentPage(currentPage); // Reinicia la página actual al actualizar la paginación
         // Marca que la actualización se ha realizado
      }else if(window.innerWidth <= 600 && !hasUpdated){
        setWidth("100%")
        updatedPagination(2);
        setCurrentPage(currentPage); // Reinicia la página actual al actualizar la paginación
      }
    };

    window.addEventListener("resize", handleResize);

    // Ejecutar una vez al cargar la página si la condición ya se cumple
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      <CarouselSevices current={t("carousel_services.packages")} />
      <ReturnPage/>
      {
        loading &&
        <Loading />
      }

      <div className="content-packages-new" style={{marginBottom:"9rem"}}>
        <div className="content-packages-colum-1">
          <div className="content-packages-new-change" style={{height:"20%"}}>
            <div className="content-packages-header">
              <h1 style={{ fontSize: "24px" }}>¿BUSCAS ALGO MÁS?</h1>
              <p style={{marginTop:"0", color: "black" }}>
                En wellezy tenemos diferentes opciones para que puedas viajar a
                cualquier destino
              </p>
            </div>
            <article>
              <section className="content-btn-pack" style={{ marginTop: "2rem" }} onClick={redirectTo}>
                <button>
                  <div>
                    <span style={{ textTransform: "uppercase" }} >{type === "colombia" ? "Internacionales" : "Colombia"}</span>
                  </div>
                </button>
              </section>
            </article>
          </div>

          <aside style={{height:"80%"}}>
            <img src={packageAside} className="img-pack-aside" alt="paquetes" />
            <div className="info">
              <div className="letter">
                <h2>
                  <span>Turismo</span>
                  en salud
                </h2>
                <p>
                  Viaja por tu salud o tu bienestar con todo incluido, todo con
                  Wellezy
                </p>
              </div>
              <div
                className="cardRegisterNowButtonContain"
                style={{
                  position: "relative",
                  padding: "0 4px",
                  bottom: "0px",
                }}
              >
                <Link
                  to={"/TourismHealth"}
                  style={{
                    backgroundColor: "#004274",
                    fontSize: "24px",
                    fontWeight: "900",
                    borderRadius:"0px",
                    width:"100%"
                  }}
                  className="cardRegisterNowButton"
                >
                  VER MÁS
                </Link>
                {/* <button
                  className="cardRegisterNowButtonLink"
                  style={{ width: "55%" }}
                >
                  <div
                    style={{ backgroundColor: "#004274" }}
                    className="cardRegisterNowClip"
                  ></div>
                  <div
                    style={{ backgroundColor: "#004274", borderLeft: "none" }}
                    className="cardRegisterNowClipBorder"
                  ></div>
                </button> */}
              </div>
            </div>
          </aside>
        </div>

        <div className="content-packages-colum-2"
        style={{maxHeight:"830px"}}>
          <div className="content-packages-new-change" style={{height:"10%"}}>
            <div className="content-packages-header">
              <h1>PAQUETES {type === "colombia" ? "COLOMBIA" : "INTERNACIONALES"}</h1>
              <p style={{ color: "black" }}>
                Viaja por colombia con paquetes todo incluido y los mejores precios que trae Wellezy para ti
              </p>
            </div>
          </div>
          <div className='layout-type-packages-result' style={{height:"90%",position:"relative"}}>
            <div className="content-packs-new-2" style={{height:"100%", marginTop: currentItems.length < 4 ? "0px" : ".7rem" }}>
              {

                packs.length > 0 ?
                  (
                    currentItems.map((item, index) => {
                      return (
                        <CardPackages
                          key={index}
                          pack={item}
                          imgType={false}
                          width={width}
                        />
                      )
                    })

                  )
                  :
                  <h3>No hay paquetes por el momento</h3>
              }
            </div>
            {
              packs?.length === 0 ? (
                null
              ) :
                packs.length > itemsPerPage ?
                  (
                    <Pagination className='pagination-packages' count={Math.ceil(packs.length / itemsPerPage)} page={currentPage} onChange={handlePageChange} style={{position:"absolute",
                  bottom:"-135px", left:"37%"}}/>
                  ) : ""
            }
          </div>

        </div>
      </div>
      <div className='carousel-type-pack'>
        <div className='content-width'>
          <div className='letters-carrousel-type'>
            <h2>
              <span style={{ fontSize: "24px" }}>Vuela con los</span>
              <span style={{ fontSize: "32px" }}>mejores</span>
              Precios
            </h2>
            <img src={paperFligth} alt="icon" />
          </div>
          <SliderOferts colorButton={"#004274"} setLoadingImage={setLoadingImage} loadingImage={loadingImage}/>
        </div>
      </div>
      <Recomendation article={packsRecomendation} />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </>
  )
}

export default TypePackages