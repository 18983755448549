import React, { useEffect, useState } from "react";
import Find from "@/ui/views/share//components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "@/ui/views/share//components/CarouselServices/CarouselSevices";
import "./styles.css";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import ModalContainer from "@/ui/views/share/components/modalContainer";
import Loading from "@/ui/views/share//components/loading";
import SuccessPay from "./successPay/SuccessPay";
import PendingPay from "./pendingPay/PendingPay";
import RejectPay from "./rejectPay/RejectPay";
import usePayFlights from "@/ui/viewModels/flights/usePayFlights";
import DetailPayFlight from "./detailPayFlight/DetailPayFlight";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { Skeleton } from "@mui/material";
import TextComponent from "@/ui/views/share//components/fonts/TextComponent";
import ReturnPage from "@/ui/views/share//components/return/ReturnPage";
import GalleryImg from "../../Hotel/DetailHotel/GalleryImg/GalleryImg";
import ModalCardDetail from "../../Hotel/HotelResult/allResultsCards/components/ModalCardDetail";
import { MdCameraAlt } from "react-icons/md";
import { GoCheckCircleFill } from "react-icons/go";
import { IoCloseCircle } from "react-icons/io5";
import { currencyFormat } from "@/ui/utils";
import AnyQuestions from "../../home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import { TbLoader2 } from "react-icons/tb";
import { BiQuestionMark } from "react-icons/bi";

const FlightsPay = () => {
  const { flightInfo, incompleteInformation, loading, statePay } =
    usePayFlights();
  const [t, i18n] = useTranslation("global");

  const [flightUrl, setFlightUrl] = useState("/fligths");

  const [loadingImage, setLoadingImage] = useState(true);
  const [slideShow, setSlideShow] = useState(false);
  const [modalCardDetail, setModalDetail] = useState(false);
  const [fotos, setFotos] = useState([
    "https://images.pexels.com/photos/46148/aircraft-jet-landing-cloud-46148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/4486521/pexels-photo-4486521.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/11190493/pexels-photo-11190493.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/9015525/pexels-photo-9015525.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/18214075/pexels-photo-18214075/free-photo-of-vuelo-volador-avion-aeronave.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/18139303/pexels-photo-18139303/free-photo-of-vuelo-volador-avion-aeronave.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/9298364/pexels-photo-9298364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  ]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingImage(false);
    }, 3000);
  }, []);

  function Image({ url }) {
    const onLoad = () => {
      setLoadingImage(false);
    };
    const onError = () => {
      setLoadingImage(true);
    };
    return (
      <img
        className="image-hotel-detail"
        src={url}
        onLoad={onLoad}
        onError={onError}
        alt="Hotel"
      />
    );
  }

  const [dollarFlight, setDollarFlight] = useState(
    localStorage.getItem("dollarFlight") === null
      ? "COP"
      : localStorage.getItem("dollarFlight")
  );
  const typeOfCoin = {
    name: dollarFlight,
    abbreviation: dollarFlight,
    simbol: "$",
  };

  function formatearFecha(fechaISO) {
    // Crear un objeto de fecha a partir de la cadena ISO
    const fecha = new Date(fechaISO);

    // Obtener los componentes de la fecha y hora
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const año = fecha.getFullYear();
    const hora = fecha.getHours().toString().padStart(2, "0");
    const minutos = fecha.getMinutes().toString().padStart(2, "0");

    // Formatear la fecha y hora según el formato deseado
    const fechaFormateada = `${dia}-${mes}-${año} ${hora}:${minutos}`;

    return fechaFormateada;
  }

  return (
    <div>
      <CarouselSevices current={servicesTravel[0].title} />
      <ReturnPage nav={flightUrl} reload={true} />
      <div className="contain-hotel-pay">
        {loading && <Loading size={90} />}
        {!loadingImage && (
          <GalleryImg
            Images={fotos}
            slideShow={slideShow}
            setSlideShow={setSlideShow}
          />
        )}
        <ModalCardDetail
          modalCardDetail={modalCardDetail}
          setModalDetail={setModalDetail}
          modalPay={true}
          statePay={statePay?.pay?.status}
          valuePay={statePay}
          value={currencyFormat(statePay?.pay?.price, typeOfCoin).total}
          dateHour={formatearFecha(statePay?.pay?.date)}
          statusPay={statePay?.pay?.status}
          incompleteInformation={incompleteInformation}
        />
        <div className="layout-hotel-pay">
          <section className="main-hotel-pay">
            <div className="main-hotel-title-pay">
              <h2>Estado de la transaccion</h2>
              <p>El estatus de tu pago actualmente</p>
            </div>
            <div className="contain-img-principal-button-hotel-pay">
              <div
                className="contain-img-principal-hotel-pay"
                onClick={() => {
                  setSlideShow(!slideShow);
                }}
              >
                <div className="section-img-big-pay">
                  <div className="section-img-1-pay">
                    {loadingImage ? (
                      <div className="img-hotel-skeleton-pay">
                        <MdCameraAlt size={40} color="var(--greyPrimary)" />
                      </div>
                    ) : (
                      <Image url={fotos[0]} />
                    )}
                  </div>
                </div>
                <div className="section-img-layout-for-pay">
                  <div className="section-img-layout-1-pay">
                    {loadingImage ? (
                      <div className="img-hotel-skeleton-pay">
                        <MdCameraAlt size={40} color="var(--greyPrimary)" />
                      </div>
                    ) : (
                      <Image url={fotos[2]} />
                    )}
                  </div>
                  <div className="section-img-layout-2-pay">
                    {loadingImage ? (
                      <div className="img-hotel-skeleton-pay">
                        <MdCameraAlt size={40} color="var(--greyPrimary)" />
                      </div>
                    ) : (
                      <Image url={fotos[3]} />
                    )}
                  </div>
                </div>
              </div>

              <button
                onClick={() => setModalDetail(true)}
                className="contain-img-principal-hotel-button-pay"
              >
                Ver información
              </button>
            </div>
          </section>
          <section className="content-state-pay-state">
            <div
              className={`content-state-pay ${
                statePay?.pay?.status === "APPROVED"
                  ? "APPROVED"
                  : statePay?.pay?.status === "PENDING"
                  ? "PENDING"
                  : statePay?.pay?.status === "REJECTED"
                  ? "REJECTED"
                  : ""
              }`}
            >
             {statePay?.pay?.status === "APPROVED" ? (
                <GoCheckCircleFill size={40} />
              ) : statePay?.pay?.status === "PENDING" ? (
                <TbLoader2 size={40} />
              ) : statePay?.pay?.status === "REJECTED" ? (
                <IoCloseCircle size={40} />
              ) : (
                <BiQuestionMark size={40} />
              )}
              <div>
                <div className="content-state-pay-message">
                  <h3>Estado:</h3>
                  <p>
                    {statePay?.pay?.status === "APPROVED"
                      ? "Aprobada"
                      : statePay?.pay?.status === "PENDING"
                      ? "Pendiente"
                      : statePay?.pay?.status === "REJECTED"
                      ? "Denegada"
                      : "Cargando..."}
                  </p>
                </div>
                <p>
                  {statePay?.pay?.status === "APPROVED"
                    ? "¡Pago realizado con exito!"
                    : statePay?.pay?.status === "PENDING"
                    ? "¡Pago pendiente!"
                    : statePay?.pay?.status === "REJECTED"
                    ? "¡No fue posible realizar el pago!"
                    : "Cargando..."}
                </p>
              </div>
            </div>
            <div className="aside-hotel-pay">
              {loading ? (
                <>
                  <Card sx={{ Width: "100%", m: 2 }}>
                    <CardHeader
                      title={
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                        />
                      }
                      subheader={
                        <Skeleton animation="wave" height={10} width="0%" />
                      }
                    />
                    <Skeleton
                      sx={{ height: 300 }}
                      animation="wave"
                      variant="rectangular"
                    />
                    <CardContent>
                      <React.Fragment>
                        <Skeleton
                          animation="wave"
                          height={10}
                        />
                        <Skeleton animation="wave" height={10} width="80%" />
                      </React.Fragment>
                    </CardContent>
                  </Card>
                  <Card sx={{ Width: "100%", m: 2 }}>
                    <CardContent>
                      <React.Fragment>
                        <Skeleton
                          animation="wave"
                          height={10}
                        />
                        <Skeleton animation="wave" height={10} width="80%" />
                      </React.Fragment>
                    </CardContent>
                  </Card>
                </>
              ) : (
                <>
                  <DetailPayFlight info={flightInfo} />
                  <div className="pay-secure-hotel">
                    <SvgIcon
                      color={"var(--bluePrimary)"}
                      name={iconList.IconKey}
                      size={40}
                    />
                    <div>
                      <TextComponent
                        children={"Pago Seguro"}
                        size={"md"}
                        fontWeight={"bold"}
                      />
                      <TextComponent
                        children={
                          "Los niveles más altos para los pagos con tarjetas"
                        }
                        size={"sm"}
                        fontWeight={"light"}
                        tag="span"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default FlightsPay;
