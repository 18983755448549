import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Selectedluggage from './Assets/Img/Selectedluggage.png'
import './Assets/styles.css'

const FamiliesButtonOrigin = ({
    adult,
    child,
    baby,
    itineraryGoingReturnConditional,
    value,
    change,
    isItOneWayType = false
}) => {

    const [selectedRate, setSelectedRate] = useState('Seleccionar')
    const [familiesOpenButton, setFamiliesOpenButton] = useState(false)

    const [dataUpsell, setDataUpsell] = useState([])

    // useEffect(() => {
    //     const apiUpsell = async () => {
    //             const url = 'https://travelflight.pdtcomunicaciones.com/api/upsell';
    //             const result = await axios.post(url, {
    //                 "adult": adult,
    //                 "child": child,
    //                 "baby": baby,
    //                 /*  "fare":`${fareType}`, */
    //                 "fare": `RP`,
    //                 "bag": false,
    //                 "segments": itineraryGoingReturnConditional
    //             })
    //             setDataUpsell(result.data.families)
    //             /* console.log(result.data) */
    //     }
    //     apiUpsell()
    // }, [dataUpsell]);


    useEffect(() => {
        const handleOneWayTariffs = async () => {
            var oneWayTariffs = []

            if(dataUpsell.length === 0) return;
            
            const count = dataUpsell.length;

         
            for (let i = 0; i < count; i++) {
                const element = dataUpsell[i]
                oneWayTariffs.push(element[0].components[0].fareFamilyDetails.fareFamilyname)
            }

            const uniqueOneWayTariffs = (oneWayTariffs) => {
                return oneWayTariffs.filter((elem, index, self) => {
                    return index === self.indexOf(elem);
                });
            }

            var itemUniqueOneWayTariffs = await uniqueOneWayTariffs(oneWayTariffs);
         
        }
        handleOneWayTariffs()
    },[]);

    const handleFamiliesOpenButton = () => {
        if (familiesOpenButton === false) {
            setFamiliesOpenButton(true)
        } else {
            setFamiliesOpenButton(false)
        }
    }

    const buildingDataUpsell = (list) => {
        const newList = [];
        const position = isItOneWayType ? 0 : 1;
        let dinamicList = [];
        if (isItOneWayType) {
            dinamicList = list;
        }
        else if(value){
            const dinamicOptions = list.filter(obj => obj[0].components[0].fareFamilyDetails.fareFamilyname === value)
            dinamicList = dinamicOptions;
        }
        for (const i in dinamicList) {
            const codeItem = dinamicList[i][0].components[position].fareFamilyDetails.fareFamilyname
            newList.push(codeItem)
        }
        const dataArr = new Set(newList);
        const result = [...dataArr];
        return result
    }

    const dataUpsellFiltered = buildingDataUpsell(dataUpsell);

    const selectItem = (feeFamilies) => {
        setSelectedRate(feeFamilies)
        setFamiliesOpenButton(false)
        if (isItOneWayType) {
            change(feeFamilies)
        }
    }

    return (
        <div className='cotainedFamilies-selected'>
            <div onClick={handleFamiliesOpenButton} style={{ paddingRight: '20px', justifyContent: 'flex-end' }} className='tittleBody planType'>
                <p className='texFamilies-Strong'>{selectedRate}</p>
                <div className='selectPlan-addLuggage_img'>
                    {!familiesOpenButton
                        ? <img src={Selectedluggage} alt="agregar equipaje" />
                        : <img src={Selectedluggage} className='SelectedluggageRotate' alt="agregar equipaje" />
                    }
                </div>
            </div>
            {familiesOpenButton &&
                <div className='bodyScrollFamilies'>
                    {
                        dataUpsellFiltered.length > 0 && dataUpsellFiltered.map((families, key) => {
                            return (
                                <div key={key}
                                    onClick={() => selectItem(families)} className='bodyScrollFamilies-item'>
                                    <p className=''>{families}</p>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default FamiliesButtonOrigin