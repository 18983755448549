/* eslint-disable no-unused-expressions */
import { Component } from 'react';
import jsSHA from 'jssha';

const translations = {
  en: { error_type: "Server error", error_help: "Please try again later", error_description: "Something went wrong" },
  es: { error_type: "Error en servidor", error_help: "Por favor intentar mas tarde", error_description: "Algo salio mal" },
  pt: { error_type: "Erro de servidor", error_help: "Por favor, tente novamente mais tarde", error_description: "Algo deu errado" },
};

class SDK_i18n {
  static get_label(locale, label) {
    return (locale = ["en", "es", "pt"].includes(locale) ? locale : "en"), translations[locale][label] || label;
  }
}

function __(locale, label) {
  return SDK_i18n.get_label(locale, label);
}

function uuidv4() {
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (16 * Math.random()) | 0;
    return ("x" == c ? r : (3 & r) | 8).toString(16);
  });
}

function getSessionId() {
  return uuidv4();
}

function getHash(message) {
  let sha256 = new jsSHA("SHA-256", "TEXT");
  return sha256.update(message), sha256.getHash("HEX");
}

class PaymentGateway extends Component {
    constructor(environment, application_code, application_key) {
        super();
        (this.DOMAIN = "paymentez.com"), (this.environment = environment), (this.application_code = application_code), (this.application_key = application_key), this.__setupListeners();
    }

  __getCCAPIUrl() {
        const ccapi_url_by_env = { local: "http://localhost:8080", dev: `https://ccapi-dev.${this.DOMAIN}`, stg: `https://ccapi-stg.${this.DOMAIN}`, "prod-qa": `https://ccapi-qa.${this.DOMAIN}`, prod: `https://ccapi.${this.DOMAIN}` };
        return ccapi_url_by_env[this.environment] || ccapi_url_by_env.stg;
    }
    __getPGMicrosUrl() {
        const pg_micros_url_by_env = { stg: `https://pg-micros-stg.${this.DOMAIN}`, prod: `https://pg-micros.${this.DOMAIN}` };
        return pg_micros_url_by_env[this.environment] || pg_micros_url_by_env.stg;
    }
    __getServiceUrl(service) {
        return { get_unixtime: `${this.__getPGMicrosUrl()}/v1/unixtime/`, generate_tokenize: `${this.__getCCAPIUrl()}/v3/card/generate_tokenize/`, tokenize: `${this.__getCCAPIUrl()}/v2/card/add/` }[service];
    }
    __generateAuthToken(unixtime) {
        let uniq_token = getHash(this.application_key + unixtime),
            string_auth_token = `${this.application_code};${unixtime};${uniq_token}`;
        return btoa(string_auth_token);
    }
    __createLoadingElement(element) {
        element.innerHTML = "";
        let loader_container = document.createElement("div");
        loader_container.setAttribute("class", "pg_js_sdk_loader_container");
        let loader = document.createElement("div");
        loader.setAttribute("class", "pg_js_sdk_loader"), loader_container.appendChild(loader), element.appendChild(loader_container);
    }
    __createErrorElement(element, error) {
        element.innerHTML = "";
        let dialog_error = document.createElement("div");
        dialog_error.setAttribute("class", "pg_js_sdk_dialog_error");
        let error_details = document.createElement("span");
        error_details.setAttribute("class", "pg_js_sdk_error_details"), (error_details.innerText = `[${error.type}] ${error.help}: ${error.description}`), dialog_error.appendChild(error_details), element.appendChild(dialog_error);
    }
    __createIframeElement(element, iframe_url) {
        element.innerHTML = "";
        let iframe = document.createElement("iframe");
        iframe.setAttribute("id", "pg_js_sdk_content"), iframe.setAttribute("scrolling", "no"), iframe.setAttribute("frameBorder", "0"), iframe.setAttribute("src", iframe_url), element.appendChild(iframe);
    }
    __iframeResize(sizes) {
        document.querySelector("iframe#pg_js_sdk_content").setAttribute("height", sizes.height);
    }
    __setupListeners() {
        window.addEventListener("message", (event) => {
            if (![this.__getCCAPIUrl()].includes(event.origin)) return console.error("Insecure event"), null;
            let message = event.data;
            switch (message.type) {
                case "iframe_resize":
                    this.__iframeResize(message.data);
                    break;
                case "incomplete_form":
                    this.__incompleteFormCallback(message.data);
                    break;
                case "tokenize_response":
                    this.__tokenizeResponseCallback(message.data);
            }
        });
    }
    __sendMessage(iframe, type, data) {
        let message = { type: type, data: data };
        iframe.contentWindow.postMessage(message, this.__getCCAPIUrl());
    }
    __consumeService(url, method, data, headers, successCallback, errorCallback, timeout = 45) {
        let options = { method: method, timeout: 1e3 * timeout, headers: headers };
        data && (options.body = JSON.stringify(data)),
            fetch(url, options)
                .then((response) => (200 === response.status ? Promise.resolve(response.json()) : response.json().then(Promise.reject.bind(Promise))))
                .then((response) => {
                    successCallback(response);
                })
                .catch((error) => {
                    errorCallback(error);
                });
    }
    __consumeAuthenticatedService(url, method, data, successCallback, errorCallback, timeout = 45) {
        let headers = { "Content-Type": "application/json" };
        const consumeOriginalService = (response) => {
            let unixtime = response.unixtime ? response.unixtime : Math.floor(new Date().getTime() / 1e3);
            (headers["Auth-Token"] = this.__generateAuthToken(String(unixtime))), this.__consumeService(url, method, data, headers, successCallback, errorCallback);
        };
        fetch(this.__getServiceUrl("get_unixtime"), { method: "GET", timeout: 2e3, headers: headers })
            .then((response) => (200 === response.status ? Promise.resolve(response.json()) : response.json().then(Promise.reject.bind(Promise))))
            .then((response) => {
                consumeOriginalService(response);
            })
            .catch((error) => {
                consumeOriginalService(error);
            });
    }
    __addRequiredData(request_data) {
        (request_data.origin = request_data.origin || "SDK_JS"), (request_data.antifraud = { session_id: getSessionId(), location: window.location.origin, user_agent: window.navigator.userAgent });
    }

  generate_tokenize(tokenize_data, parent_node, responseCallback, incompleteFormCallback) {
    let element = document.querySelector(parent_node);
    void 0 !== incompleteFormCallback && (this.__incompleteFormCallback = incompleteFormCallback),
        (this.__tokenizeResponseCallback = (response) => {
            // element.querySelector("#pg_js_sdk_content").remove(),
            responseCallback(response);
        }),
        this.__addRequiredData(tokenize_data),
        this.__createLoadingElement(element);
    let locale = tokenize_data.locale || "en";
    this.__consumeAuthenticatedService(
        this.__getServiceUrl("generate_tokenize"),
        "POST",
        tokenize_data,
        (response) => {
            if (!response.hasOwnProperty("tokenize_url")) throw "Response without tokenize url";
            this.__createIframeElement(element, response.tokenize_url);
        },
        (error_response) => {
            console.error(error_response);
            let server_error = { error: { type: __(locale, "error_type"), help: __(locale, "error_help"), description: __(locale, "error_description") } };
            (error_response = error_response.hasOwnProperty("error") ? error_response.error : server_error.error), this.__createErrorElement(element, error_response);
        }
    );
}

tokenize() {
    let iframe = document.querySelector("iframe#pg_js_sdk_content");
    if (!this.__tokenizeResponseCallback || !iframe) throw "Generate tokenize reference is required";
    this.__sendMessage(iframe, "tokenize", null);
}

  render() {
    return null;
  }
}

export default PaymentGateway;