import React, { useState, useEffect, useContext } from 'react';
import { authToken } from '../../../infra/constants/tokens.video.sdk';
import { videoSDK_api } from '../../../infra/api.js'
import { videoSdkURL } from '../../../infra/urls';
import UserContext from '../../../Context/user/userProvider';

function useInitianRoom(code) {
  const [meetingId, setMeetingId] = useState(code || '');
  const [creatingRoom, setCreatingRoom] = useState(false);
  const [isValidatingRoom, setIsValidatingRoom] = useState(false);
  const [isDisabledRoom, setIsDisabledRoom] = useState(false);
  const { userDetail } = useContext(UserContext);

  const user = {
    name: userDetail?.name || '',
    avatar: userDetail?.profile_photo_url || '',
    rol: userDetail?.roles || ''
  };

  async function createMeeting() {
    if (meetingId || creatingRoom) return;
    try {
      setCreatingRoom(true)
      const response = await videoSDK_api.post(videoSdkURL?.post?.rooms,
        {},
        {
          headers: {
            authorization: authToken,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response) {
        const roomId = response?.data?.roomId;
        setMeetingId(roomId);
        setIsDisabledRoom(true);
      }
    } catch (error) {
      setIsDisabledRoom(false);
      console.log("Error in createMeeting():", error)
    }
    finally {
      setCreatingRoom(false)
    }
  };


  const validateMeeting = async () => {
    try {
      setIsValidatingRoom(true)
      setIsDisabledRoom(true);
      const response = await videoSDK_api.get(
        `${videoSdkURL?.get?.validatedMeeting}${meetingId}`,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json"
          },
        }
      );
      if (response) {
        setIsDisabledRoom(response?.data?.disabled)
      }
    } catch (error) {
      console.log("Erro in validateMeeting()", error)
      setIsDisabledRoom(true);
    }
    finally {
      setIsValidatingRoom(false)
    }
  };


  function onMeetingLeave() {
    setMeetingId('');
  }

  const getMeetingAndToken = async (id) => {
    try {
      const newToken = id ? id : await createMeeting();
      if (newToken) {
        setIsDisabledRoom(true);
        setMeetingId(newToken);
      }
    } catch (error) {
      setIsDisabledRoom(false);
      console.log("Error in getMeetingAndToken()", error)
    }
  }


  useEffect(() => {
    if (meetingId !== '') {
      validateMeeting()
    }
  }, [meetingId]);

  return {
    meetingId,
    getMeetingAndToken,
    onMeetingLeave,
    creatingRoom,
    user,
    isValidatingRoom,
    isDisabledRoom
  }
}

export default useInitianRoom;