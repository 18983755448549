import { useState, useEffect } from "react";

function usePqrsPrimaryScreen() {
  const [activatedTab, setActivatedTab] = useState(0);
  const [isEstablishedSession, setIsEstablishedSession] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);

  const dataPqrs = [
    {
      id: "person",
      name: "Turismo Tradicional",
      isActive: true,
      videos: [
        { name: "video 1", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        {
          name: "video videovideo f video 3",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
        { name: "video 4", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "video 5", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        { name: "video 6", url: "https://www.youtube.com/watch?v=Vua0Skln52A" },
        { name: "video 7", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "video 8", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        { name: "video 9", url: "https://www.youtube.com/watch?v=Vua0Skln52A" },
        {
          name: "video 10",
          url: "https://www.youtube.com/watch?v=DbLUdJcv0uk",
        },
        {
          name: "video 11",
          url: "https://www.youtube.com/watch?v=NNu7jyCwnD8",
        },
        {
          name: "video 12",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
        {
          name: "video 13",
          url: "https://www.youtube.com/watch?v=DbLUdJcv0uk",
        },
        {
          name: "video 14",
          url: "https://www.youtube.com/watch?v=NNu7jyCwnD8",
        },
        {
          name: "video 15",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
      ],
      faq: {
        en: [
          {
            title: "¿Qué es el turismo tradicional?",
            content:
              "El turismo tradicional se refiere a los viajes que se realizan con fines de ocio, exploración cultural y recreación en destinos populares y conocidos, utilizando servicios turísticos convencionales como hoteles, guías y agencias de viaje.",
          },
          {
            title:
              "¿Cuáles son los destinos turísticos tradicionales más populares?",
            content:
              "Algunos de los destinos turísticos tradicionales más populares incluyen París, Roma, Nueva York, Londres, Tokio, Cancún, y destinos naturales como el Gran Cañón, las Pirámides de Egipto y la Gran Muralla China.",
          },
          {
            title:
              "¿Qué tipo de actividades se pueden realizar en el turismo tradicional?",
            content:
              "Actividades comunes incluyen visitas a museos, tours históricos, degustación de gastronomía local, compras, paseos por sitios emblemáticos, excursiones guiadas, y disfrutar de playas y resorts.",
          },
          {
            title: "¿Cuál es la mejor época del año para viajar?",
            content:
              "La mejor época para viajar varía según el destino. Por ejemplo, Europa es popular en primavera y verano, mientras que destinos tropicales como el Caribe son ideales durante el invierno para evitar la temporada de huracanes.",
          },
          {
            title: "¿Cómo puedo encontrar las mejores ofertas de viajes?",
            content:
              "Las mejores ofertas se pueden encontrar comparando precios en línea, suscribiéndose a boletines de agencias de viajes, utilizando aplicaciones de comparación de tarifas y aprovechando las promociones de última hora.",
          },
          {
            title: "¿Qué debo considerar al reservar un hotel?",
            content:
              "Factores a considerar incluyen la ubicación, las comodidades, las opiniones de otros huéspedes, el precio, la cercanía a atracciones turísticas y la política de cancelación.",
          },
          {
            title: "¿Es necesario contratar un seguro de viaje?",
            content:
              "Aunque no siempre es obligatorio, es altamente recomendable contratar un seguro de viaje que cubra emergencias médicas, cancelaciones, pérdida de equipaje y otros imprevistos.",
          },
          {
            title: "¿Cuáles son los documentos necesarios para viajar?",
            content:
              "Generalmente, necesitarás un pasaporte válido, y dependiendo del destino, un visado. También es útil llevar copias de tus documentos de identificación, reservas de hotel y boletos de avión.",
          },
          {
            title:
              "¿Cómo puedo evitar el jet lag al viajar a diferentes zonas horarias?",
            content:
              "Algunos consejos incluyen ajustar tu horario de sueño unos días antes del viaje, mantenerse hidratado, evitar alcohol y cafeína, y exponerse a la luz natural al llegar a tu destino.",
          },
          {
            title: "¿Qué medidas de seguridad debo tomar al viajar?",
            content:
              "Mantén tus pertenencias seguras, evita mostrar objetos de valor, infórmate sobre las áreas seguras y peligrosas del destino, sigue las recomendaciones locales, y ten a mano los contactos de emergencia.",
          },
        ],
        es: [
          {
            title: "¿Qué es el turismo tradicional?",
            content:
              "El turismo tradicional se refiere a los viajes que se realizan con fines de ocio, exploración cultural y recreación en destinos populares y conocidos, utilizando servicios turísticos convencionales como hoteles, guías y agencias de viaje.",
          },
          {
            title:
              "¿Cuáles son los destinos turísticos tradicionales más populares?",
            content:
              "Algunos de los destinos turísticos tradicionales más populares incluyen París, Roma, Nueva York, Londres, Tokio, Cancún, y destinos naturales como el Gran Cañón, las Pirámides de Egipto y la Gran Muralla China.",
          },
          {
            title:
              "¿Qué tipo de actividades se pueden realizar en el turismo tradicional?",
            content:
              "Actividades comunes incluyen visitas a museos, tours históricos, degustación de gastronomía local, compras, paseos por sitios emblemáticos, excursiones guiadas, y disfrutar de playas y resorts.",
          },
          {
            title: "¿Cuál es la mejor época del año para viajar?",
            content:
              "La mejor época para viajar varía según el destino. Por ejemplo, Europa es popular en primavera y verano, mientras que destinos tropicales como el Caribe son ideales durante el invierno para evitar la temporada de huracanes.",
          },
          {
            title: "¿Cómo puedo encontrar las mejores ofertas de viajes?",
            content:
              "Las mejores ofertas se pueden encontrar comparando precios en línea, suscribiéndose a boletines de agencias de viajes, utilizando aplicaciones de comparación de tarifas y aprovechando las promociones de última hora.",
          },
          {
            title: "¿Qué debo considerar al reservar un hotel?",
            content:
              "Factores a considerar incluyen la ubicación, las comodidades, las opiniones de otros huéspedes, el precio, la cercanía a atracciones turísticas y la política de cancelación.",
          },
          {
            title: "¿Es necesario contratar un seguro de viaje?",
            content:
              "Aunque no siempre es obligatorio, es altamente recomendable contratar un seguro de viaje que cubra emergencias médicas, cancelaciones, pérdida de equipaje y otros imprevistos.",
          },
          {
            title: "¿Cuáles son los documentos necesarios para viajar?",
            content:
              "Generalmente, necesitarás un pasaporte válido, y dependiendo del destino, un visado. También es útil llevar copias de tus documentos de identificación, reservas de hotel y boletos de avión.",
          },
          {
            title:
              "¿Cómo puedo evitar el jet lag al viajar a diferentes zonas horarias?",
            content:
              "Algunos consejos incluyen ajustar tu horario de sueño unos días antes del viaje, mantenerse hidratado, evitar alcohol y cafeína, y exponerse a la luz natural al llegar a tu destino.",
          },
          {
            title: "¿Qué medidas de seguridad debo tomar al viajar?",
            content:
              "lMantén tus pertenencias seguras, evita mostrar objetos de valor, infórmate sobre las áreas seguras y peligrosas del destino, sigue las recomendaciones locales, y ten a mano los contactos de emergencia.",
          }
        ],
      },
    },

    {
      id: "tourisnMedical",
      name: "Turismo en Salud",
      isActive: true,
      videos: [
        { name: "video 1", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        {
          name: "video videovideo f video 3",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
        { name: "video 4", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "video 5", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        { name: "video 6", url: "https://www.youtube.com/watch?v=Vua0Skln52A" },
        { name: "video 7", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "video 8", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        { name: "video 9", url: "https://www.youtube.com/watch?v=Vua0Skln52A" },
        {
          name: "video 10",
          url: "https://www.youtube.com/watch?v=DbLUdJcv0uk",
        },
        {
          name: "video 11",
          url: "https://www.youtube.com/watch?v=NNu7jyCwnD8",
        },
        {
          name: "video 12",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
        {
          name: "video 13",
          url: "https://www.youtube.com/watch?v=DbLUdJcv0uk",
        },
        {
          name: "video 14",
          url: "https://www.youtube.com/watch?v=NNu7jyCwnD8",
        },
        {
          name: "video 15",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
      ],
      faq: {
        en: [
          { title: "¿Qué es el turismo en salud?", content: "El turismo tradicional se refiere a los viajes que se realizan con fines de ocio, exploración cultural y recreación en destinos populares y conocidos, utilizando servicios turísticos convencionales como hoteles, guías y agencias de viaje." },
          {
            title:
              "¿Cuáles son los tratamientos más comunes en el turismo en salud?",
            content: "Algunos de los destinos turísticos tradicionales más populares incluyen París, Roma, Nueva York, Londres, Tokio, Cancún, y destinos naturales como el Gran Cañón, las Pirámides de Egipto y la Gran Muralla China.",
          },
          {
            title: "¿Por qué elegir el turismo en salud?",
            content: "Actividades comunes incluyen visitas a museos, tours históricos, degustación de gastronomía local, compras, paseos por sitios emblemáticos, excursiones guiadas, y disfrutar de playas y resorts.",
          },
          {
            title: "¿Qué países son populares para el turismo en salud?",
            content: "La mejor época para viajar varía según el destino. Por ejemplo, Europa es popular en primavera y verano, mientras que destinos tropicales como el Caribe son ideales durante el invierno para evitar la temporada de huracanes.",
          },
          {
            title: "¿Cómo puedo planificar un viaje de turismo en salud?",
            content: "Las mejores ofertas se pueden encontrar comparando precios en línea, suscribiéndose a boletines de agencias de viajes, utilizando aplicaciones de comparación de tarifas y aprovechando las promociones de última hora.",
          },
          {
            title: "¿Es necesario contratar un seguro de salud para el viaje?",
            content: "Factores a considerar incluyen la ubicación, las comodidades, las opiniones de otros huéspedes, el precio, la cercanía a atracciones turísticas y la política de cancelación.",
          },
          {
            title:
              "¿Qué documentos necesito para mi viaje de turismo en salud?",
            content: "Aunque no siempre es obligatorio, es altamente recomendable contratar un seguro de viaje que cubra emergencias médicas, cancelaciones, pérdida de equipaje y otros imprevistos.",
          },
          {
            title: "¿Qué riesgos están asociados con el turismo en salud?",
            content: "Generalmente, necesitarás un pasaporte válido, y dependiendo del destino, un visado. También es útil llevar copias de tus documentos de identificación, reservas de hotel y boletos de avión.",
          },
          {
            title:
              "¿Qué debo considerar en cuanto a la recuperación post-tratamiento?",
            content: "Algunos consejos incluyen ajustar tu horario de sueño unos días antes del viaje, mantenerse hidratado, evitar alcohol y cafeína, y exponerse a la luz natural al llegar a tu destino.",
          },
          {
            title: "¿Puedo combinar turismo en salud con vacaciones?",
            content: "Mantén tus pertenencias seguras, evita mostrar objetos de valor, infórmate sobre las áreas seguras y peligrosas del destino, sigue las recomendaciones locales, y ten a mano los contactos de emergencia.",
          }
        ],
        es: [
          { title: "¿Qué es el turismo en salud?", content: "El turismo tradicional se refiere a los viajes que se realizan con fines de ocio, exploración cultural y recreación en destinos populares y conocidos, utilizando servicios turísticos convencionales como hoteles, guías y agencias de viaje." },
          {
            title:
              "¿Cuáles son los tratamientos más comunes en el turismo en salud?",
            content: "Algunos de los destinos turísticos tradicionales más populares incluyen París, Roma, Nueva York, Londres, Tokio, Cancún, y destinos naturales como el Gran Cañón, las Pirámides de Egipto y la Gran Muralla China.",
          },
          {
            title: "¿Por qué elegir el turismo en salud?",
            content: "Actividades comunes incluyen visitas a museos, tours históricos, degustación de gastronomía local, compras, paseos por sitios emblemáticos, excursiones guiadas, y disfrutar de playas y resorts.",
          },
          {
            title: "¿Qué países son populares para el turismo en salud?",
            content: "La mejor época para viajar varía según el destino. Por ejemplo, Europa es popular en primavera y verano, mientras que destinos tropicales como el Caribe son ideales durante el invierno para evitar la temporada de huracanes.",
          },
          {
            title: "¿Cómo puedo planificar un viaje de turismo en salud?",
            content: "Las mejores ofertas se pueden encontrar comparando precios en línea, suscribiéndose a boletines de agencias de viajes, utilizando aplicaciones de comparación de tarifas y aprovechando las promociones de última hora.",
          },
          {
            title: "¿Es necesario contratar un seguro de salud para el viaje?",
            content: "Factores a considerar incluyen la ubicación, las comodidades, las opiniones de otros huéspedes, el precio, la cercanía a atracciones turísticas y la política de cancelación.",
          },
          {
            title:
              "¿Qué documentos necesito para mi viaje de turismo en salud?",
            content: "Aunque no siempre es obligatorio, es altamente recomendable contratar un seguro de viaje que cubra emergencias médicas, cancelaciones, pérdida de equipaje y otros imprevistos.",
          },
          {
            title: "¿Qué riesgos están asociados con el turismo en salud?",
            content: "Generalmente, necesitarás un pasaporte válido, y dependiendo del destino, un visado. También es útil llevar copias de tus documentos de identificación, reservas de hotel y boletos de avión.",
          },
          {
            title:
              "¿Qué debo considerar en cuanto a la recuperación post-tratamiento?",
            content: "Algunos consejos incluyen ajustar tu horario de sueño unos días antes del viaje, mantenerse hidratado, evitar alcohol y cafeína, y exponerse a la luz natural al llegar a tu destino.",
          },
          {
            title: "¿Puedo combinar turismo en salud con vacaciones?",
            content: "Mantén tus pertenencias seguras, evita mostrar objetos de valor, infórmate sobre las áreas seguras y peligrosas del destino, sigue las recomendaciones locales, y ten a mano los contactos de emergencia.",
          }
        ],
      },
    },
  ];

  useEffect(() => {
    if (dataPqrs && dataPqrs.length > 0) {
      for (const i in dataPqrs) {
        if (dataPqrs[i]?.isActive) {
          setActivatedTab(dataPqrs[i].id);
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (activatedTab) {
      const pqrsSelected = dataPqrs.find((obj) => obj.id === activatedTab);
      if (pqrsSelected) setDataSelected({ ...pqrsSelected });
    }
  }, [activatedTab]);

  return {
    activatedTab,
    setActivatedTab,
    dataPqrs,
    isEstablishedSession,
    dataSelected,
  };
}

export default usePqrsPrimaryScreen;

/*
  "Persona": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  },
  "Doctor": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  },
  "Clinica": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  },
  "Turismo": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  },
  "Salud": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  }*/
