import React, { useRef, useState, useEffect } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../../../share/components/iconSVG";
import { RowFiveColumnsTitle, RowFiveColumnsInfo } from "../rowFiveColumns";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import TextComponent from "../../../../../share/components/fonts/TextComponent";
import { MdCameraAlt } from "react-icons/md";

const Modal = ({ show, onClose, children }) => {
  return (
    <div
      className={`${
        show ? "FlightInfoContentModal" : "FlightInfoContentModal close"
      }`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          onClose();
        }
      }}
    >
      <div
        className={`${show ? "FlightInfoContent" : "FlightInfoContent close"}`}
      >
        <button className="FlightInfoButtonClose" onClick={onClose}>
          <MdClose size={23} color="#104172" />
        </button>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

const ComponentScales = ({ count }) => {
  const [t, i18n] = useTranslation("global");
  const getScale = count.length > 1;
  return getScale
    ? t("fligth.list_flights.scale")
    : t("fligth.list_flights.direct");
};

function ContainerChecked({
  flight,
  activedJourney,
  setActivedJourney,
  id,
  name,
}) {
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className="ContainerChecked">
      <div className="ContainerCheckedUp">
        <div className="checkActive" onClick={() => setActivedJourney(flight)}>
          {activedJourney?.num === flight?.num && (
            <div className="checkInactive"></div>
          )}
        </div>
        <div className="ContainerCheckedLogo">
          {isImageLoaded ? (
            <img
              src={`https://pics.avs.io/60/60/${id?.marketingCarrier}.png`}
              alt={id?.marketingCarrier}
              className="ContainerCheckedLogoFile"
              onError={handleImageError}
              onLoad={handleImageLoad}
            />
          ) : (
            <div className="img-fligths-skeleton">
              <MdCameraAlt size={25} color="#c8c8c8" />
            </div>
          )}
        </div>
      </div>
      <TextComponent
        children={name}
        className={"ContainerCheckedName"}
        size={"min"}
        fontWeight="normal"
      />
    </div>
  );
}

function BagsContainer({ list }) {
  const isAvailable = (key) => {
    const bag = key + 1;
    return bag <= list;
  };
  const bagsLis = [
    iconList?.IconBagSmall,
    iconList?.IconBagMedium,
    iconList?.IconBagBig,
  ];
  return (
    <div className="bagsContainer">
      {bagsLis.map((index, key) => {
        let isAvailableBag = key <= list ? true : false;
        return (
          <div key={key} className="bagsContainerIcon">
            <SvgIcon
              name={bagsLis[key]}
              color={isAvailableBag ? "#104172" : "#1d1d1d45"}
              size={50}
            />
          </div>
        );
      })}
    </div>
  );
}

function ListFlights({
  isRoundTrip,
  Seg1,
  Seg2,
  nameOrigin,
  nameDestiny,
  recommendation,
  outwardJourney,
  setOutwardJourney,
  returnJourney,
  setReturnJourney,
}) {
  const containerRef = useRef(null);
  const [t, i18n] = useTranslation("global");
  const [isShowScales, setIsShowScales] = useState(false);
  const [arraySegs, setArraySegs] = useState([Seg1, Seg2]);

  const [openModalFlight, setOpenModalFlight] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [isShowState, setIsShowState] = useState("");

  const [showAllIda, setShowAllIda] = useState(false);
  const [showAllVuelta, setShowAllVuelta] = useState(false);

  const recommendationSegsList = [
    recommendation?.seg?.Seg1,
    recommendation?.seg?.Seg2,
  ];

  const extractDate = (obj) => {
    if (obj === undefined) {
      return "No hay fecha";
    } else {
      const respuesta =
        obj[0]?.segments[0]?.productDateTime?.dateOfDeparture || "hola";

      return respuesta;
    }
  };

  const handleOpenModal = (flight, isShowState) => {
    setSelectedFlight(flight);
    setIsShowState(isShowState);
    setOpenModalFlight(true);
  };

  const RenderFlight = ({ flight, firstSegment, key, isOutward }) => {
    return (
      <div
        className={`flightContainerOptionSelect fade-in ${
          key >= 3
            ? isOutward
              ? showAllIda
                ? "show"
                : "hide"
              : showAllVuelta
              ? "show"
              : "hide"
            : ""
        }`}
        key={key}
      >
        <div className="flightContainerOptionSelectReponsive">
          <div className="nameFlightsBags">
            <ContainerChecked
              flight={flight}
              activedJourney={isOutward ? outwardJourney : returnJourney}
              setActivedJourney={
                isOutward ? setOutwardJourney : setReturnJourney
              }
              id={firstSegment?.companyId}
              name={firstSegment?.companyName}
            />
          </div>
          <RowFiveColumnsInfo
            key={key}
            col1={
              <p className="fiveColumnsTextBig">
                <span className="fiveColumnsHour">
                  {firstSegment?.productDateTime?.timeOfDeparture}
                </span>
              </p>
            }
            col2={
              <RowFiveColumnsTitle
                flight={flight}
                type={
                  isOutward === true
                    ? t("fligth.search_fligth.going")
                    : t("fligth.search_fligth.return")
                }
                origin={isOutward === true ? nameOrigin : nameDestiny}
                destiny={isOutward === true ? nameDestiny : nameOrigin}
                date={extractDate(arraySegs[isOutward ? 0 : 1])}
                isOurwar={isOutward}
                showModal={handleOpenModal}
              />
            }
            col3={
              <p className="fiveColumnsTextBig">
                <span className="fiveColumnsHour">
                  {firstSegment?.productDateTime?.timeOfArrival}
                </span>
              </p>
            }
          />
          <BagsContainer list={recommendation?.bag[isOutward ? 0 : 1]} />
        </div>

        <button
          className="fiveButtonArrow"
          onClick={() => handleOpenModal(flight, isOutward ? "Ida" : "Vuelta")}
        >
          <IoIosArrowDown size={23} color="#104172" />
        </button>
      </div>
    );
  };

  useEffect(() => {
    setArraySegs([Seg1, Seg2]);
  }, [Seg1, Seg2]);

  return (
    <div className="componentChild" ref={containerRef}>
      <Modal
        show={openModalFlight}
        state={isShowState}
        onClose={() => setOpenModalFlight(false)}
      >
        <div className="componentChildContainerModal">
          <div className="content-render-scales">
            {selectedFlight &&
              selectedFlight?.segments.map((segment, key) => {
                return (
                  <div className="flightContainerOptionSelectModal" key={key}>
                    <div className="componentChildIdaModal">{isShowState}</div>
                    <div className="ContainerCheckedModal">
                      <div className="ContainerCheckedUp">
                        <div className="ContainerCheckedLogo">
                          <img
                            src={`https://pics.avs.io/60/60/${segment?.companyId?.marketingCarrier}.png`}
                            alt={segment?.companyId?.marketingCarrier}
                            className="ContainerCheckedLogoFile"
                          />
                        </div>
                        <TextComponent
                          children={segment?.productDateTime?.dateOfDeparture}
                          size={"md"}
                        />
                      </div>
                      <TextComponent
                        children={segment?.companyName}
                        size={"md"}
                        fontWeight="bold"
                        className={"ContainerCheckedNameModal"}
                      />
                    </div>
                    <RowFiveColumnsInfo
                      openModalFlight={openModalFlight}
                      modal={true}
                      col1={
                        <p className="fiveColumnsTextBig">
                          <span className="fiveColumnsHour">
                            {segment?.productDateTime?.timeOfDeparture}
                          </span>
                          {segment?.location[0]?.locationName}
                        </p>
                      }
                      col2={
                        <RowFiveColumnsTitle
                          type={
                            isShowState === "Ida"
                              ? t("fligth.search_fligth.going")
                              : t("fligth.search_fligth.return")
                          }
                          origin={segment?.location[0]?.locationId}
                          destiny={segment?.location[1]?.locationId}
                          date={extractDate([selectedFlight])}
                          modal={true}
                          flight={selectedFlight}
                        />
                      }
                      col3={
                        <p className="fiveColumnsTextBigRight">
                          <span className="fiveColumnsHour">
                            {segment?.productDateTime?.timeOfArrival}
                          </span>
                          {segment?.location[1]?.locationName}
                        </p>
                      }
                    />
                  </div>
                );
              })}
          </div>

          <div className="componentBagsContainerModal">
            <div className="componentBagsContainerSectionModal">
              <div className="componentBagsContainerSeparationModal">
                <TextComponent
                  children={"Equipaje"}
                  size={"sm"}
                  fontWeight="bold"
                />
              </div>
              <BagsContainer
                list={recommendation?.bag[isShowState === "Ida" ? 0 : 1]}
              />
            </div>
            <TextComponent size={"sm"} fontWeight="bold">
              {recommendation?.bag[isShowState === "Ida" ? 0 : 1] == 0
                ? "Dimensiones Permitidas: Bolso o mochila pequeña: 45 x 35 x 20 cm. Las medidas y peso del equipaje incluido pueden variar, consulta en el sitio de la aerolínea."
                : recommendation?.bag[isShowState === "Ida" ? 0 : 1] == 1
                ? "Dimensiones Permitidas: Bolso o mochila pequeña, Un equipaje de mano: 55 x 35 x 25 cm, máximo 10 kg. Las medidas y peso del equipaje incluido pueden variar, consulta en el sitio de la aerolínea."
                : "Dimensiones Permitidas: Bolso o mochila pequeña, Un equipaje de mano: 55 x 35 x 25 cm, máximo 10 kg, Equipaje en Bodega: 158cm. lineales y peso máximo 23 kg. Las medidas y peso del equipaje incluido pueden variar, consulta en el sitio de la aerolínea."}
            </TextComponent>
          </div>
        </div>
      </Modal>
      <div className="componentChildContainer">
        <div className="componentChildIda">Ida</div>
        {(showAllIda
          ? recommendationSegsList[0]
          : recommendationSegsList[0].slice(0, 3)
        ).map((segmentFlightRefItem, key) => {
          const flight = arraySegs[0]?.find(
            (obj) => obj?.num == segmentFlightRefItem?.refNumber
          );
          const firstSegment = flight?.segments[0];
          if (key === 0 && !outwardJourney) {
            setOutwardJourney(flight);
          }
          return RenderFlight({ flight, firstSegment, key, isOutward: true });
        })}
        {recommendationSegsList[0].length > 3 && (
          <button
            className="componentChildIdaButtonViewMore"
            onClick={() => setShowAllIda(!showAllIda)}
          >
            {showAllIda ? "Ocultar" : "Ver más"}
            {showAllIda ? (
              <MdKeyboardArrowUp size={20} />
            ) : (
              <MdKeyboardArrowDown size={20} />
            )}
          </button>
        )}
      </div>

      {isRoundTrip === "true" && (
        <>
          <div className="componentChildContainer">
            <div className="componentChildIda">Vuelta</div>
            {(showAllVuelta
              ? recommendationSegsList[1]
              : recommendationSegsList[1]?.slice(0, 3)
            )?.map((segmentFlightRefItem, key) => {
              const flight = arraySegs[1]?.find(
                (obj) => obj?.num == segmentFlightRefItem?.refNumber
              );
              const firstSegment = flight?.segments[0];

              if (key === 0 && !returnJourney) {
                setReturnJourney(flight);
              }
              return RenderFlight({
                flight,
                firstSegment,
                key,
                isOutward: false,
              });
            })}
            {recommendationSegsList[1]?.length > 3 && (
              <button
                className="componentChildIdaButtonViewMore"
                onClick={() => setShowAllVuelta(!showAllVuelta)}
              >
                {showAllVuelta ? "Ocultar" : "Ver más"}
                {showAllVuelta ? (
                  <MdKeyboardArrowUp size={20} />
                ) : (
                  <MdKeyboardArrowDown size={20} />
                )}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ListFlights;
