import { setDestinySelectedTransladeData } from '@/store/slices/dataEnginePackage/dataEnginePackageSlice';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';

const InputDestinyTranslate = ({
    originSelected,
    destinyList,
    findDestiny,
    destinySelected,
    setOriginVerify,
    loadingDestiny,
    setDestinySelected,
    setDestinyList,
    setDestinyVerify
}) => {
    // Variables
    const dispatch = useDispatch();

    const destinyRef = useRef(null);

    const [destinyFocus, setDestinyFocus] = useState(false);
    const [showCardDestiny, setShowCardDestiny] = useState(false)
    
    const destinySelectedTransladeData = useSelector((state) => state.dataSearchEngine.destinySelectedTransladeData);

    // UseEffects
    useEffect(() => {
        setDestinySelected(destinySelectedTransladeData);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (destinyRef.current && !destinyRef.current.contains(event.target)) {
                setDestinyFocus(false);
                setShowCardDestiny(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [destinyRef]);

    useEffect(() => {
        if (destinyList.length > 0) {
            setShowCardDestiny(true);
        } else {
            setShowCardDestiny(false);
        }
    }, [destinyList]);

    useEffect(() => {
        dispatch(setDestinySelectedTransladeData(destinySelected));
    }, [destinySelected]);

    return(
        <div
            className="searchBuildPackageContentInput"
            ref={destinyRef}
            onClick={() => {
                if (
                    originSelected === ""
                ) {
                    setOriginVerify(true);
                    setDestinyFocus(true);
                } else {
                    findDestiny();
                    setDestinyFocus(true);
                }
            }}
        >
            <div className="searchBuildPackageContentInputContent">
                <p>Destino de traslado</p>
                <input type="text" placeholder="Elige un origen" value={destinySelected.name} readOnly />
            </div>
            {
                loadingDestiny ? (
                    <CircularProgress
                        size={30}
                        style={{
                            right: "5px",
                            position: "absolute",
                            top: "11px",
                        }}
                    />
                ) : (
                    <TiArrowSortedDown size={20} color="var(--bluePrimary)" />
                )
            }
            {
                showCardDestiny && destinyFocus ? (
                    <div className="dropdown-ciudad shadow">
                        <ul className="contentElemetsSearchInputBuildPackage">
                            {
                                destinyList.map((destiny) => (
                                    <li
                                        key={destiny.id}
                                        onClick={(e) => {
                                            setDestinySelected(destiny);
                                            setDestinyFocus(false);
                                            setDestinyList([]);
                                            setDestinyVerify(false);
                                            e.stopPropagation();
                                            localStorage.setItem(
                                                "destinyTransport",
                                                JSON.stringify(destiny)
                                            );
                                        }}
                                    >
                                        {destiny.name}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                ) : null
            }
        </div>
    );
};

export default InputDestinyTranslate;