import React from "react";
import "./styles.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const Paginado = ({
  nextHandler,
  prevHandler,
  currentPage,
  data,
  mostrarNext,
}) => {
  return (
    <div className="contain-Paginado-specialist">
      {data.length - currentPage === data.length ? (
        ""
      ) : (
        <button onClick={prevHandler}><IoIosArrowBack /></button>
      )}

      <button>{currentPage + 1}</button>
      {mostrarNext === false ? (
        ""
      ) : (
        <button onClick={nextHandler}>
          <IoIosArrowForward />
        </button>
      )}
    </div>
  );
};

export default Paginado;
