import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { MdCameraAlt } from "react-icons/md";
import { IoLocationOutline, IoEyeOutline } from "react-icons/io5";

const CardSpecialist = ({ data, detailDoctor,seeButton,nextStep }) => {
  const {
    name,
    image_profile,
    categories,
    cities,
    id,
    title,
    description,
    location,
    slug,
    
  } = data;

  const [imageLoaded, setImageLoaded] = useState(false);

  // Límite de ciudades a mostrar
  const cityLimit = 2;

  return (
    <div className={styles["grid-item"]}>
      <div className={styles["grid-item-content-doc"]}>
        <div className={styles["grid-item-content-img-doc"]}>
          {!imageLoaded && (
            <div className={styles.img_specialist_skeleton}>
              <MdCameraAlt size={40} color="var(--greyPrimary)" />
            </div>
          )}
          <img
            src={`https://pdtclientsolutions.com/crm-public/img/doctors/profile/${image_profile}`}
            alt={name}
            onLoad={() => setImageLoaded(true)}
            style={{ display: imageLoaded ? "block" : "none" }}
          />
        </div>

        <div className={styles["grid-item-info-doc-card"]}>
          <div className={styles["grid-item-info-text-doc"]}>
            <div className={styles["grid-item-info-doc"]}>
              <h4 className={styles["grid-item-info-title"]}>{name}</h4>
            </div>

            <div className={styles["grid-item-icon"]}>
              {categories?.[0]?.name ?? "Sin especialidad"}
            </div>
          </div>

          <div className={styles["grid-item-info-buttons"]}>
            <div className={styles["grid-item-icon-doc"]}>
              <IoLocationOutline size={25} color="#00AFEB" />
              {
                cities?.length > 0 ?
                  cities?.length > cityLimit
                    ? `${cities
                      .slice(0, cityLimit)
                      .map((city) => city.name)
                      .join(", ")}...`
                    : cities.map((city) => city.name).join(", ")
                  :

                  "No hay informacion"
              }
            </div>
          </div>

          <div className={styles["grid-item-actions"]}>
            <span className={styles["grid-item-link"]} onClick={() => detailDoctor(data, slug)}>
              <IoEyeOutline size={20} /> Ver más
            </span>
            {
              seeButton &&
              <button onClick={()=> nextStep(slug, data)} className={styles["grid-item-select-button"]}>
                Seleccionar
              </button>
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSpecialist;
