import TooltipContainer from '@/ui/views/share/components/tooltipContainer';
import React, { useEffect, useRef, useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { setTypeTravelTranslateData } from '@/store/slices/dataEnginePackage/dataEnginePackageSlice';

const InputTypeTravelTranslate = ({
    typeT,
    setTypeT
}) => {
    // Variables
    const dispatch = useDispatch();

    const typeTravelRef = useRef(null);
    const inputRef = useRef(null);

    const [ShowCardTypeTravel, setShowCardTypeTravel] = useState(false);
    const [typeTranslate, setTypeTranslate] = useState("");
    
    const typeTravelTranslateData = useSelector((state) => state.dataSearchEngine.typeTravelTranslateData);

    const options = [
        {
            id: 1,
            name: "Ida aeropuerto",
            value: "ONLY_TO_AIRPORT"
        },
        {
            id: 2,
            name: "Ida hotel",
            value: "ONLY_FROM_AIRPORT"
        },
        {
            id: 3,
            name: "Ida y vuelta",
            value: "ROUND_TRIP"
        }
    ];

    // Functions
    const handleClick = () => {
        setShowCardTypeTravel(!ShowCardTypeTravel);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    // UseEffects
    useEffect(() => {
        typeT = typeTravelTranslateData;

        const handleClickOutside = (event) => {
            if(typeTravelRef.current && !typeTravelRef.current.contains(event.target)){
                setShowCardTypeTravel(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(typeT === "ONLY_TO_AIRPORT"){
            setTypeTranslate("Ida aeropuerto");

        }else if(typeT === "ONLY_FROM_AIRPORT"){
            setTypeTranslate("Ida hotel");

        }else if(typeT === "ROUND_TRIP"){
            setTypeTranslate("Ida y vuelta");
            
        }

        dispatch(setTypeTravelTranslateData(typeT));
    }, [typeT]);

    return(
        <div className="searchBuildPackageContentInput" onClick={handleClick} ref={typeTravelRef}>
            <div className="searchBuildPackageContentInputContent">
                <p>Tipo de viaje de traslado</p>
                <input type="text" placeholder="Elije un tipo de viaje" value={typeTranslate} ref={inputRef} readOnly/>
            </div>
            <TiArrowSortedDown size={20} color="var(--bluePrimary)" />
            {
                ShowCardTypeTravel && (
                    <TooltipContainer
                        show={ShowCardTypeTravel}
                        hidden={setShowCardTypeTravel}
                        sizeWidth={100}
                        sizeHeight={80}
                    >
                        <ul className="contentElemetsSearchInputBuildPackage">
                            {
                                options.map((option) => (
                                    <li key={option.id} onClick={() => setTypeT(option.value)}>
                                        {option.name}
                                    </li>
                                ))
                            }
                        </ul>
                    </TooltipContainer>
                )
            }
        </div>
    );
};

export default InputTypeTravelTranslate;