import React from "react";
import styles from "./styles.module.css";

const Data = ({title, data, border = false}) => {

    return (
        <div className={border ? styles.containerBorder :styles.container}>
            <p className={styles.title}>{title}</p>
            <p className={styles.data}>{data}</p>
        </div>
    );
};

export default Data;