import axios from "axios"
import { useEffect, useState } from "react"

const UsePayPackages = ()=>{
    const [loading, setLoading] = useState(true)
    const [idRequest, setIdRequest] = useState(localStorage.getItem("pay-id-packages"))
    const [incompleteInformation, setIncompleteInformation] = useState("")
    const [statePay, setStatePay] = useState()
    const getStatePay = ()=>{
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/reserve/medical-packacage`
        axios.post(url,{
          request_id: Number(idRequest)
        })
        .then(res=>{
          setStatePay(res.data)
          setLoading(false)
        })
        .catch(err=>{
          console.log(err)
          setLoading(false)
        })
    }
    
    
    useEffect(() => {
      getStatePay()
    }, [])
    
    
    
      return {
        loading,
        incompleteInformation,
        statePay
      }
}

export default UsePayPackages