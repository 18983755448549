import React, { useEffect } from "react";
import AnyQuestions from "../../../home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import BannerFlow from "./bannerFlow/BannerFlow";
import SelectProcedures from "./SelectProcedures/SelectProcedures";
import StateSteps from "./stateSteps/StateSteps";
import "./styles.css";
import toast, { Toaster } from "react-hot-toast";
import MotiveConsultation from "./motiveConsultation/MotiveConsultation";
import TreatmentTime from "./treatmentTime/TreatmentTime";
import TellYourself from "./tellYourself/TellYourself";
import HowContact from "./howContact/HowContact";
import UseBooking from "../../../../viewModels/tourismHealth/UseBooking2";
import SuccessApointment from "./successApointment/SuccessApointment";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import ReturnPage from "../../../share/components/return/ReturnPage";
import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";

const BookingFlow = () => {
  const { state } = useLocation();
  const [t] = useTranslation("global");

  const {
    name,
    setName,
    email,
    setEmail,
    description,
    setDescription,
    age,
    setAge,
    setTel,
    setReason,
    setTreatmentTime,
    setBudget,
    setOtherReviews,
    department,
    setDepartment,
    city,
    setCity,
    procedure,
    setProcedure,
    errCamp,
    code,
    setCode,
    sendInfo,
    dataApointment,
    successApointmentState,
    steps,
    nextStep,
    prevStep,
    loading,
    campFull,
    optionSelect,
    setOptionSelect,
    budget,
    otherReviews,
    treatmentTime,
    tel,
  } = UseBooking();

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <CarouselSevices />
      <ReturnPage />
      {successApointmentState === true ? (
        <SuccessApointment
          name={dataApointment?.appointment?.name}
          doctor={dataApointment?.appointment?.user?.name}
          reason={dataApointment?.appointment?.reason}
          status={dataApointment?.appointment?.status}
          show={successApointmentState}
        />
      ) : (
        ""
      )}

      {steps === 1 && (
        <SelectProcedures procedure={procedure} setProcedure={setProcedure} />
      )}
      {steps === 2 && (
        <MotiveConsultation
          setReason={setReason}
          setDescription={setDescription}
          description={description}
          setOptionSelect={setOptionSelect}
          optionSelect={optionSelect}
        />
      )}
      {steps === 3 && (
        <TreatmentTime
          setBudget={setBudget}
          setOtherReviews={setOtherReviews}
          setTreatmentTime={setTreatmentTime}
          budget={budget}
          otherReviews={otherReviews}
          treatmentTime={treatmentTime}
        />
      )}
      {steps === 4 && (
        <TellYourself
          ages={age}
          setAges={setAge}
          city={city}
          setCity={setCity}
          deparment={department}
          setDeparment={setDepartment}
        />
      )}
      {steps === 5 && (
        <HowContact
          code={code}
          email={email}
          name={name}
          tel={tel}
          setCode={setCode}
          setEmail={setEmail}
          setName={setName}
          setTel={setTel}
        />
      )}
      {/* {campFull && (
        <p className="form-incomplete-camp">
          {t("specialist.booking_flow.complete_form_next")}
        </p>
      )}
      {errCamp && (
        <p className="form-incomplete-camp">
          {t("specialist.booking_flow.complete_form_send")}
        </p>
      )} */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          maxWidth: "1280px",
          width: "100%",
          margin: "0 auto",
          marginBottom: 30,
        }}
      >
        {steps > 1 && steps < 6 && (
          <div className="btn-double-booking-flowT">
            <button onClick={() => prevStep()}>
              {t("specialist.booking_flow.back")}
            </button>
            {steps === 5 ? (
              <button
                onClick={() => {
                  sendInfo(state?.id);
                }}
              >
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  t("specialist.booking_flow.send")
                )}
              </button>
            ) : (
              <button onClick={() => nextStep()}>
                {t("specialist.booking_flow.next")}
              </button>
            )}
          </div>
        )}

        {steps === 1 && (
          <div className="btn-one-booking-flowT">
            <button onClick={() => nextStep()}>
              {t("specialist.booking_flow.continue")}
            </button>
          </div>
        )}
        {steps === 6 ? "" : <StateSteps step={steps} />}
      </div>

      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default BookingFlow;
