import React from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextComponent from "../../../share/components/fonts/TextComponent";

function MenuSideBarHome({ clearSideBar }) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");



  const optionsList = [
    { text: t("browse_home.nav_bar.join_club.start"), icon: iconList.IconHospital, url: '/' },
    { text: t("browse_home.nav_bar.join_club.my_profile"), icon: iconList.IconProfile, url: '/profile' },
    { text: t("browse_home.nav_bar.tourism"),icon:iconList.IconBag, url: '/Tourismhealth' },
    { text: t("browse_home.nav_bar.fligths"), icon: iconList.IconPlane, url: '/fligths' },
    { text: t("browse_home.nav_bar.us"), icon: iconList.IconPeoples, url: '/aboutus' },
    { text: t("browse_home.nav_bar.specialist_services"), icon: iconList.IconListItem, url: '/aboutus' },
    { text: t("browse_home.nav_bar.build_pack"), icon: iconList.IconTravel, url: '/build-package' },
  ]

  function goToOptionMenu(screen) {
    const urlPattern = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/[\w .-]*)*\/?$/i;
    const extarnalURL = urlPattern.test(screen);
    extarnalURL ? (window.location.href = screen) : navigate(`${screen}`, {});
    clearSideBar(false);
  }

  return (
    <div className="MenuSideBarHome">
      <div className="MenuSideBarHomeContainer">
        {optionsList.map((index, key) => {
          return (
            <div
              key={key}
              className="MenuSideBarHomeOption"
              onClick={() => goToOptionMenu(index?.url)}
            >
              <div className="MenuSideBarHomeOptionIcon">
                <SvgIcon name={index?.icon} color={"#FFFFFF"} size={25} />
              </div>
              {/* <p className="MenuSideBarHomeOptionText">{index?.text}</p> */}
              <TextComponent children={index?.text} fontWeight={400} size={"sm"} className={"MenuSideBarHomeOptionText"}/>
            </div>
          );
        })}
        <div className="MenuSideBarHomeFooter">
          <TextComponent children={"© Wellezy - All rights reserved."} fontWeight={400} size={"sm"} className={"MenuSideBarHomeFooterText"}/>
        </div>
      </div>
    </div>
  );
}

export default MenuSideBarHome;
