import './styles.css';

function OptionsTabs({ tabActived, tabList, tabChoose }) {
  return (
    <div className='pqrsOptionsTabs'>
      {tabList && tabList.length > 0 && tabList.map((tab, key) => {
        const isActivated = tab?.id === tabActived;
        if (tab.isActive) return (
          <div 
            key={key} 
            onClick={() => tabChoose(tab?.id)}
            className={`pqrsTab ${isActivated ? 'pqrsTabOn' : 'pqrsTabOff'}`}
          >
            {tab?.name}
          </div>
        );
      })}
    </div>
  );
}

export default OptionsTabs;
