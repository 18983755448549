import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import TextComponent from "../../../../share/components/fonts/TextComponent";
import { MdClose } from "react-icons/md";

const FilterAsideHotels = ({
  widthContain,
  position,
  setFirstRange,
  setSecondRange,
  activeFilterPrice,
  setActiveFilterPrice,
  errorFilter,
  setResetResult,
  resetResult,
  filterHotelModal,
  setFilterHotelModal,
  setSelectedStars: setParentSelectedStars,
}) => {
  const [t] = useTranslation("global");
  const [selectedStars, setSelectedStars] = useState(0);
  let hotelDefault = false;

  const handleStarClick = (starCount) => {
    const newStarCount = selectedStars === starCount ? 0 : starCount;
    setSelectedStars(newStarCount);
    setParentSelectedStars(newStarCount);
  };
  
  useEffect(() => {
    console.log("Filtros aplicados con estrellas: ", localStorage.getItem("defaultHotel"));
    hotelDefault = localStorage.getItem("defaultHotel");
    if (hotelDefault === "true") {  
      setSelectedStars(5);
      setParentSelectedStars(5);
      hotelDefault = false;
      localStorage.setItem("defaultHotel", false);
    }else{
      setSelectedStars(0);
      setParentSelectedStars(0);
    }
  }, []);

  return (
    <div
      className={`${
        filterHotelModal
          ? "container-filter-price"
          : "container-filter-price close"
      }`}
    >
      <button
        className="ModalChangeCloseHotelButton"
        onClick={() => setFilterHotelModal(false)}
      >
        <MdClose size={23} color="#104172" />
      </button>
      <TextComponent
        className={"filter-hotels"}
        tag="h2"
        children={t("hotel.filters")}
        fontWeight="bold"
        size={"lg"}
      />

      <div className="input-filtrado-price">
        <TextComponent
          tag="h3"
          children={t("hotel.stars")}
          fontWeight="bold"
          size={"md"}
        />
        <div className="stars-filter">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              style={{
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
              onClick={() => handleStarClick(star)}
            >
              <SvgIcon
                name={iconList.IconStar}
                color={star <= selectedStars ? "#FFD902" : "#E0E0E0"}
                size={20}
              />
            </button>
          ))}
        </div>
      </div>

      {/* <div className="aplicar-filtro">
          <button
            onClick={() => {
              setActiveFilterPrice(!activeFilterPrice);
              // Aquí deberías agregar la lógica para aplicar el filtro de estrellas
              console.log("Filtros aplicados con estrellas: ", selectedStars);
            }}
          >
            {t("hotel.apply")}
          </button>
          <button
            onClick={() => {
              setResetResult(!resetResult);
            }}
          >
            {t("hotel.to_reset")}
          </button>
        </div> */}
    </div>
  );
};

export default FilterAsideHotels;
