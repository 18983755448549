import React from 'react';
import InputNacionality from './SingleInputs/InputNacionality';

const InputsHotel = ({
    paceholders,
    validationForm,
    nacionalityDropdown,
    setNacionalityDropdown,
    nacionality,
    codeNacionality,
    findCountryClient,
    loading,
    dataNacionality,
    selectedNacionality
}) => {
    return(
        <>
            <InputNacionality 
                nacionalityDropdown={nacionalityDropdown}
                setNacionalityDropdown={setNacionalityDropdown}
                nacionality={nacionality}
                validationForm={validationForm}
                paceholders={paceholders}
                codeNacionality={codeNacionality}
                findCountryClient={findCountryClient}
                loading={loading}
                dataNacionality={dataNacionality}
                selectedNacionality={selectedNacionality}
            />

        </>
    );
};

export default InputsHotel;