import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UseTour = () => {
    const location = useLocation();
    const condition = location.pathname;
    const [dataAlways, setDataAlways] = useState([]);
    let counter = 0;
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const itemsForPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const [mostrarNext, setMostrarNext] = useState(true);
    const [urlImg, setUrlImg] = useState("");
    const [city, setCity] = useState("");
    const [name, setName] = useState("");
    const navigate = useNavigate();


    function findTour(page = 1, name = "", city = "") {

        setLoading(true)
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/tours?page=${page}`;
        axios.get(url)
            .then(res => {
                setLoading(false)
                setServices(res.data.tours)
                setUrlImg(res.data.url)
                return setDataAlways(res.data.tours.data);
            })
            .catch(error => {
                setLoading(false)
                console.log(error);
            });

    }


    useEffect(() => {
        findTour();
    }, []);

    const nextHandler = (condition, page) => {
        findTour(
            condition ? page : Number(services?.current_page) + 1,
            name,
            city
        );
    };

    const prevHandler = () => {
        findTour(Number(services?.current_page) - 1, name, city);
    };



   
    const detailTour = (doctor, id) => {
        navigate(`/Specialists/${id}`);
    };



    return {
        dataAlways,
        loading,
        nextHandler,
        prevHandler,
        currentPage,
        services,
        mostrarNext,
        urlImg,
     
        detailTour,
        setCurrentPage,
        findTour,
        setCity,
        setName,
        city,
        name,
    };

}
export default UseTour;