import React, { useCallback, useEffect, useState } from "react";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import axios from "axios";

import "./styles.css";

import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";

const TellYourself = ({
  setCity,
  city,
  setAges,
  ages,
  setDeparment,
  deparment,
}) => {
  const [modalDepartment, setModalDepartment] = useState(false);

  const [modalCity, setModalCity] = useState(false);

  const [modalAges, setModalAges] = useState(false);

  const [value, setValue] = useState("");

  const [t] = useTranslation("global");

  const [departments, setDepartments] = useState([]);
  
  const [loading, setLoading] = useState(true);

  const getCountry = (token) => {
    axios
      .get("https://www.universal-tutorial.com/api/countries", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get("https://www.universal-tutorial.com/api/getaccesstoken", {
        headers: {
          "api-token":
            "GbJsAdxDNoersUVczRvXivBwtLZ-geuUCESuqtuRhOIeDsM1_nk4lO5mftBYLi2X4MY",
          "user-email": "gabrielpelota.8@gmail.com",
        },
      })
      .then((res) => {
        getCountry(res.data?.auth_token);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const agesA = [
    `18 - 25 años`,
    `25 - 35 años`,
    `35 - 45 años`,
    `45 - 60 años`,
    `60 años o mas`,
  ];

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
    if (newValue) {
        setDeparment(newValue.country_name);
    }
}, [setDeparment]);

  return (
    <div className="content-tell-yourself">
      <h2>{t("specialist.booking_flow.talked_you")}</h2>
      <section className="contain-camp-select-procedures">
        <label htmlFor="">{t("specialist.booking_flow.deparment")}</label>
        {departments ? (
          <div className="select-country-package">
            <Autocomplete
              id="combo-box-demo"
              options={departments}
              onChange={handleChange}
              onInputChange={(event, newInputValue) => {
                setDeparment(newInputValue);
              }}
              getOptionLabel={(option) => option.country_name || ''}
              renderOption={(props, option, index) => (
                <li key={index} className='list-origin' {...props} style={{ fontFamily: 'Montserrat' }}>
                  {option.country_name}
                </li>
              )}
              renderInput={(params) => 
                <TextField 
                  {...params} 
                  variant="outlined" 
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      null
                    ),
                    style: {
                      borderRadius: 8,
                    },
                  }}
                />
              }
            />
          </div>
        ) : (
          <div>Cargando...</div>
        )}
        
      </section>
      
      <section className="contain-camp-select-procedures">
        <label htmlFor="">{t("specialist.booking_flow.city")}</label>
        <input
          className="camp-select-procedures"
          placeholder="Ciudad"
          type="text"
          onChange={(e) => {
            setCity(e.target.value);
          }}
        ></input>
      </section>
      <section className="contain-camp-select-procedures">
        <label htmlFor="">{t("specialist.booking_flow.what_age")}</label>
        <div
          className="camp-select-procedures"
          onClick={() => {
            setModalAges(!modalAges);
          }}
        >
          <span>
            {ages === "" ? t("specialist.booking_flow.select_age") : ages}
          </span>
          <SvgIcon name={iconList.IconArrowDown2} size={20} color={"#004274"} />
          {modalAges && (
            <div className="modal-procedures-select">
              <ul>
                {agesA.map((index, key) => {
                  return (
                    <li
                      key={key}
                      onClick={() => {
                        setAges(index);
                      }}
                    >
                      {index}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default TellYourself;
