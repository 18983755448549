import React, { useEffect, useState } from "react";
import Find from "../Find";
import CarouselSevices from "../../CarouselServices/CarouselSevices";
import { useLocation, Link } from "react-router-dom";
import SvgIcon, { iconList } from "../../iconSVG";
import CardResultGlobal from "./CardResultGlobal/CardResultGlobal";
import "./styles.css";
import Paginado from "../../../../Hotel/HotelResult/Paginado/Paginado";
import UseFind from "../../../../../viewModels/Find/UseFind";
import FiltersResult from "./FilterResult/FiltersResult";

const ResultSearchGlobal = () => {
  const {
    prevHandler,
    nextHandler,
    mostrarNext,
    currentPage,
    dataHotels,
    setDataHotels,
    services,
  } = UseFind();

  const { state } = useLocation();

  const [dropdownFilter, setDropdownFilter] = useState(false);

  return (
    <div>
      <Find />
      <CarouselSevices />
      <div className="contain-route-hotel-result">
        <p className="route-clinic">
          {" "}
          <Link to={"/"}>Home</Link> {">"} <span>Resultados</span>
        </p>
      </div>
      <div className="contain-buttons-mobile-hotels">
        <button
          onClick={() => {
            setDropdownFilter(!dropdownFilter);
          }}
        >
          <SvgIcon color={"#004274"} size={30} name={iconList.IconFilters} />
          Filtros
        </button>

        {dropdownFilter && (
          <FiltersResult
            dataT={services}
            data={dataHotels}
            setData={setDataHotels}
            position={"absolute"}
            top={"60px"}
            setDropdown={setDropdownFilter}
          />
        )}
      </div>
      <div className="main-content-result-hotel">
        <div className="contain-aside-hotel-result">
          <FiltersResult
            dataT={services}
            data={dataHotels}
            setData={setDataHotels}
            setDropdown={setDropdownFilter}
          />
        </div>
        <div className="contain-all-results-global">
          {dataHotels.length === 0 ? (
            <h2 style={{ textAlign: "center" }}>No Hay Resultados</h2>
          ) : (
            dataHotels.map((item, key) => {
              return <CardResultGlobal data={item} key={key} url={state.url} />;
            })
          )}

          <Paginado
            currentPage={currentPage}
            data={services}
            mostrarNext={mostrarNext}
            prevHandler={prevHandler}
            nextHandler={nextHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultSearchGlobal;
