import React, { useEffect, useState } from 'react'
import ServicesOdontologia from '../../Components/ServicesDetails/ServicesOdontologia'
import {useLocation} from "react-router-dom"
import axios from 'axios'
import ServicesFarmacia from '../../Components/ServicesDetails/ServicesFarmacia'
import ServicesEnfermeras from '../../Components/ServicesDetails/ServicesEnfermeras'
import ServicesRestaurantes from '../../Components/ServicesDetails/ServicesRestaurantes'
import ServicesTraductores from '../../Components/ServicesDetails/ServicesTraductores'
import ServicesGimnasios from '../../Components/ServicesDetails/ServicesGimnasios'
import ServicesSpa from '../../Components/ServicesDetails/ServicesSpa'
import ServicesRentaAutos from '../../Components/ServicesDetails/ServicesRentaAutos'
import ServicesAmbulancias from '../../Components/ServicesDetails/ServicesAmbulancias'
import ServicesCasaDescanso from '../../Components/ServicesDetails/ServicesCasaDescanso'
import ServicesHoteles from '../../Components/ServicesDetails/ServicesHoteles'
import ServicesTurismo from '../../Components/ServicesDetails/ServicesTurismo'
import ServicesConductores from '../../Components/ServicesDetails/ServicesConductores'
import ServicesEstetica from '../../Components/ServicesDetails/ServicesEstetica'


const ServicesCategory = () => {

  const location = useLocation()
  const position = location.pathname.split('/')[2]
  const data = location.state
  const [services, setServices] = useState([])
  const [change, setChange] = useState(false)

  const peticiones = [
    {
      id: "Odontologia" ,
      url:"https://pdtclientsolutions.com/wellezy/api/odontology"
    },
    {
      id: "Farmacias" ,
      url:"https://pdtclientsolutions.com/wellezy/api/get/pharmacies/list"
    },
    {
      id: "Enfermeras" ,
      url:"https://pdtclientsolutions.com/wellezy/api/nurses"
    },
    {
      id: "Restaurantes" ,
      url:"https://pdtclientsolutions.com/wellezy/api/restaurants"
    },
    {
      id: "Traductores" ,
      url:"https://pdtclientsolutions.com/wellezy/api/translate"
    },
    {
      id: "Gimnasios" ,
      url:"https://pdtclientsolutions.com/wellezy/api/gym"
    },
    {
      id: "Spa" ,
      url:"https://pdtclientsolutions.com/wellezy/api/spa"
    },
    {
      id: "RentadeAutos" ,
      url:"https://pdtclientsolutions.com/wellezy/api/car_rentar"
    },
    {
      id: "Ambulancias" ,
      url:"https://pdtclientsolutions.com/wellezy/api/ambulance"
    },
    {
      id: "EsteticasyPeluquerias" ,
      url:"https://pdtclientsolutions.com/wellezy/api/list/aesthetics"
    },
    {
      id: "CasasdeDescanso" ,
      url:"https://pdtclientsolutions.com/wellezy/api/list/resthouses"
    },
    {
      id: "Hoteles" ,
      url:"https://pdtclientsolutions.com/wellezy/api/hotels"
    },
    {
      id: "Turismo" ,
      url:"https://pdtclientsolutions.com/wellezy/api/list/tours"
    },
    {
      id: "Conductores" ,
      url:"https://pdtclientsolutions.com/wellezy/api/get/drivers/list"
    }

  ]
  
  
  useEffect(() => {

    peticiones.map(peti=>{
      if(position == peti.id){
        axios.get(peti.url)
    .then( (response)=> {
        setServices(response.data)
        setChange(true)
        
  })
    .catch(function (error) { 
        console.log(error) 
        
    })
      }
    })
    
  }, [data])
  

  return (
    <div>
      {change === false &&
     
     <h1>Cargando ......</h1>
      
      } 
      {
        position === "Odontologia" &&
        <ServicesOdontologia data={services}/>
      }
      {
        position === "Farmacias" &&
        <ServicesFarmacia data={services}></ServicesFarmacia>
      }
      {
        position === "Enfermeras" &&
        <ServicesEnfermeras data={services}></ServicesEnfermeras>
      }
      {
        position === "Restaurantes" && 
        <ServicesRestaurantes data={services}></ServicesRestaurantes>
      }
      {
        position === "Traductores" &&
        <ServicesTraductores data={services}></ServicesTraductores>
      }
      {
        position === "Gimnasios" &&
        <ServicesGimnasios data={services}></ServicesGimnasios>
      }
      {
        position === "Spa" &&
        <ServicesSpa data={services}></ServicesSpa>
      }
      {
        position === "RentadeAutos" &&
        <ServicesRentaAutos data={services}></ServicesRentaAutos>
      }
      {
        position === "Ambulancias" &&
        <ServicesAmbulancias data={services}></ServicesAmbulancias>
      }
      {
        position === "EsteticasyPeluquerias" &&
        <ServicesEstetica data={services}></ServicesEstetica>
      }
      {
        position === "CasasdeDescanso" &&
        <ServicesCasaDescanso data={services}></ServicesCasaDescanso>
      }
      {
        position === "Hoteles" &&
        <ServicesHoteles data={services}></ServicesHoteles>
      }
      {
        position === "Turismo" &&
        <ServicesTurismo data={services}></ServicesTurismo>
      }
      {
        position === "Conductores" &&
        <ServicesConductores data={services}></ServicesConductores>
      }
    </div>
  )
}

export default ServicesCategory