import React, { useState } from 'react'
import "./styles.css"
import { useTranslation } from 'react-i18next'

const MotiveConsultation = ({setReason,setDescription}) => {

    const [camp, setCamp] = useState(false)

    const selectReason = (option)=>{
        if(option == "a"){
            setReason("Informacion sobre el precio")
            
        }else if(option == "b"){
            setReason("Informacion sobre el tratamiento")
            
        }else if(option == "c"){
            setReason("Opciones de financiacion")
           
        }else if(option == "d"){
           
            setReason("Otros")
        }
    }

    const [t] = useTranslation("global")

  return (
    <div className='contain-motive-consultation'>
        <h2>{t("specialist.booking_flow.which_reason")}</h2>
        <section className='contain-select-motive'>
            <div className='camp-checkbox-motive'>
                <input type="radio" name="motive" id="motive" onClick={()=>{
                    selectReason("a")
                }}/>
                <label htmlFor="motive">{t("specialist.booking_flow.information_price")}</label>
            </div>
            <div className='camp-checkbox-motive'>
                <input type="radio" name="motive" id="motive" onClick={()=>{
                    selectReason("b")
                }}/>
                <label htmlFor="motive">{t("specialist.booking_flow.information_tratement")}</label>
            </div>
            <div className='camp-checkbox-motive'>
                <input type="radio" name="motive" id="motive" onClick={()=>{
                    selectReason("c")
                }}/>
                <label htmlFor="motive">{t("specialist.booking_flow.option_finantation")}</label>
            </div>
            <div className='camp-checkbox-motive'>
                <input type="radio" name="motive" id="motive" onClick={()=>{
                    selectReason("d")
                }}/>
                <label htmlFor="motive">{t("specialist.booking_flow.other")}</label>
            </div>
        </section>
      
            <section className='contain-describe-consultation'>
            <h3>{t("specialist.booking_flow.add_comment")}</h3>
            <textarea name="" id=""  placeholder={t("specialist.booking_flow.place_holder")} onChange={(e)=>{setDescription(e.target.value)}}></textarea>
            </section>
        
        
    </div>
  )
}

export default MotiveConsultation