import HomeIcon from '@mui/icons-material/Home';
import InputIcon from '@mui/icons-material/Input'
import CssIcon from '@mui/icons-material/Css';
import TextFieldsIcon from '@mui/icons-material/TextFields';

const drawerList = [
    {
        name: "Inicio",
        icon: <HomeIcon />,
        route: "/documentation",
    },
    {
        name: "inputs",
        icon: <InputIcon />,
        route: "/documentation/inputs",
    },
    {
        name: "CSS",
        icon: <CssIcon />,
        route: "/documentation/css",
    },
    {
        name: "Tipografia",
        icon: <TextFieldsIcon/>,
        route: "/documentation/typography"
    }
]

export default drawerList;