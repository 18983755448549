import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "iconsax-react";
import ReplayIcon from '@mui/icons-material/Replay';

const ReturnPage = ({ nav = -1, reload = false }) => {
  const navigate = useNavigate();

  const volverPaginaAnterior = () => {
    navigate(nav);
  };

  const recargarPagina = () => {
    window.location.reload();
  };

  return (
    <p className="return-url">
      <div onClick={volverPaginaAnterior}>
        <ArrowLeft size={20} color="var(--bluePrimary)"></ArrowLeft> Volver
      </div>
      {reload && (
        <div onClick={recargarPagina}>
          Recargar <ReplayIcon size={20} color="var(--bluePrimary)"></ReplayIcon>
        </div>
      )}
    </p>
  );
};

export default ReturnPage;
