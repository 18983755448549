import React from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SuccessApointment = ({ name, status, reason, doctor, show }) => {
  const { state } = useLocation();

  const [t] = useTranslation("global");

  return (
    <div
      className={`${
        show ? "content-succes-apointment" : "content-succes-apointment close"
      }`}
    >
      <section
        className={`${
          show ? "contain-data-appointment" : "contain-data-appointment close"
        }`}
      >
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
            textAlign: "center",
          }}
        >
          <SvgIcon
            name={iconList.IconCheckSucces}
            color={"#0eb30e"}
            size={30}
          ></SvgIcon>
          {t("specialist.booking_flow.send_data_correct")}
        </h2>
        <div className="list-data-appointment">
          <div className="camp">
            <label htmlFor="">
              {t("specialist.booking_flow.name_pacient")}:
            </label>
            <input type="text" value={name} readOnly />
          </div>
          <div className="camp">
            <label htmlFor="">{t("specialist.booking_flow.status")}:</label>
            <input type="text" value={status} readOnly />
          </div>
          <div className="camp">
            <label htmlFor="">{t("specialist.booking_flow.reason")}:</label>
            <input type="text" value={reason} readOnly />
          </div>
          <div className="camp">
            <label htmlFor="">{t("specialist.booking_flow.doctor")}:</label>
            <input type="text" value={doctor} readOnly />
          </div>
        </div>
      </section>
    </div>
  );
};

export default SuccessApointment;
