import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Assets/styles.module.css";
import { MdCameraAlt, MdOutlineTimer } from "react-icons/md";
import GalleryImg from "@/ui/views/Hotel/DetailHotel/GalleryImg/GalleryImg";
import CarouselSevices from "@/ui/views/share/components/CarouselServices/CarouselSevices";
import ReturnPage from "@/ui/views/share/components/return/ReturnPage";
import { useTranslation } from "react-i18next";
import { HiCalendarDays } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { BiAnalyse, BiCalendarCheck, BiCalendarX } from "react-icons/bi";
import CustomSlider from "@/ui/views/Packages/DetailPackages/sliderImg/CustomSlider";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";
import RecomendationTour from "./components/recomendationTour/RecomendationTour";

const TourDetail = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  const [loadingImage, setLoadingImage] = useState(true);
  const [slideShow, setSlideShow] = useState(false);

  const [data, setData] = useState({});
  const [dataTours, setDataTours] = useState([]);
  const [imageUrls, setImageUrls] = useState();
  const [URLlink, setURLlink] = useState("");
  const [imagesCarrusel, setImagesCarrusel] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const url = `${process.env.REACT_APP_URL_API_AIOP}api/tours/${id}`;
          const response = await axios.get(url);
          const tourData = response.data.tour;
          setData(tourData);
          let urlFetch = response.data.url;
          setURLlink(response.data.url);

          const urls = tourData.images.map(
            (image) => `${urlFetch}/${image.url}`
          );
          setImageUrls(urls);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    const fetchDataTour = async () => {
      try {
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/tours`;
        const response = await axios.get(url);
        const dataToursResponse = response.data.tours.data;
        setDataTours(dataToursResponse);
      } catch (error) {
        console.error("Error fetching all tours data:", error);
      }
    };

    fetchData();
    fetchDataTour();
  }, [id]);

  useEffect(() => {
    if (data && data.description && data.description) {
      const imgUrls = extractImages(data.description);
      setImagesCarrusel(imgUrls);
    }
    console.log(imagesCarrusel);
  }, [data]);

  const extractImages = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const imgElements = doc.querySelectorAll("img");
    const imgUrls = Array.from(imgElements).map((img) => img.src);
    return imgUrls;
  };

  function Image({ url }) {
    const handleLoad = () => {
      // Reset loading state on image load
      setLoadingImage(false);
    };

    const handleError = () => {
      // Keep loading state if image fails to load
      setLoadingImage(true);
    };

    return (
      <img
        className={styles.imageHotelDetail}
        src={url}
        onLoad={handleLoad}
        onError={handleError}
        alt="Hotel"
      />
    );
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (imageUrls.length > 0 && loadingImage) {
        setLoadingImage(false);
      }
    }, 3000);

    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, [imageUrls, loadingImage]);
  function convertirMinutosAHoras(minutos) {
    const horas = Math.floor(minutos / 60); // Obtener la parte entera de las horas
    const minutosRestantes = Math.round(minutos % 60); // Obtener los minutos restantes
    return `${horas} hora(s) y ${minutosRestantes} minuto(s)`;
  }

  function formatDateTime(dateTime) {
    if (!dateTime) {
      return "Fecha inválida"; // Manejo de error si la fecha es undefined o null
    }

    // Reemplazar el espacio con "T" para asegurar un formato ISO 8601 válido
    const correctedDateTime = dateTime.replace(" ", "T");

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const date = new Date(correctedDateTime);

    // Verificar si la fecha es válida
    if (isNaN(date)) {
      return "Fecha inválida"; // Manejo de error si la fecha no es válida
    }

    return new Intl.DateTimeFormat("es-ES", options).format(date);
  }

  return (
    <>
      {!loadingImage && (
        <GalleryImg
          Images={imageUrls}
          slideShow={slideShow}
          setSlideShow={setSlideShow}
        />
      )}

      <CarouselSevices current={t("carousel_services.tours")} />
      <ReturnPage />
      <div className={styles.layoutMax}>
        <div className={styles.detailTour}>
          <div
            className={styles.containImgPrincipalHotel}
            onClick={() => {
              setSlideShow(!slideShow);
            }}
          >
            <div className={styles.sectionImgBig}>
              <div className={styles.sectionImg1}>
                {loadingImage || !imageUrls[0] ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="var(--greySecundary)" />
                  </div>
                ) : (
                  <Image url={imageUrls[0]} />
                )}
              </div>
              <div className={styles.sectionImg2}>
                {loadingImage || !imageUrls[1] ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="var(--greySecundary)" />
                  </div>
                ) : (
                  <Image url={imageUrls[1]} />
                )}
              </div>
            </div>
            <div className={styles.sectionImgLayoutFor}>
              <div className={styles.sectionImgLayout1}>
                {loadingImage || !imageUrls[2] ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="var(--greySecundary)" />
                  </div>
                ) : (
                  <Image url={imageUrls[2]} />
                )}
              </div>
              <div className={styles.sectionImgLayout1}>
                {loadingImage || !imageUrls[3] ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="var(--greySecundary)" />
                  </div>
                ) : (
                  imageUrls[3] && <Image url={imageUrls[3]} />
                )}
              </div>
            </div>
          </div>

          <div className={styles.containTitleHotel}>
            <div className={styles.title}>
              <h2>{data.name}</h2>
              <span className={styles.city}>{data?.city?.name?.trim()}</span>
            </div>

            <div className={styles.informationContent}>
              <div className={styles.information}>
                <h2>Información</h2>
                <span className={styles.city}>
                  Información adicional sobre el Tour, para que tengas una
                  experiencia única!.
                </span>
              </div>

              <div className={styles.informationTour}>
                <div className={styles.items}>
                  <div className={styles.information}>
                    <h2>Descripción</h2>
                    <span
                      className={styles.html}
                      dangerouslySetInnerHTML={{
                        __html: data?.description
                          ? data?.description.replace(
                              /<img/g,
                              '<img class="hidden-img-carr"'
                            )
                          : "<p>No hay informacion disponible</p>",
                      }}
                    />
                  </div>
                </div>

                <div className={styles.imagesGallery}>
                  <div style={{ cursor: "pointer" }}>
                    {imagesCarrusel.length >= 1 && (
                      <CustomSlider>
                        {imagesCarrusel?.map((imgUrl, index) => {
                          return (
                            // eslint-disable-next-line jsx-a11y/img-redundant-alt
                            <img
                              onClick={() => setShow(true)}
                              key={index}
                              className="section-image"
                              src={imgUrl}
                              alt={`Image ${index + 1}`}
                              style={{
                                objectFit: "cover",
                                height: "100%",
                                width: "100%",
                                maxHeight: "500px",
                              }}
                            />
                          );
                        })}
                      </CustomSlider>
                    )}
                  </div>
                  <div
                    className={`${
                      show
                        ? "modal-list-images-pack"
                        : "modal-list-images-pack close"
                    }`}
                    onClick={(e) => {
                      if (e.currentTarget === e.target) {
                        setShow(false);
                      }
                    }}
                  >
                    <div
                      className={`${
                        show
                          ? "content-list-images-pack"
                          : "content-list-images-pack close"
                      }`}
                    >
                      <button
                        className="content-list-images-pack-button-close"
                        onClick={() => setShow(false)}
                      >
                        <IoClose size={20} />
                      </button>
                      {imagesCarrusel.length >= 1 && (
                        <CustomSlider>
                          {imagesCarrusel?.map((imgUrl, index) => {
                            return (
                              // eslint-disable-next-line jsx-a11y/img-redundant-alt
                              <img
                                key={index}
                                className="section-image"
                                src={imgUrl}
                                alt={`Image ${index + 1}`}
                              />
                            );
                          })}
                        </CustomSlider>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.information}>
                <h2>Información adicional</h2>
              </div>

              <div className={styles.informationTour}>
                <div className={styles.items}>
                  <div className={styles.item}>
                    <MdOutlineTimer size={30} />
                    <div className={styles.itemContent}>
                      <h4>Duración</h4>
                      <p>{convertirMinutosAHoras(data?.duration)}</p>
                    </div>
                  </div>

                  <div className={styles.item}>
                    <BiAnalyse size={30} />
                    <div className={styles.itemContent}>
                      <h4>Temporada</h4>
                      <p>{data.season}</p>
                    </div>
                  </div>

                  <div className={styles.item}>
                    <HiCalendarDays size={30} />
                    <div className={styles.itemContent}>
                      <h4>Disponibilidad</h4>
                      <p>{data.disponibility}</p>
                    </div>
                  </div>

                  <div className={styles.item}>
                    <BiCalendarCheck size={30} />
                    <div className={styles.itemContent}>
                      <h4>Empieza</h4>
                      <p>{formatDateTime(data?.start_time)}</p>
                    </div>
                  </div>

                  <div className={styles.item}>
                    <BiCalendarX size={30} />
                    <div className={styles.itemContent}>
                      <h4>Termina</h4>
                      <p>{formatDateTime(data?.end_time)}</p>
                    </div>
                  </div>
                </div>

                <div className={styles.information}>
                  {/* <h2>Que incluye?</h2> */}
                  <span
                    className={styles.html}
                    style={{ fontWeight: 300 }}
                    dangerouslySetInnerHTML={{
                      __html: data?.include
                        ? data?.include.replace(
                            /<img/g,
                            '<img class="hidden-img-carr"'
                          )
                        : "<p>No hay informacion disponible</p>",
                    }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecomendationTour article={dataTours} />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </>
  );
};

export default TourDetail;
