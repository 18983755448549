import React, { useState, useEffect } from 'react'
import CarouselSevices from '@/ui/views/share/components/CarouselServices/CarouselSevices'
import styles from "./styles.module.css"
import axios from 'axios'
import Loading from '@/ui/views/share/components/loading'
import useHeaderSession from '@/ui/viewModels/session/header.session.viewModel'
import InputProcedures from '@/ui/views/CartCheckout/components/specialist-clinic/components/InputProcedures/InputProcedures'
import useSearchBuildPackage from '@/Hooks/BuildPackage/useBuildPackageSearchEngine/UseBuildPackageSearchEngine'
import StepHome from "../../components/Step/StepHome";
import StepStep from "../../components/Step/StepStep";
import ModalAction from "../../components/ModalAction/ModalAction";
import SessionModal from '@/ui/views/session/components/SessionModal/SessionModal'
import AnyQuestions from '@/ui/views/home/components/anyQuestion'

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentStep, setDataPackage, setLastSearch, setProceduresData, setRoutes, } from "@/store/slices/dataStepz/dataStepzSlice";
import { useTranslation } from "react-i18next";
import { ApiCountryCodePhone } from '@/Api/ApiCountryCodePhone'
import QuestionMamoplastia from './questionMamoplastia/QuestionMamoplastia'
import DataSearch from '../../components/DataSearch/DataSearch'

const Std = () => {

    const { userDetail } = useHeaderSession()
    const navigate = useNavigate()
    const [activeSession, setActiveSession] = useState(false)

    const [dataMamoplastia, setDataMamoplastia] = useState({
        size_brazier: "",
        want_size: "",
        hope_to_achieve: {
            add_size: false,
            reduce_size: false,
            get_up: false,
            same_size_firmer: false,
            correct_asymmetry: false,
            reduce_nipple: false,
            others: false,
            description: ""
        },
        performed_breast_surgery: false,
        performed_breast_surgery_description: "",
        know_implant_place: "",
        consider_reduction_lift: false,
        biggest_concern_doubt: {
            scars: false,
            specialist_suitability: false,
            security_clearance: false,
            expected_results: false,
            recovery_time: false,
            info_about_implants: false,
            info_about_techniques: false
        },
        something_you_consider_important: "",
        someone_recommended_us: false,
        recommend_description: ""
    })

    const [eps, setEps] = useState("")
    const [height, setHeight] = useState("")
    const [weight, setWeight] = useState("")
    const [phone, setPhone] = useState(null)
    const [prefixPhone, setPrefixPhone] = useState(null)
    const [typeProcedure, setTypeProcedure] = useState("")
    const [haveChild, setHaveChild] = useState(false)
    const [quantityChild, setQuantityChild] = useState("")
    const [drinkAlcohol, setDrinkAlcohol] = useState(false)
    const [smoke, setSmoke] = useState(false)
    const [sons, setSons] = useState(false)
    const [whichSons, setWhichSons] = useState("")
    const [haveSurgery, setHaveSurgery] = useState(false)
    const [whichSurgery, setWhichSurgery] = useState("")
    const [haveIllness, setHaveIllness] = useState(false)
    const [whichIllness, setWhichIllness] = useState("")
    const [drugsConsume, setDrugsConsume] = useState(false)
    const [whichDrugs, setWhichDrugs] = useState("")
    const [consumeMedicaments, setConsumeMedicaments] = useState(false)
    const [whichMedicaments, setWhichMedicaments] = useState("")
    const [youAlergicKnowed, setYouAlergicKnowed] = useState(false)
    const [whichAlergicKnowed, setWhichAlergicKnowed] = useState("")
    const [youAlergic, setYouAlergic] = useState(false)
    const [whichAlergic, setWhichAlergic] = useState("")
    const [validation, setValidation] = useState(false)
    const [terms, setTerms] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);

    const [procedures, setProcedures] = useState([])

    const [t] = useTranslation("global");
    const dispatch = useDispatch();
    const location = useLocation();

    const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
    const especialtieAvliable = useSelector((state) => state.dataStepz.especialtieAvliable);

    const valueFlight = dataPackage.flight ? dataPackage.flight.priceTotal : 0;
    const valueHotel = dataPackage.hotel ? dataPackage.hotel.price : 0;

    const totalPrice = 3000000 + valueFlight + valueHotel;

    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsConfirmed(event.target.checked);
    };

    const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
    const idEspecialtieData = useSelector((state) => state.dataSearchEngine.idEspecialtieData);
    const procedureData = useSelector((state) => state.dataSearchEngine.procedureData);


    const routes = useSelector((state) => state.dataStepz.routes);

    const [dataStepzTitles, setDataStepzTitles] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([1]);

    const handleStepClick = (step) => {
        if (step <= activeStep + 1) {
            setActiveStep(step);
            if (!completedSteps.includes(step)) {
                setCompletedSteps([...completedSteps, step]);
            }
        }
    };

    useEffect(() => {
        if (setSpetVariableData === 2) {
            dispatch(setCurrentStep(6));
        } else {
            dispatch(setCurrentStep(5))
        }

        dispatch(
            setRoutes({
                ...routes,
                std: {
                    route: location.pathname,
                    search: location.search,
                },
            })
        );
        const url = `${process.env.REACT_APP_URL_API_FINANMED}/category/sub/47`
        axios.get(url)
            .then((res) => {
                setProcedures(res.data)
            })
            .catch(err => {
                console.log(err)
            })

        if (!userDetail) {
            setActiveSession(true)
        }
    }, []);

    useEffect(() => {
        let titles = [];
        if (setSpetVariableData === 0) {
            titles = ["Hotel", "Especialista", "Clínica", "Resumen", "STD"];
        } else if (setSpetVariableData === 1) {
            titles = ["Vuelo", "Especialista", "Clínica", "Resumen", "STD"];
        } else if (setSpetVariableData === 2) {
            titles = ["Vuelo", "Hotel", "Especialista", "Clínica", "Resumen", "STD"];
        } else if (setSpetVariableData === 3) {
            titles = ["Especialista", "Clínica", "Vuelo", "Hotel", "Traslado", "Resumen", "STD"];
        }
        setDataStepzTitles(titles);
    }, [setSpetVariableData]);




    const sendStd = () => {

        const body = {
            eps: eps,
            height: height,
            weight,
            number_children: !haveChild ? "0" : quantityChild,
            previous_surgery: haveSurgery ? whichSurgery : "No",
            major_disease: haveIllness ? whichIllness : "No",
            drugs: drugsConsume ? whichDrugs : "No",
            drink_medication: consumeMedicaments ? whichMedicaments : "No",
            allergic_medication: youAlergic ? whichAlergic : "No",
            type_procedure: typeProcedure,
            smoke: smoke ? "Si" : "No",
            alcohol: drinkAlcohol ? "Si" : "No",
            name: userDetail.name,
            email: userDetail.email,
            questions: dataMamoplastia
        }

        if (
            !height ||
            !weight ||
            (sons === true && !whichSons) ||
            (haveSurgery === true && !whichSurgery) ||
            (haveIllness === true && !whichIllness) ||
            (drugsConsume === true && !whichDrugs) ||
            (consumeMedicaments === true && !whichMedicaments) ||
            (youAlergicKnowed === true && !whichAlergicKnowed) ||
            (youAlergic === true && !whichAlergic) ||
            !terms ||
            !dataMamoplastia?.size_brazier ||
            !dataMamoplastia?.want_size ||
            !dataMamoplastia?.something_you_consider_important ||
            (dataMamoplastia?.hope_to_achieve?.others && (!dataMamoplastia?.hope_to_achieve.description) ) ||
            (dataMamoplastia?.performed_breast_surgery && (!dataMamoplastia?.performed_breast_surgery_description || !dataMamoplastia.know_implant_place)) ||
            (dataMamoplastia?.someone_recommended_us && !dataMamoplastia?.recommend_description)
        ) {
            setValidation(true)
        } else {
            setValidation(false)
            setLoading(true)
            


            let bodyPackage = {};

            if (especialtieAvliable) {
                bodyPackage = {
                    specialtie_id: idEspecialtieData,
                    clinic_id: dataPackage.clinic.id,
                    procedure_id: procedureData.id,
                    name: userDetail.name,
                    email: userDetail.email,
                    phone: `+${prefixPhone} ${phone}`,
                }

                if (dataPackage.flight) {
                    let itinerary = [];

                    let itineraryFrom = {
                        dateArrival: dataPackage?.flight?.itinerary?.[0]?.dateArrival || '',
                        dateDeparture: dataPackage?.flight?.itinerary?.[0]?.dateDeparture || '',
                        from: dataPackage?.flight?.itinerary?.[0]?.from || '',
                        to: dataPackage?.flight?.itinerary?.[0]?.to || '',
                        company: dataPackage?.flight?.itinerary?.[0]?.company || '',
                        family: dataPackage?.flight?.itinerary?.[0]?.family || ''
                    };

                    itinerary = [itineraryFrom]

                    if (dataPackage?.flight?.itinerary?.length > 1) {
                        let itineraryTo = {
                            dateArrival: dataPackage.flight.itinerary[1]?.dateArrival || '',
                            dateDeparture: dataPackage.flight.itinerary[1]?.dateDeparture || '',
                            from: dataPackage.flight.itinerary[1]?.from || '',
                            to: dataPackage.flight.itinerary[1]?.to || '',
                            company: dataPackage.flight.itinerary[1]?.company || '',
                            family: dataPackage.flight.itinerary[1]?.family || ''
                        };

                        itinerary = [...itinerary, itineraryTo];
                    }

                    let flight = {
                        company: dataPackage.flight.company,
                        currency: dataPackage.flight.currency,
                        itinerary,
                        price: dataPackage.flight.priceTotal,
                    }

                    bodyPackage.flight = flight
                }

                if (dataPackage.hotel) {
                    let hotel = {
                        arrival: dataPackage.hotel.arrival,
                        departure: dataPackage.hotel.departure,
                        checkIn: dataPackage.hotel.dataHot.checkIn,
                        checkOut: dataPackage.hotel.dataHot.checkOut,
                        country: dataPackage.hotel.country,
                        countryClient: dataPackage.hotel.countryClient,
                        hotel: dataPackage.hotel.hotel,
                        price: dataPackage.hotel.price,
                        stars: dataPackage.hotel.stars,
                    }

                    bodyPackage.hotel = hotel
                }
            }

            const urlPackage = `https://crmedic.pdtclientsolutions.com/api/wellezy/store/package`

            const url = `${process.env.REACT_APP_URL_API_FINANMED}/store/std`
            axios.post(url, body)
                .then((res) => {
                    if (especialtieAvliable) {
                        axios.post(urlPackage, bodyPackage)
                            .then((res) => {
                                setLoading(false)
                                setOpen(true)
                                dispatch(setLastSearch({}))
                                dispatch(setDataPackage({}))
                                dispatch(setRoutes({}))
                                dispatch(setProceduresData([]))
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    } else {
                        setLoading(false)
                        setOpen(true)
                        dispatch(setLastSearch({}))
                        dispatch(setDataPackage({}))
                        dispatch(setRoutes({}))
                        dispatch(setProceduresData([]))
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        }
    }

    const closeModal = (moda) => {
        navigate("/")
    }

    useEffect(() => {
        if (!userDetail) {
            setActiveSession(true)
        } else {
            setActiveSession(false)
        }

    }, [userDetail])

    return (
        <>
            {
                loading &&
                <Loading />
            }
            <SessionModal activeSessionModal={activeSession} setActiveSessionModal={setActiveSession} />
            <ModalAction open={open} setOpen={closeModal} />
            <CarouselSevices />
            <div className={styles.layoutMax}>
                {
                    especialtieAvliable && (
                        <>
                            <div className={styles.itemSectionAll}>
                                <StepHome />
                                {dataStepzTitles.map((item, index) => (
                                    <StepStep
                                        key={index}
                                        title={item}
                                        step={index + 1}
                                        onClick={handleStepClick}
                                        dataPackage={dataPackage}
                                    />
                                ))}
                            </div>

                            <DataSearch />
                        </>
                    )
                }
                <div className={styles.layout}>
                    <form action="" className={styles.form}>
                        <legend>STD</legend>
                        <div>
                            <div className={styles['camp-two-check-column']}>
                                <label>{ userDetail.name }</label>
                                <label>{ userDetail.email }</label>
                                {
                                    userDetail.prex_phone_public && userDetail.phone_public && (
                                        <label>{ userDetail.prex_phone_public } { userDetail.phone_public }</label>
                                    )
                                }
                            </div>
                        </div>
                        <div className={styles["camp-three"]}>
                            {/* <div className={styles.left}>
                                <label htmlFor="">EPS *</label>
                                <input type="text" placeholder='Sura' className={!eps && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                    setEps(e.target.value)
                                }} />
                            </div> */}
                            <div className={styles.center}>
                                <label htmlFor="">Estatura *</label>
                                <input type="number" placeholder='PJ 1.60' className={!height && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                    setHeight(e.target.value)
                                }} />
                            </div>
                            <div className={styles.right}>
                                <label htmlFor="">Peso *</label>
                                <input type="number" placeholder='PJ 60Kg' className={!weight && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                    setWeight(e.target.value)
                                }} />
                            </div>
                        </div>
                        {/* <div className={styles["camp-one"]}>
                            <label htmlFor="">Tipo de procedimiento *</label>
                            <InputProcedures 
                                showProcedures={showProcedures}
                                setShowProcedures={setShowProcedures}
                                procedure={typeProcedure}
                                setProcedure={setTypeProcedure}
                                checkProcedure={checkProcedure}
                                idSpecialty={47}
                                setCheckProcedure={setCheckProcedure}
                            />
                        </div> */}
                        {/* <div className={styles.containerInputPhone}>
                            <div className={styles.inputPrefix}>
                                <label htmlFor="">Cod. *</label>
                                <select className={!prefixPhone && validation === true ? styles.obligatory : ""} onChange={(e) => setPrefixPhone(e.target.value)}>
                                    <option value="">+0</option>
                                    {
                                        ApiCountryCodePhone.filter(item => item.phone_code).map((item, key) => (
                                            <option key={key} value={item.phone_code}>+{item.phone_code}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className={styles.inputPhone}>
                                <label htmlFor="">Télefono *</label>
                                <input type="number" placeholder='3001379214' className={!phone && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                    setPhone(e.target.value)
                                }} />
                            </div>
                        </div> */}
                        {/* <div className={styles["camp-two-check"]}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={haveChild}
                                    onChange={() => {
                                        setHaveChild(!haveChild)
                                    }}
                                />
                                <label htmlFor="" style={{ textWrap: "nowrap" }}>¿Tiene Hijos?</label>
                            </div>
                            {
                                haveChild &&
                                <div className={styles['camp-short']}>
                                    <label htmlFor="">¿Cuanto tiene? *</label>
                                    <input style={{ maxWidth: "100px" }} className={!quantityChild && validation === true ? styles.obligatory : ""} type="number" placeholder='2' onChange={(e) => {
                                        setQuantityChild(e.target.value)
                                    }} />
                                </div>
                            }

                        </div> */}
                        <div className={styles['camp-two-check-column']}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={drinkAlcohol}
                                    onChange={() => {
                                        setDrinkAlcohol(!drinkAlcohol)
                                    }}
                                />
                                <label htmlFor="">¿Consume alcohol?</label>
                            </div>
                        </div>   

                        <div className={styles['camp-two-check-column']}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={smoke}
                                    onChange={() => {
                                        setSmoke(!smoke)
                                    }}
                                />
                                <label htmlFor="">¿Fuma?</label>
                            </div>
                        </div>

                        <div className={styles['camp-two-check-middle']}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={sons}
                                    onChange={() => {
                                        setSons(!sons)
                                    }}
                                />
                                <label htmlFor="">¿Tienes hijos?</label>
                            </div>
                            {
                                sons &&
                                <div className={styles['camp-middle']}>
                                    <label htmlFor="">¿Cuantos y que edades? *</label>
                                    <input type="text" placeholder='PJ 2 (2 años) (10 años) ' className={!whichSurgery && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                        setWhichSons(e.target.value)
                                    }} />
                                </div>
                            }

                        </div>

                        <div className={styles['camp-two-check-middle']}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={haveSurgery}
                                    onChange={() => {
                                        setHaveSurgery(!haveSurgery)
                                    }}
                                />
                                <label htmlFor="">¿Se ha practicado alguna cirugia?</label>
                            </div>
                            {
                                haveSurgery &&
                                <div className={styles['camp-middle']}>
                                    <label htmlFor="">Cirugias Anteriores *</label>
                                    <input type="text" placeholder='PJ Liposuccion' className={!whichSurgery && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                        setWhichSurgery(e.target.value)
                                    }} />
                                </div>
                            }

                        </div>
                        <div className={styles['camp-two-check-middle']}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={haveIllness}
                                    onChange={() => {
                                        setHaveIllness(!haveIllness)
                                    }}
                                />
                                <label htmlFor="">¿Sufre alguna enfermedad importante?</label>
                            </div>
                            {
                                haveIllness &&
                                <div className={styles['camp-middle']}>
                                    <label htmlFor="">¿Cuales? *</label>
                                    <input type="text" className={!whichIllness && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                        setWhichIllness(e.target.value)
                                    }} />
                                </div>
                            }

                        </div>

                        <div className={styles['camp-two-check-middle']}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={drugsConsume}
                                    onChange={() => {
                                        setDrugsConsume(!drugsConsume)
                                    }}
                                />
                                <label htmlFor="">¿Consume algun estupefaciente?</label>
                            </div>
                            {
                                drugsConsume &&
                                <div className={styles['camp-middle']}>
                                    <label htmlFor="">¿Cuales? *</label>
                                    <input type="text" className={!whichDrugs && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                        setWhichDrugs(e.target.value)
                                    }} />
                                </div>
                            }

                        </div>
                        <div className={styles['camp-two-check-middle']}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={consumeMedicaments}
                                    onChange={() => {
                                        setConsumeMedicaments(!consumeMedicaments)
                                    }}
                                />
                                <label htmlFor="">¿Tomas algun medicamento?</label>
                            </div>
                            {
                                consumeMedicaments &&
                                <div className={styles['camp-middle']}>
                                    <label htmlFor="">Medicamentos que toma *</label>
                                    <input type="text" className={!whichMedicaments && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                        setWhichMedicaments(e.target.value)
                                    }} />
                                </div>
                            }

                        </div>

                        <div className={styles['camp-two-check-middle']}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={youAlergicKnowed}
                                    onChange={() => {
                                        setYouAlergicKnowed(!youAlergicKnowed)
                                    }}
                                />
                                <label htmlFor="">¿Tienes alguna alergia conocida?</label>
                            </div>
                            {
                                youAlergicKnowed &&
                                <div className={styles['camp-middle']}>
                                    <label htmlFor="">¿A qué? *</label>
                                    <input type="text" className={!whichAlergicKnowed && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                        setWhichAlergicKnowed(e.target.value)
                                    }} />
                                </div>
                            }
                        </div>

                        <div className={styles['camp-two-check-middle']}>
                            <div className={styles.checkboxCont}>
                                <input
                                    type="checkbox"
                                    checked={youAlergic}
                                    onChange={() => {
                                        setYouAlergic(!youAlergic)
                                    }}
                                />
                                <label htmlFor="">¿Es alergico algun medicamento o sutura?</label>
                            </div>
                            {
                                youAlergic &&
                                <div className={styles['camp-middle']}>
                                    <label htmlFor="">¿Cuales? *</label>
                                    <input className={!whichAlergic && validation === true ? styles.obligatory : ""} type="text" onChange={(e) => {
                                        setWhichAlergic(e.target.value)
                                    }} />
                                </div>
                            }

                        </div>
                    </form>
                    <div className={styles.previewDate}>
                        <section className={styles.contPrev}>

                            {
                                procedureData?.name == "Mamoplastia" &&
                                <QuestionMamoplastia
                                dataMamoplastia={dataMamoplastia}
                                setDataMamoplastia={setDataMamoplastia}
                                validation={validation}
                            />

                            }
                            
                            <div className={styles.contNote}>
                                <span>Nota:</span>
                                <p>
                                    Para continuar debes de llenar todos los datos del formulario, para facilitar tu valoración con nuestro sistema del STD
                                </p>
                            </div>

                            <button className={styles.btnContinue} disabled={!terms} style={{
                                opacity: !terms ? "0.5" : "1"
                            }} onClick={sendStd}>Continuar</button>

                            <div className={styles.checkTerms}>
                                <input type="checkbox" className={!terms && validation === true ? styles.obligatory : ""}
                                    checked={terms}
                                    onChange={() => {
                                        setTerms(!terms)
                                    }}
                                />
                                <p>
                                    He leído y acepto las <span>Politicas de Tratamientos de Datos Personales</span>
                                </p>
                            </div>

                        </section>
                    </div>
                </div>
            </div>
            <AnyQuestions title={t("browse_home.have_doubt")} />
        </>
    )
}



export default Std;
