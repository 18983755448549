import { People, Timer } from 'iconsax-react';
import twoPeople from "./twoPeople.svg"
import reloj from "./reloj.svg"
import { useState } from 'react';
import { MdCameraAlt } from "react-icons/md";
import ModalContainer from '../../../share/components/modalContainer';
import { useLocation } from 'react-router-dom';

const CardTransport = ({ item, goToReservation }) => {
    const location = useLocation();

    const [imageLoaded, setImageLoaded] = useState(false)
    const [modal, setModal] = useState(false)

    const routeStartsWithBuildPackageTrasnport = location.pathname.startsWith(
        "/build-package/transport"
    );

    return (
        <>

            {
                modal === true &&
                <ModalContainer show={modal} hiddenButton={false} hiddsetActiveChangeServiceen={setModal}>
                    <div className='content-all-info-detail-transport'>
                        <h2>Detalle de la reserva</h2>
                        <p className='code-reser'>Codigo de la reserva: <span>{item?.id}</span></p>
                        <div className='line-separation-transport'></div>
                        <div className='info-detail-transport'>
                            <div className="camp-detail">
                                <h3>Nombre completo</h3>
                                <span>{item?.vehicle}</span>
                            </div>
                            <div className="camp-detail">
                                <h3>Tipo de vehiculo</h3>
                                <span>{item?.vehicleType}</span>
                            </div>
                            <div className="camp-detail">
                                <h3>Capacidad</h3>
                                <span>{item?.passengerCapacity}</span>
                            </div>
                            <div className="camp-detail">
                                <h3>Medidas para covid</h3>
                                <span>{item?.covidPrepared ? "Si" : "No"}</span>
                            </div>
                            <div className="camp-detail">
                                <h3>Bienvenida</h3>
                                <span>{item?.meetAndGreet ? "Si" : "No"}</span>
                            </div>
                            <div className="camp-detail">
                                <h3>Duracion</h3>
                                <span>{item?.duracionInMinutes} min</span>
                            </div>
                        </div>
                        <div className='line-separation-transport'></div>
                        <div className='info-detail-transport'>
                            <div className="camp-detail">
                                <h3 style={{ color: "#2a2a2a", fontWeight: "500" }}>Total</h3>
                                <span><b>${item?.totalPriceUSD?.toFixed(1)} USD</b></span>
                            </div>
                        </div>
                        <div className='line-separation-transport'></div>
                        <div className='note-trans'>
                            <p>
                                Podría haber cargos adicionales al pagar en el traslado. De ser así, te los informaremos en detalle antes de finalizar tu compra.
                            </p>
                        </div>
                    </div>
                </ModalContainer>
            }
            <div className='card-traslado'>
                <div className="layout-card-traslado content-img">
                    {
                        !imageLoaded &&
                        <div className='img-transport-skeleton'>
                            <MdCameraAlt size={40} color="#c8c8c8" />
                        </div>
                    }
                    <img
                        src={item?.vehicleHDImage}
                        alt={item?.vehicle}
                        onLoad={() => setImageLoaded(true)}
                        style={{ display: imageLoaded ? 'block' : 'none' }}
                    />

                </div>
                <div className='mini-modal'>
                    ida y vuelta
                </div>
                <div className='layout-card-traslado-second'>
                    <div className="content-info information-car">
                        <h3>{item?.vehicle}</h3>
                        <div>
                            <p>{item?.vehicleType} {item?.meetAndGreet === true ? ' - Meet And Greet' : ''}</p>
                            <div className='cuantity-p'>
                                <div className='icons-cuantity'>
                                    <img src={twoPeople} alt="" />
                                    <span>{item?.passengerCapacity}</span>
                                </div>
                                <div className='icons-cuantity'>
                                    <img src={reloj} alt="" />
                                    <span>{item?.duracionInMinutes} Min</span>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className=" price-card">
                        <div className='price-content'>
                            <h3>${item?.totalPriceUSD?.toFixed(1)} USD</h3>
                            <span>${(item?.totalPriceUSD + (item?.totalPriceUSD * 0.20))?.toFixed(1)} USD</span>
                            
                        </div>
                        <div className='content-reserva'>
                            <div className='more-details' style={{ marginLeft: "0px" }}>
                                <p onClick={() => {
                                    setModal(true)
                                }}>Más detalles</p>
                            </div>
                            <button onClick={() => { goToReservation(item) }}>
                                {
                                    routeStartsWithBuildPackageTrasnport ? "Seleccionar" : "Reservar Ahora"
                                }
                               
                            </button>
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}

export default CardTransport