import useHeaderSession from "@/ui/viewModels/session/header.session.viewModel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDataPackage } from "@/store/slices/dataStepz/dataStepzSlice";
import { useState } from "react";

const useHotelBuildPackage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetail } = useHeaderSession();

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
  const setUrlHotel = useSelector((state) => state.dataSearchEngine.urlS);
  const setUrlSpecialist = useSelector((state) => state.dataSearchEngine.urlS);

  const [activeSessionModal, setActiveSessionModal] = useState(false);
  const [modalQuestion, setModalQuestion] = useState(false);

  async function nextStep(data) {
    dispatch(setDataPackage({ ...dataPackage, hotel: data }));
    
    if (!userDetail) {
      setActiveSessionModal(true);
      return;
    }

    navigate(setUrlSpecialist.specialist);
  }

  return {
    dataPackage,
    setSpetVariableData,
    nextStep,
    activeSessionModal,
    setActiveSessionModal,
    setModalQuestion,
    modalQuestion,
    setUrlSpecialist
  };
};

export default useHotelBuildPackage;
