import { createContext } from "react";

export const SearchEnginePackageContext = createContext();

export const SearchEnginePackageProvider = ({ children }) => {
  return (
    <SearchEnginePackageContext.Provider value={{}}>
      {children}
    </SearchEnginePackageContext.Provider>
  );
};
