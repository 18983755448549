import PackageTourismVoucher from "../../Components/PackageTourismVoucher/PackageTourismVoucher";

const PackageTourismVoucherRoute = () => {
  return (
    <div>
      <PackageTourismVoucher />
    </div>
  );
};

export default PackageTourismVoucherRoute;
