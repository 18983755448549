import React from 'react'
import SvgIcon,{iconList} from '../../../../share/components/iconSVG'
import "./styles.css"
import doctor from "../../../../../assets/images/doctorExample.png"
import { useTranslation } from 'react-i18next'

const BannerFlow = ({name,state,urlImage,stars}) => {

    const [t] = useTranslation("global")

  return (
    <div className='content-banner-flow'>
        <section className='contain-info-doctor-flow'>
            <div className='content-img-doctor-flow'>
                <img src={urlImage} alt="" />
            </div>
            <div className='content-info-doctor-detail-flow'>
                <h2>Dr {name}</h2>
                <p>{state}</p>
                <ul>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                </ul>
                <p>(86) - 90% {t("specialist.booking_flow.recomment")}</p>
            </div>
        </section>
    </div>
  )
}

export default BannerFlow