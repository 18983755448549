let globalFilters = {};

const setGlobalFilters = (filters) => {
  globalFilters = filters;
};

const filterFunctions = {
  filterByPriceAirlineAndBags(flight) {
    const flightPrice = parseInt(flight.recPriceInfo.monetaryDetail[0].amount);

    const airlineMatch =
      !globalFilters.airline ||
      flight.paxFareProduct.some((paxFareProduct) =>
        paxFareProduct.paxFareDetail.codeShareDetails.some(
          (codeShareDetail) => codeShareDetail.company === globalFilters.airline
        )
      );

    const airlineWithBags =
      !globalFilters.typeFlightWithBags || flight.bag.some((bag) => bag > 0);

    return (
      flightPrice >= Math.floor(globalFilters.range[0]) &&
      flightPrice <= Math.ceil(globalFilters.range[1]) &&
      airlineMatch &&
      airlineWithBags
    );
  },

  updateSegmentReferences(flight, tempSeg1, tempSeg2) {
    if (flight?.seg?.Seg1) {
      tempSeg1.push(...flight.seg.Seg1.map((seg) => seg.refNumber));
    }

    if (flight?.seg?.Seg2) {
      tempSeg2.push(...flight.seg.Seg2.map((seg) => seg.refNumber));
    }
  },

  filterSeg1ByTimeRange(item, flightsList) {
    return item.seg.Seg1.filter((seg1Item) => {
      const refNumber = seg1Item.refNumber;
      const segment = flightsList.Seg1.find((seg) => seg.num == refNumber);

      if (!segment) {
        return false;
      }

      const timeArrivalSeconds =
        segment.segments[0].productDateTime.timeArrivalSeconds;
      const [startTime, endTime] = globalFilters.timeRange;

      return (
        startTime === 0 ||
        (timeArrivalSeconds >= startTime && timeArrivalSeconds <= endTime)
      );
    });
  },
};

const filterFlights = (recommendation, flightsList) => {
  let tempSeg1 = [];
  let tempSeg2 = [];

  const filtered =
    recommendation?.filter((flight) => {
      const isMatch = filterFunctions.filterByPriceAirlineAndBags(flight);

      if (isMatch) {
        filterFunctions.updateSegmentReferences(flight, tempSeg1, tempSeg2);
      }

      return isMatch;
    }) || [];

  const filteredCopy = JSON.parse(JSON.stringify(filtered));

  const newResult = filteredCopy.map((item) => {
    item.seg.Seg1 = filterFunctions.filterSeg1ByTimeRange(item, flightsList);
    return item;
  });

  return newResult;
};

export { setGlobalFilters, filterFlights };
