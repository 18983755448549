import axios from "axios";

const urlWellezy = `${process.env.REACT_APP_URL_API_AIOP_STG}api/`;
const urlSDK = process.env.REACT_APP_URL_VIDEO_SDK;

const wellezy_api = axios.create({
  baseURL: urlWellezy,
});

const videoSDK_api = axios.create({
  baseURL: urlSDK,
});

export { wellezy_api, videoSDK_api };
