import "./styles.css";

import NeedInformation from "../../../tourismHealth/needInformation/NeedInformation";
import play from "./play.png";
import { useLocation } from "react-router-dom";

function FormContactUs() {
  const { pathname } = useLocation();
  const btns = {
    need: {
      title: "Mira los videos",
      textButton: "Conoce más",
      description: "Conoce los videos e instructivos Wellezy",
      img: play,
    },
  };

  return (
    <div className="pqrsFormContactUs">
      <p className="pqrsFormContactUsTitle">Preguntas frecuentes</p>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/SspU4tSoRns?si=xkVco03qqwm-I9Cq"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>

      <div
        className="layout-need-information-tourism-health"
        style={{ marginBottom: 50, width: "100%" }}
      >
        <NeedInformation
          description={btns.need.description}
          img={btns.need.img}
          title={btns.need.title}
          textButton={btns.need.textButton}
          color={"#104172"}
          active={true}
          url={"https://www.youtube.com/@wellezy4453"}
        />
      </div>
    </div>
  );
}

export default FormContactUs;
