import React, { useState, useEffect, useRef } from "react";
import RoomCard from "./RoomCard/RoomCard";
import "./Assets/styles.css";
import { useTranslation } from "react-i18next";

const HotelRoomsCard = ({ cardRef, roomsSelected, setRoomsSelected, setCardRooms }) => {
  const [t, i18n] = useTranslation("global");

  const scrollRef = useRef(null);
  const [totalPersons, setTotalPersons] = useState(JSON.parse(localStorage.getItem("totalPersons")) || [1]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [roomsSelected]);

  useEffect(() => {
    localStorage.setItem("totalPersons", JSON.stringify(totalPersons));
  }, [totalPersons]);

  const deletRoom = (index) => {
    const value = [...roomsSelected];
    const valueTotal = [...totalPersons];
    value.splice(index, 1);
    valueTotal.splice(index, 1);
    setRoomsSelected(value);
    setTotalPersons(valueTotal);
  };

  const addAdult = (index) => {
    setTotalPersons((prevTotalPersons) => {
      const newTotalPersons = [...prevTotalPersons];
      if (newTotalPersons[index] < 8) {
          newTotalPersons[index] += 1;
      }
      return newTotalPersons;
    });
    setRoomsSelected(
      roomsSelected.map((room, newIndex) => {
        if (newIndex === index && totalPersons[index] < 8) {
          return { ...room, adult: room.adult + 1 };
        } else {
          return room;
        }
      })
    );
  };

  const subAdult = (index) => {
    setTotalPersons((prevTotalPersons) => {
        const newTotalPersons = [...prevTotalPersons];
        if (roomsSelected[index].adult > 1) {
          newTotalPersons[index] -= 1;
        }
        return newTotalPersons;
    });
    setRoomsSelected(
      roomsSelected.map((room, newIndex) => {
        if (newIndex === index) {
          if (room.adult === 1) {
            return { ...room, adult: 1 };
          }
          return { ...room, adult: room.adult - 1 };
        } else {
          return room;
        }
      })
    );
  };

  const addChild = (index) => {
    setTotalPersons((prevTotalPersons) => {
      const newTotalPersons = [...prevTotalPersons];
      if (newTotalPersons[index] < 8 && roomsSelected[index].child < 4) {
        newTotalPersons[index] += 1;
      }
      return newTotalPersons;
    });
    
    setRoomsSelected(
      roomsSelected.map((room, newIndex) => {
        if (newIndex === index) {
          const totalPersonsInRoom = totalPersons[newIndex];
          const totalChildrenInRoom = room.child;
    
          if (totalPersonsInRoom < 8 && totalChildrenInRoom < 4) {
            return { ...room, child: room.child + 1, age: [...room.age, 1] };
          }
        }
        return room;
      })
    );
  };

  const subChild = (index) => {
    setTotalPersons((prevTotalPersons) => {
        const newTotalPersons = [...prevTotalPersons];
        if (roomsSelected[index].child > 0) {
          newTotalPersons[index] -= 1;
        }
        return newTotalPersons;
    });
    setRoomsSelected(
      roomsSelected.map((room, newIndex) => {
        if (newIndex === index) {
          if (room.child === 0) {
            return { ...room, child: 0, age: [] };
          }
          const updatedAge = room.age.slice(0, -1);
          return { ...room, child: room.child - 1, age: updatedAge };
        } else {
          return room;
        }
      })
    );
  };

  return (
    <div className="hotelRoomsCard" ref={cardRef} style={{ zIndex: '999999' }}>
      <div className={
        roomsSelected.length > 2 && "hotelRoomsCardScroll3" ||
        roomsSelected.length > 1 && "hotelRoomsCardScroll2" ||
        roomsSelected.length === 1 && "hotelRoomsCardScroll1"
        } ref={scrollRef}>
        {roomsSelected.map((element, index) => (
          <RoomCard
            key={index}
            index={index}
            deletRoom={deletRoom}
            addAdult={addAdult}
            subAdult={subAdult}
            addChild={addChild}
            subChild={subChild}
            {...element}
            data={element}
            roomSelected={roomsSelected}
            setRoomSelected={setRoomsSelected}
            totalPersons={totalPersons[index]}
          />
        ))}
      </div>
      <div className="hotelRoomsCard_">
        <div className="hotelRoomsCard_addRoom">
          {
            roomsSelected.length < 4 ? (
              <p
                onClick={() =>{
                    setTotalPersons([...totalPersons, 1])
                    setRoomsSelected([...roomsSelected, { adult: 1, child: 0, age: [] }])
                  }
                }
              >
                {t("hotel.search_engine.add_room")}
              </p>
            ):(
              <p style={{ cursor: "default", textDecoration: "none" }}>
                {t("hotel.search_engine.alert_max_room")}
              </p>
            )
          }
          <button
            className="hotelRoomsCard_addRoomButton"
            onClick={() => setCardRooms(false)}
          >
            {t("fligth.search_fligth.continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HotelRoomsCard;






