import React from "react";

import "./Assets/styles.css";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";

function HotelSearchCard({
  cardRef,
  search,
  setSearch,
  destiny,
  destinationSelectionFunc,
  placeholder,
  inputCity,
  paceholders,
  validationForm,
  showSearchScrolled,
  isScrolled,
  activeIndex,
  selectFligth
}) {
  const [t, i18n] = useTranslation("global");
  const location = useLocation();

  const buildPackageRoute = location.pathname === "/build-package";

  return (
    <div className="hotelSearchCard" ref={cardRef}>
      <div className={buildPackageRoute ? "hotelSearchNew2" : "hotelSearchNew"}>
        <span
          style={{
            fontSize: "14px",
            color: (paceholders === "") & validationForm ? "var(--redPrimary)" : "var(--greySecundary)",
          }}
        >
          {buildPackageRoute ? "Destino de hotel" :t("fligth.search_fligth.destination")}
        </span>

        <input
          ref={inputCity}
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          required
          placeholder={t("hotel.search_engine.choose_destiny")}
        />

        {(search.length > 2) & (destiny.length <= 0) ? (
          <CircularProgress
            style={{ position: "absolute", right: 15, top: 14 }}
            size={30}
          />
        ) : (
          ""
        )}
      </div>

      {destiny.length > 0 && !(isScrolled && showSearchScrolled) && (
        <div className="hotelSearchCard_elements">
          {destiny.map((element, index) => {
            return (
              <div key={index} className="hotelSearchCard_element">
                <button onClick={() => {
                  destinationSelectionFunc(element)
                  if(activeIndex == 2 || activeIndex == 1){
                    selectFligth(element?.flight_id)
                  }}}>
                  {element?.name}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default HotelSearchCard;
