import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./disabledModal.css";
import { FiAlertTriangle } from "react-icons/fi";
import ReactDOM from "react-dom";

const Modal = ({ show, onClose, children }) => {
  // Crear un contenedor para el modal
  const modalRoot = document.createElement("div");
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    // Agregar el contenedor al body cuando el componente se monte
    document.body.appendChild(modalRoot);
    if (show) {
      // Agregar la clase para deshabilitar el scroll
      document.body.style.overflow = "hidden";
    } else {
      // Eliminar la clase para habilitar el scroll
      document.body.style.overflow = "auto";
    }

    // Eliminar el contenedor del body cuando el componente se desmonte
    return () => {
      document.body.removeChild(modalRoot);
      // Asegurarse de que la clase se elimine cuando el modal se desmonte
      document.body.style.overflow = "auto";
    };
  }, [modalRoot, show]);

  // useEffect(() => {
  //   if (!show) {
  //     setIsClosing(true);
  //     const timer = setTimeout(() => {
  //       setIsClosing(false);
  //       onClose();
  //     }, 300); // Duración de la animación de fadeOut
  //     return () => clearTimeout(timer);
  //   }
  // }, [show, onClose]);

  const handleClickClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 500);
  };

  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={`AlertInfoContentModal ${show && !isClosing ? "fadeInBackground" : "fadeOutBackground"
        }`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          handleClickClose()
        }
      }}
    >
      <div
        className={`AlertInfoContentModalContent ${show && !isClosing ? "fadeIn" : "fadeOut"
          }`}
      >
        <button
          className="AlertInfoContentModalContentClose"
          onClick={() => handleClickClose()}
        >
          <MdClose size={20} color="#104172" />
        </button>
        <div className="AlertInfoContentModalContentInfo">{children}</div>
      </div>
    </div>,

    modalRoot
  );
};

const DisabledModal = ({
  item,
  children,
  active = true,
  width = "100%",
  color = "",
  linkExit = false,
  center,
}) => {
  const [openModalFlight, setOpenModalFlight] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = (e) => {
    e.preventDefault(); // Previene la navegación del Link siempre
    if (!item.disable) {
      setOpenModalFlight(true); // Abre el modal si el item está deshabilitado
    }
  };

  const redirect = () => {
    if (linkExit && item.disable) {
      window.open(item.route, "_blank");
    } else if (item.disable && active) {
      navigate(item.route);
    }
  };

  return (
    <>
      <div
        onClick={handleOpenModal}
        style={{
          cursor: item.disable ? "pointer" : "not-allowed",
          width: width,
          height: "100%",
        }}
        className="contentpo"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span
          onClick={redirect}
          className={`iconLinkp ${isHovered ? "colorBlue" : ""}`}
          style={{
            cursor: item.disable === true ? "pointer" : "not-allowed",
            opacity: !item.disable ? "0.5" : 1,
            alignItems: center && "start",
          }}
        >
          {children}
        </span>
      </div>

      <Modal show={openModalFlight} onClose={() => setOpenModalFlight(false)}>
        <div className="iconAlertModalDevelop">
          <FiAlertTriangle size={25} color="#ff5b5b98" />
        </div>
        <h2 className="AlertInfoContentModalContentTitle">¡Lo sentimos!</h2>
        <span className="AlertInfoContentModalContentDesc">
          Este servicio se encuentra en desarrollo. <br />
          Te avisaremos cuando esté listo!
        </span>
      </Modal>
    </>
  );
};

export default DisabledModal;
