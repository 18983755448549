import Axios from "axios";
import { useState, useCallback, useEffect, useContext } from "react";
import UserContext from "../../../Context/user/userProvider";
import { keys, set as setLocalStorage } from "../../localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCountryCodePhone } from "@/Api/ApiCountryCodePhone";

function useRegisterUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUserDetail, setUserToken } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    prex_phone_public: "",
    phone_public: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const CODES = ApiCountryCodePhone.map((item) => item.phone_code);

  // Expresiones regulares para validaciones
  const regexList = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    name: /^[A-Za-z\s']+$/,
    password:
      /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,16}$/,
    phone_public: /^\d{10,15}$/,
    countryPrefix: new RegExp(`^(${CODES.join("|")})$`),
  };

  // Función para validar la entrada
  const validateInput = (id, value) => {
    let error = "";
    if (value.trim() === "") {
      error = "Este campo es obligatorio";
    } else if (id === "countryPrefix" && !regexList.countryPrefix.test(value)) {
      error = "El código es inválido";
    } else if (id === "phoneNumber" && !regexList.phoneNumber.test(value)) {
      error = "El número de celular es inválido";
    } else if (id === "email" && !regexList.email.test(value)) {
      error = "El email es inválido";
    } else if (id === "name" && !regexList.name.test(value)) {
      error = "Nombre inválido";
    } else if (id === "password" && !regexList.password.test(value)) {
      error = "Contraseña inválida";
    } else if (id === "confirmPassword" && value !== formValues.password) {
      error = "Las contraseñas no coinciden";
    }

    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [id]: error,
    }));
  };

  const handleInputChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      validateInput(name, value);
    },
    [formValues]
  );

  const handleBlur = (event) => {
    const { id, value } = event.target;
    validateInput(id, value);
  };

  const inputsList = [
    {
      label: "Nombres y apellidos",
      type: "text",
      id: "name",
      value: formValues.name,
      placeholder: "Ingresa tu nombre",
      required: true,
      func: handleInputChange,
      onBlur: handleBlur,
    },
    {
      type: "flex",
      fields: [
        {
          label: "Prefijo",
          type: "text",
          id: "prex_phone_public",
          value: formValues.prex_phone_public,
          options: CODES,
          placeholder: "Ingresa un prefijo",
          required: true,
          func: handleInputChange,
          onBlur: handleBlur,
        },
        {
          label: "Numero de celular",
          type: "number",
          id: "phone_public",
          value: formValues.phone_public,
          placeholder: "Ingresa el numero de celular",
          required: true,
          func: handleInputChange,
          onBlur: handleBlur,
        },
      ],
    },
    {
      label: "Correo electrónico",
      type: "email",
      id: "email",
      value: formValues.email,
      placeholder: "name@gmail.com",
      required: true,
      func: handleInputChange,
      onBlur: handleBlur,
    },
    {
      label: "Contraseña",
      type: showPassword ? "text" : "password",
      id: "password",
      value: formValues.password,
      placeholder: "***************",
      required: true,
      func: handleInputChange,
      onBlur: handleBlur,
    },
    {
      label: "Confirmar Contraseña",
      type: showPassword ? "text" : "password",
      id: "confirmPassword",
      value: formValues.confirmPassword,
      placeholder: "***************",
      required: true,
      func: handleInputChange,
      onBlur: handleBlur,
    },
  ];

  useEffect(() => {
    const hasErrors = Object.values(inputErrors).some((error) => error);
    const allFieldsFilled = Object.values(formValues).every(
      (value) => value.trim() !== ""
    );
    setBtnDisabled(hasErrors || !allFieldsFilled);
  }, [formValues, inputErrors]);

  const handleRegister = async (modal, urlT) => {
    if (loading) return;
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP}api/register`;
      const { name, email, password, prex_phone_public, phone_public } =
        formValues;
      const { data: userData } = await Axios.post(url, {
        name,
        email,
        password,
        prex_phone_public,
        phone_public,
      });

      if (userData && userData.token) {
        const session = await setLocalStorage(keys.authUserSession, userData);
        if (session) {
          const screen = !modal ? "/" : urlT ? urlT : location.pathname;
          setUserDetail(userData.user);
          setUserToken(userData.token);
          navigate(screen);
        }
      } else {
        setInputErrors({
          email: "Error en el registro",
          password: "Error en el registro",
        });
      }
    } catch (error) {
      let errorMsg = "Error en el registro";
      if (error.response && error.response.data && error.response.data.errors) {
        const { errors } = error.response.data;

        if (errors.email && Array.isArray(errors.email) && errors.email.length > 0) {
          errorMsg = errors.email[0];
        }
      }

      setInputErrors({ email: errorMsg });
    } finally {
      setLoading(false);
    }
  };


  return {
    inputsList,
    btnDisabled,
    showPassword,
    setShowPassword,
    loading,
    inputErrors,
    handleRegister,
  };
}

export default useRegisterUser;
