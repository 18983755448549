import React from 'react'
import "./styles.css"

const DetailPay = () => {

const hab = JSON.parse(localStorage.getItem("hab-selected"))
const fligth = JSON.parse(localStorage.getItem("flightSelected"))
const adult = JSON.parse(localStorage.getItem("flightSelected")).passengersSelected.adult


const boy = JSON.parse(localStorage.getItem("flightSelected")).passengersSelected.boy

const baby = JSON.parse(localStorage.getItem("flightSelected")).passengersSelected.baby

const persons = adult + boy + baby

const taxes = fligth.priceTaxes
const packs = fligth.priceWithOutTaxes + Number(hab[0]["@attributes"]?.prr)
const total =  fligth.priceTaxes + packs
// const packs =
// const total = 
function formatearDinero(monto, simbolo = '$', decimales = 2, separadorMiles = ',') {
    const montoFormateado = monto.toFixed(decimales); // Fija el número de decimales
    const partes = montoFormateado.split('.'); // Divide la parte entera y decimal
    
    let parteEntera = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, separadorMiles); // Agrega comas para separar los miles
    
    // Si el monto es negativo, elimina el signo '-' temporalmente para agregarlo al final
    if (parteEntera.startsWith('-')) {
        parteEntera = parteEntera.slice(1);
        return `-${parteEntera}${simbolo} ${partes[1]}`;
    } else {
        return `${simbolo} ${parteEntera}${decimales > 0 ? '.' + partes[1] : ''}`;
    }
}

    return (
        <div className='contain-detail-pay'>
            <div className='content-layout-detail-pay'>
                <section className='column-description-pay'>
                    <p>Paquete para {persons} personas</p>
                    <p>Impuestos,tasas y cargos</p>
                    
                </section>
                <section className='column-price-pay'>
                    <p className='letter-green'>{formatearDinero(packs)}</p>
                    <p className='letter-green'>{formatearDinero(taxes)}</p>
                   
                </section>
            </div>
            <p className='line-separate'></p>
            <div className='content-layout-detail-pay'>
                <section className='column-description-pay'>
                    <p>Total</p>
                </section>
                <section className='column-price-pay'>
                    <p>{formatearDinero(total)}</p>
                </section>
            </div>

        </div>
    )
}

export default DetailPay