import React, { useState } from "react";
import "./styles.css";
import axios from "axios";
import SuccessApointment from "./successApointment/SuccessApointment";
import { CircularProgress } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import Input from "../../../share/components/inputsNew/Input";

const validateName = (name) => {
  if (name === "") {
      return "Este campo es obligatorio";
  }
  return "";
};

const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (email === "") {
      return "Este campo es obligatorio";
  } else if (!emailRegex.test(email) || /[\s]/.test(email)) {
      return "Este campo debe ser valido";
  }
  return "";
}

const validateTel = (tel) => {
  if (tel === "") {
      return "Este campo es obligatorio";
  } else if (isNaN(tel)) {
      return "Este campo debe ser numerico";
  }
  return "";
};

const validateProcedure = (procedure) => {
  if (procedure === "") {
      return "Este campo es obligatorio";
  }
  return "";
}

const validateTravel = (travel) => {
  if (travel === "") {
      return "Este campo es obligatorio";
  }
  return "";
}

const FormSuggestions = ({ width }) => {
  const [t] = useTranslation("global");
  const [selectCode, setSelectCode] = useState("+57");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [procedure, setProcedure] = useState("");
  const [travel, setTravel] = useState("");
  const [message, setMessage] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [succesForm, setSuccesForm] = useState(false);
  const [campErr, setCampErr] = useState(false);
  const [dataSuccess, setDataSuccess] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    tel: "",
    procedure: "",
    travel: "",
    terms: "",
  });

  const [touched, setTouched] = useState({
    name: false,
    email: false,
    tel: false,
    procedure: false,
    travel: false,
    termsAccepted: false
  });

  const validateFields = () => {
    const newErrors = {};

    if (!name) newErrors.name = "Este campo es obligatorio";
    if (!email) newErrors.email = "Este campo es obligatorio";
    if (email && !/\S+@\S+\.\S+/.test(email))
      newErrors.email = "Correo electrónico inválido";
    if (!tel) newErrors.tel = "Este campo es obligatorio";
    if (!procedure) newErrors.procedure = "Este campo es obligatorio";
    if (!travel) newErrors.travel = "Este campo es obligatorio";
    if (!termsAccepted) newErrors.terms = "Debes aceptar los términos";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleBlur = (field, value) => {
    let error = "";
    
    switch (field) {
      case "name":
        error = validateName(value);
        break;
      case "email":
        error = validateEmail(value);
        break;
      case "tel":
        error = validateTel(value);
        break;
      case "procedure":
        error = validateProcedure(value);
        break;
      case "travel":
        error = validateTravel(value);
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  };

  const handleChange = (field, setter) => (e) => {
    setter(e);
    if (touched[field]) {
      validateFields();
    }
  };

  const handleCheckboxChange = () => {
    setTermsAccepted((prev) => {
      const newValue = !prev;
      const newErrors = { ...errors };
      if (!newValue) {
        newErrors.terms = "Debes aceptar los términos";
      } else {
        delete newErrors.terms;
      }
      setErrors(newErrors);
      return newValue;
    });
  };

  const submit = (e) => {
    e.preventDefault();
    setTouched({
      name: true,
      email: true,
      tel: true,
      procedure: true,
      travel: true,
      termsAccepted: true,
    });
    if (validateFields()) {
      setLoading(true);
      const data = {
        name,
        email,
        tel,
        procedure,
        travel,
        message,
      };
      axios
        .post(`${process.env.REACT_APP_URL_API_AIOP}api/quote-surgery`, data)
        .then((res) => {
          setDataSuccess(res.data);
          setSuccesForm(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      setShow(true);
      setCampErr(false);
    } else {
      setCampErr(true);
    }
  };

  return (
    <>
      <SuccessApointment
        name={dataSuccess?.name}
        doctor={dataSuccess?.email}
        reason={dataSuccess?.tel}
        status={dataSuccess?.travel}
        succesForm={succesForm}
        show={show}
        setShow={setShow}
      />
      <form className={`content-form-suggestions ${width}`} onSubmit={submit}>
        <legend style={{ fontSize: 18, fontWeight: 800 }}>
          Solicita tu cotización gratuita
        </legend>

        <div className="camp">
          <div className="content-inputs-form-tourism-health">
            <label htmlFor="name">Nombre *</label>
            <input 
              type="text"
              id="name"
              placeholder="Tu nombre"
              className={errors.name ? "input-error-tourismhealth" : ""}
              onChange={(e) => {
                setName(e.target.value);
                handleBlur("name", e.target.value);
              }}
              onBlur={(e) => handleBlur("name", e.target.value)}
              value={name}
            />
            {errors.name && <p className="error">{errors.name}</p>}
          </div>
        </div>

        <div className="camp">
          <div className="content-inputs-form-tourism-health">
            <label htmlFor="email">Correo Electrónico *</label>
            <input 
              type="email"
              id="email"
              placeholder="Tu correo electronico"
              className={errors.email ? "input-error-tourismhealth" : ""}
              onChange={(e) => {
                setEmail(e.target.value);
                handleBlur("email", e.target.value);
              }}
              onBlur={(e) => handleBlur("email", e.target.value)}
              value={email}
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
        </div>

        <div
          className="camp-phone-suggestions"
          style={{
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 5,
            width: "100%",
          }}
        >
          <label htmlFor="tel">Número de celular *</label>
          <div style={{
            width: "100%",
          }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems:"flex-start",
                width: "100%",
              }}
            >
              <div
                className="content-select"
                style={{ display: "flex", alignItems: "center" }}
              >
                <select name="code">
                  <option value="+57">+57</option>
                  <option value="+58">+58</option>
                  <option value="+56">+56</option>
                  <option value="+55">+55</option>
                </select>

              </div>
              <div className="camp">
                <input 
                  type="number"
                  id="tel"
                  placeholder="Tu numero"
                  className={errors.tel ? "input-error-tourismhealth" : ""}
                  onChange={(e) => {
                    setTel(e.target.value);
                    handleBlur("tel", e.target.value);
                  }}
                  onBlur={(e) => handleBlur("tel", e.target.value)}
                  value={tel}
                />
              </div>
            </div>
            {errors.tel && <p className="error">{errors.tel}</p>}
          </div>
        </div>

        <div className="camp">
          <div className="content-inputs-form-tourism-health">
            <label htmlFor="procedure">Procedimiento *</label>
            <input 
              type="text"
              id="procedure"
              placeholder="¿Qué procedimiento te interesa?"
              className={errors.procedure ? "input-error-tourismhealth" : ""}
              onChange={(e) => {
                setProcedure(e.target.value);
                handleBlur("procedure", e.target.value);
              }}
              onBlur={(e) => handleBlur("procedure", e.target.value)}
              value={procedure}
            />
            {errors.procedure && <p className="error">{errors.procedure}</p>}
          </div>
        </div>
        
        <div className="camp">
          <div className="content-inputs-form-tourism-health">
            <label htmlFor="travel">Lugar *</label>
            <input 
              type="text"
              id="travel"
              placeholder="¿A dónde quieres viajar?"
              className={errors.travel ? "input-error-tourismhealth" : ""}
              onChange={(e) => {
                setTravel(e.target.value);
                handleBlur("travel", e.target.value);
              }}
              onBlur={(e) => handleBlur("travel", e.target.value)}
              value={travel}
            />
            {errors.travel && <p className="error">{errors.travel}</p>}
          </div>
        </div>

        <div className="camp">
          <textarea
            placeholder="Tu mensaje"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          ></textarea>
        </div>

        <div className="camp-terms">
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={handleCheckboxChange}
            id="accept"
          />
          <label htmlFor="accept">
            Acepto los Términos de uso, Términos de SMS, Política de privacidad
            y Política de cookies
          </label>
        </div>
        {errors.terms && <p className="error">{errors.terms}</p>}

        <button type="submit">
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            t("specialist.booking_flow.send")
          )}
        </button>
      </form>
    </>
  );
};

export default FormSuggestions;
