import * as React from 'react';
import styles from "./css.module.css";

export function ColorPrimary() {
  return (
    <div className={styles.colorPrimary}>

    </div>
  );
}

export function ColorSecondary() {
  return (
    <div className={styles.colorSecundary}>

    </div>
  );
}

export function YellosPrimary() {
  return (
    <div className={styles.yellowPrimary}>

    </div>
  );
}

export function ColorPrimaryLight() {
  return (
    <div className={styles.colorPrimaryLight}>

    </div>
  );
}

export function ColorGreyPrimary() {
  return (
    <div className={styles.colorGreyPrimary}>

    </div>
  );
}

export function ColorGreySecondary() {
  return (
    <div className={styles.colorGreySecundary}>

    </div>
  );
}

export function ColorWhite() {
  return (
    <div className={styles.colorWhite}>

    </div>
  );
}

export function ColorBlack() {
  return (
    <div className={styles.colorBlack}>

    </div>
  );
}

export function ColorTransparentModal() {
    return (
        <div className={styles.colorTransparentModal}>
    
        </div>
    );
}

export function ColorGreenPrimary() {
  return (
    <div className={styles.colorGreenPrimary}>

    </div>
  );
}

export function ColorRedPrimary() {
  return (
    <div className={styles.colorRedPrimary}>

    </div>
  );
}