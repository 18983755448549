import React, { useState } from 'react'
import SvgIcon, { iconList } from '../iconSVG';
import "./flow.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrangeHorizontal } from "iconsax-react"

const FlowPack = ({ data, fen, fsa, persons, type }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const urlFligth = localStorage.getItem("urlFligth")
    const urlHotel = localStorage.getItem("urlHotel")
    const listHotel = localStorage.getItem("hab-selected") === null ? "" : localStorage.getItem("hab-selected")
    const habSelected = listHotel === "" ? null : JSON.parse(listHotel) 
    
    const extract = (stringt, urlt) => {
        if (urlt === null) {

        } else {
            const startIndex = urlt.indexOf(stringt);
            if (startIndex !== -1) {
                // Encontrado, buscar el índice del ampersand siguiente
                const endIndex = urlt.indexOf("&", startIndex);

                if (endIndex !== -1) {
                    // Extraer el valor de dateOfDeparture
                    const dateOfDeparture = urlt.slice(startIndex + stringt.length, endIndex);

                    return dateOfDeparture;
                }
            }
        }

    }
    function calcularCantidadDeNoches(fechaInicio, fechaFin) {
        // Parsear las fechas en el formato mes/día/año
        const fechaInicioParts = fechaInicio.split("/");
        const fechaFinParts = fechaFin.split("/");

        const diaInicio = parseInt(fechaInicioParts[1]);
        const mesInicio = parseInt(fechaInicioParts[0]) - 1; // Restar 1 al mes porque en JavaScript los meses van de 0 a 11
        const anoInicio = parseInt(fechaInicioParts[2]);

        const diaFin = parseInt(fechaFinParts[1]);
        const mesFin = parseInt(fechaFinParts[0]) - 1;
        const anoFin = parseInt(fechaFinParts[2]);

        // Crear objetos Date con las fechas
        const fechaInicioObj = new Date(anoInicio, mesInicio, diaInicio);
        const fechaFinObj = new Date(anoFin, mesFin, diaFin);

        // Calcular la diferencia en milisegundos
        const diferenciaEnMilisegundos = fechaFinObj - fechaInicioObj;

        // Calcular la cantidad de noches dividiendo la diferencia por 86400000 (milisegundos en un día)
        const cantidadDeNoches = Math.ceil(diferenciaEnMilisegundos / 86400000);

        return cantidadDeNoches;
    }


    function formatearFecha(fechaISO) {
        const diasSemana = ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"];
        const meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

        const fecha = new Date(fechaISO);

        const diaSemana = diasSemana[fecha.getUTCDay()];
        const dia = fecha.getUTCDate();
        const mes = meses[fecha.getUTCMonth()];
        const año = fecha.getUTCFullYear();

        return `${diaSemana} ${dia} de ${mes} `;
    }
    const empy = extract("departure=", urlFligth)

    return (
        <section className='contain-flow' style={{ width: `${type === "pay" ? "90%" : ""}`, justifyContent: "center", gap: "1rem", margin: "2rem auto" }}>
            <div className={type === "hotel" ? "tranfor ind-flow" : "ind-flow"} style={{ border: type === "hotel" ? "3px solid #004274" : "none" }}>
                <h2><span>1</span> Hoteles</h2>
                <div>
                    <h3>
                        {
                            habSelected === null ?
                                data === null ?
                                    ""
                                    :

                                    data[0]?.nom
                                :
                                habSelected[0]?.name
                        }
                    </h3>
                    <p>{
                        habSelected === null ?
                            data === null ?
                                ""
                                :

                                data[0]?.res?.pax[0]?.hab[0]?.reg[0]["@attributes"]?.cod
                            :
                            habSelected[0]["@attributes"]?.cod

                    }</p>
                    <p className='hotel-flow' style={{ display: "flex", alignItems: "center" }}><SvgIcon color={"#004274"} size={30} name={iconList.IconHotel}></SvgIcon>{
                        habSelected === null ?
                            data === null ?
                                ""
                                :

                                `${calcularCantidadDeNoches(fen, fsa)} noches,${persons} personas`
                            :
                            `${habSelected[0]?.acomodation} personas`
                    }
                    </p>
                </div>
            </div>
            <div className={type === "fligth" ? "tranfor ind-flow" : "ind-flow"} style={{ border: type === "fligth" ? "3px solid #004274" : "none" }}>
                <h2><span>2</span>Vuelos</h2>
                <div>
                    <h3 style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                        {urlFligth !== null ? extract("iataD=", urlFligth) : "Origen"} <ArrangeHorizontal color='#004274' size={20} /> {urlFligth !== null ? extract("iataO=", urlFligth) : "Destino"}
                    </h3>
                    <p>Vuelos Directos</p>
                    <p className='hotel-flow' style={{ display: "flex", alignItems: "center" }}><SvgIcon color={"#004274"} size={30} name={iconList.IconCalendar}></SvgIcon>
                        {
                            urlFligth === null ?
                                ""
                                :

                                extract("departure=", urlFligth) === "" ?
                                    `${formatearFecha(extract("return=", urlFligth))}`
                                    :
                                    `${formatearFecha(extract("return=", urlFligth))} - ${formatearFecha(extract("departure=", urlFligth))}`

                        }

                    </p>
                </div>
            </div>
            <div className={type === "pay" ? "tranfor ind-flow" : "ind-flow"} style={{ border: type === "pay" ? "3px solid #004274" : "none" }}>
                <h2><span>3</span>Adicionales</h2>
                <div>
                    <h3>
                        Planifica tu viaje a lo grande:
                    </h3>
                    <p>Completalo con actividades o traslados</p>

                </div>
                <button className='btn-ind-flow' onClick={() => {
                    navigate("/packages/pay")
                }}>Ver Viaje</button>
            </div>
        </section>
    )
}

export default FlowPack