import { setOriginSelectedFlightData } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import DesinitySearcher from "@/ui/views/flights/components/desinitySearcher";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const InputOriginFlight = ({
    getSelectedOrigin,
    isCardRefOrigin,
    selectedOrigin,
    selectedDestination,
    ellipsisText,
    validationSearchDestiny
}) => {
    // Variables
    const dispatch = useDispatch();

    const [t] = useTranslation("global");
    const [focusOrigin, setFocusOrigin] = useState(false);

    const originSelectedFlightData = useSelector((state) => state.dataSearchEngine.originSelectedFlightData);

    const desinitySearcherTypes = {
        origin: {
            text: t("fligth.search_fligth.origin"),
            errorText: t("fligth.search_fligth.errorMessage"),
            placeholder: t("fligth.search_fligth.placeholderOrigin"),
        }
    };

    // //UseEffects
    // useEffect(() => {
    //     selectedOrigin = originSelectedFlightData;
    // }, []);

    useEffect(() => {
        dispatch(setOriginSelectedFlightData(selectedOrigin));
    }, [selectedOrigin]);

    return (
        <div className="content-inputs content-inputs-destiny-origin-build-package" style={{
            borderColor: selectedOrigin != "o" || validationSearchDestiny
            ? ""
            : "var(--redPrimary)"
        }}>
            <div className="first-section">
                <TextComponent
                    className={"label-destiny"}
                    children={"Origen de vuelo"}
                    size={"md2"}
                    fontWeight={
                        selectedOrigin != "o" || validationSearchDestiny
                            ? "light"
                            : "400"
                    }
                    color={
                        selectedOrigin != "o" || validationSearchDestiny
                            ? ""
                            : "var(--redPrimary)"
                    }
                />

                {focusOrigin == true ? (
                    <DesinitySearcher
                        type={desinitySearcherTypes.origin}
                        change={getSelectedOrigin}
                        condition={{build:true}}
                        inputFocused={isCardRefOrigin}
                        setFocus={setFocusOrigin}
                    />
                ) : selectedOrigin === selectedDestination ? (
                    <TextComponent
                        className={"value-origin-destiny"}
                        color={"var(--redPrimary)"}
                        children={ellipsisText("Cambia tu origen", 17)}
                        size={"md2"}
                        fontWeight={"bold"}
                        onclick={{
                            voidP: (pa) => getSelectedOrigin(pa),
                            parameters: "o",
                        }}
                    />
                ) : (
                    <p
                        style={
                            selectedOrigin != "o" || validationSearchDestiny
                                ? {
                                    fontWeight: "500"
                                }
                                : {
                                    color: "var(--greySecundary)",
                                    fontWeight: "400",
                                }
                        }
                        className="value-origin-destiny"
                        onClick={() => {
                            setFocusOrigin(true);
                        }}
                    >
                        {selectedOrigin === "o"
                            ? "Selecciona un origen"
                            : selectedOrigin}
                    </p>
                )}
            </div>
            {focusOrigin === true ? (
                ""
            ) : (
                <div>
                    <SvgIcon
                        name={iconList.IconPinMap2}
                        color={"var(--bluePrimary)"}
                        size={30}
                    />
                </div>
            )}
        </div>
    );
};

export default InputOriginFlight;