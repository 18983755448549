import { useContext, useEffect, useState } from "react";
import "./styles.css";
import styles from "./styles.module.css";
import useResultSearch from "../../../viewModels/transport/ResultSearch_Model";
import TransportContext from "../../../../Context/transport/TransportProvider";
import { Filter } from "iconsax-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useTransport from "../../../viewModels/transport/TransportC";
import CarouselSevices, {
  servicesTravel,
} from "../../share/components/CarouselServices/CarouselSevices";
import Loading from "../../../views/share/components/loading";
import Find from "../../share/components/Find/Find";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import SearchTransport from "../primaryView/SearchTransport";
import CardTransport from "./cardTransport/CardTransport";
import ModalContainer from "../../share/components/modalContainer";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import RangeFilterDouble from "./range/RangeFilterDouble";
import { Slider, ThemeProvider, createTheme } from "@mui/material";
import { currencyFormat } from "../../../utils";
import ReturnPage from "../../share/components/return/ReturnPage";
import AnyQuestions from "../../home/components/anyQuestion";

const theme = createTheme({
  palette: {
    wellezy: {
      main: "#004274",
      light: "#10417280",
    },
  },
});

const ResultSearch = () => {
  const location = useLocation();
  const [t] = useTranslation("global");

  const {
    buscadorMenu,
    passengerFilter,
    setPassengerFilter,
    resetFilter,
    resultados,
    filterPerPassenger,
    loader,
    goToReservation,
    setMenuHamburger,
    menuHamburger,
    priceMax,
    priceMin,
    handleDoubleRangeChange,
    range,
    handleDoubleRangeChangeCommitted,
    handlePassRangeChange,
    handlePassRangeChangeCommitted,
    passMax,
    passMin,
  } = useResultSearch();

  const {
    adult,
    baby,
    setTypeT,
    ciudadDropdown,
    setCiudadDropdown,
    aeropuertoDropdown,
    setAeropuertoDropdown,
    passengersCal,
    cityList,
    citySelected,
    setcitySelected,
    findOrigin,
    originList,
    originSelected,
    setOriginSelected,
    findDestiny,
    destinyList,
    destinySelected,
    setDestinySelected,
    destinyDropdown,
    setDestinyDropdown,
    findServices,
    selectedDate,
    handleDateChange,
    formValidate,
    setCityList,
    loadingCity,
    loadingDestiny,
    loadingOrigin,
    setCity,
    city,
    setOriginList,
    setDestinyList,
    cityVerify,
    setCityVerify,
    destinyVerify,
    setDestinyVerify,
    originVerify,
    setOriginVerify,
    date,
    setDate,
    hoursSe,
    setHoursSe,
    calendarVerify,
    setCalendarVerify,
  } = useTransport();
  const [firstRender, setFirstRender] = useState(false);
  const [marksPrice, setMarksPrice] = useState([priceMin, priceMax]);

  function valuetext(value) {
    return `${"$" + value}`;
  }

  const marks = [
    {
      value: priceMin,
      label: `$${marksPrice[0]?.toFixed(1)}USD`,
    },
    {
      value: priceMax,
      label: `$${marksPrice[1]?.toFixed(1)}USD`,
    },
  ];

  function valuetextPass(value) {
    return `${value}`;
  }

  const marksPass = [
    {
      value: passMin,
      label: `${passMin} Pas`,
    },
    {
      value: passMax,
      label: `${passMax} Pas`,
    },
  ];

  useEffect(() => {
    if (firstRender) {
      setMarksPrice([range[0], range[1]]);
    } else {
      setFirstRender(true);
    }
  }, [range]);

  const routeStartsWithBuildPackageTransport = location.pathname.startsWith(
    "/build-package/transport"
  );

  return (
    <div>
      {!routeStartsWithBuildPackageTransport && (
        <>
          <CarouselSevices current={t("carousel_services.transfers")} />
          <ReturnPage />
          <SearchTransport
            adult={adult}
            aeropuertoDropdown={aeropuertoDropdown}
            baby={baby}
            cityList={cityList}
            citySelected={citySelected}
            ciudadDropdown={ciudadDropdown}
            destinyDropdown={destinyDropdown}
            destinyList={destinyList}
            destinySelected={destinySelected}
            findDestiny={findDestiny}
            findOrigin={findOrigin}
            findServices={findServices}
            formValidate={formValidate}
            handleDateChange={handleDateChange}
            originList={originList}
            originSelected={originSelected}
            passengersCal={passengersCal}
            selectedDate={selectedDate}
            setAeropuertoDropdown={setAeropuertoDropdown}
            setCiudadDropdown={setCiudadDropdown}
            setDestinyDropdown={setDestinyDropdown}
            setDestinySelected={setDestinySelected}
            setOriginSelected={setOriginSelected}
            setTypeT={setTypeT}
            setcitySelected={setcitySelected}
            buscadorMenu={buscadorMenu}
            resetFilter={resetFilter}
            width={"100% !important"}
            setCityList={setCityList}
            loadingCity={loadingCity}
            loadingDestiny={loadingDestiny}
            loadingOrigin={loadingOrigin}
            setCity={setCity}
            setDestinyList={setDestinyList}
            setOriginList={setOriginList}
            typeView={false}
            cityVerify={cityVerify}
            setCityVerify={setCityVerify}
            originVerify={originVerify}
            setOriginVerify={setOriginVerify}
            destinyVerify={destinyVerify}
            setDestinyVerify={setDestinyVerify}
            date={date}
            setDate={setDate}
            hoursSe={hoursSe}
            setHoursSe={setHoursSe}
            calendarVerify={calendarVerify}
            setCalendarVerify={setCalendarVerify}
          />
        </>
      )}

      <main className="contenedor-principal-resultados">
        <div className="hamburger-menu-filters">
          <span
            onClick={() => {
              setMenuHamburger(true);
            }}
          >
            {" "}
            Filtrar
            <Filter size="20" color="var(--white)" />
          </span>
        </div>
        <ModalContainer
          show={menuHamburger}
          setHidden={setMenuHamburger}
          hiddsetActiveChangeServiceen={setMenuHamburger}
          sizeHeight={52}
        >
          <div className={"filters-box"}>
            <h2>Filtros</h2>
            <button onClick={resetFilter} className="btn-reset-fil-trans">
              Limpiar Filtro
            </button>
            <section className="container-filter-price-transport">
              <h3>Precios</h3>
              <p className={`${styles.subtitleFilter}`}>Rango de precios</p>
              {!loader && (
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={range}
                  onChange={handleDoubleRangeChange}
                  onChangeCommitted={handleDoubleRangeChangeCommitted}
                  max={priceMax}
                  min={priceMin}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  marks={marks}
                />
              )}
            </section>
            <section className="container-filter-price-transport">
              <h3>Pasajeros</h3>
              <p className={`${styles.subtitleFilter}`}>Rango de pasajeros</p>
              {!loader && (
                <Slider
                  getAriaLabel={() => "Passenger range"}
                  onChange={handlePassRangeChange}
                  onChangeCommitted={handlePassRangeChangeCommitted}
                  max={passMax}
                  min={passMin}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetextPass}
                  marks={marksPass}
                  value={passengerFilter}
                />
              )}
            </section>
          </div>
        </ModalContainer>
        <aside className={"filters-container-transfers"}>
          <div className={"display-none-t filters-box"}>
            <div>
              <h2>Filtros</h2>
              <button onClick={resetFilter} className="btn-reset-fil-trans">
                Limpiar Filtro
              </button>
            </div>

            <section className="container-filter-price-transport">
              <h3>Precios</h3>
              <p className={`${styles.subtitleFilter}`}>Rango de precios</p>
              {!loader && (
                <Slider
                  getAriaLabel={() => "Price range"}
                  value={range}
                  onChange={handleDoubleRangeChange}
                  onChangeCommitted={handleDoubleRangeChangeCommitted}
                  max={priceMax}
                  min={priceMin}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  marks={marks}
                />
              )}
            </section>
            <section className="container-filter-price-transport">
              <h3>Pasajeros</h3>
              <p className={`${styles.subtitleFilter}`}>Rango de pasajeros</p>
              {!loader && (
                <Slider
                  getAriaLabel={() => "Passenger range"}
                  onChange={handlePassRangeChange}
                  onChangeCommitted={handlePassRangeChangeCommitted}
                  max={passMax}
                  min={passMin}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetextPass}
                  marks={marksPass}
                  value={passengerFilter}
                />
              )}
            </section>
          </div>
        </aside>

        <section className="content-all-card-t">
          {loader === true ? (
            <Loading size={100}></Loading>
          ) : resultados.length <= 0 ? (
            <div>
              <h2 style={{ textAlign: "center" }}>
                No se encontraron traslados disponibles
              </h2>
            </div>
          ) : (
            resultados.map((item, index) => {
              return (
                <CardTransport
                  key={index}
                  goToReservation={goToReservation}
                  item={item}
                />
              );
            })
          )}
        </section>
      </main>
      {!routeStartsWithBuildPackageTransport && (
        <AnyQuestions title={t("browse_home.have_doubt")} />
      )}
    </div>
  );
};

export default ResultSearch;
