import React, { useState, useEffect } from 'react';
import styles from './TextCarousel.module.css';

const TextCarousel = () => {
  const texts = [
    'Todo lo que necesitas, en un solo lugar.',
    'Calidad y confianza en cada detalle.',
    'Tu satisfacción es nuestra prioridad.'
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    if (animating) {
      const timer = setTimeout(() => {
        setAnimating(false);
      }, 300); // Tiempo igual a la duración de la animación en CSS
      return () => clearTimeout(timer);
    }
  }, [animating]);

  const handleButtonClick = (index) => {
    if (index !== currentIndex && !animating) {
      setAnimating(true);
      setTimeout(() => {
        setCurrentIndex(index);
      }, 300); // Tiempo de la animación de salida
    }
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.textWrapper}>
        {texts.map((text, index) => (
          <div
            key={index}
            className={`${styles.text} ${
              index === currentIndex && !animating ? styles.active : ''
            } ${
              index === currentIndex && animating ? styles.fadeOut : ''
            }`}
          >
            {text}
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        {texts.map((_, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(index)}
            className={`${styles.button} ${
              index === currentIndex ? styles.activeButton : ''
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default TextCarousel;
