import * as React from 'react';
import css from "@/data/documentation/css/css"
import BodySectionDocumentation from '@/Components/SectionDocumentation/SectionDocumentation';

export default function Css() {
    return (
        <div>
            <BodySectionDocumentation title="Colores" data={css} />
        </div>
    );
}