import React from "react";
import Data from "./components/Data";
import styles from "./styles.module.css";

import { useSelector } from "react-redux";

const DataSearch = () => {
    const lastSearch = useSelector((state) => state.dataStepz.lastSearch);

    const origin = lastSearch?.originSelectedFlightData?.split(" - ")[0];
    const destination = lastSearch?.destinySelectedFlightData?.split(" - ")[0];

    const startDate = new Date(lastSearch?.dateGoingSelectedData);
    const endDate = new Date(lastSearch?.dateReturnSelectedData);

    const differenceInTime = endDate - startDate;

    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return (
        <div className={styles.container}>
            <Data title="Procedimiento" data={lastSearch?.procedureData?.name} border={true} />
            <Data title="Fecha de inicio" data={lastSearch?.dateGoingSelectedData} border={true} />
            {
                lastSearch?.isRoundTripData && 
                <>
                    <Data title="Fecha de regreso" data={lastSearch?.dateReturnSelectedData} border={true} />
                    
                </>
            }
            <Data title="Días" data={differenceInDays} border={true} />
            {
                lastSearch?.activeIndex != 0 ?
                (
                    <>
                        <Data title="Origen" data={origin} border={true} />
                        <Data title="Destino" data={lastSearch?.cityHotelData} />
                    </>
                ):(
                    <Data title="Destino" data={lastSearch?.cityHotelData} />
                )
            }
            
        </div>
    );
};

export default DataSearch;