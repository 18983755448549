import * as React from "react";
import inputs from "@/data/documentation/inputs/inputs";
import BodySectionDocumentation from "@/Components/SectionDocumentation/SectionDocumentation";

export default function Inputs() {
  return (
    <div>
      <BodySectionDocumentation title="Inputs" data={inputs} />
    </div>
  );
}
