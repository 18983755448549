import axios from "axios";
import { useState, useRef, useContext, useEffect } from "react";
import * as locales from "react-date-range/dist/locale";
import Api from "./Api";
import { useLocation, useNavigate } from "react-router-dom";

const TransportC = () => {
  const location = useLocation();
  const condition = location.pathname;
  const [formValidate, setFormValidate] = useState("");

  const navigate = useNavigate();

  const { Loggin, cityUrl, originUrl, destinyUrl, servicesUrl } = Api();
  const constraintsRef = useRef(null);
  const [letters, setLetters] = useState(1);
  const [adult, setAdult] = useState(1);
  const [boy, setBoy] = useState(0);
  const [searchStorage, setSearchStorage] = useState(
    JSON.parse(localStorage.getItem("storageLastSearch")) || []
  );
  const [baby, setBaby] = useState(0);
  const [calendar, setcalendar] = useState(false);
  const [calendarTwo, setCalendarTwo] = useState(false);
  const [typeT, setTypeT] = useState("ONLY_TO_AIRPORT");
  const [ciudadDropdown, setCiudadDropdown] = useState(false);
  const [aeropuertoDropdown, setAeropuertoDropdown] = useState(false);
  const [destinyDropdown, setDestinyDropdown] = useState(false);
  const [dated, setDated] = useState(null);

  const [selectedDate, setSelectedDate] =
    useState();
    // localStorage.getItem("dateTransport")
    //   ? new Date(
    //       JSON.parse(localStorage.getItem("dateTransport"))?.selectedDate
    //     )
    //   : new Date()
  const [dateApi, setDateApi] =
    useState();
    // localStorage.getItem("dateTransport")
    //   ? JSON.parse(localStorage.getItem("dateTransport"))?.dateapi
    //   : ""
  const [cityVerify, setCityVerify] = useState(false);
  const [destinyVerify, setDestinyVerify] = useState(false);
  const [calendarVerify, setCalendarVerify] = useState(false);
  const [originVerify, setOriginVerify] = useState(false);
  const [politicas, setPoliticas] = useState(localStorage.getItem("politicas"));
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState("");
  const [citySelected, setcitySelected] = useState(
    localStorage.getItem("cityTransport")
      ? JSON.parse(localStorage.getItem("cityTransport"))
      : "Selecciona la ciudad"
  );
  const [originList, setOriginList] = useState([]);
  const [originSelected, setOriginSelected] = useState(
    localStorage.getItem("originTransport")
      ? JSON.parse(localStorage.getItem("originTransport"))
      : ""
  );
  const [loadingCity, setLoadingCity] = useState(false);
  const [loadingOrigin, setLoadingOrigin] = useState(false);
  const [loadingDestiny, setLoadingDestiny] = useState(false);
  const [destinyList, setDestinyList] = useState([]);
  const [destinySelected, setDestinySelected] = useState(
    localStorage.getItem("destinyTransport")
      ? JSON.parse(localStorage.getItem("destinyTransport"))
      : ""
  );
  const [date, setDate] = useState(condition === "/transport" ? "" : localStorage.getItem("dateTransport") === null ? "" : new Date(localStorage.getItem("dateTransport")));

  const [hoursSe, setHoursSe] = useState(condition === "/transport" ? "" : localStorage.getItem("hourTransport") === null ? "" : localStorage.getItem("hourTransport")
  );
  const regex = /RESTEASY\d{6}/;
  const [loader, setLoader] = useState(false);
  const nameMapper = {
    de: "German",
    enGB: "English (United Kingdom)",
    enUS: "English (United States)",
    es: "Spanish",
  };

  const localeOptions = Object.keys(locales)
    .map((key) => ({
      value: key,
      label: `${key} - ${nameMapper[key] || ""}`,
    }))
    .filter((item) => nameMapper[item.value]);

  const [locale, setLocale] = useState("es");

  const changeScreen = (e) => {
    setLetters(e);
  };

  const deleteItemsExpiration = () => {
    const date = new Date();
    const searchStorageFilter = searchStorage.filter((e) => {
      if (e?.type == "flight") {
        const expiration = new Date(e?.departureDate);
        return expiration > date; // Aquí debes devolver el resultado de la comparación
      } else if (e?.type == "hotel") {
        const expiration = new Date(e?.arrival);
        return expiration > date; // Aquí debes devolver el resultado de la comparación
      } else if (e?.type == "transport") {
        const [year, month, day, hour, minute] = e.dateApi
          .split("-")
          .map(Number);
        // Crear un objeto Date correctamente interpretado
        const expiration = new Date(year, month - 1, day, hour, minute);
        return expiration > date; // Devolver el resultado de la comparación
      }
    });
    setSearchStorage(searchStorageFilter);
    localStorage.setItem(
      "storageLastSearch",
      JSON.stringify(searchStorageFilter)
    );
  };

  const handleDateChange = (date) => {
    //esta funcion me convierte la fecha en el siguiente formato 2023-04-29-14-55
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}`;
    setDateApi(formattedDate);
    setSelectedDate(date);

    const placeHolder = {
      dateapi: formattedDate,
      selectedDate: date,
    };
    localStorage.setItem("dateTransport", JSON.stringify(placeHolder));
  };

  function reloadPage() {
    window.location.reload(true);
  }

  const passengersCal = (e, tip, num) => {
    e.preventDefault();
    if (tip == "ad") {
      if (num == -1 && adult == 0) {
        setAdult(0);
      } else {
        setAdult(adult + num);
      }
    } else if (tip == "bo") {
      if (num == -1 && boy == 0) {
        setBoy(0);
      } else {
        setBoy(boy + num);
      }
    } else {
      if (num == -1 && baby == 0) {
        setBaby(0);
      } else {
        setBaby(baby + num);
      }
    }
  };

  if (condition === "/transport") {
    axios
      .post(Loggin)
      .then((res) => {
        localStorage.setItem("politicas", res.data.response.token);
        localStorage.setItem("userId", res.data.response.userId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    const findCity = () => {
      setLoadingCity(true);
      if (city === "") {
        setCityList([]);
        setcitySelected(
          localStorage.getItem("cityTransport")
            ? JSON.parse(localStorage.getItem("cityTransport"))
            : "Selecciona la ciudad"
        );
        setLoadingCity(false);
      } else {
        axios
          .post(
            cityUrl,
            {
              token: localStorage.getItem("politicas"),
              city: city,
            },
            { cancelToken: source.token }
          )
          .then((res) => {
            setCityList(res.data.response);
            setLoadingCity(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              console.log("Error en searchCity: ", err);
              setLoadingCity(false);
            }
          });
      }
    };
    // const getResults = async () => {
    //   try {
    //     setDestiny([])
    //     const res = await axios.post('https://travelflight.pdtcomunicaciones.com/api/restel/cities', {
    //       "code": `${search}`
    //     }, { cancelToken: source.token });

    //     setDestiny(res.data);
    //   } catch (err) {
    //     if (!axios.isCancel(err)) {
    //       console.log("Error en searchCity: ", err);
    //     }
    //   }
    // };

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const debouncedSearchCity = debounce(findCity, 2000);

    debouncedSearchCity();

    return () => {
      source.cancel("Componente desmontado. Cancelar solicitudes pendientes.");
    };
  }, [city]);

  const findOrigin = () => {
    setLoadingOrigin(true);
    axios
      .post(originUrl, {
        service: "FROM_TO_AIRPORT",
        city_id: citySelected.id,
        destination_id: 0,
        token: localStorage.getItem("politicas"),
      })
      .then((res) => {
        setOriginList(res.data.response);
        setLoadingOrigin(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingOrigin(false);
      });
  };

  function formatFecha(fecha) {
    // Asegúrate de que la fecha sea un objeto Date
    const date = new Date(fecha);

    // Obtiene el año en formato de dos dígitos
    const year = date.getFullYear().toString();

    // Obtiene el mes y ajusta a dos dígitos
    const month = ("0" + (date.getMonth() + 1)).slice(-2);

    // Obtiene el día y ajusta a dos dígitos
    const day = ("0" + date.getDate()).slice(-2);

    // Retorna la fecha en el formato deseado
    return `${year}-${month}-${day}`;
  }
  function formatHora(hora) {
    // Reemplaza los dos puntos (:) por guiones (-)
    return hora.replace(":", "-");
  }

  const findDestiny = () => {
    setLoadingDestiny(true);
    axios
      .post(destinyUrl, {
        service: "FROM_TO_AIRPORT",
        city_id: citySelected.id,
        origin_id: originSelected.id,
        token: localStorage.getItem("politicas"),
      })
      .then((res) => {
        setDestinyList(res.data.response);
        setLoadingDestiny(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingDestiny(false);
      });
  };

  const findServices = (e) => {
    e.preventDefault();
    if (typeT === "") {
      setFormValidate("Escoge el tipo de Traslado");
      setCityVerify(true);
    } else if (citySelected === "" || citySelected === "Selecciona la ciudad") {
      if (originSelected === "") {
        setOriginVerify(true);
      }
      if (destinySelected === "") {
        setDestinyVerify(true);
      }
      if (date === "" || hoursSe === "") {
        setFormValidate("Escoge la fecha y hora");
        setCalendarVerify(true);
      }
      setCityVerify(true);
    } else if (originSelected === "") {
      setOriginVerify(true);
      if (destinySelected === "") {
        setDestinyVerify(true);
      }
      if (date === "" || hoursSe === "") {
        setFormValidate("Escoge la fecha y hora");
        setCalendarVerify(true);
      }
    } else if (destinySelected === "") {
      setDestinyVerify(true);
      if (date === "" || hoursSe === "") {
        setFormValidate("Escoge la fecha y hora");
        setCalendarVerify(true);
      }
    } else if (date === "" || hoursSe === "") {
      setFormValidate("Escoge la fecha y hora");
      setCalendarVerify(true);
    } else if (adult === 0) {
      setFormValidate("No se puede ir sin pasajeros");
    } else {
      if (searchStorage.length < 4) {
        const data = {
          typet: typeT,
          cityId: citySelected?.id,
          originId: originSelected?.id,
          destinyId: destinySelected?.id,
          dateApi: formatFecha(date) + `-${formatHora(hoursSe)}`,
          adult: adult,
          baby: baby,
          type: "transport",
          city: citySelected?.name,
          url: `/transport/&typeT=${typeT}&cityId=${
            citySelected?.id
          }&originId=${originSelected?.id}&destinyId=${
            destinySelected.id
          }&dateApi=${
            formatFecha(date) + `-${formatHora(hoursSe)}`
          }&adult=${adult}&baby=${baby}&userId=${userId}&`,
        };
        let newArray = searchStorage.filter(
          (item) => item.type !== "transport"
        );
        localStorage.setItem(
          "storageLastSearch",
          JSON.stringify([...newArray, data])
        );
      }
      navigate(
        `/transport/&typeT=${typeT}&cityId=${citySelected?.id}&originId=${
          originSelected?.id
        }&destinyId=${destinySelected.id}&dateApi=${
          formatFecha(date) + `-${formatHora(hoursSe)}`
        }&adult=${adult}&baby=${baby}&userId=${userId}&`
      );
    }
  };

  useEffect(() => {
    deleteItemsExpiration();
  }, []);

  return {
    constraintsRef,
    formValidate,
    letters,
    setLetters,
    adult,
    setAdult,
    baby,
    setBaby,
    calendar,
    setcalendar,
    calendarTwo,
    setCalendarTwo,
    typeT,
    setTypeT,
    ciudadDropdown,
    setCiudadDropdown,
    aeropuertoDropdown,
    setAeropuertoDropdown,
    dated,
    setDated,
    date,
    setDate,
    nameMapper,
    localeOptions,
    locale,
    setLocale,
    changeScreen,
    passengersCal,
    locales,
    politicas,
    userId,
    cityList,
    citySelected,
    setcitySelected,
    findOrigin,
    originList,
    originSelected,
    setOriginList,
    setOriginSelected,
    findDestiny,
    destinyList,
    setDestinyList,
    destinySelected,
    setDestinySelected,
    destinyDropdown,
    setDestinyDropdown,
    findServices,
    selectedDate,
    handleDateChange,
    loader,
    setCityList,
    loadingCity,
    loadingDestiny,
    loadingOrigin,
    city,
    setCity,
    cityVerify,
    setCityVerify,
    destinyVerify,
    setDestinyVerify,
    originVerify,
    setOriginVerify,
    hoursSe,
    setHoursSe,
    setCalendarVerify,
    calendarVerify,
  };
};

export default TransportC;
