import React, { useState } from "react";
import { PiMedal } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import { IoStarSharp } from "react-icons/io5";
import { BsCalendar2Date } from "react-icons/bs";
import { FiSend } from "react-icons/fi";
import { MdComputer } from "react-icons/md";
import { TbMoneybag } from "react-icons/tb";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { BsHospital } from "react-icons/bs";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";
import SearchBuildPackage from "../../components/SearchBuildPackage/SearchBuildPackage";
import StepStep from "../../components/Step/StepStep";

const DetailClinic = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  const data = [
    {
      id: 1,
      title: "Especialista",
      name: "Dr. Daniel Andres Correa Posada",
      description: "Cirujano Plástico, Estético y Reconstructivo",
      location: "Medellín, Colombia",
    },
    {
      id: 2,
      title: "Clínica",
      name: "Dr. Daniel Andres Correa Posada",
      description: "Cirujano Plástico, Estético y Reconstructivo",
      location: "Medellín, Colombia",
    },
    {
      id: 3,
      title: "Vuelos",
      name: "Dr. Daniel Andres Correa Posada",
      description: "Cirujano Plástico, Estético y Reconstructivo",
      location: "Medellín, Colombia",
    },
    {
      id: 4,
      title: "Hoteles",
      name: "Dr. Daniel Andres Correa Posada",
      description: "Cirujano Plástico, Estético y Reconstructivo",
      location: "Medellín, Colombia",
    },
    {
      id: 5,
      title: "Hoteles",
      name: "Dr. Daniel Andres Correa Posada",
      description: "Cirujano Plástico, Estético y Reconstructivo",
      location: "Medellín, Colombia",
    },
  ];

  const sections = [
    { name: "Sobre Mi", href: "#sobre-mi" },
    { name: "Experiencias", href: "#experiencias" },
    { name: "Opiniones", href: "#opiniones" },
    { name: "Consultorios", href: "#consultorios" },
  ];

  const itemConsult = [
    {
      name: "En línea",
      icon: <MdComputer size={25} />,
    },
    {
      name: "Dirección",
      icon: <IoLocationOutline size={25} />,
    },
  ];

  const itemCalendar = [
    {
      name: "Cita  presencial",
      icon: <BsHospital size={25} color="#00afe8" />,
    },
    {
      name: "Consulta en línea ",
      icon: <MdComputer size={25} color="#00afe8" />,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeCalendarIndex, setActiveCalendarIndex] = useState(0);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>

      <SearchBuildPackage />

      <div className="maxWidthBuildPackage">
        <div className="itemSectionAll">
          {data.map((item) => (
            <StepStep
              key={item.id}
              {...item}
              active={
                item.id === activeStep || completedSteps.includes(item.id)
              }
              onClick={handleStepClick}
              showArrow={item.id === activeStep}
            />
          ))}
        </div>

        <div className="itemDetailDoc">
          <div className="itemDetailDocInfo">
            <div className="itemDetailDocInfoSectionDoc">
              <div className="itemDetailDocInfoSectionDocImg">
                <img src="" alt="" />
              </div>

              <div className="itemDetailDocInfoSectionDocName">
                <div className="itemDetailDocInfoSectionDocNameInfo">
                  <h3>CLÍNICA ESPECIALISTAS DEL POBLADO</h3>
                  <p>
                    Especialista en Cirugía Plástica, Estética y Reconstructiva
                  </p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfo">
                  <div className="itemDetailDocInfoSectionDocInfoIcon">
                    <IoLocationOutline size={25} color="#00AFE8" />
                    <p>3 sedes en Barranquilla, Bogotá Norte... Ver mapa</p>
                  </div>

                  <div className="itemDetailDocInfoSectionDocInfoIcon">
                    <PiMedal size={25} color="#00AFE8" />
                    <p>Registro profesional 1104872209</p>
                  </div>

                  <div>
                    <IoStarSharp size={25} color="#FFD900" />
                    <IoStarSharp size={25} color="#FFD900" />
                    <IoStarSharp size={25} color="#FFD900" />
                    <IoStarSharp size={25} color="#FFD900" />
                    <IoStarSharp size={25} color="#FFD900" />
                  </div>

                  <div className="itemDetailDocInfoSectionDocInfoButtons">
                    <button className="itemDetailDocInfoSectionDocInfoButton">
                      <BsCalendar2Date size={23} />
                      Agendar Cita
                    </button>

                    <button className="itemDetailDocInfoSectionDocInfoButton">
                      <FiSend size={23} />
                      Enviar mensaje
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <nav>
              <ul className="menuItemDetail">
                {sections.map((section, index) => (
                  <li key={index}>
                    <a href={section.href}>{section.name}</a>
                  </li>
                ))}
              </ul>
            </nav>

            <div className="itemDetailDocInfoSectionDocConsult">
              <div className="itemDetailDocInfoSectionDocConsultName">
                <h3>CONSULTORIOS</h3>
              </div>

              <div className="itemDetailDocInfoSectionDocConsultItems">
                {itemConsult.map((section, index) => (
                  <li key={index}>
                    <button
                      className={`itemDetailDocInfoSectionDocConsultItemsButton ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => setActiveIndex(index)}
                    >
                      {section.icon} {section.name}
                    </button>
                  </li>
                ))}
              </div>

              <div className="itemDetailDocInfoSectionDocInfoIcons">
                <div className="itemDetailDocInfoSectionDocInfoIcon">
                  <BsCalendar2Date size={25} color="#00AFE8" />
                  <p>Reservar cita Ver calendario</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoIcon">
                  <TbMoneybag size={25} color="#00AFE8" />
                  <p>Valor de la cita: $ 150.000 COP</p>
                </div>
              </div>
            </div>

            <div className="itemDetailDocInfoSectionDocProcess">
              <div className="itemDetailDocInfoSectionDocConsultName">
                <h3>PROCEDIMIENTOS</h3>
              </div>

              <label
                htmlFor="itemDetailDocInfoSectionDocConsultLabel"
                className="itemDetailDocInfoSectionDocConsultLabel"
              >
                Cirugía Plástica
              </label>
              <div className="itemDetailDocInfoSectionDocProcessIcons">
                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Otoplastia</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Otoplastia</p>
                </div>

                <div></div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Bichectomía</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Bichectomía</p>
                </div>
              </div>

              <label
                htmlFor="itemDetailDocInfoSectionDocConsultLabel"
                className="itemDetailDocInfoSectionDocConsultLabel"
              >
                Cirugía Estetica
              </label>
              <div className="itemDetailDocInfoSectionDocProcessIcons">
                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Ácido hialurónico</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Botox</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Drenaje linfático</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Rinomodelación</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Plasma rico en plaquetas</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Mesoterapia</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Rejuvenecimiento facial</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Depilación Láser</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Celulitis</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Tratamientos para adelgazar</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Eliminar ojeras</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Microblanding</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Peeling</p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfoProcessIcon">
                  <IoMdCheckmarkCircleOutline size={25} color="#00AFE8" />
                  <p>Aumento de labios</p>
                </div>
              </div>
            </div>

            <div className="itemDetailDocInfoSectionDocAbout">
              <div className="itemDetailDocInfoSectionDocConsultName">
                <h3>SOBRE MÍ</h3>
              </div>

              <div className="itemDetailDocInfoSectionDocAboutDesc">
                El Dr. Daniel Hernández es un profesional de la salud con varios
                años de experiencia en promover el rejuvenecimiento facial de
                las personas por medio de tratamientos estéticos no invasivos.
                Médico cirujano egresado de la Universidad del Norte de
                Barranquilla, cuenta con un diplomado en medicina estética y
                antienvejecimiento del Instituto Dermatológico y de Medicina
                Estética. El Dr. Daniel es un médico comprometido con la calidad
                y la ética laboral, por lo que a la hora de atender a sus
                pacientes, procura despejar todas las dudas e inquietudes que
                pueda tener la persona.
              </div>

              <label
                htmlFor="itemDetailDocInfoSectionDocConsultLabel"
                className="itemDetailDocInfoSectionDocConsultLabel"
              >
                Especialidades
              </label>

              <p>
                De todos los servicios ofrecidos por el doctor, los más
                destacados son los siguientes: Perfilamiento de labios,
                procedimiento en donde se utiliza el ácido hialurónico como
                activo para aumentar el volumen, hidratar y mejorar la simetría
                de los labios. El tratamiento no dura más de media hora y sus
                resultados se perciben fácilmente al instante, además, pueden ir
                mejorando con el paso de los días. Rinomodelación, una forma
                innovadora de perfilar los rasgos de la nariz sin necesidad de
                ingresar a quirófano, con ayuda del ácido hialurónico se puede
                tener una nariz más refinada y una piel hidratada. Los niveles
                de dolor de este procedimiento son mínimos, y los resultados
                pueden mantenerse por De todos los servicios ofrecidos por el
                doctor, los más destacados son los siguientes: Perfilamiento de
                labios, procedimiento en donde se utiliza el ácido hialurónico
                como activo para aumentar el volumen, hidratar y mejorar la
                simetría de los labios. El tratamiento no dura más de media hora
                y sus resultados se perciben fácilmente al instante, además,
                pueden ir mejorando con el paso de los días. Rinomodelación, una
                forma innovadora de perfilar los rasgos de la nariz sin
                necesidad de ingresar a quirófano, con ayuda del ácido
                hialurónico se puede tener una nariz más refinada y una piel
                hidratada. Los niveles de dolor de este procedimiento son
                mínimos, y los resultados pueden mantenerse por De todos los
                servicios ofrecidos por el doctor, los más destacados son los
                siguientes: Perfilamiento de labios, procedimiento en donde se
                utiliza el ácido hialurónico como activo para aumentar el
                volumen, hidratar y mejorar la simetría de los labios. El
                tratamiento no dura más de media hora y sus resultados se
                perciben fácilmente al instante, además, pueden ir mejorando con
                el paso de los días. Rinomodelación, una forma innovadora de
                perfilar los rasgos de la nariz sin necesidad de ingresar a
                quirófano, con ayuda del ácido hialurónico se puede tener una
                nariz más refinada y una piel hidratada. Los niveles de dolor de
                este procedimiento son mínimos, y los resultados pueden
                manteners
              </p>
            </div>

            <button className="itemDetailDocInfoSectionDocShowMore">
              Mostrar más <IoMdArrowDropdown size={25} color="#00afe8" />
            </button>

            <div className="itemDetailDocInfoSectionDocComment">
              <div className="itemDetailDocInfoSectionDocConsultName">
                <h3>COMENTARIOS</h3>
              </div>

              <div className="itemDetailDocInfoSectionDocCommentCount">
                <div className="itemDetailDocInfoSectionDocCommentCountItemOne">
                  <h5>40 comentarios de pacientes</h5>

                  <div>
                    <IoStarSharp size={30} color="#FFD900" />
                    <IoStarSharp size={30} color="#FFD900" />
                    <IoStarSharp size={30} color="#FFD900" />
                    <IoStarSharp size={30} color="#FFD900" />
                    <IoStarSharp size={30} color="#FFD900" />
                  </div>
                  <p>Valoración general</p>
                </div>

                <button className="itemDetailDocInfoSectionDocCommentCountButton">
                  Deja tu comentario
                </button>
              </div>

              <div className="itemDetailDocInfoSectionDocCommentCountItemOne">
                <h5>40 comentarios de pacientes</h5>
                <div className="itemDetailDocInfoSectionDocCommentCountItemOneCommentsButton">
                  <div className="itemDetailDocInfoSectionDocCommentCountItemOneComments">
                    <input type="text" placeholder="Consulta en línea" />
                    <IoMdArrowDropdown size={25} color="#00afe8" />
                  </div>
                  <button className="itemDetailDocInfoSectionDocCommentCountItemOneCommentsButtonB">
                    Buscar
                  </button>
                </div>
              </div>
            </div>

            <div className="itemDetailDocInfoSectionDocCommentUser">
              <div className="itemDetailDocInfoSectionDocCommentUserImage">
                <img
                  src="https://images.pexels.com/photos/1040881/pexels-photo-1040881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt=""
                />
              </div>
              <div className="itemDetailDocInfoSectionDocCommentUserInfo">
                <div className="itemDetailDocInfoSectionDocCommentUserInfoName">
                  <h3>Name</h3>
                  <p>Description</p>
                </div>
                <div>
                  <div>
                    <IoStarSharp size={20} color="#FFD900" />
                    <IoStarSharp size={20} color="#FFD900" />
                    <IoStarSharp size={20} color="#FFD900" />
                    <IoStarSharp size={20} color="#FFD900" />
                    <IoStarSharp size={20} color="#FFD900" />
                  </div>
                  <p>8 de mayo de 2024</p>
                </div>

                <div className="itemDetailDocInfoSectionDocCommentUserInfoComment">
                  Excelente servicio, excelente ser humano, la atención, el
                  detalle en las explicaciones, la paciencia, la recomiendo
                  muchísimo!!
                </div>

                <div className="itemDetailDocInfoSectionDocCommentUserInfoCommentView">
                  <h3>Dr. Jose Rodriguez</h3>
                  <p>
                    Excelente servicio, excelente ser humano, la atención, el
                    detalle en las explicaciones, la paciencia, la recomiendo
                    muchísimo!!
                  </p>
                </div>
              </div>
            </div>

            <button className="itemDetailDocInfoSectionDocShowMore">
              Mostrar más <IoMdArrowDropdown size={25} color="#00afe8" />
            </button>
          </div>

          <div className="itemDetailDocCalendarSection">
            <div className="itemDetailDocCalendar">
              <div className="itemDetailDocCalendarTitle">
                <h3>AGENDAR CITA</h3>
              </div>

              <div className="itemDetailDocCalendarList">
                {itemCalendar.map((section, index) => (
                  <li key={index}>
                    <button
                      className={`itemDetailDocCalendarListButton ${
                        activeCalendarIndex === index ? "active" : ""
                      }`}
                      onClick={() => setActiveCalendarIndex(index)}
                    >
                      {section.icon} {section.name}
                    </button>
                  </li>
                ))}
              </div>

              <div className="itemDetailDocCalendarSelect">
                <label
                  htmlFor="itemDetailDocInfoSectionDocConsultLabel"
                  className="itemDetailDocInfoSectionDocConsultLabel"
                >
                  Motivo de la visita
                </label>
                <div className="itemDetailDocInfoSectionDocCommentCountItemOneComments">
                  <input type="text" placeholder="Consulta en línea" />
                  <IoMdArrowDropdown size={25} color="#00afe8" />
                </div>
              </div>
            </div>

            <div className="itemDetailDocCalendar">
              <div className="itemDetailDocCalendarSelect">
                <label
                  htmlFor="itemDetailDocInfoSectionDocConsultLabel"
                  className="itemDetailDocInfoSectionDocConsultLabel"
                >
                  Motivo de la visita
                </label>

                <div className="itemDetailDocCalendarDayHour">
                  <div className="itemDetailDocCalendarDays">
                    <div>
                      <div>Hoy</div>
                      <span>22 May</span>
                    </div>

                    <div>
                      <div>Mañana</div>
                      <span>23 May</span>
                    </div>

                    <div>
                      <div>Viernes</div>
                      <span>24 May</span>
                    </div>

                    <div>
                      <div>Sabado</div>
                      <span>25 May</span>
                    </div>

                    <div>
                      <div>Domingo</div>
                      <span>26 May</span>
                    </div>
                    <MdOutlineArrowDropDownCircle size={25} color="#00afe8" />
                  </div>

                  <div className="itemDetailDocCalendarHour">
                    <div className="itemDetailDocCalendarHourNow">
                      <span>14:00</span>
                    </div>

                    <div className="itemDetailDocCalendarHourNow">
                      <span>14:00</span>
                    </div>

                    <div className="itemDetailDocCalendarHourNow">
                      <span>14:00</span>
                    </div>

                    <div className="itemDetailDocCalendarHourNow">
                      <span>14:00</span>
                    </div>

                    <div className="itemDetailDocCalendarHourNow">
                      <span>14:00</span>
                    </div>
                    <MdOutlineArrowDropDownCircle size={25} color="#00afe8" />
                  </div>
                </div>
              </div>
            </div>

            <button className="itemDetailDocInfoSectionDocShowMore">
              Mostrar más <IoMdArrowDropdown size={25} color="#00afe8" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailClinic;
