import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Keywords_Transport from './Keywords_Transport';
import toast from "react-hot-toast";
import axios from 'axios';

const useMakeReservationTransport = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loadingReservation, setLoadingReservation] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [paxes, setPaxes] = useState([]);
  const [errorReservation, setErrorReservation] = useState("");
  const [reservation, setReservation] = useState({});
  const [needUser, setNeedUser] = useState(false)
  let passengerCapacity = '1 - 3';
  let cantPassenger = passengerCapacity.split("-");
  const { Words } = Keywords_Transport()

  const notify = (title, icon) =>
  toast(title, {
      icon: icon,
  });

  const request = {
    "price": state?.price,
    "arrival": state?.type === Words.onlyFromAiport || state?.type === Words.RoundTrip ? state?.dateTime : null,
    "departure": state?.type === Words.onlyToAirport || state?.type === Words.RoundTrip ? state?.dateTime : null,
    "passengers": state?.passengers,
    "baby": state?.baby,
    "name": paxes[0]?.name + " " + paxes[0]?.lastname,
    "email": paxes[0]?.email,
    "cel": paxes[0]?.tel,
    "flight": state?.type === Words.onlyFromAiport || state?.type === Words.RoundTrip ? paxes[0]?.flightNumber : null,
    "departureFlight": state?.type === Words.onlyToAirport || state?.type === Words.RoundTrip ? paxes[0]?.flightNumber : null,
    "hotel": paxes[0]?.hotel,
    "direction": paxes[0]?.address,
    "token": state?.token,
    "product": state?.product,
    "special": "a",
    "user": state?.user,
    "service": 1,
    "transfer": state?.type === Words.RoundTrip ? 1 : state?.type === Words.onlyFromAiport ? 2 : state?.type === Words.onlyToAirport ? 3 : "",
    "agency": "Wellezy",
    "code": "",
    "type": state?.type,
    "origin": state?.origin,
    "destination": state?.destination,
    "city": state?.city,
    "originApp":"wellezy"
  }

  const createLinkPay = () => {
    const data = {
      "name": paxes[0]?.name,
      "lastname": paxes[0]?.lastname,
      "document": paxes[0]?.doc,
      "email": paxes[0]?.email,
      "cel": paxes[0]?.tel,
      "localizator": paxes[0]?.doc,
      "type": "pollitan",
      "currency":"USD",
      "price":  state?.price,   //state?.price,
      "url": `${process.env.REACT_APP_URL_API_WELLEZY}transport/PayTransport`
    }
    if (paxes[0]?.name === undefined || paxes[0]?.lastname === undefined || paxes[0]?.flightNumber === undefined || paxes[0]?.email === undefined || paxes[0]?.tel === undefined || paxes[0]?.address === undefined || paxes[0]?.doc === undefined || paxes[0]?.hotel === undefined) {
      notify("Debes llenar todos los datos", "❌")
    } else {
      localStorage.setItem('data-pay-transport', JSON.stringify(state))
      localStorage.setItem('request-pay-transport', JSON.stringify(request))
      setLoadingReservation(true)
      setNeedUser(false)
      const url = process.env.REACT_APP_URL_CREATE_LINK
      axios.post(url, data)
        .then(res => {
          if (res.data?.status?.status === "FAILED") {
            setErrorReservation(res.data?.status?.message)
            setLoadingReservation(false)
            setPaxes([])
          } else {
            localStorage.setItem("request-id-pay", res.data?.requestId)
            window.location.href = res.data?.processUrl
          }

        })
        .catch(err => {
          setLoadingReservation(false)
          setErrorReservation("error al crear link de pago")
          console.log(err)
        })
    }

  }








  return {
    createLinkPay,
    needUser,
    cantPassenger,
    reservation,
    errorReservation,
    paxes,
    setPaxes,
    loadingResponse,
    loadingReservation,
    setLoadingResponse,
    setLoadingReservation,
    state
  };

};
export default useMakeReservationTransport;
