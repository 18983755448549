const appointments = [
  {
    doctorName: "Dr. John Smith",
    doctorSpecialty: "Cardiology",
    appointmentDescription: "Routine heart check-up and consultation.",
    detailsLink: "https://example.com/appointment-details/1",
    meetCode: "ABCD1234",
    time: "10:00 AM",
    specialty: "Cardiology",
    creationDate: "2024-09-01",
    status: "leída",
    important: false,
    date: "2024-09-06",
  },
  {
    doctorName: "Dr. Emily Davis",
    doctorSpecialty: "Dermatology",
    appointmentDescription: "Skin evaluation for persistent rashes.",
    detailsLink: "https://example.com/appointment-details/2",
    meetCode: "EFGH5678",
    time: "2:30 PM",
    specialty: "Dermatology",
    creationDate: "2024-09-02",
    status: "no leída",
    important: true,
    date: "2024-09-12",
  },
  {
    doctorName: "Dr. Michael Brown",
    doctorSpecialty: "Neurology",
    appointmentDescription: "Follow-up on migraine management plan.",
    detailsLink: "https://example.com/appointment-details/3",
    meetCode: "IJKL9101",
    time: "11:15 AM",
    specialty: "Neurology",
    creationDate: "2024-09-03",
    status: "archivada",
    important: false,
    date: "2024-09-15",
  },
  {
    doctorName: "Dr. Sarah Johnson",
    doctorSpecialty: "Pediatrics",
    appointmentDescription: "Annual physical examination for children.",
    detailsLink: "https://example.com/appointment-details/4",
    meetCode: "MNOP1122",
    time: "9:00 AM",
    specialty: "Pediatrics",
    creationDate: "2024-09-05",
    status: "leída",
    important: true,
    date: "2024-09-20",
  },
  {
    doctorName: "Dr. William Garcia",
    doctorSpecialty: "Orthopedics",
    appointmentDescription: "Consultation for knee pain and injury assessment.",
    detailsLink: "https://example.com/appointment-details/5",
    meetCode: "QRST3344",
    time: "3:45 PM",
    specialty: "Orthopedics",
    creationDate: "2024-09-06",
    status: "no leída",
    important: false,
    date: "2024-09-22",
  },
  {
    doctorName: "Dr. Linda Martinez",
    doctorSpecialty: "Gastroenterology",
    appointmentDescription: "Consultation for digestive health.",
    detailsLink: "https://example.com/appointment-details/6",
    meetCode: "UVWX5566",
    time: "4:00 PM",
    specialty: "Gastroenterology",
    creationDate: "2024-09-07",
    status: "archivada",
    important: true,
    date: "2024-09-25",
  },
  {
    doctorName: "Dr. James Wilson",
    doctorSpecialty: "Oncology",
    appointmentDescription: "Consultation for cancer treatment options.",
    detailsLink: "https://example.com/appointment-details/7",
    meetCode: "YZAB7788",
    time: "10:30 AM",
    specialty: "Oncology",
    creationDate: "2024-09-08",
    status: "leída",
    important: false,
    date: "2024-09-27",
  },
  {
    doctorName: "Dr. Susan Lee",
    doctorSpecialty: "Endocrinology",
    appointmentDescription: "Evaluation of hormonal imbalances.",
    detailsLink: "https://example.com/appointment-details/8",
    meetCode: "CDEF9910",
    time: "1:00 PM",
    specialty: "Endocrinology",
    creationDate: "2024-09-09",
    status: "no leída",
    important: true,
    date: "2024-09-30",
  },
  {
    doctorName: "Dr. David Young",
    doctorSpecialty: "Nephrology",
    appointmentDescription: "Kidney function evaluation and treatment.",
    detailsLink: "https://example.com/appointment-details/9",
    meetCode: "GHIK1122",
    time: "2:15 PM",
    specialty: "Nephrology",
    creationDate: "2024-09-10",
    status: "archivada",
    important: false,
    date: "2024-10-02",
  },
  {
    doctorName: "Dr. Karen Wright",
    doctorSpecialty: "Hematology",
    appointmentDescription: "Blood disorder diagnosis and management.",
    detailsLink: "https://example.com/appointment-details/10",
    meetCode: "LMNO3344",
    time: "11:45 AM",
    specialty: "Hematology",
    creationDate: "2024-09-11",
    status: "leída",
    important: true,
    date: "2024-10-05",
  },
  {
    doctorName: "Dr. Joseph White",
    doctorSpecialty: "Ophthalmology",
    appointmentDescription: "Vision and eye health consultation.",
    detailsLink: "https://example.com/appointment-details/11",
    meetCode: "PQRS5566",
    time: "9:30 AM",
    specialty: "Ophthalmology",
    creationDate: "2024-09-12",
    status: "no leída",
    important: false,
    date: "2024-10-07",
  },
  {
    doctorName: "Dr. Jessica Thompson",
    doctorSpecialty: "Psychiatry",
    appointmentDescription: "Mental health evaluation and therapy.",
    detailsLink: "https://example.com/appointment-details/12",
    meetCode: "TUVW7788",
    time: "3:00 PM",
    specialty: "Psychiatry",
    creationDate: "2024-09-13",
    status: "archivada",
    important: true,
    date: "2024-10-09",
  },
  {
    doctorName: "Dr. Brian Harris",
    doctorSpecialty: "Pulmonology",
    appointmentDescription: "Lung health and respiratory evaluation.",
    detailsLink: "https://example.com/appointment-details/13",
    meetCode: "XYZA9910",
    time: "12:15 PM",
    specialty: "Pulmonology",
    creationDate: "2024-09-14",
    status: "leída",
    important: false,
    date: "2024-10-12",
  },
  {
    doctorName: "Dr. Jennifer Clark",
    doctorSpecialty: "Rheumatology",
    appointmentDescription: "Joint and muscle pain management.",
    detailsLink: "https://example.com/appointment-details/14",
    meetCode: "BCDE1133",
    time: "10:45 AM",
    specialty: "Rheumatology",
    creationDate: "2024-09-15",
    status: "no leída",
    important: true,
    date: "2024-10-15",
  },
  {
    doctorName: "Dr. Kevin Lopez",
    doctorSpecialty: "Urology",
    appointmentDescription: "Consultation for urinary tract health.",
    detailsLink: "https://example.com/appointment-details/15",
    meetCode: "FGHI3344",
    time: "4:15 PM",
    specialty: "Urology",
    creationDate: "2024-09-16",
    status: "archivada",
    important: false,
    date: "2024-10-18",
  },
  {
    doctorName: "Dr. Laura King",
    doctorSpecialty: "Geriatrics",
    appointmentDescription: "Care for elderly patients.",
    detailsLink: "https://example.com/appointment-details/16",
    meetCode: "JKLM5566",
    time: "8:30 AM",
    specialty: "Geriatrics",
    creationDate: "2024-09-17",
    status: "leída",
    important: true,
    date: "2024-10-21",
  },
  {
    doctorName: "Dr. Edward Hill",
    doctorSpecialty: "Allergy and Immunology",
    appointmentDescription: "Assessment of allergies and immune system.",
    detailsLink: "https://example.com/appointment-details/17",
    meetCode: "NOPQ7788",
    time: "2:00 PM",
    specialty: "Allergy and Immunology",
    creationDate: "2024-09-18",
    status: "no leída",
    important: false,
    date: "2024-10-23",
  },
  {
    doctorName: "Dr. Patricia Scott",
    doctorSpecialty: "Obstetrics and Gynecology",
    appointmentDescription: "Routine OB/GYN examination.",
    detailsLink: "https://example.com/appointment-details/18",
    meetCode: "QRST9901",
    time: "1:30 PM",
    specialty: "Obstetrics and Gynecology",
    creationDate: "2024-09-19",
    status: "archivada",
    important: true,
    date: "2024-10-26",
  },
  {
    doctorName: "Dr. Mark Adams",
    doctorSpecialty: "Plastic Surgery",
    appointmentDescription: "Consultation for cosmetic surgery.",
    detailsLink: "https://example.com/appointment-details/19",
    meetCode: "UVWX1133",
    time: "11:00 AM",
    specialty: "Plastic Surgery",
    creationDate: "2024-09-20",
    status: "leída",
    important: false,
    date: "2024-10-29",
  },
  {
    doctorName: "Dr. Nancy Miller",
    doctorSpecialty: "Infectious Disease",
    appointmentDescription: "Evaluation of chronic infections.",
    detailsLink: "https://example.com/appointment-details/20",
    meetCode: "YZAB3344",
    time: "3:15 PM",
    specialty: "Infectious Disease",
    creationDate: "2024-09-21",
    status: "no leída",
    important: true,
    date: "2024-11-01",
  },
  {
    doctorName: "Dr. Steven Moore",
    doctorSpecialty: "Podiatry",
    appointmentDescription: "Foot health and pain management.",
    detailsLink: "https://example.com/appointment-details/21",
    meetCode: "CDEF5566",
    time: "9:15 AM",
    specialty: "Podiatry",
    creationDate: "2024-09-22",
    status: "archivada",
    important: false,
    date: "2024-11-03",
  },
  {
    doctorName: "Dr. Angela Taylor",
    doctorSpecialty: "Otolaryngology",
    appointmentDescription: "Consultation for ear, nose, and throat issues.",
    detailsLink: "https://example.com/appointment-details/22",
    meetCode: "GHIK7788",
    time: "1:45 PM",
    specialty: "Otolaryngology",
    creationDate: "2024-09-23",
    status: "leída",
    important: true,
    date: "2024-11-06",
  },
  {
    doctorName: "Dr. Richard Thomas",
    doctorSpecialty: "Radiology",
    appointmentDescription: "Imaging and radiological assessment.",
    detailsLink: "https://example.com/appointment-details/23",
    meetCode: "LMNO9910",
    time: "10:00 AM",
    specialty: "Radiology",
    creationDate: "2024-09-24",
    status: "no leída",
    important: false,
    date: "2024-11-09",
  },
  {
    doctorName: "Dr. Karen Anderson",
    doctorSpecialty: "Palliative Care",
    appointmentDescription: "Supportive care for chronic illness.",
    detailsLink: "https://example.com/appointment-details/24",
    meetCode: "PQRS1133",
    time: "4:45 PM",
    specialty: "Palliative Care",
    creationDate: "2024-09-25",
    status: "archivada",
    important: true,
    date: "2024-11-12",
  },
  {
    doctorName: "Dr. Robert Martinez",
    doctorSpecialty: "Anesthesiology",
    appointmentDescription: "Pre-surgical anesthesia consultation.",
    detailsLink: "https://example.com/appointment-details/25",
    meetCode: "TUVW3344",
    time: "2:45 PM",
    specialty: "Anesthesiology",
    creationDate: "2024-09-26",
    status: "leída",
    important: false,
    date: "2024-11-15",
  },
  {
    doctorName: "Dr. Barbara Jackson",
    doctorSpecialty: "Family Medicine",
    appointmentDescription: "Routine check-up and general health review.",
    detailsLink: "https://example.com/appointment-details/26",
    meetCode: "XYZA5566",
    time: "8:45 AM",
    specialty: "Family Medicine",
    creationDate: "2024-09-27",
    status: "no leída",
    important: true,
    date: "2024-11-18",
  },
  {
    doctorName: "Dr. Matthew Rodriguez",
    doctorSpecialty: "Emergency Medicine",
    appointmentDescription: "Emergency health evaluation and treatment.",
    detailsLink: "https://example.com/appointment-details/27",
    meetCode: "BCDE7788",
    time: "3:30 PM",
    specialty: "Emergency Medicine",
    creationDate: "2024-09-28",
    status: "archivada",
    important: false,
    date: "2024-11-21",
  },
  {
    doctorName: "Dr. Anthony Hernandez",
    doctorSpecialty: "Sports Medicine",
    appointmentDescription: "Consultation for sports-related injuries.",
    detailsLink: "https://example.com/appointment-details/28",
    meetCode: "FGHI9910",
    time: "10:15 AM",
    specialty: "Sports Medicine",
    creationDate: "2024-09-29",
    status: "leída",
    important: true,
    date: "2024-11-24",
  },
  {
    doctorName: "Dr. Lisa Perez",
    doctorSpecialty: "Reproductive Endocrinology",
    appointmentDescription: "Consultation for reproductive health.",
    detailsLink: "https://example.com/appointment-details/29",
    meetCode: "JKLM1122",
    time: "12:30 PM",
    specialty: "Reproductive Endocrinology",
    creationDate: "2024-09-30",
    status: "no leída",
    important: false,
    date: "2024-11-27",
  },
  {
    doctorName: "Dr. Charles Gonzalez",
    doctorSpecialty: "Occupational Medicine",
    appointmentDescription: "Workplace health and safety consultation.",
    detailsLink: "https://example.com/appointment-details/30",
    meetCode: "NOPQ3344",
    time: "11:30 AM",
    specialty: "Occupational Medicine",
    creationDate: "2024-10-01",
    status: "archivada",
    important: true,
    date: "2024-11-30",
  },
  {
    doctorName: "Dr. Samantha Walker",
    doctorSpecialty: "Geriatric Psychiatry",
    appointmentDescription: "Mental health care for elderly patients.",
    detailsLink: "https://example.com/appointment-details/31",
    meetCode: "QRST5566",
    time: "3:00 PM",
    specialty: "Geriatric Psychiatry",
    creationDate: "2024-10-02",
    status: "leída",
    important: false,
    date: "2024-12-02",
  },
  {
    doctorName: "Dr. Daniel Lee",
    doctorSpecialty: "Pain Management",
    appointmentDescription: "Consultation for chronic pain management.",
    detailsLink: "https://example.com/appointment-details/32",
    meetCode: "UVWX7788",
    time: "1:00 PM",
    specialty: "Pain Management",
    creationDate: "2024-10-03",
    status: "no leída",
    important: true,
    date: "2024-12-05",
  },
  {
    doctorName: "Dr. Laura Young",
    doctorSpecialty: "Sleep Medicine",
    appointmentDescription: "Evaluation of sleep disorders.",
    detailsLink: "https://example.com/appointment-details/33",
    meetCode: "YZAB9910",
    time: "10:45 AM",
    specialty: "Sleep Medicine",
    creationDate: "2024-10-04",
    status: "archivada",
    important: false,
    date: "2024-12-08",
  },
  {
    doctorName: "Dr. Ryan King",
    doctorSpecialty: "Medical Genetics",
    appointmentDescription: "Consultation for genetic testing and analysis.",
    detailsLink: "https://example.com/appointment-details/34",
    meetCode: "CDEF1122",
    time: "2:15 PM",
    specialty: "Medical Genetics",
    creationDate: "2024-10-05",
    status: "leída",
    important: true,
    date: "2024-12-11",
  },
  {
    doctorName: "Dr. Susan Martinez",
    doctorSpecialty: "Neuro-ophthalmology",
    appointmentDescription: "Evaluation of vision and neurological issues.",
    detailsLink: "https://example.com/appointment-details/35",
    meetCode: "GHIK3344",
    time: "9:30 AM",
    specialty: "Neuro-ophthalmology",
    creationDate: "2024-10-06",
    status: "no leída",
    important: false,
    date: "2024-12-14",
  },
  {
    doctorName: "Dr. David Carter",
    doctorSpecialty: "Critical Care Medicine",
    appointmentDescription: "Critical care and emergency consultation.",
    detailsLink: "https://example.com/appointment-details/36",
    meetCode: "LMNO5566",
    time: "4:30 PM",
    specialty: "Critical Care Medicine",
    creationDate: "2024-10-07",
    status: "archivada",
    important: true,
    date: "2024-12-17",
  },
  {
    doctorName: "Dr. Maria Evans",
    doctorSpecialty: "Transplant Surgery",
    appointmentDescription: "Consultation for transplant surgery.",
    detailsLink: "https://example.com/appointment-details/37",
    meetCode: "PQRS7788",
    time: "1:15 PM",
    specialty: "Transplant Surgery",
    creationDate: "2024-10-08",
    status: "leída",
    important: false,
    date: "2024-12-20",
  },
  {
    doctorName: "Dr. Michael Brooks",
    doctorSpecialty: "Pulmonology",
    appointmentDescription: "Assessment of lung and respiratory health.",
    detailsLink: "https://example.com/appointment-details/38",
    meetCode: "TUVW9910",
    time: "12:45 PM",
    specialty: "Pulmonology",
    creationDate: "2024-10-09",
    status: "no leída",
    important: true,
    date: "2024-12-23",
  },
  {
    doctorName: "Dr. Rachel Allen",
    doctorSpecialty: "Orthopedic Surgery",
    appointmentDescription: "Consultation for bone and joint surgery.",
    detailsLink: "https://example.com/appointment-details/39",
    meetCode: "XYZA1122",
    time: "9:00 AM",
    specialty: "Orthopedic Surgery",
    creationDate: "2024-10-10",
    status: "archivada",
    important: false,
    date: "2024-12-26",
  },
  {
    doctorName: "Dr. Christopher Green",
    doctorSpecialty: "Vascular Surgery",
    appointmentDescription: "Evaluation and surgery of blood vessels.",
    detailsLink: "https://example.com/appointment-details/40",
    meetCode: "BCDE3344",
    time: "11:15 AM",
    specialty: "Vascular Surgery",
    creationDate: "2024-10-11",
    status: "leída",
    important: true,
    date: "2024-12-29",
  },
  {
    doctorName: "Dr. Sandra Hall",
    doctorSpecialty: "Urology",
    appointmentDescription: "Consultation for urinary tract health.",
    detailsLink: "https://example.com/appointment-details/41",
    meetCode: "FGHI5566",
    time: "2:30 PM",
    specialty: "Urology",
    creationDate: "2024-10-12",
    status: "no leída",
    important: false,
    date: "2025-01-02",
  },
  {
    doctorName: "Dr. Kevin White",
    doctorSpecialty: "Clinical Nutrition",
    appointmentDescription: "Nutritional evaluation and dietary planning.",
    detailsLink: "https://example.com/appointment-details/42",
    meetCode: "JKLM7788",
    time: "10:30 AM",
    specialty: "Clinical Nutrition",
    creationDate: "2024-10-13",
    status: "archivada",
    important: true,
    date: "2025-01-05",
  },
  {
    doctorName: "Dr. Amy Turner",
    doctorSpecialty: "Clinical Pharmacology",
    appointmentDescription: "Consultation for medication and drug therapy.",
    detailsLink: "https://example.com/appointment-details/43",
    meetCode: "NOPQ9910",
    time: "1:00 PM",
    specialty: "Clinical Pharmacology",
    creationDate: "2024-10-14",
    status: "leída",
    important: false,
    date: "2025-01-08",
  },
  {
    doctorName: "Dr. Jason Lopez",
    doctorSpecialty: "Endocrine Surgery",
    appointmentDescription:
      "Consultation for surgical treatment of endocrine disorders.",
    detailsLink: "https://example.com/appointment-details/44",
    meetCode: "QRST1122",
    time: "3:45 PM",
    specialty: "Endocrine Surgery",
    creationDate: "2024-10-15",
    status: "no leída",
    important: true,
    date: "2025-01-11",
  },
  {
    doctorName: "Dr. Emma Nelson",
    doctorSpecialty: "Behavioral Neurology",
    appointmentDescription: "Evaluation of behavioral and cognitive disorders.",
    detailsLink: "https://example.com/appointment-details/45",
    meetCode: "UVWX3344",
    time: "2:00 PM",
    specialty: "Behavioral Neurology",
    creationDate: "2024-10-16",
    status: "archivada",
    important: false,
    date: "2025-01-14",
  },
  {
    doctorName: "Dr. Benjamin Rivera",
    doctorSpecialty: "Nuclear Medicine",
    appointmentDescription:
      "Diagnostic imaging and treatment using radioactive substances.",
    detailsLink: "https://example.com/appointment-details/46",
    meetCode: "YZAB5566",
    time: "11:45 AM",
    specialty: "Nuclear Medicine",
    creationDate: "2024-10-17",
    status: "leída",
    important: true,
    date: "2025-01-17",
  },
  {
    doctorName: "Dr. Rebecca Wright",
    doctorSpecialty: "Trauma Surgery",
    appointmentDescription: "Consultation for trauma and emergency surgery.",
    detailsLink: "https://example.com/appointment-details/47",
    meetCode: "CDEF7788",
    time: "9:45 AM",
    specialty: "Trauma Surgery",
    creationDate: "2024-10-18",
    status: "no leída",
    important: false,
    date: "2025-01-20",
  },
  {
    doctorName: "Dr. Brian Morgan",
    doctorSpecialty: "Clinical Neurophysiology",
    appointmentDescription:
      "Assessment of neurological function and disorders.",
    detailsLink: "https://example.com/appointment-details/48",
    meetCode: "GHIK9910",
    time: "1:30 PM",
    specialty: "Clinical Neurophysiology",
    creationDate: "2024-10-19",
    status: "archivada",
    important: true,
    date: "2025-01-23",
  },
  {
    doctorName: "Dr. Stephanie Scott",
    doctorSpecialty: "Hyperbaric Medicine",
    appointmentDescription: "Treatment using hyperbaric oxygen therapy.",
    detailsLink: "https://example.com/appointment-details/49",
    meetCode: "LMNO1122",
    time: "3:15 PM",
    specialty: "Hyperbaric Medicine",
    creationDate: "2024-10-20",
    status: "leída",
    important: false,
    date: "2025-01-26",
  },
  {
    doctorName: "Dr. Thomas Brown",
    doctorSpecialty: "Geriatric Medicine",
    appointmentDescription:
      "Consultation for elderly care and geriatric issues.",
    detailsLink: "https://example.com/appointment-details/50",
    meetCode: "PQRS3344",
    time: "2:45 PM",
    specialty: "Geriatric Medicine",
    creationDate: "2024-10-21",
    status: "no leída",
    important: true,
    date: "2025-01-29",
  },
];

export default appointments;
