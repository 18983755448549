import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const UseDetailClinics = (setCalendar) => {
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [succesScreen, setSuccesScreen] = useState(false);
  const [modal, setModal] = useState(false);
  const [loadingS, setLoadingS] = useState(false);
  const { pathname } = useLocation();

  const url = pathname;
  const parts = url.split("/");
  const id = parts[2];
  const [dataClinic, setDataClinic] = useState({});

  const sendInformation = (e, id) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/appointment/office`;
    if (
      name === "" ||
      tel === "" ||
      email === "" ||
      description === "" ||
      subject === ""
    ) {
      setError(true);
    } else {
      setError(false);
      setLoadingS(true);
      axios
        .post(url, {
          name: name,
          tel: tel,
          email: email,
          description: description,
          identification: subject,
          office_id: id,
        })
        .then((res) => {
          setSuccesScreen(true);
          setLoadingS(false);
          setTimeout(() => {
            setModal(false);
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          setLoadingS(false);
        });
    }
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_URL_API_FINANMED}/v2/get/doctor/${id}`;

    setLoadingS(true);
    axios
      .get(url)
      .then((res) => {
        setLoadingS(false);
        setDataClinic(res.data);
        setCalendar(new Date(), false, res.data?.id);
      })
      .catch((error) => {
        console.log(error);
        setLoadingS(false);
      });
  }, []);

  return {
    sendInformation,
    name,
    setName,
    email,
    setEmail,
    error,
    succesScreen,
    description,
    setDescription,
    tel,
    setTel,
    subject,
    setSubject,
    setModal,
    modal,
    loadingS,
    dataClinic,
    id,
  };
};

export default UseDetailClinics;
