import * as React from 'react';
import ParagraphWellezy from '@/Components/ParagraphWellezy/ParagraphWellezy';

export function ParagraphWellezyPrimary() {
    return (
        <ParagraphWellezy size="large" color="black">
            Paragraph1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
            blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
            neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
            quasi quidem quibusdam.
        </ParagraphWellezy>
    );
}

export function ParagraphWellezySecondary() {
    return (
        <ParagraphWellezy size="medium" color="black">
            Paragraph2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
            blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
            neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
            quasi quidem quibusdam.
        </ParagraphWellezy>
    );
}

export function ParagraphWellezyTernary() {
    return (
        <ParagraphWellezy size="small" color="black">
            Paragraph3. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
            blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
            neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
            quasi quidem quibusdam. 
        </ParagraphWellezy>
    );
}