import useCommercialProfileSpecialities from "../../../../../../../viewModels/profile/commercial/commercialProfile.specialities.viewModel";

// import useCommercialProfileAcknowledgments from '../../../../../../../viewModels/profile/commercial/commercialProfile.acknowledgments.viewModel';

import useCommercialProfilePhotos from "../../../../../../../viewModels/profile/commercial/commercialProfile.photos.viewModel";
import useCommercialProfileVideos from "../../../../../../../viewModels/profile/commercial/commercialProfile.videos.viewModel";

import ActivityIndicator from "../../../../../../share/components/activityIndicator";
import SvgIcon, { iconList } from "../../../../../../share/components/iconSVG";
import ModalContainer, {
  sliceFrom,
} from "../../../../../../share/components/modalContainer";
import Switcher from "../../../../../../share/components/switch";
import "../../../../../styles.css";
import "../../styles.css";

const ModalMessage = ({ text, unsuccessful = false }) => {
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        padding: "0px 20px",
      }}
    >
      {unsuccessful ? (
        <SvgIcon
          color={"#ff0000"}
          name={iconList.IconCloseCircleOutLine}
          size={100}
        />
      ) : (
        <SvgIcon color={"#50AE42"} name={iconList.IconCheckSucces} size={100} />
      )}
      <p
        style={{
          textAlign: "center",
        }}
      >
        {text}
      </p>
    </div>
  );
};

/*
const FormAcknowledgments = ()=>{
  return false
  return(
    <div className='modalCardAcknowledgments'>
<div className='modalCardAcknowledgmentsHead'>
            <p className='modalCardAcknowledgmentsHeadTitle'>Registra tus reconocimientos</p>
            <p className='modalCardAcknowledgmentsHeadSubTitle'>Agrega el nombre, la fecha y una imagen.</p>
            <p className='modalCardAcknowledgmentsHeadSubTitle'>Todos los campos son requeridos</p>
          </div>
          <div className='modalCardAcknowledgmentsBody'>
            {
              acknowledgmentsInputsList.length > 0 && acknowledgmentsInputsList.map((index, key) => {
                return (
                  <div key={key} className='modalCardAcknowledgmentsBodyGroup'>
                    <label className='modalCardAcknowledgmentsBodyGroupLabel'>{index?.label}</label>
                    <div className='modalCardAcknowledgmentsBodyGroupInputWrap'>
                      <input
                        id={index.id}
                        placeholder={index.placeHolder}
                        type={index.type}
                        value={index.value}
                        onChange={index.change}
                        accept={index.accept}
                        className='modalCardAcknowledgmentsBodyGroupInput'
                        autoComplete='off'
                      />
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className='modalCardAcknowledgmentsFoot'>
            <div onClick={saveAdapterAcknowledgment} className='modalCardAcknowledgmentsFootBtn'>

              {
                savingAcknowledgmentsList ?
                  <ActivityIndicator colorFill='#FFFFFF' size={25} />
                  :
                  <p className='modalCardAcknowledgmentsFootBtnText'>Guardar</p>
              }

            </div>
          </div>
          </div>
  )
}
*/
const CommercialSpecialities = ({ props }) => {
  const {
    isLoading,
    isSending,
    specialtiesList,
    userSpecialtiesList,
    message,
    showModal,
    isShowSpecialities,
    isSetChangesSpecialities,
    isSelectedThisSpeciality,
    selecterSpeciality,
    setIsShowSpecialities,
    cancelSpecialities,
    saveSpecialities,
    setShowModal,
  } = props;

  return (
    <div>
      {isLoading && <ActivityIndicator size={40} colorFill="#004274" />}
      <ModalContainer
        show={showModal}
        hidden={setShowModal}
        sizeHeight={75}
        sizeWidth={30}
        from={sliceFrom.right}
      >
        <ModalMessage text={message} />
      </ModalContainer>
      {!isLoading && (
        <div className="CardGaleryHeader">
          <p className="title">Especialidades ({userSpecialtiesList.length})</p>
          <p className="subtitle">Selecciona tus especialidades</p>
        </div>
      )}
      {!isLoading && isShowSpecialities && specialtiesList.length > 0 && (
        <div className="CardGaleryBody specialityContainer">
          {specialtiesList &&
            specialtiesList.map((index, key) => {
              const name = index?.name.toUpperCase();
              const isSelected = isSelectedThisSpeciality(index?.id);
              return (
                <div key={key} className="specialityWrap">
                  <Switcher
                    size={14}
                    value={isSelected}
                    change={(status) => selecterSpeciality(index?.id, status)}
                  />
                  <p className="specialityName">{name}</p>
                </div>
              );
            })}
        </div>
      )}
      {!isLoading && !isSending && (
        <div className="CardGaleryFooter">
          <div
            className="CardGaleryFooterBtn Big"
            onClick={() => setIsShowSpecialities(!isShowSpecialities)}
          >
            <p className="CardGaleryFooterBtnText">
              {isShowSpecialities ? "Ocultar " : "Ver "} lista de especialidades
            </p>
          </div>
        </div>
      )}
      {!isLoading && !isSending && isSetChangesSpecialities && (
        <div className="CardGaleryFooter">
          <div
            className="CardGaleryFooterBtn Small"
            onClick={() => cancelSpecialities()}
          >
            <p className="CardGaleryFooterBtnText">Cancelar</p>
          </div>
          <div
            className="CardGaleryFooterBtn Small"
            onClick={() => saveSpecialities()}
          >
            <p className="CardGaleryFooterBtnText">Guardar</p>
          </div>
        </div>
      )}
      {!isLoading && isSending && (
        <div className="CardGaleryFooter">
          <div className="CardGaleryFooterBtn Big">
            <ActivityIndicator colorFill="#FFFFFF" size={25} />
          </div>
        </div>
      )}
      <div className="gallerySeparator" />
    </div>
  );
};
/*
const CommercialAcknowledgments = ({ props }) => {
  return false
  const {
    acknowledgmentsList,
    acknowledgmentsInputsList,
    acknowledgmentsListModal,
    setAcknowledgmentsListModal,
    saveAdapterAcknowledgment,
    savingAcknowledgmentsList,
  } = props;
  return (
    <>
      <div className='CardGaleryBody'>
        <div className='CardGaleryBodyContainer'>
          <div className='CardGaleryBodyContainerFormGroup' style={{ width: '50%' }}>
            <p>Reconocimientos</p>
          </div>
          <div className='GalleryAdminButton'
            onClick={() => setAcknowledgmentsListModal(true)}>
            <SvgIcon
              color={'#FFFFFF'}
              name={iconList.IconGalleryPlay}
              size={30} />
            <p className='GalleryAdminButtonText'>Agregar más imagenese</p>
          </div>
        </div>
        {acknowledgmentsList && acknowledgmentsList.length > 0 &&
          <div className='acknowledgmentsList'>
            {acknowledgmentsList.map((index, key) => {
              return (
                <div key={key} className='acknowledgmentsFile'>
                  <div className='acknowledgmentsFileImgWrap'>
                    <img src={index?.url} className='acknowledgmentsFileImg' alt={index?.name} />
                  </div>
                  <p className='acknowledgmentsFileName'>
                    {index?.date} - {index?.name}
                  </p>
                </div>
              )
            })}
          </div>
        }
      </div>
    </>
  )
}
*/

const CommercialVideos = ({ props }) => {
  const {
    isSaving,
    fileInputRef,
    handleClick,
    handleChange,
    error,
    message,
    isShoModal,
    setShowModal,
    selectedFiles,
    roundUpNumber,
    deleteFile,
    saveVideos,
  } = props;
  return (
    <div>
      <ModalContainer
        show={isShoModal}
        hidden={setShowModal}
        sizeHeight={75}
        sizeWidth={30}
        from={sliceFrom.right}
      >
        <ModalMessage text={message} />
      </ModalContainer>

      <div className="CardGaleryHeader">
        <p className="title">Videos para galeria</p>
        <p className="subtitle">
          Publica videos en el blog Wellezy y en tu perfil profesional
        </p>
      </div>
      <div className="CardGaleryBody">
        <div className="CardGaleryBodyContainer">
          {isSaving ? (
            <button type="button" className="CardGaleryBigButton">
              <ActivityIndicator colorFill="#FFFFFF" size={60} />
              <p
                className="CardGaleryBigButtonText"
                style={{ marginTop: "40px" }}
              >
                Subiendo archivos
              </p>
            </button>
          ) : (
            <button
              type="button"
              onClick={handleClick}
              className="CardGaleryBigButton"
            >
              <SvgIcon color={"#FFFFFF"} name={iconList.IconPlus} size={100} />
              <p className="CardGaleryBigButtonText">Agregar videos</p>
            </button>
          )}
          <input
            type="file"
            accept="video/*"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <div className="CardGaleryBodyContainerForm">
            <div className="GalleryAdminButton">
              <SvgIcon
                color={"#004274"}
                name={iconList.IconGalleryPlay}
                size={30}
              />
              <p className="GalleryAdminButtonText">
                Administra tu galeria de videos
              </p>
            </div>
          </div>
        </div>
        {error && <p className="messageError">{error}</p>}
        {selectedFiles.length > 0 && (
          <div className="filesContainer">
            {selectedFiles.map((index, key) => {
              return (
                <div key={key} className="fileColumn">
                  <div className="fileRow">
                    <div className="fileRowInfo">
                      <p className="fileRowInfoSize">
                        {roundUpNumber(index?.size)}
                      </p>
                      <p className="fileRowInfoName">{index.name}</p>
                    </div>
                    <div className="fileRowOpctions">
                      <div
                        className="fileRowOpctionsBtn"
                        onClick={() => deleteFile(index.id)}
                      >
                        <SvgIcon
                          color={"#FF0000"}
                          name={iconList.IconTrashOutLine}
                          size={20}
                        />
                      </div>
                    </div>
                  </div>
                  {index?.errorMsg && (
                    <div className="fileRowError">
                      <p className="fileRowErrorText">{index.errorMsg}</p>
                    </div>
                  )}
                </div>
              );
            })}
            <div className="galleryFileFooter">
              <div
                onClick={() => saveVideos()}
                className="modalCardAcknowledgmentsFootBtn"
              >
                <p className="modalCardAcknowledgmentsFootBtnText">Guardar</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="gallerySeparator" />
    </div>
  );
};

const CommercialPhotos = ({ props }) => {
  const {
    isSaving,
    fileInputRef,
    handleClick,
    handleChange,
    error,
    message,
    isShoModal,
    setShowModal,
    selectedFiles,
    roundUpNumber,
    deleteFile,
    saveImages,
  } = props;
  return (
    <>
      <ModalContainer
        show={isShoModal}
        hidden={setShowModal}
        sizeHeight={75}
        sizeWidth={30}
        from={sliceFrom.right}
      >
        <ModalMessage text={message} />
      </ModalContainer>
      <div className="CardGaleryHeader">
        <p className="title">Fotos para galeria</p>
        <p className="subtitle">
          Publica fotos en el blog Wellezy y en tu perfil profesional
        </p>
      </div>
      <div className="CardGaleryBody">
        <div className="CardGaleryBodyContainer">
          {isSaving ? (
            <button type="button" className="CardGaleryBigButton">
              <ActivityIndicator colorFill="#FFFFFF" size={60} />
              <p
                className="CardGaleryBigButtonText"
                style={{ marginTop: "40px" }}
              >
                Subiendo archivos
              </p>
            </button>
          ) : (
            <button
              type="button"
              onClick={handleClick}
              className="CardGaleryBigButton"
            >
              <SvgIcon color={"#FFFFFF"} name={iconList.IconPlus} size={100} />
              <p className="CardGaleryBigButtonText">Agregar foto</p>
            </button>
          )}
          <input
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <div className="CardGaleryBodyContainerForm">
            <div className="GalleryAdminButton">
              <SvgIcon
                color={"#FFFFFF"}
                name={iconList.IconGalleryPlay}
                size={30}
              />
              <p className="GalleryAdminButtonText">
                Administra tu galeria de fotos
              </p>
            </div>
          </div>
        </div>
        {error && <p className="messageError">{error}</p>}
        {selectedFiles.length > 0 && (
          <div className="filesContainer">
            {selectedFiles.map((index, key) => {
              return (
                <div key={key} className="fileColumn">
                  <div className="fileRow">
                    <div className="fileRowInfo">
                      <p className="fileRowInfoSize">
                        {roundUpNumber(index?.size)}
                      </p>
                      <p className="fileRowInfoName">{index.name}</p>
                    </div>
                    <div className="fileRowOpctions">
                      <div
                        className="fileRowOpctionsBtn"
                        onClick={() => deleteFile(index.id)}
                      >
                        <SvgIcon
                          color={"#FF0000"}
                          name={iconList.IconTrashOutLine}
                          size={20}
                        />
                      </div>
                    </div>
                  </div>
                  {index?.errorMsg && (
                    <div className="fileRowError">
                      <p className="fileRowErrorText">{index.errorMsg}</p>
                    </div>
                  )}
                </div>
              );
            })}
            <div className="galleryFileFooter">
              <div
                onClick={() => saveImages()}
                className="modalCardAcknowledgmentsFootBtn"
              >
                <p className="modalCardAcknowledgmentsFootBtnText">Guardar</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="gallerySeparator" />
    </>
  );
};

function CommercialGallery() {
  const propsSpecialites = useCommercialProfileSpecialities();
  // const propsAcknowledgments = useCommercialProfileAcknowledgments();

  const propsVideos = useCommercialProfileVideos();
  const propsPhotos = useCommercialProfilePhotos();

  return (
    <>
      <div className="profileScreen_CardShadow">
        <CommercialSpecialities props={propsSpecialites} />
        {/*
       
        <CommercialAcknowledgments props={propsAcknowledgments} /> 
        */}
        <CommercialPhotos props={propsPhotos} />
        <CommercialVideos props={propsVideos} />
      </div>
    </>
  );
}

export default CommercialGallery;
