import React, { useEffect, useState } from "react";
import "./Assets/styles.css";
import { CloseCircle } from "iconsax-react";
import { useTranslation } from "react-i18next";

const RoomCard = ({
  index,
  deletRoom,
  addAdult,
  subAdult,
  addChild,
  subChild,
  adult,
  child,
  data,
  roomSelected,
  setRoomSelected,
  totalPersons,
}) => {
  const [t, i18n] = useTranslation("global");

  const changeAge = (childIndex, value) => {
    const updatedData = roomSelected.map((room, roomIndex) => {
      if (roomIndex === index) {
        return {
          ...room,
          age: room.age.map((age, ageIndex) => {
            if (ageIndex === childIndex) {
              let newAge = age + value;
              if (newAge < 1) newAge = 1;
              if (newAge > 17) newAge = 17;
              return newAge;
            }
            return age;
          }),
        };
      }
      return room;
    });
    setRoomSelected(updatedData);
  };

  return (
    <div className={index === 0 || index === 1 ? "roomCard" : null}>
      <div className="roomCard_">
        <div className="roomCard_roomOne">
          <p>{t("hotel.search_engine.room")} {index + 1}</p>
          {index >= 1 && (
            <CloseCircle
              className="roomCard_roomOneCloseButton"
              size="30"
              color="var(--bluePrimary)"
              onClick={() => deletRoom(index)}
            />
          )}
        </div>
        <div className="roomCard_roomPeople">
          <div className="roomCard_roomPerson">
            <div className="roomCard_roomPerson_">
              <h4>{t("fligth.search_fligth.adults")}</h4>
              <p>{t("fligth.search_fligth.from")} 18 {t("fligth.search_fligth.years")}</p>
            </div>
            <div className="roomCard_roomPersonButtons">
              <button
                onClick={() => subAdult(index)}
                style={
                  adult === 1
                    ? {
                        backgroundColor: "var(--blueTransparent)",
                        cursor: "not-allowed",
                      }
                    : { backgroundColor: "var(--bluePrimary)" }
                }
              >
                -
              </button>
              <p>{adult}</p>
              <button 
                onClick={() => addAdult(index)}
                style={
                  totalPersons === 8
                    ? {
                        backgroundColor: "var(--blueTransparent)",
                        cursor: "not-allowed",
                      }
                    : { backgroundColor: "var(--bluePrimary)" }
                }
              >+</button>
            </div>
          </div>
          <div className="roomCard_roomPerson">
            <div className="roomCard_roomPerson_">
              <h4>{t("fligth.search_fligth.childrens")}</h4>
              <p>{t("fligth.search_fligth.until")} 17 {t("fligth.search_fligth.years")}</p>
            </div>
            <div className="roomCard_roomPersonButtons">
              <button
                onClick={() => subChild(index)}
                style={
                  child === 0
                    ? {
                        backgroundColor: "var(--blueTransparent)",
                        cursor: "not-allowed",
                      }
                    : { backgroundColor: "var(--bluePrimary)" }
                }
              >
                -
              </button>
              <p>{child}</p>
              <button 
                onClick={() => addChild(index)}
                style={
                  child === 4 || totalPersons === 8
                    ? {
                        backgroundColor: "var(--blueTransparent)",
                        cursor: "not-allowed",
                      }
                    : { backgroundColor: "var(--bluePrimary)" }
                }
              >+</button>
            </div>
          </div>
          {child >= 1 && (
            <div className="contain-age-childrens">
              <div className="contain-all-ages-children">
                {data.age.map((age, ageIndex) => (
                  <div className="contain-all-children" key={ageIndex}>
                    <div>
                      <h3>{t("hotel.search_engine.age")}</h3>
                      <h4>{t("hotel.search_engine.child")} {ageIndex + 1}</h4>
                    </div>
                    <div className="contain-buttons-age-children">
                      <button onClick={() => {
                        changeAge(ageIndex, -1)
                      }}
                      style={
                        age === 1
                          ? {
                              backgroundColor: "var(--blueTransparent)",
                              cursor: "not-allowed",
                            }
                          : { backgroundColor: "var(--bluePrimary)" }
                      }
                      >-</button>
                      <p>{age}</p>
                      <button 
                        onClick={() => changeAge(ageIndex, 1)}
                        style={
                          age === 17
                            ? {
                                backgroundColor: "var(--blueTransparent)",
                                cursor: "not-allowed",
                              }
                            : { backgroundColor: "var(--bluePrimary)" }
                        }
                      >+</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
