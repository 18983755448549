import * as React from "react";
import InputNew from "@/ui/views/share/components/inputsNew/Input";

export default function SelectInput() {
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState(false);

  const options = [
    { value: "", label: "Nulo" },
    { value: "1", label: "Opción 1" },
    { value: "2", label: "Opción 2" },
    { value: "3", label: "Opción 3" },
  ];

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <InputNew
      label={"Seleccione una opción"}
      type="select"
      value={inputValue}
      setValue={setInputValue}
      handleChange={handleChange}
      fullWidth
      small={false}
      disabled={false}
      required={true}
      maxLength={50}
      options={options}
      error={error}
      setError={setError}
    />
  );
}
