import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from "react-hot-toast";

const UseBooking = () => {

    const [name, setName] = useState(localStorage.getItem("BooName") === null ? "" : localStorage.getItem("BooName"))
    const [email, setEmail] = useState(localStorage.getItem("BooEmail") === null ? "" : localStorage.getItem("BooEmail"))
    const [description, setDescription] = useState(localStorage.getItem("BooDescription") === null ? "" : localStorage.getItem("BooDescription"))
    const [age, setAge] = useState(localStorage.getItem("BooAges") === null ? "" : localStorage.getItem("BooAges"))
    const [tel, setTel] = useState(localStorage.getItem("BooTel") === null ? "" : localStorage.getItem("BooTel")) 
    const [reason, setReason] = useState(localStorage.getItem("BooReason") === null ? "" : localStorage.getItem("BooReason"))
    const [treatmentTime, setTreatmentTime] = useState(localStorage.getItem("BooTreatmen") === null ? "" : localStorage.getItem("BooTreatmen"))
    const [budget, setBudget] = useState(localStorage.getItem("BooBudget") === null ? false : localStorage.getItem("BooBudget") === "false" ? false : true)
    const [otherReviews, setOtherReviews] = useState(localStorage.getItem("BooOtherReviews") === null ? false : localStorage.getItem("BooOtherReviews") === "false" ? false : true)
    const [department, setDepartment] = useState(localStorage.getItem("BooDeparment") === null ? "" : localStorage.getItem("BooDeparment"))
    const [city, setCity] = useState(localStorage.getItem("BooCity") === null ? "" : localStorage.getItem("BooCity"))
    const [zone, setZone] = useState("")
    const [optionSelect, setOptionSelect] = useState(localStorage.getItem("BooOption") === null ? "" : localStorage.getItem("BooOption"))
    const [procedure, setProcedure] = useState(localStorage.getItem("BooProcedure") === null ? "" : localStorage.getItem("BooProcedure"))
    const [errCamp, setErrCamp] = useState(false)
    const [code, setCode] = useState(localStorage.getItem("BooCode") === null ? "+57" : localStorage.getItem("BooCode"))
    const [successApointmentState, setSuccessApointmentState] = useState(false)
    const [dataApointment, setDataApointment] = useState()
    const [steps, setSteps] = useState(1)
    const [loading, setLoading] = useState(false)
    const [campFull, setCampFull] = useState(false)

   const navigate = useNavigate()


   const deleteMemory = ()=>{
    localStorage.removeItem("BooName")
    localStorage.removeItem("BooEmail")
    localStorage.removeItem("BooDescription")
    localStorage.removeItem("BooAges")
    localStorage.removeItem("BooTel")
    localStorage.removeItem("BooReason")
    localStorage.removeItem("BooTreatmen")
    localStorage.removeItem("BooBudget")
    localStorage.removeItem("BooOtherReviews")
    localStorage.removeItem("BooDeparment")
    localStorage.removeItem("BooCity")
    localStorage.removeItem("BooOption")
    localStorage.removeItem("BooProcedure")
    localStorage.removeItem("BooCode")
   }

   const notify = (title, icon) =>
   toast(title, {
     icon: icon,
   });
    const sendInfo = (id)=>{
        const telNew = code.concat(tel)
        const data = {
            "name": name,
            "email":email,
            "description":description,
            "age":age,
            "tel":telNew,
            "reason":reason,//motivo consulta
            "treatment_time":treatmentTime,//tiempo de tratamiento,
            "budget":budget ? 0 : 1, //presupuesto
            "other_reviews":otherReviews,//revisiones otros especialistas
            "department":department,
            "city":city,
            "zone":"n/a",
            "procedure":procedure,//tratamiento a realizar
            "pack_id": 0
          }
        if(name === "" || email === "" || tel === "" || code === ""){
            setErrCamp(true)
            notify("Datos Incompletos para enviar la solicitud", "❌");
            setTimeout(() => {
                setErrCamp(false)
            }, 3000);
        }else{
            setLoading(true)
            const url = `${process.env.REACT_APP_URL_API_AIOP}api/appointment/doctor`
            axios.post(url,data)
            .then(res=>{
                setDataApointment(res.data)
                setSteps(6)
                setSuccessApointmentState(true)
                setLoading(false)
                deleteMemory()
                setTimeout(() => {
                  window.location.href = "/Tourismhealth"
                }, 3000);
            })
            .catch(err=>{
              notify("Error en la api", "❌");
                console.log(err)
            })
        }
    }

    const nextStep = ()=>{
      if(steps != 5){
        if(steps === 1){
          if(procedure === ""){
            setCampFull(true)
            notify("Llena todos los campos para seguir", "❌");
            setTimeout(() => {
              setCampFull(false)
            }, 3000);
          }else{
            setSteps(steps + 1)
          }
        }else if(steps === 2){
          if(reason === "" || description === ""){
            setCampFull(true)
            notify("Llena todos los campos para seguir", "❌");
            setTimeout(() => {
              setCampFull(false)
            }, 3000);
          }else{
            setSteps(steps + 1)
          }
        }else if(steps === 3){
          if(budget === "" || treatmentTime === "" || otherReviews === ""){
            setCampFull(true)
            notify("Llena todos los campos para seguir", "❌");
            setTimeout(() => {
              setCampFull(false)
            }, 3000);
          }else{
            setSteps(steps + 1)
          }
        }else if(steps === 4){
          if(city === "" || department === "" || age === ""){
            setCampFull(true)
            notify("Llena todos los campos para seguir", "❌");
            setTimeout(() => {
              setCampFull(false)
            }, 3000);
          }else{
            setSteps(steps + 1)
          }
        }
      }else{
      }
      }
    
      const prevStep = ()=>{
        if(steps > 1){
          setSteps(steps - 1)
        }else{
        }
      }



  return {
    name,
    setName,
    email, 
    setEmail,
    description, 
    setDescription,
    age, 
    setAge,
    tel, 
    setTel,
    reason, 
    setReason,
    treatmentTime,
    setTreatmentTime,
    budget,
    setBudget,
    otherReviews,
    setOtherReviews,
    department,
    setDepartment,
    city,
    setCity,
    zone,
    setZone,
    procedure,
    setProcedure,
    errCamp,
    setCode,
    code,
    sendInfo,
    successApointmentState,
    dataApointment,
    steps,
    prevStep,
    nextStep,
    loading,
    campFull,
    optionSelect,
    setOptionSelect,
    budget,
    otherReviews
  }
}

export default UseBooking