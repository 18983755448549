import React from "react";
import { global } from "../../../../global/global";
export const iconList = global.img.iconsSVG;
const SvgIcon = ({ name, color, size }) => {
  const Icon = name;
  if (!Icon) {
    return (
      <div style={{ color: "#ccc", fontSize: 10 }}>
        No se encontró el icono.
      </div>
    );
  }
  return (
    <Icon
      style={{
        title: name,
        width: `${size}px`,
        height: `${size}px`,
        userSelect: "none",
      }}
      fill={color}
    />
  );
};
export default SvgIcon;
