import React from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

const SuccessApointment = ({
  name,
  status,
  reason,
  doctor,
  succesForm,
  show,
  setShow,
}) => {
  const { state } = useLocation();

  const [t] = useTranslation("global");

  return (
    <div
      className={`${show ? "ModalChange" : "ModalChange close"}`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setShow(false);
        }
      }}
    >
      <section
        className={`${
          show ? "ModalChangeContent" : "ModalChangeContent close"
        }`}
      >
        <button
          className="ModalChangeCloseButton"
          onClick={() => setShow(false)}
        >
          <MdClose size={23} color="#104172" />
        </button>
        <h2
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: 18,
            fontWeight: 600,
            color: "#004274",
            gap: 10,
            paddingRight: 20,
             textAlign: 'center'
          }}
        >
          {t("specialist.booking_flow.send_data_correct")}
          <SvgIcon
            name={iconList.IconCheckSucces}
            color={"#0eb30e"}
            size={30}
          ></SvgIcon>
        </h2>
        <div className="list-data-appointment">
          <div className="camp">
            <label htmlFor="">
              {t("specialist.booking_flow.name_pacient")}:
            </label>
            <input type="text" value={name} readOnly />
          </div>
          <div className="camp">
            <label htmlFor="">Viaje a:</label>
            <input type="text" value={status} readOnly />
          </div>
          <div className="camp">
            <label htmlFor="">Telefono:</label>
            <input type="text" value={reason} readOnly />
          </div>
          <div className="camp">
            <label htmlFor="">Email:</label>
            <input type="text" value={doctor} readOnly />
          </div>
        </div>
      </section>
    </div>
  );
};

export default SuccessApointment;
