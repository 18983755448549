import { imageListClasses } from '@mui/material'
import React, { useState } from 'react' // Importa useState
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../../../utils';
import { MdCameraAlt } from "react-icons/md";

const CardPackages = ({ pack, imgType, width = "" }) => {
    const { image, price, name, currency, due_date, start_date, id } = pack;
    const navigate = useNavigate()

    // Estado para controlar si la imagen ha cargado
    const [imageLoaded, setImageLoaded] = useState(false);

    function formatearFecha(fecha) {
        let fechaObj = new Date(fecha);
        let meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
        let dia = fechaObj.getDate();
        let mes = meses[fechaObj.getMonth()];
        return `${dia} de ${mes}`;
    }

    const handleClick = () => {
        navigate(`/packages/${id}`)
    }

  

    function recortarTexto(cantidadCaracteres, texto) {
        if (texto.length <= cantidadCaracteres) {
          return texto;
        }
        return texto.slice(0, cantidadCaracteres) + "...";
      }

    return (
        <div className='card-packages-new' style={{width: width ? width : ""}}>
            {/* Muestra el esqueleto solo si la imagen no ha cargado */}
            {
                    !imageLoaded &&
                    <div className='img-transport-skeleton'>
                        <MdCameraAlt size={40} color="#c8c8c8" />
                    </div>
                }

            {/* Agrega el evento onLoad a la imagen para cambiar el estado cuando se cargue */}
            <img
                src={`${imgType === true ? image : `${process.env.REACT_APP_URL_API_AIOP}storage/${image}`}`}
                alt={name}
                onLoad={() => setImageLoaded(true)}
                style={{display: imageLoaded ? 'block' : 'none'}} // Oculta la imagen hasta que se cargue
            />

            <div className='description'>
                <h2>{recortarTexto(22,name)}</h2>
                <span>Todo incluido</span>
                <p>{recortarTexto(40,`Inicia el ${formatearFecha(start_date)} y vence ${formatearFecha(due_date)}`)}</p>
            </div>
            <div className='price'>
                <span className='back-price'>
                     {formatMoney(price)} {currency}
                </span>
                <span className='see-more' onClick={handleClick}>
                    Ver más
                </span>
            </div>
        </div>
    )
}

export default CardPackages