import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import styles from "./styles.module.css";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa6";
import { BsCameraVideoFill, BsCameraVideoOffFill } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";

function Controls({ closeMeeting, setCloseMeeting, setCode, participantId }) {
  const { leave, toggleMic, toggleWebcam } = useMeeting();

  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
    useParticipant(participantId);

  const permissionStates = {
    granted: { value: "granted", showModal: false },
    denied: { value: "denied", showModal: true },
    prompt: { value: "prompt", showModal: true },
    unprompted: { value: "unprompted", showModal: true },
    dismissed: { value: "dismissed", showModal: true },
    inactive: { value: "inactive", showModal: true },
  };

  const permissionsList = {
    microphone: "microphone",
    camera: "camera",
  };

  const requestAccess = async (data) => {
    try {
      await navigator.mediaDevices.getUserMedia({ ...data });
    } catch (error) {
      console.error("Error al acceder a la cámara:", error);
    }
  };

  const checkPermission = async (permissionName) => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: permissionName,
      });
      if (permissionStatus) {
        const currentPermission = permissionStates[permissionStatus.state];
        return currentPermission.showModal;
      }
    } catch (error) {
      console.error("Error al verificar el estado del permiso:", error);
    }
    return false;
  };

  const controlMic = async () => {
    const permissionsStatus = await checkPermission(permissionsList.microphone);
    if (!permissionsStatus) toggleMic();
  };

  const controlCam = async () => {
    const permissionsStatus = await checkPermission(permissionsList.camera);
    if (!permissionsStatus) toggleWebcam();
  };

  return (
    <div className={styles.controlsContainer}>
      <div onClick={controlMic} className={styles.controlBtnMicro}>
        {micOn ? <FaMicrophone size={19} /> : <FaMicrophoneSlash />}
      </div>
      <div onClick={controlCam} className={styles.controlBtnWebCam}>
        {webcamOn ? <BsCameraVideoFill /> : <BsCameraVideoOffFill />}
      </div>
      <div
        onClick={() => {
          leave();
          setCloseMeeting(false);
          setCode("");
        }}
        className={styles.controlBtnExit}
      >
        <div className={styles.controlIconExit}>
          <FaPhoneAlt />
        </div>
      </div>
    </div>
  );
}

export default Controls;
