import * as React from 'react';
import styled from './paragraphWellezy.module.css';
import { Typography } from '@mui/material';

export default function ParagraphWellezy({size, color, children}) {

    let paragraphClassName = `
    ${size === "small" ? styled.fonSizeWellezySmall : ""} 
    ${size === "medium" ? styled.fonSizeWellezyMedium : ""} 
    ${size === "large" ? styled.fonSizeWellezyLarge : ""}
    ${color === "white" ? styled.colorWellezyWhite : ""}
    ${color === "black" ? styled.colorWellezyBlack : ""}
    `;

    return (
        <Typography variant="subtitle2" gutterBottom className={paragraphClassName}>
            {children}
        </Typography>
    );
}