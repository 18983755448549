import React, { useState } from "react";
import styles from "./styles.module.css";
import { RiHotelFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import ModalContainer from "@/ui/views/share/components/modalContainer";
import { useSelector } from "react-redux";

const Hotel = ({ hotels, title }) => {
  const [activeChangeService, setActiveChangeService] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);

  const spetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);

  const lat = hotels.dataHot.latitud;
  const lon = hotels.dataHot.longitud;

  const delta = 0.0005;

  const latNum = parseFloat(lat);
  const lonNum = parseFloat(lon);
  
  const minLat = latNum - delta;
  const maxLat = latNum + delta;
  const minLon = lonNum - delta;
  const maxLon = lonNum + delta;

  const mapUrl = `https://www.openstreetmap.org/export/embed.html?bbox=${minLon}%2C${minLat}%2C${maxLon}%2C${maxLat}&layer=mapnik&marker=${lat}%2C${lon}`;

  return (
    <div className={styles.cartItemsItem}>
      {!title ? null : <h3>{spetVariableData === 0 ? '3': '4'}. Hotel</h3>}

      <ModalContainer
        show={moreDetails}
        hiddsetActiveChangeServiceen={setMoreDetails}
        width={700}
        height={200}
      >
        <div className='content-all-info-detail-transport'>
          <h2>Detalles del hotel</h2>
          <div className='line-separation-transport'></div>
          <div className='info-detail-transport'>
            <div className={styles.sectionData}>
              <p>Nombre completo</p>
              <span>{hotels.dataHot.nombre_h}</span>
            </div>

            {hotels?.dataHot.tipo_establecimiento && (
              <div className={styles.sectionData}>
                <p>Tipo</p>
                <span>
                  {hotels?.dataHot.tipo_establecimiento
                    ? "Hotel"
                    : hotels?.dataHot.tipo_establecimiento
                  ? "Hotel"
                  : hotels?.dataHot.tipo_establecimiento}
                </span>
              </div>
            )}

            {(hotels?.dataHot.pob || hotels?.dataHot.poblacion) && (
              <div className={styles.sectionData}>
                <p>Ciudad</p>
                <span>
                  {hotels?.dataHot.pob?.charAt(0).toUpperCase() +
                    hotels?.dataHot.pob?.slice(1).toLowerCase() ||
                    hotels?.dataHot.poblacion?.charAt(0).toUpperCase() +
                    hotels?.dataHot.poblacion?.slice(1).toLowerCase() ||
                    hotels?.dataHot.poblacion?.charAt(0).toUpperCase() +
                    hotels?.dataHot.poblacion?.slice(1).toLowerCase()}
                </span>
              </div>
            )}
            {hotels?.dataHot.direccion && (
              <div className={styles.sectionData}>
                <p>Dirección</p>
                <span>
                  {hotels?.dataHot.direccion}
                </span>
              </div>
            )}

            {hotels?.dataHot.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc && (
              <div className={styles.sectionData}>
                <p>Además</p>
                <span>
                  {hotels?.dataHot.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc}
                </span>
              </div>
            )}
            {hotels?.dataHot.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc && (
              <div className={styles.sectionData}>
                <p>Además</p>
                <span>
                  {hotels?.dataHot.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc}
                </span>
              </div>
            )}
          </div>
        </div>
      </ModalContainer>

      <ModalContainer
        show={activeChangeService}
        hiddsetActiveChangeServiceen={setActiveChangeService}
        width={1200}
        height={700}
      >
        <iframe
          key={iframeKey}
          title="Map hotel"
          width="1200"
          height="630"
          loading="lazy"
          src={mapUrl}
        />
      </ModalContainer>

      <div className={styles.cartItemsItemOne}>
        <div className={styles.itemHotel}>
          <div className={styles.itemHotelContent}>
            <div className={styles.itemHeader}>
              <div className={styles.itemHeaderIconText}>
                <div className={styles.icon}>
                  <RiHotelFill />
                </div>
                <div className={styles.itemHeaderText}>
                  <h4 className={styles.title}>{hotels.hotel}</h4>                    
                  <p className={styles.shortDescription} onClick={() => setActiveChangeService(true)}>
                    Ver Mapa
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.itemDetailsHotel}>
              <ul>
                                {
                  Array.isArray(hotels.dataHot.servicios.servicio) && hotels.dataHot.servicios.servicio.length > 0 ? (
                    hotels.dataHot.servicios.servicio.slice(0, 3).map((option, i) => (
                      <li key={i}>{option.desc_serv}</li>
                    ))
                  ) : (
                    <li>No cuenta con servicios</li>
                  )
                }
              </ul>

              <p
                className={styles.detailsButtonHotel}
                onClick={() => setMoreDetails(true)}
              >
                Ver más detalles
              </p>
            </div>

            <div className={styles.itemLinks}>
              <p>
                Fecha de ingreso: <span>{hotels.arrival}</span>
              </p>
              <p>
                Check in: <span>{hotels.dataHot.checkin}</span>
              </p>
              <p>
                Fecha de salida: <span>{hotels.departure}</span>
              </p>
              <p>
                Check out: <span>{hotels.dataHot.checkout}</span>
              </p>
            </div>
          </div>

          <div className={styles.image}>
            <img src={hotels.dataHot.fotos.foto[0]} alt={`Imagen de ${hotels.hotel}`} />
          </div>
        </div>

        <div className={styles.totalPrice}>
          <p className={styles.totalPriceText}>
            Precio total:{" "}
            <span>
              {
                hotels?.price
                .toLocaleString("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 0,
                })}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hotel;
