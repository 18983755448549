import useHeaderSession from "@/ui/viewModels/session/header.session.viewModel";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDataPackage } from "@/store/slices/dataStepz/dataStepzSlice";
import { useState } from "react";

const useFlightBuildPackage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetail } = useHeaderSession();

  const [activeSessionModal, setActiveSessionModal] = useState(false);

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector(
    (state) => state.dataSearchEngine.spetVariableData
  );
  const setUrlHotel = useSelector((state) => state.dataSearchEngine.urlS);
  const setUrlSpecialist = useSelector((state) => state.dataSearchEngine.urlS);

  const nextStep = (data) => {
    dispatch(setDataPackage({ ...dataPackage, flight: data }));

    if (setSpetVariableData === 1) {
      if (!userDetail) {
        setActiveSessionModal(true);
        return;
      }

      navigate(setUrlSpecialist.specialist);
    } else if (setSpetVariableData === 2) {
      navigate(`/build-package${setUrlHotel.hotel}`);
    }
  };

  return {
    dataPackage,
    setSpetVariableData,
    nextStep,
    activeSessionModal,
    setActiveSessionModal,
    setUrlSpecialist
  };
};

export default useFlightBuildPackage;
