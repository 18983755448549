import TextInput from "@/pages/documentation/inputs/TextInput";
import NumberInput from "@/pages/documentation/inputs/NumberInput";
import SelectInput from "@/pages/documentation/inputs/SelectInput";

const codes = [
  `import * as React from "react";
import InputNew from "../../../ui/views/share/components/inputsNew/Input";

export default function TextInput() {
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState(false);

  return (
    <InputNew
        label={"text"}
        type="text"
        value={inputValue}
        setValue={setInputValue}
        error={error}
        setError={setError}
        fullWidth
        small={false}
        disabled={false}
        required={true}
        email={false}
        maxLength={50}
    />
  );
}
  `,
  `import * as React from "react";
import InputNew from "../../../ui/views/share/components/inputsNew/Input";

export default function TextInput() {
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState(false);

  return (
    <InputNew
        label={"number"}
        type="number"
        value={inputValue}
        setValue={setInputValue}
        error={error}
        setError={setError}
        fullWidth
        small={false}
        disabled={false}
        required={true}
    />
  );
}
`,
  `import * as React from "react";
import InputNew from "@/ui/views/share/components/inputsNew/Input";

export default function SelectInput() {
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState(false);

  const options = [
    { value: "", label: "Nulo" },
    { value: "1", label: "Opción 1" },
    { value: "2", label: "Opción 2" },
    { value: "3", label: "Opción 3" },
  ];

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <InputNew
      label={"Seleccione una opción"}
      type="select"
      value={inputValue}
      setValue={setInputValue}
      handleChange={handleChange}
      fullWidth
      small={false}
      disabled={false}
      required={true}
      maxLength={50}
      options={options}
      error={error}
      setError={setError}
    />
  );
}`
]


const inputs = [
  {
    title: "Input text",
    description: "Input text description",
    component: <TextInput />,
    typeCode: "jsx",
    code: codes[0],
  },
  {
    title: "Input number",
    description: "Input number description",
    component: <NumberInput />,
    typeCode: "jsx",
    code: codes[1],
  },
  {
    title: "Input select",
    description: "Input select description",
    component: <SelectInput />,
    typeCode: "jsx",
    code: codes[2],
  },
];

export default inputs;
