import React, { useEffect } from 'react'
import './styles.css'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import Loading from '../../share/components/loading'
import { useTranslation } from 'react-i18next'
import CardSpecialist from '../../Specialist/AllCardsSpecialist/CardSpecialist/CardSpecialist'
import styles from "./styles.module.css"
import UseClinics from '@/Hooks/BuildPackage/useClinic/UseClinics'
import { useLocation } from "react-router-dom";

const AllClinics = ({ dataClinics, urlImage, loading, detailClinics,seeButton}) => {
    const location = useLocation();

    const [t,i18n] = useTranslation("global")
    const {
        nextStep,
        dataPackage
    } = UseClinics()

    const isStepThree = location.pathname === "/build-package/clinic";

    return (
        <div className={isStepThree ? styles.grid_container_doc_step : styles.grid_container_doc}>
            {
                loading ?

                    <Loading size={100} />
                    :

                    (
                        dataClinics?.length === 0 ?
                            <h2>{t('clinics.no_result')}</h2>
                            :

                            dataClinics?.map((item, key) => {
                                return (
                                   <CardSpecialist key={key} data={item} detailDoctor={detailClinics} seeButton={seeButton} nextStep={nextStep}/>
                                )
                            })
                    )

            }
        </div>
    )
}

export default AllClinics