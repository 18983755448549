import React from 'react'
import "./styles.css"
import question from "./question.svg"
import DisabledModal from '../../share/components/disabledModal/disabledModal'
import TextComponent from '../../share/components/fonts/TextComponent'

const NeedInformation = ({
    title = "titulo",
    description = "description",
    textButton = "texto-button",
    img = "",
    color = "",
    active = false,
    url = "#",
    linkExit = false
}) => {

    const item = {
        route: url,
        disable:active
    }

    return (
        <div className='need-information-tourism'>
            <div className='column-A'>
                <img src={img} alt="icon" />
                <div className='info' >
                    <TextComponent size="lg" fontWeight="bold" color={color ? color : ""}  children={title}/>
                    <TextComponent size="sm" fontWeight="normal" children={description}/>
                </div>
            </div>
            <div className='column-B'>
                <button style={{backgroundColor:color ? color : ""}}>
                    <DisabledModal item={item}  active={active} color={'white'} linkExit={linkExit}>
                        {textButton}
                    </DisabledModal>
                    
                </button>
            </div>
        </div>
    )
}

export default NeedInformation