import React from 'react';
import './styles.css';
import { global } from '../../../../global';

const {
  img: {
    logos: {
      wellezyLogo,
    },
  }
} = global;

function Loading({ size }) {
  return (
    <div className='loaderContainer'>
      <div className='loaderContainerWrap'>
        <div className="load">
          <img src={wellezyLogo} alt="wellezy logo" />
          <div className='containerBalls'>
            <div className="balls"></div>
            <div className="balls"></div>
            <div className="balls"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Loading;