import React, { useEffect, useState } from "react";
import "./styles.css";
import { HambergerMenu, CloseCircle } from "iconsax-react";
import { global } from "../../../../global";
import SideBar from "../sideBar";
import ChangeLanguage from "../changeLanguage";
import JoinTheClub from "../joinTheClub";
import { NavLink, useLocation } from "react-router-dom";
import MenuSideBarHome from "../../../home/components/MenuSideBar";
import { useNavigate } from "react-router-dom";
import HeaderSession from "../headerSession";
import { useTranslation } from "react-i18next";

import { MdLocationOn } from "react-icons/md";
import DisabledModal from "../disabledModal/disabledModal";
import TextComponent from "../fonts/TextComponent";

const {
  logos: { wellezyLogo },
} = global.img;

function Header() {
  const [isShowerSideBar, setIsShowerSideBar] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("global");
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [colorNavbar, setColorNavbar] = useState(true);
  const navBarList = [
    /*{ text: t("browse_home.nav_bar.fligths"), url: '/fligths' }, },*/
    { text: t("browse_home.nav_bar.tourism"), url: "/Tourismhealth" },
    { text: t("browse_home.nav_bar.us"), url: "/aboutus" },
    /*{ text: t("browse_home.nav_bar.build_pack"), url: 'https://wa.link/640gfp' }, },*/
    { text: "Arma tu paquete", url: "/build-package" },
    { text: "PQRS", url: "/pqrs" },
  ];

  function goToOptionMenu(screen) {
    const urlPattern = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/[\w .-]*)*\/?$/i;
    const extarnalURL = urlPattern.test(screen);
    extarnalURL ? (window.location.href = screen) : navigate(`${screen}`, {});
  }

  return (
    <>
      <SideBar
        open={isShowerSideBar}
        close={setIsShowerSideBar}
        width={400}
        child={<MenuSideBarHome clearSideBar={setIsShowerSideBar} />}
      />
      <div className="headerContainerWrap">
        <div className="headerContainer">
          <div className="headerContainerLeft">
            <div
              className="headerMenu"
              onClick={() => setIsShowerSideBar(!isShowerSideBar)}
            >
              {isShowerSideBar ? (
                <CloseCircle size="32" color="#FFFFFF" />
              ) : (
                <HambergerMenu size="32" color="#FFFFFF" />
              )}
            </div>
            <NavLink to="/">
              <div className="headerLogo">
                <img
                  src={wellezyLogo}
                  alt="logo-wellezy"
                  className="headerLogoImg"
                />
              </div>
            </NavLink>
          </div>

          <div className="headerContainerRight">
            {/* <div className="location">
              <MdLocationOn size={20} className="iconLocation" />
              <select className="selectLocation">
                <option value="" selected disabled>
                  {t("browse_home.nav_bar.ubication")}
                </option>
                <option value="1">Bogotá</option>
                <option value="2">Medellín</option>
                <option value="3">Cali</option>
                <option value="4">Barranquilla</option>
                <option value="5">Cartagena</option>
                <option value="6">Santa Marta</option>
                <option value="7">Pereira</option>
              </select>
            </div> */}
            <div className="navbarTextContainer">
              {navBarList.map((index, key) => {
                const isActive = window.location.pathname === index?.url;
                const item = {
                  route: index.url,
                  disable: false,
                };
                return (
                  <div key={key}>
                    {index.url === "/aboutus" ? (
                      <DisabledModal
                        item={item}
                        active={false}
                        width={""}
                        key={key}
                      >
                        <div
                          className={`navBarOption ${
                            isActive ? "navBarOptionActive" : ""
                          }`}
                        >
                          <TextComponent
                            className={"navBarOptionText"}
                            children={index.text}
                            size={"md2"}
                          />
                        </div>
                      </DisabledModal>
                    ) : (
                      <div
                        key={key}
                        className={`navBarOption ${
                          isActive ? "navBarOptionActive" : ""
                        }`}
                        onClick={() => goToOptionMenu(index?.url)}
                      >
                        <TextComponent
                          className={"navBarOptionText"}
                          children={index.text}
                          size={"md2"}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {/* <JoinTheClub /> */}
            <ChangeLanguage />
            <HeaderSession />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
