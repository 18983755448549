import { useState } from "react";
import notificationsData from "./notifications";
import styles from "./index.module.css";
import {
  MdDateRange,
  MdDelete,
  MdNotifications,
  MdNotificationsActive,
} from "react-icons/md";
import useMyNotifications from "@/ui/viewModels/profile/MyNotifications/useMyNotifications.viewModel";
import { HiOutlineMail, HiOutlineMailOpen } from "react-icons/hi";
import { GoFileDirectory } from "react-icons/go";
import { AiOutlineExclamationCircle, AiOutlinePushpin } from "react-icons/ai";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import { Link } from "react-router-dom";

function MyNotifications({ code }) {
  const { codeValue, getNewCode, initMyNotifications, isLoading } =
    useMyNotifications(code);

  const [notifications] = useState(notificationsData);

  const [filterCriteria, setFilterCriteria] = useState({
    status: null,
    important: null,
    date: null,
  });

  const [dateInputVisible, setDateInputVisible] = useState(false);

  const filters = [
    {
      key: "status",
      value: "leída",
      label: "Leídas",
      icon: <HiOutlineMailOpen size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "status",
      value: "no leída",
      label: "No Leídas",
      icon: <HiOutlineMail size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "status",
      value: "archivada",
      label: "Archivadas",
      icon: <GoFileDirectory size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "important",
      value: true,
      label: "Importantes",
      icon: <AiOutlinePushpin size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "date",
      label: "Por Fecha",
      icon: <MdDateRange size={20} color="var(--bluePrimary)" />,
      renderInput: dateInputVisible && (
        <input
          type="date"
          id="filter-date"
          className={styles.dateInput}
          value={filterCriteria.date || ""}
          onChange={(e) => handleFilterChange("date", e.target.value)}
        />
      ),
    },
  ];

  const filterNotifications = (notifications, criteria) => {
    return notifications.filter((notification) => {
      if (criteria.status && notification.status !== criteria.status) {
        return false;
      }
      if (
        criteria.important !== null &&
        notification.important !== criteria.important
      ) {
        return false;
      }
      if (criteria.date && notification.date !== criteria.date) {
        return false;
      }
      return true;
    });
  };

  const filteredNotifications = filterNotifications(
    notifications,
    filterCriteria
  );

  const handleFilterChange = (key, value) => {
    setFilterCriteria((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleButtonClick = (key, value) => {
    if (key === "date") {
      setDateInputVisible(!dateInputVisible);
      if (filterCriteria.date) {
        setFilterCriteria((prev) => ({
          ...prev,
          date: null,
        }));
      }
    } else {
      setFilterCriteria((prev) => ({
        ...prev,
        [key]: filterCriteria[key] === value ? null : value,
      }));
    }
  };

  const isFilterActive = (key, value) => filterCriteria[key] === value;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMovements = filteredNotifications.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const nextHandler = () => {
    if (currentPage < Math.ceil(filteredNotifications.length / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  return (
    <div className={styles.notificationsSection}>
      <div className={styles.notifications}>
        <h3>Notificaciones</h3>
        <div className={styles.notificationsItems}>
          {currentMovements.length > 0 ? (
            currentMovements.map((notification) => (
              <div key={notification.id} className={styles.notificationsItem}>
                <div className={styles.notificationsContent}>
                  <div
                    className={`${styles.notificationsIcon} ${
                      notification.status === "leída" ? styles.read : ""
                    }`}
                  >
                    {notification.status === "leída" ? (
                      <MdNotifications size={20} color="var(--white)" />
                    ) : (
                      <MdNotificationsActive size={20} color="var(--white)" />
                    )}
                  </div>
                  <div className={styles.notificationsText}>
                    {notification.message}
                    <p>{notification.details}</p>
                  </div>
                </div>
                <div className={styles.notificationsTime}>
                  {formattedToday === notification.date
                    ? notification.time
                    : notification.date}
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noItems}>
              <div className={styles.message}>
                No hay notificaciones
                <AiOutlineExclamationCircle
                  size={20}
                  color="var(--bluePrimary)"
                />
              </div>

              <Link to="/" className={styles.buy}>
                Ir a comprar
              </Link>
            </div>
          )}
        </div>
        {currentMovements.length > 0 && (
          <Paginado
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={filteredNotifications}
            itemsPerPage={itemsPerPage}
          />
        )}
      </div>

      <div className={styles.filters}>
        <h3>Filtros</h3>
        <div className={styles.filterButtons}>
          {filters.map(({ key, value, label, icon, renderInput }) => (
            <div key={label} className={styles.filterContainer}>
              <button
                className={`${styles.buttonFilter} ${
                  isFilterActive(key, value) ? styles.activeFilter : ""
                }`}
                onClick={() => handleButtonClick(key, value)}
              >
                {icon} {label}
              </button>
              {renderInput}
            </div>
          ))}
          <button
            className={styles.buttonFilterDelete}
            onClick={() =>
              setFilterCriteria({ status: null, important: null, date: null })
            }
          >
            <MdDelete size={20} color="var(--bluePrimarys)" /> Restablecer
            Filtros
          </button>
        </div>
      </div>
    </div>
  );
}

export default MyNotifications;
