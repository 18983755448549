import { useState, useEffect } from 'react';
import 'react-multi-carousel/lib/styles.css';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import axios from 'axios'
import '../UpsellSelectedFamiliesOneWay/Assets/styles.css'
import CardFamilies from './components/CardFamilies';


const UpsellSelectedFamiliesRoundTrip = ({  recommendation, passengersSelected, FlightSelectedGoing, FlightSelectedReturn, setDataCabin, setDataComponents, setOpenLuggageFamilies, setTittleFamilies, totalPriceJson }) => {

    const FlightGoing = FlightSelectedGoing?.segments;
    const FlightReturn = FlightSelectedReturn?.segments;
    const goingBookingClass = recommendation.paxFareProduct[0].fareDetails[0].groupOfFares[0].productInformation.cabinProduct.length > 1 ? recommendation.paxFareProduct[0].fareDetails[0].groupOfFares[0].productInformation.cabinProduct[0].rbd : recommendation.paxFareProduct[0].fareDetails[0].groupOfFares[0].productInformation.cabinProduct.rbd

    /* 
    *---------ITINERARY GOING AND RETURN-------- 
    */

    const itineraryGoingReturnConditional = FlightGoing.length >= 2 ?
        (FlightGoing.length === 2 ?
            [
                {
                    "group": FlightGoing.indexOf(FlightGoing[0]) + 1,
                    "from": `${FlightGoing[0].location[0].locationId}`,
                    "to": `${FlightGoing[0].location[1].locationId}`,
                    "company": `${FlightGoing[0].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightGoing[0].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightGoing[0].productDateTime.dateOfDeparture} 00:00:00`,
                },

                {
                    "group": FlightGoing.indexOf(FlightGoing[1]) + 1,
                    "from": `${FlightGoing[1].location[0].locationId}`,
                    "to": `${FlightGoing[1].location[1].locationId}`,
                    "company": `${FlightGoing[1].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightGoing[1].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightGoing[1].productDateTime.dateOfDeparture} 00:00:00`,
                }
            ]
            :
            [
                {
                    "group": FlightGoing.indexOf(FlightGoing[0]) + 1,
                    "from": `${FlightGoing[0].location[0].locationId}`,
                    "to": `${FlightGoing[0].location[1].locationId}`,
                    "company": `${FlightGoing[0].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightGoing[0].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightGoing[0].productDateTime.dateOfDeparture} 00:00:00`,
                }
                ,
                {
                    "group": FlightGoing.indexOf(FlightGoing[1]) + 1,
                    "from": `${FlightGoing[1].location[0].locationId}`,
                    "to": `${FlightGoing[1].location[1].locationId}`,
                    "company": `${FlightGoing[1].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightGoing[1].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightGoing[1].productDateTime.dateOfDeparture} 00:00:00`,
                },
                {
                    "group": FlightGoing.indexOf(FlightGoing[2]) + 1,
                    "from": `${FlightGoing[2].location[0].locationId}`,
                    "to": `${FlightGoing[2].location[1].locationId}`,
                    "company": `${FlightGoing[2].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightGoing[2].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightGoing[2].productDateTime.dateOfDeparture} 00:00:00`,
                }
            ]
        )
        :
        [
            {
                "group": FlightGoing.indexOf(FlightGoing[0]) + 1,
                "from": `${FlightGoing[0].location[0].locationId}`,
                "to": `${FlightGoing[0].location[1].locationId}`,
                "company": `${FlightGoing[0].companyId.marketingCarrier}`,
                "flightNumber": `${FlightGoing[0].flightOrtrainNumber}`,
                "class": `${goingBookingClass}`,
                "hour": `${FlightGoing[0].productDateTime.dateOfDeparture} 00:00:00`,
            }
        ];

    const handleitineraryReturn = () => {
        if (FlightReturn.length === 1) {
            itineraryGoingReturnConditional.push({
                "group": FlightGoing.length + 1,
                "from": `${FlightReturn[0].location[0].locationId}`,
                "to": `${FlightReturn[0].location[1].locationId}`,
                "company": `${FlightReturn[0].companyId.marketingCarrier}`,
                "flightNumber": `${FlightReturn[0].flightOrtrainNumber}`,
                "class": `${goingBookingClass}`,
                "hour": `${FlightReturn[0].productDateTime.dateOfDeparture} 00:00:00`,
            })
        }
        if (FlightReturn.length >= 2) {
            if (FlightReturn.length === 2) {
                itineraryGoingReturnConditional.push({
                    "group": FlightGoing.length + 1,
                    "from": `${FlightReturn[0].location[0].locationId}`,
                    "to": `${FlightReturn[0].location[1].locationId}`,
                    "company": `${FlightReturn[0].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightReturn[0].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightReturn[0].productDateTime.dateOfDeparture} 00:00:00`,
                })
                itineraryGoingReturnConditional.push({
                    "group": FlightGoing.length + 2,
                    "from": `${FlightReturn[1].location[0].locationId}`,
                    "to": `${FlightReturn[1].location[1].locationId}`,
                    "company": `${FlightReturn[1].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightReturn[1].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightReturn[1].productDateTime.dateOfDeparture} 00:00:00`,
                })

            } else {
                itineraryGoingReturnConditional.push({
                    "group": FlightGoing.length + 1,
                    "from": `${FlightReturn[0].location[0].locationId}`,
                    "to": `${FlightReturn[0].location[1].locationId}`,
                    "company": `${FlightReturn[0].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightReturn[0].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightReturn[0].productDateTime.dateOfDeparture} 00:00:00`,
                })
                itineraryGoingReturnConditional.push({
                    "group": FlightGoing.length + 2,
                    "from": `${FlightReturn[1].location[0].locationId}`,
                    "to": `${FlightReturn[1].location[1].locationId}`,
                    "company": `${FlightReturn[1].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightReturn[1].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightReturn[1].productDateTime.dateOfDeparture} 00:00:00`,
                })
                itineraryGoingReturnConditional.push({
                    "group": FlightGoing.length + 3,
                    "from": `${FlightReturn[2].location[0].locationId}`,
                    "to": `${FlightReturn[2].location[1].locationId}`,
                    "company": `${FlightReturn[2].companyId.marketingCarrier}`,
                    "flightNumber": `${FlightReturn[2].flightOrtrainNumber}`,
                    "class": `${goingBookingClass}`,
                    "hour": `${FlightReturn[2].productDateTime.dateOfDeparture} 00:00:00`,
                })
            }
        }
    }
    handleitineraryReturn()
    /* 
    *---------END-------- 
    */



    /* const goingCompanyRecommendation = Array.isArray(recommendation.paxFareProduct[0].paxFareDetail.codeShareDetails) === true ? recommendation.paxFareProduct[0].paxFareDetail.codeShareDetails[0].company : recommendation.paxFareProduct[0].paxFareDetail.codeShareDetails.company; */
    // const fareType = Array.isArray(recommendation.paxFareProduct[0].fareDetails[0].groupOfFares[0].productInformation.fareProductDetail.fareType) === true ? recommendation.paxFareProduct[0].fareDetails[0].groupOfFares[0].productInformation.fareProductDetail.fareType[0] : recommendation.paxFareProduct[0].fareDetails[0].groupOfFares[0].productInformation.fareProductDetail.fareType

    const [dataUpsell, setDataUpsell] = useState()


    const [activeGoingBag, setActiveGoingBag] = useState(false)
    const [onewayFares, setOnewayFares] = useState(false)



    const [lowestCostFamily, setLowestCostFamily] = useState();

    const searchForCheapestRate = () => {
        const fareDataQualifierCode = "712";
        let lowerValue = {
            price: 0,
            tariffPosition: 0
        }

        const allFamilies = dataUpsell?.families
        .log("allFamilies: ", allFamilies)


        for (const f in allFamilies) {
            const ListFareDataSupInformation = allFamilies[f][0]?.fare?.fareDataSupInformation;
            const CodeFareDataQualifier = ListFareDataSupInformation.filter(obj => obj?.fareDataQualifier === fareDataQualifierCode)
            const priceObj = CodeFareDataQualifier.filter(obj => obj.fareDataQualifier === fareDataQualifierCode)
            const price = priceObj[0].fareAmount
            const newLowerValue = {
                price: price,
                tariff: f
            }
            if (lowerValue.price === 0) {
                lowerValue = newLowerValue;
            }
            else if (price < lowerValue.price) {
                lowerValue = newLowerValue;
            }
        }

        const position = lowerValue?.tariff;

        const selectedFamilie = allFamilies[position][0]
     
        setLowestCostFamily(selectedFamilie)




    }

    useEffect(() => {
        if (dataUpsell) {
         
            searchForCheapestRate()
        }
    }, [dataUpsell]);




    useEffect(() => {
        const apiUpsell = async () => {
            const url = 'https://travelflight.pdtcomunicaciones.com/api/upsell';
            const result = await axios.post(url, {
                "adult": passengersSelected.adult,
                "child": passengersSelected.boy,
                "baby": passengersSelected.baby,
                /*  "fare":`${fareType}`, */
                "fare": `RP`,
                "bag": false,
                "segments": itineraryGoingReturnConditional
            })
            setDataUpsell(result.data)
        }
        apiUpsell()
    }, []);



    const handleActiveGoing = () => {
        setActiveGoingBag(true)
    }

    const handleActiveReturn = () => {
        setActiveGoingBag(false)
    }


    if (dataUpsell === undefined) {
        return (
            <div>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </div>
        )
    } else {
        return (
            <div className='contained-items-bodyAndFooterCards'>
                <div className='selectedCardsSegments'>
                    <CardFamilies
                        isItOneWayType={true}
                        isItRoundTrip={false}
                        passengersSelected={passengersSelected}
                        itineraryGoingReturnConditional={itineraryGoingReturnConditional}
                        value={onewayFares}
                        change={setOnewayFares}
                        lowestCostFamily={lowestCostFamily}
                    />
                    <CardFamilies
                        isItOneWayType={false}
                        isItRoundTrip={true}
                        passengersSelected={passengersSelected}
                        itineraryGoingReturnConditional={itineraryGoingReturnConditional}
                        value={onewayFares}
                        change={setOnewayFares}
                        lowestCostFamily={lowestCostFamily}
                    />
                </div>

                <div className='items-bodyAndFooterCards'>
                    <div className='itemFooter info-FooterTotalprice'>
                        <div className='totalTittle-FooterCards'><p>Total:</p></div>
                        <div className='buyFee'><p>COP {totalPriceJson}</p></div>
                    </div>
                    <div className='itemFooter buyFee'><p>Comprar</p></div>
                </div>
            </div>
        )
    }
}


export default UpsellSelectedFamiliesRoundTrip