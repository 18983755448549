import Axios from "axios";
import { useState, useCallback, useContext, useEffect } from "react";
import UserContext from "../../../Context/user/userProvider";
import { iconList } from "../../views/share/components/iconSVG";
import { keys, set as setLocalStorage } from "../../localStorage";
import { useLocation, useNavigate } from "react-router-dom";

function useLoginUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUserDetail, setUserToken } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState({});
  const [btnDisable, setBtnDisable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [formRegister, setFormRegister] = useState({ email: "", password: "" });

  const regexList = { email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ };

  const validateInput = (id, value) => {
    let error = "";
    if (value.trim() === "") {
      error = "Este campo es obligatorio";
    } else if (id === "email" && !regexList.email.test(value)) {
      error = "El email es inválido";
    } else if (id === "password" && value.length < 7) {
      error = "La contraseña debe tener al menos 7 caracteres";
    }

    setInputError((prevErrors) => ({
      ...prevErrors,
      [id]: error,
    }));
  };

  const getInputsForm = useCallback((event) => {
    const { id, value } = event.target;
    setFormRegister((prev) => ({ ...prev, [id]: value }));
    validateInput(id, value);
  }, []);

  const handleBlur = (event) => {
    const { id, value } = event.target;
    validateInput(id, value);
  };

  const inputsList = [
    {
      label: "Correo electrónico",
      icon: iconList.IconPencilOutLine,
      type: "text",
      id: "email",
      value: formRegister.email,
      placeholder: "name@gmail.com",
      required: true,
      func: getInputsForm,
      onBlur: handleBlur,
    },
    {
      label: "Contraseña",
      icon: iconList.IconPencilOutLine,
      type: showPassword ? "text" : "password",
      id: "password",
      value: formRegister.password,
      placeholder: "***************",
      required: true,
      func: getInputsForm,
      onBlur: handleBlur,
    },
  ];

  const getInputError = (input) => {
    return inputError[input.id] || false;
  };

  const onLogin = async (modal = false, urlT) => {
    if (loading) return;
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP}api/login`;
      const { data: userData } = await Axios.post(url, formRegister);

      // Check if the login was successful
      if (userData && userData.token) {
        const session = await setLocalStorage(keys.authUserSession, userData);
        if (session) {
          setUserDetail(userData.user);
          setUserToken(userData.token);

          const screen = !modal ? "/" : urlT ? urlT : location.pathname;
          const dataProps = { ...location.state?.dataMemory, refresh: true };
          navigate(screen, { state: dataProps });
        }
      } else {
        // Show generic error if no specific error message is available
        setInputError({
          email: "Credenciales inválidas",
          password: "Credenciales inválidas",
        });
      }
    } catch (error) {
      const status = error.response?.status;
      const message = error.response?.data?.error;

      if (status === 400 && message.includes("email already exists")) {
        // Specific error message when email already exists
        setInputError({ email: "El email ya está registrado", password: "" });
      } else if (status === 401) {
        // Invalid credentials
        setInputError({
          email: "Credenciales inválidas",
          password: "Credenciales inválidas",
        });
      } else {
        // Other errors
        setInputError({
          email: message || "Error en el inicio de sesión",
          password: message || "Error en el inicio de sesión",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const hasErrors = Object.values(inputError).some((error) => error);
    setBtnDisable(
      hasErrors ||
      formRegister.password.length < 7 ||
      !regexList.email.test(formRegister.email)
    );
  }, [formRegister, inputError]);

  return {
    screensList: { home: "/", register: "/register" },
    inputsList,
    btnDisable,
    showPassword,
    setShowPassword,
    loading,
    getInputError,
    onLogin,
  };
}

export default useLoginUser;
