import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import Find from "../../ui/views/share/components/Find/Find";
import CarouselSevices from "../../ui/views/share/components/CarouselServices/CarouselSevices";
import ActivityIndicator from "../../ui/views/share/components/activityIndicator";
import AnyQuestions from "../../ui/views/home/components/anyQuestion";

import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "./Assets/Styles.css";
import ScreenSuccessPay from "./ScreenSuccessPay/ScreenSuccessPay";
import ScreenFailPay from "./ScreenFailPay/ScreenFailPay";
import ScreenProcessPay from "./ScreenProcessPay/ScreenProcessPay";
import ReturnPage from "../../ui/views/share/components/return/ReturnPage";

function TerrawindGenerateVoucherDetail() {
    // const location = useLocation();
    // const dataString = decodeURIComponent(location.pathname.split("/")[4]);
    // const data = JSON.parse(dataString);
    // const formData = Array.isArray(data) ? data[1].dataForm.formData : data.dataForm.formData;
    const product = localStorage.getItem("product");
    const amount = localStorage.getItem("amount");
    const requestId = localStorage.getItem("requestId");
    const formData = localStorage.getItem("formData");
    const dataForm = decodeURIComponent(formData);
    const dataVoucherForm = JSON.parse(dataForm);
    const env = process.env.REACT_APP_URL_ENV

    let url = process.env.REACT_APP_URL_API_AIOP;
    
    const [loading , setLoading] = useState(true);
    const [dataVoucher, setDataVoucher] = useState();
    const [checkVoucher, setCheckVoucher] = useState(false)
    const [statusPay, setStatusPay] = useState("")

    const [t] = useTranslation("global");


    const navigate = useNavigate();

    //  const generateVoucher = async () => {
    //      const url = `${process.env.REACT_APP_URL_API_PLANS}api/terrawind/generate-voucher`

    //      try{
    //          const response = await axios.post(url, formData);
    //         if(response.data.voucher_status === "OK"){
    //             setDataVoucher(response.data.voucher)
    //         }else if(response.data.error.error_number === "520"){
    //             setCheckVoucher(true)
    //             setDataVoucher(response.data.error)
    //         }
    //      }catch(error){
    //          console.error("Error en la llamada a la API de planes", error)
    //      }finally{
    //          setLoading(false);
    //      }
    // }

    const goToBuyAgain = () => {
        navigate("/travelSecure")
    }

    async function getVoucher(){
        try{
            const response = await axios.post(`${url}api/place-to-pay/session`,{
                "request_id": requestId
            })


            if(response.data.status.status === "APPROVED"){
                setStatusPay("APPROVED")
                axios.post(`${process.env.REACT_APP_URL_API_AIOP}api/terrawind/generate-voucher`, dataVoucherForm.formData)
                    .then(responseVoucher => {
                        if(responseVoucher.data.voucher_status === "OK"){
                            setDataVoucher(responseVoucher.data.voucher)
                        }else{
                            setCheckVoucher(true)
                            setDataVoucher(responseVoucher.data.error)
                        }
                    })
                    .catch(error => {
                        console.error("Error en la llamada a la API de planes", error)
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            }else if(response.data.status.status === "REJECTED"){
                setStatusPay("REJECTED")
                setLoading(false);
            }else if(response.data.status.status === "PENDING"){
                setStatusPay("PENDING")
                setLoading(false);
            }
        }catch(error){
            console.error("Error en la llamada a la API de planes", error)
        }
    }

    useEffect(() => {
        getVoucher();
    }, []);

    // useEffect(() => {
    //     console.log("data", data)

    //     generateVoucher();
    // }, []);


    return (
        <div>
            {/* <Find/> */}
            <CarouselSevices current={t("carousel_services.travel_secure")}/>
            <ReturnPage/>
            {
                loading ? 
                <div className="loading-generate-voucher">
                    <ActivityIndicator colorFill='#004274' size={50} />
                </div>
                :
                statusPay === "APPROVED" ?
                <ScreenSuccessPay 
                    checkVoucher={checkVoucher} 
                    errorDescription={dataVoucher.error_description} 
                    product={product} 
                    amount={amount} 
                    dataVoucher={dataVoucher} 
                    goToBuyAgain={goToBuyAgain}
                />
            : statusPay === "REJECTED" ?
                <ScreenFailPay
                    product={product} 
                    amount={amount} 
                    goToBuyAgain={goToBuyAgain}
                />

                : statusPay === "PENDING" ?
            
                    <ScreenProcessPay
                        product={product} 
                        amount={amount} 
                        goToBuyAgain={goToBuyAgain}
                    />
            : null
            }
            <AnyQuestions title={t('browse_home.have_doubt')}/>
        </div> 
    );
}

export default TerrawindGenerateVoucherDetail;