import "./styles.css";
import SearchFlights from "../../../flights/search.flights.view";
import useSearchFlights from "../../../../viewModels/flights/search.flights.viewModel";
import { useState } from "react";
function BrowseHome({ pathname }) {

  const isIframe = false;

  const [checkDate, setCheckDate] = useState(false);
  const [flightDefault, setFlightDefault] = useState(false);

  const {
    cardRefPlace,
    cardRefPassenger,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    buttonDisable,
    searcherFlight,

    //ciudades
    //ida
    isCardRefOrigin,
    setIsCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,

    //retorno
    isCardRefDestination,
    setIsCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    //fechas
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    isCardRefDate,
    setIsCardRefDate,
    setDateGoingSelectedApi,
    setDateReturnSelectedApi,

    //pasajeros
    isManagingPassengers,
    setIsManagingPassengers,
    passengersSelecteds,
    getPassengersSelecteds,
    finishProcess,
    ellipsisText,
    setPassengersSelecteds,

    //Validation
    validationSearchDestiny,
    setValidationSearchDestiny,
    validationSearchDate
  } = useSearchFlights(isIframe);

  

 

  return (
    <section className="browseHomeColumn">
      <section className="contain-search-fligth-banner">
        <SearchFlights
          accompanied={false}
          buttonDisable={buttonDisable}
          cardRefDate={cardRefDate}
          cardRefPassenger={cardRefPassenger}
          cardRefPlace={cardRefPlace}
          dateGoingSelected={dateGoingSelected}
          dateReturnSelected={dateReturnSelected}
          ellipsisText={ellipsisText}
          finishProcess={finishProcess}
          getPassengersSelecteds={getPassengersSelecteds}
          getSelectedDestination={getSelectedDestination}
          getSelectedOrigin={getSelectedOrigin}
          isCardRefDate={isCardRefDate}
          isCardRefDestination={isCardRefDestination}
          isCardRefOrigin={isCardRefOrigin}
          isManagingPassengers={isManagingPassengers}
          isRoundTrip={isRoundTrip}
          passengersSelecteds={passengersSelecteds}
          searcherFlight={searcherFlight}
          selectedDestination={selectedDestination}
          selectedOrigin={selectedOrigin}
          setDateGoingSelected={setDateGoingSelected}
          setDateGoingSelectedApi={setDateGoingSelectedApi}
          setDateReturnSelected={setDateReturnSelected}
          setDateReturnSelectedApi={setDateReturnSelectedApi}
          checkDate={checkDate} 
          setCheckDate={setCheckDate}
          setFlightDefault={setFlightDefault}
          flightDefault={flightDefault}
          setIsCardRefDate={setIsCardRefDate}
          setIsCardRefDestination={setIsCardRefDestination}
          setIsCardRefOrigin={setIsCardRefOrigin}
          setIsManagingPassengers={setIsManagingPassengers}
          setIsRoundTrip={setIsRoundTrip}
          validationSearchDestiny={validationSearchDestiny}
          setValidationSearchDestiny={setValidationSearchDestiny}
          setPass={setPassengersSelecteds}
          validationSearchDate={validationSearchDate}
        />
      </section>

    
      <div className="effect-bacground">
      
        <div className="contain-home-browse">
         
        </div>
      </div>
    </section>
  );
}
export default BrowseHome;
