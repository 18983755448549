import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import { useTranslation } from "react-i18next";

const SuccessApointment = ({ name, status, reason, doctor, show, setShow }) => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  useEffect(() => {
    if (show) {
      // Redirigir a la página deseada después de 5 segundos
      const timer = setTimeout(() => {
        navigate("/Tourismhealth"); // Cambia esto a la ruta de la página de destino
      }, 2000); // 5000 milisegundos = 5 segundos

      // Limpiar el timeout si el componente se desmonta antes del tiempo de redirección
      return () => clearTimeout(timer);
    }
  }, [show, navigate]);

  return (
    <div
      className={`${
        show ? "content-succes-apointment" : "content-succes-apointment close"
      }`}
    >
      <section
        className={`${
          show ? "contain-data-appointment" : "contain-data-appointment close"
        }`}
      >
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
            textAlign: 'center'
          }}
        >
          {t("specialist.booking_flow.send_data_correct")}

          <SvgIcon
            name={iconList.IconCheckSucces}
            color={"#0eb30e"}
            size={30}
          />
        </h2>
      </section>
    </div>
  );
};

export default SuccessApointment;
