import React, { useEffect, useState } from "react";

import CarouselSevices, {
  servicesTravel,
} from "../../share/components/CarouselServices/CarouselSevices";

import axios from "axios";
import ModalForm from "./ModalForm/ModalForm";
import ModalAlert from "./ModalAlert/ModalAlert";
import Slider from "react-slick";
import ModalPay from "./ModalPay/ModalPay";
import CustomSlider from "./sliderImg/CustomSlider";

import { Link, useLocation } from "react-router-dom";
import { currencyFormat, formatMoney } from "../../../utils";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./stylesNew.css";
import { useTranslation } from "react-i18next";
import Recomendation from "../typePackages/Recomendation/Recomendation";
import Loading from "../../share/components/loading";
import AnyQuestions from "../../home/components/anyQuestion";
import ReturnPage from "../../share/components/return/ReturnPage";
import { IoClose } from "react-icons/io5";
import { MdDateRange, MdOutlineLocationOn } from "react-icons/md";
import { FaRegCalendarCheck } from "react-icons/fa6";

const DetailPackages = () => {
  const imageUrls = [];

  const location = useLocation();
  const idPackage = location.pathname.split("/")[2];
  const regex = /(\d+)\snoches,\s(\d+)\sdías/;
  const [t] = useTranslation("global");
  const allParagraft = document.querySelectorAll(".paragraphs-packages p");

  const [selectedImage, setSelectedImage] = useState(imageUrls[0]);
  const [packaged, setPackaged] = useState();
  const [loading, setLoading] = useState(true);
  const [cleanDescription, setCleanDescription] = useState("");
  const [images, setImages] = useState([]);
  const [isModalOpenPackage, setIsModalOpenPackage] = useState(false);
  const [isModalOpenPay, setIsModalOpenPay] = useState(false);
  const [isModalOpenAlert, setIsModalOpenAlert] = useState(false);
  const [packsRecomendation, setPacksRecomendation] = useState([]);

  function closeModal() {
    setIsModalOpenPackage(false);
    setIsModalOpenPay(false);
    setIsModalOpenAlert(false);
  }

  function calcularDiasYNoches(comienzo, salida) {
    // Convertir las cadenas de fecha a objetos Date
    const fechaComienzo = new Date(comienzo);
    const fechaSalida = new Date(salida);

    // Calcular la diferencia en milisegundos
    const diferenciaEnMilisegundos = fechaSalida - fechaComienzo;

    // Convertir la diferencia a días (1 día = 24 horas * 60 minutos * 60 segundos * 1000 milisegundos)
    const diferenciaEnDias = diferenciaEnMilisegundos / (24 * 60 * 60 * 1000);

    // El número de noches es la diferencia en días menos uno
    const noches = diferenciaEnDias - 1;

    return {
      dias: diferenciaEnDias,
      noches: noches,
    };
  }

  const getPacksRecomendation = () => {
    const url = `${process.env.REACT_APP_URL_API_AIOP}api/packages`;
    axios
      .get(url)
      .then((response) => {
        setPacksRecomendation(response.data.packs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const findServices = () => {
    axios
      .get(`${process.env.REACT_APP_URL_API_AIOP}api/packages/${idPackage}`)
      .then((response) => {
        setPackaged(response.data);
        setCleanDescription(response.data.pack.description);
        const imageUrls = response?.data?.pack?.images?.map(
          (image) => image.url
        );
        setImages([
          `${response.data.pack.image}`,
          ...imageUrls.map((url) => `pack/${url}`),
        ]);

        setTimeout(() => {
          setLoading(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 1000);
      })
      .catch((error) => {
        console.error("Error en la llamada a la API de planes", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    findServices();
    getPacksRecomendation();
  }, [location.pathname]);

  useEffect(() => {
    if (packaged !== undefined) {
      setSelectedImage(`${packaged.url}/${images[0]}`);
      images?.map((image, index) => {});
    }
  }, [images]);

  const [imagesCarrusel, setImagesCarrusel] = useState([]);

  useEffect(() => {
    if (packaged && packaged.pack && packaged.pack.description) {
      const imgUrls = extractImages(packaged.pack.description);
      setImagesCarrusel(imgUrls);
    }
    console.log(imagesCarrusel);
  }, [packaged]);

  const extractImages = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const imgElements = doc.querySelectorAll("img");
    const imgUrls = Array.from(imgElements).map((img) => img.src);
    return imgUrls;
  };

  const [show, setShow] = useState(false);

  return (
    <>
      <CarouselSevices current={t("carousel_services.packages")} />
      <ReturnPage />
      <div className="content-detail-pack-new">
        <header>
          <h1>{packaged?.pack?.name}</h1>
          {/* <p>Saliendo los jueves desde {} - 15 DÍAS / 13 NOCHES</p> */}
        </header>
        {loading == true ? (
          <Loading />
        ) : (
          <main>
            <article>
              {images.length == 0 ? (
                <p>No hay imagenes para este paquete</p>
              ) : images?.length == 1 ? (
                <img
                  className="only-img"
                  src={`${process.env.REACT_APP_URL_API_AIOP}storage/${packaged.pack.image}`}
                  onClick={() =>
                    setSelectedImage(
                      `${process.env.REACT_APP_URL_API_AIOP}storage/${packaged.pack.image}`
                    )
                  }
                  style={{ maxHeight: "500px" }}
                  alt={`${1}`}
                />
              ) : (
                <CustomSlider>
                  {images?.map((image, index) => {
                    return (
                      <img
                        className="section-image"
                        src={`${process.env.REACT_APP_URL_API_AIOP}storage/${image}`}
                        key={index}
                        // onClick={() => setSelectedImage(`${process.env.REACT_APP_URL_API_AIOP}storage/${image}`)}
                        alt={`${index + 1}`}
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          width: "100%",
                          maxHeight: "500px",
                        }}
                      />
                    );
                  })}
                </CustomSlider>
              )}
              <div className="content-btn-actions-pack">
                {/* <h2>PAQUETES <span>TURISMO</span></h2> */}

                <div className="camp">
                  <span>Desde</span>
                  <h3 style={{ fontSize: "24px", fontWeight: "700" }}>
                    {packaged?.pack?.price
                      ? formatMoney(packaged?.pack?.price)
                      : "No existe este paquete"}
                    {packaged?.pack?.currency}
                  </h3>
                </div>
                <div className="camp-flex">
                  <MdDateRange size={30} color="var(--bluePrimary)" />
                  <div className="camp">
                    <span>Fecha de salida y regreso</span>
                    <h3>
                      Desde el {packaged?.pack?.start_date || 0} hasta el{" "}
                      {packaged?.pack?.due_date || 0}
                    </h3>
                    <h3>
                      {
                        calcularDiasYNoches(
                          packaged?.pack?.start_date,
                          packaged?.pack?.due_date
                        ).dias || 0
                      }{" "}
                      dias /{" "}
                      {
                        calcularDiasYNoches(
                          packaged?.pack?.start_date,
                          packaged?.pack?.due_date
                        ).noches || 0
                      }{" "}
                      noches
                    </h3>
                  </div>
                  {/* <p>15 DÍAS / 13 NOCHES</p> */}
                </div>
                <div className="camp-flex">
                  <FaRegCalendarCheck size={25} color="var(--bluePrimary)" />
                  <div className="camp">
                    <span>Validez</span>
                    <h3>Hasta agotar existencia</h3>
                  </div>

                  {/* <p>15 DÍAS / 13 NOCHES</p> */}
                </div>

                <div className="camp-flex">
                  <MdOutlineLocationOn size={30} color="var(--bluePrimary)" />
                  <div className="camp">
                    <span>Destino</span>
                    <h3>{packaged?.pack?.destination}</h3>
                  </div>

                  {/* <p>15 DÍAS / 13 NOCHES</p> */}
                </div>

                <div className="content-buttons-packs">
                  {/* <button className='btnCheckout' >Comprar este paquete</button> */}
                  {/* <button className='btnCheckout' >Solicitud en linea</button> */}
                  <button
                    className="btnCheckout"
                    onClick={() => setIsModalOpenPackage(true)}
                  >
                    Cotizar
                  </button>
                  {/* <button onClick={() => setIsModalOpenPay(true)}>Pagar</button> */}
                </div>
              </div>
            </article>
            <aside>
              <div className="column-a">
                <div className="content-list-detail-pack">
                  <h3 className="titlet">Descripcion del paquete:</h3>
                  <div
                    className="paragraphs-packages-images"
                    dangerouslySetInnerHTML={{
                      __html: packaged?.pack?.description
                        ? packaged?.pack?.description?.replace(
                            /<img/g,
                            '<img class="hidden-img-carr"'
                          )
                        : "<h4>No hay informacion disponible</h4>",
                    }}
                  />

                  <div style={{ cursor: "pointer" }}>
                    {imagesCarrusel.length >= 1 && (
                      <CustomSlider>
                        {imagesCarrusel?.map((imgUrl, index) => {
                          return (
                            <img
                              onClick={() => setShow(true)}
                              key={index}
                              className="section-image"
                              src={imgUrl}
                              alt={`Image ${index + 1}`}
                              style={{
                                objectFit: "cover",
                                height: "100%",
                                width: "100%",
                                maxHeight: "500px",
                              }}
                            />
                          );
                        })}
                      </CustomSlider>
                    )}
                  </div>

                  <div
                    className={`${
                      show
                        ? "modal-list-images-pack"
                        : "modal-list-images-pack close"
                    }`}
                    onClick={(e) => {
                      if (e.currentTarget === e.target) {
                        setShow(false);
                      }
                    }}
                  >
                    <div
                      className={`${
                        show
                          ? "content-list-images-pack"
                          : "content-list-images-pack close"
                      }`}
                    >
                      <button
                        className="content-list-images-pack-button-close"
                        onClick={() => setShow(false)}
                      >
                        <IoClose size={20} />
                      </button>
                      {imagesCarrusel.length >= 1 && (
                        <CustomSlider>
                          {imagesCarrusel?.map((imgUrl, index) => {
                            return (
                              <img
                                key={index}
                                className="section-image"
                                src={imgUrl}
                                alt={`Image ${index + 1}`}
                              />
                            );
                          })}
                        </CustomSlider>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-b">
                <div className="content-list-detail-pack">
                  <h3 className="titlet">Incluye:</h3>
                  <div
                    className="paragraphs-packages"
                    dangerouslySetInnerHTML={{
                      __html: packaged?.pack?.include
                        ? packaged?.pack?.include
                        : "<h4>No hay informacion disponible</h4>",
                    }}
                  />
                  <h3 className="titlet">No incluye:</h3>
                  <div
                    className="paragraphs-packages"
                    dangerouslySetInnerHTML={{
                      __html: packaged?.pack?.not_include
                        ? packaged?.pack?.not_include
                        : "<h4>No hay informacion disponible</h4>",
                    }}
                  />
                </div>
              </div>
            </aside>
          </main>
        )}
      </div>
      <div>
        <ModalForm
          isModalOpen={isModalOpenPackage}
          setIsModalOpenAlert={setIsModalOpenAlert}
          closeModal={closeModal}
          packId={packaged?.pack?.id}
          total={packaged?.pack?.price}
        />
        <ModalAlert isModalOpen={isModalOpenAlert} closeModal={closeModal} />
        <ModalPay
          isModalOpen={isModalOpenPay}
          closeModal={closeModal}
          packId={packaged?.pack?.id}
          totalValue={packaged?.pack?.price}
        />
      </div>
      <Recomendation article={packsRecomendation} />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </>
  );
};

export default DetailPackages;
