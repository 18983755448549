import React from "react";
import PropTypes from "prop-types";

const TextComponent = ({ handleInput, children, fontWeight = "normal", size, className, color, onclick = null, tag = "p" }) => {

  // El onclick recibe un objeto con funciones y parámetros en caso que las necesites
  // Estos son los parámetros que puedes enviar:
  // {
  //   void: () => funcion(),
  //   voidP: (param) => funcion(param),
  //   parameters: param
  // }

  const getSize = () => {
    switch (size) {
      case "xl":
        return "32px";
      case "lg":
        return "24px";
      case "md":
        return "16px";
      case "md2":
        return "14px";
      case "sm":
        return "12px";
      case "min":
        return "10px";
      default:
        return "12px";
    }
  };

  const getWeight = () => {
    switch (fontWeight) {
      case "superBold":
        return 900;
      case "bold":
        return 700;
      case "semiBold":
        return 600;
      case "semiNormal":
          return 500;
      case "normal":
        return 400;
      case "light":
        return 300;
      default:
        return 400;
    }
  };

  const handleClick = () => {
    if (onclick) {
      if (onclick.parameters) {
        onclick.voidP(onclick.parameters);
      } else if (onclick.void) {
        onclick.void();
      }
    }
  };

  return React.createElement(
    tag,
    {
      className,
      style: { fontSize: getSize(), fontWeight: getWeight(), color: color || "" },
      onClick: handleClick
    },
    children
  );
};

TextComponent.propTypes = {
  children: PropTypes.node.isRequired,
  fontWeight: PropTypes.oneOf(["superBold", "bold", "normal", "light"]),
  size: PropTypes.oneOf(["xl", "lg", "md", "md2", "sm", "min"]),
  className: PropTypes.string,
  color: PropTypes.string,
  onclick: PropTypes.shape({
    void: PropTypes.func,
    voidP: PropTypes.func,
    parameters: PropTypes.any
  }),
  tag: PropTypes.string
};

TextComponent.defaultProps = {
  fontWeight: "normal",
  size: "sm",
  className: "",
  color: "",
  onclick: null,
  tag: "p"
};

export default TextComponent;
