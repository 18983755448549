import React from 'react'
import "./styles.css"
import CardPackages from '../../../home/components/PackagesSection/CardPackages'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const Recomendation = ({ article }) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1354,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='recomendation-packs'>
      <h2 className='title-recomendation'>Mas Recomendados para ti</h2>
      <p className='paragrafh-recomendation'>Viaja por colombia con paquetes todo incluido y los mejores precios que trae Wellezy para ti </p>
      <div className='content-cards-recomendation'>
        {
          article?.length > 0 ?
            (
              <Slider {...settings}>
                {
                  article.map((item, key) => {
                    return (

                      <CardPackages
                        key={key}
                        pack={item}
                        imgType={false}
                      />
                    )
                  })
                }
              </Slider>
            )

            :

            <h2 style={{ color: "#004274", display: "flex", alignItems: "center", gap: "10px" }}> <img style={{ height: "30px", width: "30px" }} src="https://cdn-icons-png.freepik.com/512/1159/1159417.png" alt="no hay" />No hay items por mostrar</h2>
        }
      </div>
    </div>
  )
}

export default Recomendation