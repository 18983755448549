import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { Calendar as CalendarNew } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./Assets/styles.css";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as locales from "react-date-range/dist/locale";
import SvgIcon, { iconList } from "../../ui/views/share/components/iconSVG";
import toast, { Toaster } from "react-hot-toast";

const FlightsDateGoing = ({
  setOneWay,
  oneWay,
  setRoundTrip,
  roundTrip,
  setDateGoing,
  dateGoing,
  cardRef,
  setDateGoingSelected,
  setDateGoingSelectedApi,
  setDateSreturnSelected,
  setDateSreturnSelectedApi,
  checkDate,
  setCheckDate,
  dateGoingSelected,
  dateSreturnSelected,
  accompanied,
  dateOneWay,
  setDateOneWay,
  dateReturn,
  setDateReturn,
  setIsManagingPassengers,
  flightDefault,
  activeIndex,
  daysProcedure = 0
}) => {
  const [range, setRange] = useState([
    {
      startDate: daysProcedure !== 0 ? new Date() : (dateOneWay || null),
      endDate: daysProcedure !== 0 ? new Date() : (dateReturn || null),
      key: "selection"
    },
  ]);

  // {
  //   startDate: daysProcedure !== 0 ? new Date(new Date().setDate(new Date().getDate() + 1)) : (dateOneWay || new Date(new Date().setDate(new Date().getDate() + 1))),
  //   endDate: daysProcedure !== 0 ? new Date(new Date().setDate(new Date().getDate() + daysProcedure.days)) : (dateReturn || null),
  //   key: "selection",
  // },

  const nameMapper = {
    ar: "Arabic",
    bg: "Bulgarian",
    ca: "Catalan",
    cs: "Czech",
    cy: "Welsh",
    da: "Danish",
    de: "German",
    el: "Greek",
    enGB: "English (United Kingdom)",
    enUS: "English (United States)",
    eo: "Esperanto",
    es: "Spanish",
    et: "Estonian",
    faIR: "Persian",
    fi: "Finnish",
    fil: "Filipino",
    fr: "French",
    hi: "Hindi",
    hr: "Croatian",
    hu: "Hungarian",
    hy: "Armenian",
    id: "Indonesian",
    is: "Icelandic",
    it: "Italian",
    ja: "Japanese",
    ka: "Georgian",
    ko: "Korean",
    lt: "Lithuanian",
    lv: "Latvian",
    mk: "Macedonian",
    nb: "Norwegian Bokmål",
    nl: "Dutch",
    pl: "Polish",
    pt: "Portuguese",
    ro: "Romanian",
    ru: "Russian",
    sk: "Slovak",
    sl: "Slovenian",
    sr: "Serbian",
    sv: "Swedish",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    vi: "Vietnamese",
    zhCN: "Chinese Simplified",
    zhTW: "Chinese Traditional",
  };

  const localeOptions = Object.keys(locales)
    .map((key) => ({
      value: key,
      label: `${key} - ${nameMapper[key] || ""}`,
    }))
    .filter((item) => nameMapper[item.value]);

  const [locale, setLocale] = useState("es");
  const [t, i18n] = useTranslation("global");
  const [fecha, setFecha] = useState(0);

  const location = useLocation();
  const position = location.pathname.split("/")[1];

  const dateMax = new Date();
  dateMax.setDate(dateMax.getDate() + 360);

  const hiddenM = () => {
    if (flightDefault) {
      setIsManagingPassengers(true);
    }
    if (roundTrip) {
      const differenceInTime = new Date(dateReturn) - new Date(dateOneWay);
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if (daysProcedure.days) {
        if (differenceInDays < daysProcedure.days) {
          notify(`El procedimiento ${daysProcedure.name} tiene un minimo de ${daysProcedure.days} dias`, "❌");
          setDateGoingSelected("")
          setDateSreturnSelected("")
        } else {
          setDateGoing(false)
        }
      } else {
        setDateGoing(false)

      }
    }else{
      setDateGoing(false)
    }


  };

  useEffect(() => {
    if (range[0].startDate && range[0].endDate) {
      const startDate = range[0].startDate;
      const endDate = range[0].endDate;

      localStorage.setItem("dateOne", startDate);
      setDateOneWay(startDate);
      const mm = String(startDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
      const dd = String(startDate.getDate()).padStart(2, '0');
      const yy = String(startDate.getFullYear()).slice(-2);
      const formattedDate = `${mm}/${dd}/${yy}`;
      setDateGoingSelected(formattedDate);
      setDateGoingSelectedApi(startDate.toISOString());

      localStorage.setItem("dateReturn", endDate);
      setDateReturn(endDate);
      setFecha(calcularDiasEntreFechas(startDate, endDate));
      const mme = String(endDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
      const dde = String(endDate.getDate()).padStart(2, '0');
      const yye = String(endDate.getFullYear()).slice(-2);
      const formattedDatee = `${mme}/${dde}/${yye}`;
      setDateSreturnSelected(formattedDatee);
      setDateSreturnSelectedApi(endDate.toISOString());
    } else if (range[0].startDate) {
      const startDate = range[0].startDate;

      localStorage.setItem("dateOne", startDate);
      setDateOneWay(startDate);
      const mm = String(startDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
      const dd = String(startDate.getDate()).padStart(2, '0');
      const yy = String(startDate.getFullYear()).slice(-2);
      const formattedDate = `${mm}/${dd}/${yy}`;
      setDateGoingSelected(formattedDate);
      setDateGoingSelectedApi(startDate.toISOString());
    }
  }, [range]);

  useEffect(() => {

    if (daysProcedure.days == 0) {
      const storedDateOne = localStorage.getItem("dateOne");
      const storedDateReturn = localStorage.getItem("dateReturn");

      if (storedDateOne) {
        const startDate = new Date(storedDateOne);
        setDateOneWay(startDate);
        const mm = String(startDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
        const dd = String(startDate.getDate()).padStart(2, '0');
        const yy = String(startDate.getFullYear()).slice(-2);
        const formattedDate = `${mm}/${dd}/${yy}`;
        setDateGoingSelected(formattedDate);
        setDateGoingSelectedApi(startDate.toISOString());
        setRange((prevRange) => [{ ...prevRange[0], startDate }]);
      }

      if (storedDateReturn) {
        const endDate = new Date(storedDateReturn);
        setDateReturn(endDate);
        const mme = String(endDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
        const dde = String(endDate.getDate()).padStart(2, '0');
        const yye = String(endDate.getFullYear()).slice(-2);
        const formattedDatee = `${mme}/${dde}/${yye}`;
        setDateSreturnSelected(formattedDatee);
        setDateSreturnSelectedApi(endDate.toISOString());
        setRange((prevRange) => [{ ...prevRange[0], endDate }]);
      }
    }

    if (daysProcedure.days) {
      setDateGoingSelected("")
      setDateSreturnSelected("")
    }

  }, []);

  const handleRangeChange = (item) => {
    const differenceInTime = new Date(item.selection.endDate) - new Date(item.selection.startDate);
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    

    if (new Date(item.selection.startDate).getTime() === new Date(item.selection.endDate).getTime()) {
      setRange([{
        ...range[0],
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
      }]);
    } else if (item.selection.startDate && item.selection.endDate) {
      if (differenceInDays < daysProcedure.days) {
        notify(`El procedimiento ${daysProcedure.name} tiene un minimo de ${daysProcedure.days} dias`, "❌");
        const newEndDate = new Date(item.selection.startDate);
        newEndDate.setDate(newEndDate.getDate() + daysProcedure.days);

        // Actualiza el rango con el nuevo endDate
        setRange([{
          ...range[0],
          startDate: item.selection.startDate,
          endDate: newEndDate,

        }]);
      } else {
        setRange([item.selection]);
      }
    } else if (item.selection.startDate) {
      // Solo actualizar la fecha de ida si es un viaje de ida y vuelta
      if (roundTrip) {
        setRange([{ ...range[0], startDate: item.selection.startDate }]);
      }
    } else if (item.selection.endDate) {
      setRange([{ ...range[0], endDate: item.selection.endDate }]);
    }

  };

  const onChangeOnlyDateOne = (item) => {

    const newRange = [{ ...range[0], startDate: item, endDate: null }];
    setRange(newRange);
    localStorage.setItem("dateOne", item);
    setDateOneWay(item);

    const mm = String(item.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const dd = String(item.getDate()).padStart(2, '0');
    const yy = String(item.getFullYear()).slice(-2);
    const formattedDate = `${mm}/${dd}/${yy}`;
    setDateGoingSelected(formattedDate);
    setDateGoingSelectedApi(item.toISOString());
  };

  function calcularDiasEntreFechas(fechaInicio, fechaFin) {
    const unDiaEnMilisegundos = 1000 * 60 * 60 * 24; // 1 día en milisegundos
    const diferenciaEnMilisegundos = fechaFin - fechaInicio;
    const diasDeDiferencia = Math.round(
      diferenciaEnMilisegundos / unDiaEnMilisegundos
    );
    return diasDeDiferencia;
  }

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });


    console.log(checkDate + " " + activeIndex)

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="flightsDateGoing_container" ref={cardRef}>
        {
          !roundTrip ?
            <div className="camp-enter-calendar">
              <div>
                <label htmlFor="">Fecha</label>
                <p>{dateOneWay.toLocaleDateString("es-ES", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}</p>
              </div>
              <SvgIcon color={"var(--bluePrimary)"} size={30} name={iconList.IconCalendar2} />
            </div>

            :
            <div className="camp-enter-calendar">
              <div>
                <label htmlFor="">Fecha</label>
                <p>{dateOneWay.toLocaleDateString("es-ES", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })} - {dateReturn.toLocaleDateString("es-ES", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}</p>
              </div>
              <SvgIcon color={"var(--bluePrimary)"} size={30} name={iconList.IconCalendar2} />
            </div>
        }
        {
          checkDate == false && activeIndex == 1 ? (
            <div
              className="flightsDateGoing"
              style={{ right: `${accompanied ? "auto" : "0"}` }}
            >
              <div className="dateRangeOnly">
                {roundTrip ? (
                  null
                ) : (
                  <CalendarNew
                    minDate={new Date()}
                    maxDate={dateMax}
                    locale={locales[locale]}
                    onChange={onChangeOnlyDateOne}
                    date={range[0].startDate} // Mostrar fecha de ida seleccionada
                  />
                )}
                <div className="contain-btn-select-f">
                  <button onClick={hiddenM}>
                    {t("fligth.search_fligth.select_fligth")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flightsDateGoing">
              {
                daysProcedure?.days  ?
                  <p style={{ color: "var(--bluePrimary)", fontSize: "11px", fontWeight: "600" }}>El rango minimo son {daysProcedure.days} dias {daysProcedure.name}</p>
                  :
                  null
              }

              <div className="dateRange">
                <div className="dateRangeOnly">
                  {/* <DateRange
                editableDateInputs={true}
                onChange={handleRangeChange}
                moveRangeOnFirstSelection={false}
                ranges={range}
                minDate={new Date()}
                maxDate={dateMax}
              /> */}
                  <DateRange
                    editableDateInputs={true}
                    locale={locales[locale]}
                    onChange={handleRangeChange}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    direction="horizontal"
                    // months={2}
                    minDate={new Date()}
                    maxDate={dateMax}
                    rangeColors={["#004274", "#969696", "#969696"]}
                  />
                </div>
                {fecha >= 0 ? (
                  ""
                ) : (
                  <div className="error-day-fligth">
                    <p>La fecha inicial debe ser mayor a la fecha de salida</p>
                  </div>
                )}
                <div className="contain-btn-select-f">
                  <button onClick={hiddenM}>
                    {t("fligth.search_fligth.select")} {fecha}{" "}
                    {t("fligth.search_fligth.days_fligth")}
                  </button>
                </div>
              </div>
            </div>
          )
        }
      </div >
    </>

  );
};

export default FlightsDateGoing;
