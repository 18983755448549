import React, { useState } from 'react'
import "./styles.css"
import SvgIcon, { iconList } from '../../../../share/components/iconSVG'
import { useTranslation } from 'react-i18next'

const HowContact = ({setName, setEmail, setTel, setCode, code,name,email,tel}) => {

const [modalCode, setModalCode] = useState(false)
const [selectCode, setSelectCode] = useState("+57")

const [t] = useTranslation("global")


const codes =[
    "+57",
    "+58",
    "+54",
    "+1",
    "+2"
]

    return (
        <div className='content-how-contactT'>
            <h2>{t("specialist.booking_flow.how_contact")}</h2>
            <section className='contain-camp-select-procedures'>
                <label htmlFor="">{t("specialist.booking_flow.how_name")}</label>
                <input className='camp-select-procedures' value={name} placeholder='Nombre y Apellido' type='text' onChange={(e)=>{
                        setName(e.target.value)
                        localStorage.setItem('BooName', e.target.value)
                    }}>

                </input>
            </section>
            <section className='contain-camp-select-procedures'>
                <label htmlFor="">E-mail</label>
                <input className='camp-select-procedures' value={email} placeholder='Correo electronico' type='text' onChange={(e)=>{
                        setEmail(e.target.value)
                        localStorage.setItem('BooEmail', e.target.value)
                    }}>

                </input>
            </section>
            <section className='contain-camp-select-how'>
                <label htmlFor="">{t("specialist.booking_flow.phone")}</label>
                <div className='camp-phone-how-contact'>
                    <div className='content-code-phone' onClick={()=>{
                        setModalCode(!modalCode)
                    }}>
                        <SvgIcon name={iconList.IconPhoneRing} size={20} color={"#004274"} />
                        <span>{code}</span>
                        <SvgIcon name={iconList.IconArrowDown2} size={10} color={"#004274"} />
                        {
                            modalCode &&
                            <div className='modal-procedures-select'>
                                <ul>
                                    {
                                        codes.map((index, key) => {
                                            return (
                                                <li key={key} onClick={() => { 
                                                    setCode(index) 
                                                    localStorage.setItem('BooCode', index)
                                                }}>{index}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                    <input type="text" placeholder={t("specialist.booking_flow.digit_number")} value={tel} onChange={(e)=>{
                        setTel(e.target.value)
                        localStorage.setItem('BooTel', e.target.value)
                    }}/>
                </div>
            </section>
        </div>
    )
}

export default HowContact