import React from "react";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import "./styles.css";

const DetailPayHotel = ({
  price,
  img,
  nom,
  listReserve,
  checkIn,
  checkOut,
  address,
  dataHot,
}) => {
  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }

  const mountRoom = JSON.parse(localStorage.getItem("mountRoom"));

  const stars = [];
  const star = dataHot?.categoria;

  for (let i = 0; i < Number(star); i++) {
    stars.push(
      <SvgIcon
        key={i}
        name={iconList.IconStar}
        color={"var(--yellowPrimary)"}
        size={20}
      />
    );
  }

  return (
    <div className="detail-pay-hotel">
      <div className="header-pay-hotel">
        <div className="content-info">
          <h2>{nom}</h2>

          <div className="content-info-address">
            <div className="stars-div-pay">
              <p>{address || dataHot?.direccion}</p>{" "}
              <div className="stars-div-pay">{stars}</div>
            </div>
            <span>
              <SvgIcon
                color={"var(--bluePrimary)"}
                name={iconList.IconHotel}
                size={25}
              />{" "}
              {listReserve?.length}
            </span>
          </div>
        </div>
      </div>
      <div className="content-check-detail">
        <section>
          <p className="content-check-detail-item">Check-In </p>
          <SvgIcon
            color={"var(--bluePrimary)"}
            name={iconList.IconArrowLarge}
            size={20}
          />
          <p>{checkIn}</p>
        </section>
        <section>
          <p className="content-check-detail-item">Check-Out</p>
          <SvgIcon
            color={"var(--bluePrimary)"}
            name={iconList.IconArrowLarge}
            size={20}
          />
          <p>{checkOut}</p>
        </section>
      </div>
      <div className="line-separation-detail-pay"></div>
      {listReserve?.map((item, index) => {
        let allAcomodation = item?.acomodation?.split("-")?.map(Number);

        return (
          <div className="content-hab-total-paxes" key={index}>
            <p>
              {allAcomodation[0]} adultos, {allAcomodation[1]} niños
            </p>
            <p>({mountRoom[index]}) Habitaciones</p>
            <span>
              <SvgIcon
                color={"var(--bluePrimary)"}
                name={iconList.IconHotel}
                size={20}
              />{" "}
              {item?.name}
            </span>
          </div>
        );
      })}
      {listReserve?.map((item, index) => {
        return (
          <div key={index} className="content-hab-total-paxes-item">
            <SvgIcon
              color={"var(--bluePrimary)"}
              size={25}
              name={iconList.IconCoupon}
            />
            <span>{item["@attributes"]?.cod}</span>
          </div>
        );
      })}
      <div className="line-separation-detail-pay"></div>
      <div className="row-detail-pay row-total-pay">
        <p>Total</p>
        <span>{formatearCantidad(price)}</span>
      </div>
    </div>
  );
};

export default DetailPayHotel;
