import React, { useEffect, useState } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";
import ActivityIndicator from "../../../share/components/activityIndicator";
import ParticipantView from "../../components/participantView";
import { Call } from "iconsax-react";
import "./styles.css";
function MeetingViewPX(props) {
  const { name, meetingId, setIsShowControls, setTotalParticipants } = props;
  const [joined, setJoined] = useState(null);

  const statusList = {
    join: { value: "JOIN" },
    joined: { value: "JOINED" },
    joining: { value: "JOINING" },
  };

  const { join, participants } = useMeeting({
    onMeetingJoined: () => {
      setJoined(statusList?.joined?.value);
    },
  });

  const joinMeeting = () => {
    setJoined(statusList?.joining?.value);
    join();
  };

  useEffect(() => {
    const status = joined && joined == statusList?.joined?.value;
    setIsShowControls(status);
  }, [joined]);

  useEffect(() => {
    setTotalParticipants(participants?.size);

    const localParticipants = [...participants].map((index) => {
      if (index?.data?.local) return index;
      return null;
    });
  }, [participants]);

  return (
    <div className="containerJoinPX">
      {!joined && (
        <div className="meetingViewPX">
          <p className="meetCodeLabelBigPX">Hola {name}!</p>
          <p className="meetCodeLabelPX">Meeting Id:</p>
          <p className="meetCodeValuePX">{meetingId}</p>
          <div className="btnJoinPX" onClick={joinMeeting}>
            <Call size="40" color="#ffffff" />
            <p className="btnJoinTextPX">{statusList?.join?.value}</p>
          </div>
          <div onClick={() => props.back(1)} className="btnBackPX">
            <p className="btnBackTextPX">volver atras</p>
          </div>
        </div>
      )}

      {joined && joined == statusList?.joining?.value && (
        <div className="allCenterPX">
          <ActivityIndicator size={40} colorFill={"#FFFFFF"} />
          <p className="onlyTextPX">Joining the meeting...</p>
        </div>
      )}

      {joined && joined == statusList?.joined?.value && (
        <div style={{}}>
          <div className="participantContainerPX">
            {[...participants].map((index) => {
              const id = index[0];
              const data = index[1];
              return (
                <ParticipantView
                  data={data}
                  participantId={id}
                  key={id}
                  totalParticipants={participants?.size}
                  setMicOn={props?.setMicOn}
                  setWebcamOn={props?.setWebcamOn}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
export default MeetingViewPX;
