import axios from "axios";
import { useState } from "react";

const UseBooking = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [age, setAge] = useState("");
  const [tel, setTel] = useState("");
  const [reason, setReason] = useState("");
  const [treatmentTime, setTreatmentTime] = useState("");
  const [budget, setBudget] = useState(false);
  const [otherReviews, setOtherReviews] = useState(false);
  const [department, setDepartment] = useState("");
  const [city, setCity] = useState("");
  const [zone, setZone] = useState("");
  const [procedure, setProcedure] = useState("");
  const [errCamp, setErrCamp] = useState(false);
  const [code, setCode] = useState("+57");
  const [successApointmentState, setSuccessApointmentState] = useState(false);
  const [dataApointment, setDataApointment] = useState();
  const [steps, setSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const [campFull, setCampFull] = useState(false);

  const sendInfo = (id) => {
    const telNew = code.concat(tel);
    const url = process.env.REACT_APP_URL_APOINTMENT_OFFICE_CLINICS;
    const data = {
      name: name,
      email: email,
      description: description,
      age: age,
      tel: telNew,
      reason: reason, //motivo consulta
      treatment_time: treatmentTime, //tiempo de tratamiento,
      budget: budget, //presupuesto
      other_reviews: otherReviews, //revisiones otros especialistas
      department: department,
      city: city,
      zone: "n/a",
      procedure: procedure, //tratamiento a realizar
      office_id: id,
    };

    if (name === "" || email === "" || tel === "" || code === "") {
      setErrCamp(true);
      setTimeout(() => {
        setErrCamp(false);
      }, 3000);
    } else {
      setLoading(true);
      axios
        .post(url, data)
        .then((res) => {
          setDataApointment(res.data);
          setSteps(1);
          setSuccessApointmentState(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const nextStep = () => {
    if (steps != 5) {
      if (steps === 1) {
        if (procedure === "") {
          setCampFull(true);
          setTimeout(() => {
            setCampFull(false);
          }, 3000);
        } else {
          setSteps(steps + 1);
        }
      } else if (steps === 2) {
        if (reason === "" || description === "") {
          setCampFull(true);
          setTimeout(() => {
            setCampFull(false);
          }, 3000);
        } else {
          setSteps(steps + 1);
        }
      } else if (steps === 3) {
        if (budget === "" || treatmentTime === "" || otherReviews === "") {
          setCampFull(true);
          setTimeout(() => {
            setCampFull(false);
          }, 3000);
        } else {
          setSteps(steps + 1);
        }
      } else if (steps === 4) {
        if (city === "" || department === "" || age === "") {
          setCampFull(true);
          setTimeout(() => {
            setCampFull(false);
          }, 3000);
        } else {
          setSteps(steps + 1);
        }
      }
    } else {
    }
  };

  const prevStep = () => {
    if (steps > 1) {
      setSteps(steps - 1);
    } else {
    }
  };

  return {
    name,
    setName,
    email,
    setEmail,
    description,
    setDescription,
    age,
    setAge,
    tel,
    setTel,
    reason,
    setReason,
    treatmentTime,
    setTreatmentTime,
    budget,
    setBudget,
    otherReviews,
    setOtherReviews,
    department,
    setDepartment,
    city,
    setCity,
    zone,
    setZone,
    procedure,
    setProcedure,
    errCamp,
    setCode,
    code,
    sendInfo,
    successApointmentState,
    dataApointment,
    steps,
    prevStep,
    nextStep,
    loading,
    campFull,
  };
};

export default UseBooking;
