import React from "react";
import "./styles.css";
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const ModalCardDetail = ({
  data,
  include,
  more,
  total,
  modalCardDetail,
  setModalDetail,
  modalPay,
  statePay,
  valuePay,
  value,
  dateHour,
  statusPay,
  incompleteInformation,
}) => {
  const [t] = useTranslation("global");
  return (
    <div
      className={`${
        modalCardDetail ? "modal-card-detail" : "modal-card-detail close"
      }`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setModalDetail(false);
        }
      }}
    >
      <div
        className={`${
          modalCardDetail
            ? "content-modal-card-detail"
            : "content-modal-card-detail close"
        }`}
      >
        <button
          className="modal-card-detail-close"
          onClick={() => setModalDetail(false)}
        >
          <IoIosClose size={45} color="var(--bluePrimary)" />
        </button>

        <div className="content-modal-card">
          <div className="content-modal-card-title">
            <h2>
              {statePay
                ? statePay === "APPROVED"
                  ? " Compra Finalizada Tickete Emitido"
                  : statePay === "PENDING"
                  ? "Compra Pendiente Recarga la pagina"
                  : statePay === "REJECTED"
                  ? "Compra Rechazada Intenta de nuevo"
                  : "Ocurrio algun error"
                : "Detalle de la reserva"}
            </h2>

            <p>
              {valuePay?.pay?.reference ? "Referencia:" : "Código de reserva:"} <span>{data?.cod || data?.codigo_hotel || valuePay?.pay?.reference}</span>
            </p>
          </div>
          <ul className="list-content-modal-card">
            {(data?.nom || data?.nombre_h) && (
              <li>
                <span className="label-content-modal-card">
                  Nombre completo
                </span>
                <span className="value-content-modal-card">
                  
                {data?.nom || data?.nombre_h}
                </span>
              </li>
            )}

            {valuePay?.pay?.reference && (
              <li>
                <span className="label-content-modal-card">Referencia</span>
                <span className="value-content-modal-card">
                  {valuePay?.pay?.reference}
                </span>
              </li>
            )}

            {value && (
              <li>
                <span className="label-content-modal-card">Valor</span>
                <span className="value-content-modal-card">{value}</span>
              </li>
            )}

            {dateHour && (
              <li>
                <span className="label-content-modal-card">Dia y Hora</span>
                <span className="value-content-modal-card">{dateHour}</span>
              </li>
            )}
            {statusPay && (
              <li>
                <span className="label-content-modal-card">Status</span>
                <span className="value-content-modal-card">{statusPay || data?.nombre_h}
              </span>
              </li>
            )}

            {data?.tipo_establecimiento && (
              <li>
                <span className="label-content-modal-card">Tipo</span>
                <span className="value-content-modal-card">
                  {data?.tipo_establecimiento
                    ? "Hotel"
                    : data?.tipo_establecimiento
                  ? "Hotel"
                  : data?.tipo_establecimiento}
                </span>
              </li>
            )}

            {(data?.pob || data?.poblacion) && (
              <li>
                <span className="label-content-modal-card">Ciudad</span>
                <span className="value-content-modal-card">
                  {data?.pob?.charAt(0).toUpperCase() +
                    data?.pob?.slice(1).toLowerCase() ||
                  data?.poblacion?.charAt(0).toUpperCase() +
                    data?.poblacion?.slice(1).toLowerCase() ||
                    data?.poblacion?.charAt(0).toUpperCase() +
                      data?.poblacion?.slice(1).toLowerCase()}
                </span>
              </li>
            )}
            {data?.direccion && (
              <li>
                <span className="label-content-modal-card">Dirección</span>
                <span className="value-content-modal-card">
                  {data?.direccion}
                </span>
              </li>
            )}

              {data?.direccion && (
              <li>
                <span className="label-content-modal-card">Dirección</span>
                <span className="value-content-modal-card">
                  {data?.direccion}
                </span>
              </li>
            )}
            {include && (
              <li>
                  <span className="label-content-modal-card">Incluye</span>
                  <span className="value-content-modal-card">{include}</span>
                </li>
            )}

            {data?.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc && (
              <li>
                <span className="label-content-modal-card">Además</span>
                <span className="value-content-modal-card">
                  {data?.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc}
                </span>
              </li>
            )}
            {data?.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc && (
              <li>
                <span className="label-content-modal-card">Además</span>
                <span className="value-content-modal-card">
                  {data?.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc}
                </span>
              </li>
            )}

            {more && (
              <li>
                <span className="label-content-modal-card">Adicional</span>
                <span className="value-content-modal-card">{more}</span>
              </li>
            )}
            {more && (
              <li>
                <span className="label-content-modal-card">Adicional</span>
                <span className="value-content-modal-card">{more}</span>
              </li>
            )}

            {!valuePay && (
              <li>
                <span className="label-content-modal-card">
                  {t("hotel.include_taxes")}
                </span>
              </li>
            )}

            {incompleteInformation !== "" && (
              <li>
                <span className="label-content-modal-card">
                  {incompleteInformation}
                </span>
              </li>
            )}
          </ul>

          <div className="price-content-modal-card">
            <span className="value-content-modal-card">Total</span>
            <span>
              <b>
                {total || value} {modalPay ? "" : "COP"}
              </b>
            </span>
          </div>
          <p
            className="price-content-modal-card-description"
          >
            
            {statePay
              ? statePay === "APPROVED"
                ? `Su transacción ${valuePay?.pay?.request_id} fue aprobada por la entidad financiera. En caso de tener dudas sobre el vuelo, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a compras@wellezy.com`
                : statePay === "PENDING"
                ? `En estos momentos su transacción ${valuePay?.pay?.request_id} se encuentra pendiente de validación por la entidad financiera. Por favor espera unos minutos a que esta se resuelva. En caso
        de no recibir una respuesta, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a compras@wellezy.com`
                : statePay === "REJECTED"
                ? `Su transacción ${valuePay?.pay?.request_id} fue rechazada  por la entidad financiera. En caso de tener algun problema, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a compras@wellezy.com`
                : "Ocurrio algun error"
              : "Podría haber cargos adicionales a pagar en el alojamiento. De ser así, te los informaremos en detalle antes de finalizar tu compra."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalCardDetail;
