import "./styles.css";
import { IoClose } from "react-icons/io5";

export const sliceFrom = {
  up: { show: "modalContainerShowUp", hidden: "modalContainerHiddenUp" },
  down: { show: "modalContainerShowDown", hidden: "modalContainerHiddenDown" },
  left: { show: "modalContainerShowLeft", hidden: "modalContainerHiddenLeft" },
  right: {
    show: "modalContainerShowRight",
    hidden: "modalContainerHiddenRight",
  },
};

function ModalContainer({
  show,
  hiddsetActiveChangeServiceen,
  children,
  hiddenButton = false,
  width,
  height,
}) {
  return (
    <div
      className={` ${show ? "modalContainer" : "modalContainer close"}`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          hiddsetActiveChangeServiceen(false);
        }
      }}
    >
      <div
        className={`${show ? "cardContainer" : "cardContainer close"}`}
        style={{ width: width, height: height }}
      >
        <div
          className="modalContainerBtn"
          onClick={() => {
            hiddsetActiveChangeServiceen(false);
          }}
        >
          <IoClose size={30} color="#1e5b98" />
        </div>
        {children}
      </div>
    </div>
  );
}

export default ModalContainer;
