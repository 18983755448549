import * as React from "react";
import { useValidationRequired } from "@/Hooks/useInputs";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import styles from "./styles.module.css";

const Input = ({
  type = "text", // Tipo de input, puede ser "text" "number" o "select"
  value, // Valor actual del input
  setValue, // Función para actualizar el valor del input
  options = [], // Opciones disponibles para el select
  label, // Etiqueta del input
  name, // Nombre del input
  fullWidth = false, // Hace que el input ocupe el 100% del ancho disponible
  halfWidth = false, // Hace que el input ocupe el 50% del ancho disponible
  small = false, // Aplica estilos para un input más pequeño
  disabled = false, // Deshabilita el input
  required = false, // Indica si el input es obligatorio
  email = false, // Indica si el input debe ser un email
  maxLength, // Longitud máxima del valor del input
  error = false, // Indica si hay un error en el input
  setError // Función para actualizar el estado de error
}) => {
  const [touched, setTouched] = React.useState(false);
  const [message, setMessage] = React.useState("");

  //Hooks validations
  useValidationRequired(
    value, 
    error, 
    setError, 
    required, 
    email, 
    touched, 
    setMessage
  );

  const inputClassNames = `${styles.inputShare} ${
    error ? styles.inputShareError : ""
  } ${fullWidth ? styles.fullWidth : ""} ${halfWidth ? styles.halfWidth : ""} ${
    small ? styles.small : ""
  }`;

  return (
    <FormControl
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
      required={required}
      sx={{ margin: 0 }}
      className={inputClassNames}
    >
      {type === "select" ? (
        <>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-label`}
            id={name}
            value={value}
            label={label}
            onChange={(e) => {
              setValue(e.target.value);
              setTouched(true);
            }}
            className={inputClassNames}
            sx={{ borderRadius: "8px", margin: 0 }}
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <TextField
          id={name}
          label={`${label} ${required ? " *": ""}`}
          variant="outlined"
          type={type}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setTouched(true);
          }}
          inputProps={{ maxLength }}
          error={error}
          fullWidth={fullWidth}
          className={inputClassNames}
          sx={{ margin: 0 }}
          InputProps={{
            sx: {
              "& fieldset": {
                borderRadius: "8px",
              },
            },
          }}
        />
      )}
      {error && (
        <FormHelperText error sx={{ margin: 0 }}>
          {message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Input;