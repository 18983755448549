import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import axios from "axios";
import { Slider, Tooltip } from "@mui/material";
import { TiArrowSortedDown } from "react-icons/ti";
import TooltipContainer from "@/ui/views/share/components/tooltipContainer";


const Filter = ({
  findSpecialist,
  setCity,
  setProcedure,
  city,
  procedure,
  setModal,
  seeFilter,
  specialist,
  priceMax,
  priceMin,
  handleDoubleRangeChange,
  setRange,
  range,
  handleDoubleRangeChangeCommitted,
  filterPerCity,
  filterPerProcedure,
  filterPerSpecialist,
  speciality,
  setSpeciality,
  filterReset
}) => {

  const [cities, setCities] = useState([]);
  const inputRef = useRef(null);
  const [procedures, setProcedures] = useState([])
  const [proceduresCopy, setProceduresCopy] = useState([])
  const [specialities, setSpecialities] = useState([])
  const [marksPrice, setMarksPrice] = useState([priceMin, priceMax]);
  const [firstRender, setFirstRender] = useState(false);
  const [showProcedures, setShowProcedures] = useState(false)
  const [especialtiesSearch, setEspecialtiesSearch] = useState([]);


  const specialtiesRef = useRef(null);
    const [cardEspecialties, setCardEspecialties] = useState(false);

  const selectSpeRef = useRef(null);
  const selectProRef = useRef(null);
  const selectCitRef = useRef(null);

  const resetSelect = () => {
    if (selectSpeRef.current) {
      selectSpeRef.current.value = ""; // Restablece el select a la primera opción
    }
    if (selectProRef.current) {
      selectProRef.current.value = ""; // Restablece el select a la primera opción
    }
    if (selectCitRef.current) {
      selectCitRef.current.value = ""; // Restablece el select a la primera opción
    }
    filterReset()
    setModal(false); // También puedes realizar otras acciones aquí
  };

  function valuetext(value) {
    return `${"$" + value}`;
  }

  const formatCOP = (value) => {
    if (value >= 1000000) {
      return "$ " + (value / 1000000).toFixed(2) + " M";
    } else {
      return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0, // Elimina los decimales
      }).format(value);
    }
  };

  const marks = [
    {
      value: priceMin,
      label: formatCOP(marksPrice[0]) + " COP",
    },
    {
      value: priceMax,
      label: formatCOP(marksPrice[1]) + " COP",
    },
  ];

  useEffect(() => {
    const url = `${process.env.REACT_APP_URL_API_FINANMED}`;
    axios
      .get(`${url}/list/ciudades`)
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get(`${url}/category`)
      .then((res) => {
        setSpecialities(res.data);
      })
      .catch((err) => {
        console.error(err);
      });


  }, []);


  useEffect(() => {
    if (firstRender) {
      setMarksPrice([range[0], range[1]]);
    } else {
      setFirstRender(true);
    }
  }, [range]);


  useEffect(() => {
    const url = `${process.env.REACT_APP_URL_API_FINANMED}`;

    if (speciality) {
      axios
        .get(`${url}/category/sub/${speciality}`)
        .then((res) => {
          setProcedures(res.data);
          setEspecialtiesSearch(res.data)
          setProceduresCopy(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [speciality])

   // Functions
   const handleClick = () => {
    if(speciality){
        setProcedure("");
        setShowProcedures(true);
        setCardEspecialties(true);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }
};


useEffect(() => {
  if (procedure === "") {
      setEspecialtiesSearch(procedures);
  } else {
      setEspecialtiesSearch(procedures?.filter((especialty) => 
          especialty?.name && especialty?.name?.toLowerCase().includes(procedure?.toLowerCase())
      ));
  }
}, [procedure]);


  return (
    <div className={styles.contain_filters}>
      <div className={styles.filters}>
        <h2>Filtros</h2>
        <button
          onClick={() => {
            setProcedure("");
            setCity("");
            setSpeciality("")
            filterPerCity("")
            setCardEspecialties(false)
            setShowProcedures(false)
            setRange([priceMin, priceMax])
            setModal(false);
            resetSelect()
          }}
        >
          Limpiar Filtro
        </button>
      </div>

      <div className={styles.filters}>
        <h2>Especialidad</h2>
        <span>Filtro por especialidad</span>
        <select
          name=""
          id=""
          ref={selectSpeRef}
          onChange={(e) => {
            setSpeciality(e.target.value);
            setModal(false);
            if(!e.target.value){
              setCardEspecialties(false)
            setShowProcedures(false)
            }
            filterPerSpecialist(e.target.value)
          }}
        >
          <option value="">Seleccione</option>
          {specialities.map((specialist, index) => (
            specialist?.name === "Cirugia Plastica Estetica y Reconstructiva" && (
              <option key={index} value={specialist.id}>
                {specialist?.name}
              </option>
            )
          ))}
        </select>
      </div>

      <div className={`${styles.filters} ${speciality ? "" : styles.disable}`} >
        <h2>Procedimiento</h2>
        <span>Filtro por procedimiento</span>
        {/* <select
          name=""
          ref={selectProRef}
          id=""
          onChange={(e) => {
            setProcedure(e.target.value);
            setModal(false);
            filterPerProcedure(e.target.value)
          }}
        >
          <option value="">Seleccione</option>
          {procedures.map((specialist, index) => {
            return (
              <option key={index} value={specialist.id}>
                {specialist?.name}
              </option>
            );
          })}
        </select> */}
         <div className="searchBuildPackageContentInput" onClick={handleClick} style={{padding: ".4rem", borderRadius:"5px"}}  ref={specialtiesRef}>
            <div className="searchBuildPackageContentInputContent">
                <input 
                    type="text" 
                    style={{padding:"0", border:"none"}}
                    placeholder={ showProcedures ? '' : 'Elige un procedimiento' } 
                    ref={inputRef} 
                    value={procedure}
                    onChange={(e) => setProcedure(e.target.value)}
                />
            </div>
            <TiArrowSortedDown size={20} color="var(--bluePrimary)" />
            {
                cardEspecialties && (
                    <TooltipContainer
                        show={showProcedures}
                        hidden={setShowProcedures}
                        sizeWidth={100}
                        sizeHeight={80}
                    >
                        <ul className="contentElemetsSearchInputBuildPackage">
                            {
                                especialtiesSearch.length === 0 ? (
                                    <li className="list-not-results">
                                        No se encontraron resultados
                                    </li>
                                ) : (
                                    especialtiesSearch.map((especialtieItem, index) => (
                                        <Tooltip title={especialtieItem.name} arrow placement="right">
                                            <li key={index} onClick={(e) => {
                                                e.stopPropagation();
                                                setProcedure(especialtieItem.name);
                                                filterPerProcedure(especialtieItem.id)
                                                setShowProcedures(false);
                                                setCardEspecialties(false);
                                            }}>
                                                <span>{especialtieItem.name}</span>
                                            </li>
                                        </Tooltip>
                                    ))
                                )
                            }
                        </ul>
                    </TooltipContainer>
                )
            }
        </div>

      </div>

      <div className={styles.filters}>
        <h2>Destino</h2>
        <span>Filtro por Destino</span>
        <select
          name=""
          id=""
          ref={selectCitRef}
          onChange={(e) => {
            setCity(e.target.value);
            setModal(false);
            filterPerCity(e.target.value)
          }}
        // value={city}
        >
          <option value="">Seleccione</option>
          {cities.map((city, index) => {
            return (
              <option key={index} value={city.id}>
                {city.name}
              </option>
            );
          })}
        </select>
      </div>

      <div className={styles.filters}>
        <h2>Precio</h2>
        <span>Rango de precios</span>
        <Slider
          getAriaLabel={() => "Temperature range"}
          value={range}
          onChange={handleDoubleRangeChange}
          onChangeCommitted={handleDoubleRangeChangeCommitted}
          max={priceMax}
          min={priceMin}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          marks={marks}
        />
      </div>


    </div>
  )
}

export default Filter