import React, { useEffect, useState } from 'react';
import "./styles.css";
import Loading from '../../../share/components/loading';
import axios from 'axios';
import InputSimple from '../../../../../Components/TerrawindVoucherDetail/FormDataPassenger/InputSimple/InputSimple';

// Funciones de validación reutilizables
const validateName = (name) => {
    if (name === "") {
        return "Este campo es obligatorio";
    }
    return "";
};

const validatePhone = (phone) => {
    if (phone === "") {
        return "Este campo es obligatorio";
    } else if (isNaN(phone)) {
        return "Este campo debe ser numerico";
    }
    return "";
};

const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (email === "") {
        return "Este campo es obligatorio";
    } else if (!emailRegex.test(email) || /[\s]/.test(email)) {
        return "Este campo debe ser valido";
    }
    return "";
};

const validateCity = (city) => {
    if (city === "") {
        return "Este campo es obligatorio";
    }
    return "";
};

const FormPayHealth = ({ pack, loading, setLoading, setShowModalPay }) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [observation, setObservation] = useState("");
    const [error, setError] = useState("");
    const [terms, setTerms] = useState(false);
    const [errors, setErrors] = useState({ name: "", phone: "", email: "", city: "" });

    const handleBlur = (field, value) => {
        let error = "";
        switch (field) {
            case "name":
                error = validateName(value);
                break;
            case "phone":
                error = validatePhone(value);
                break;
            case "email":
                error = validateEmail(value);
                break;
            case "city":
                error = validateCity(value);
                break;
            default:
                break;
        }
        setErrors(prevErrors => ({ ...prevErrors, [field]: error }));
    };

    const handleFormReserve = (e) => {
        e.preventDefault();

        const nameError = validateName(name);
        const phoneError = validatePhone(phone);
        const emailError = validateEmail(email);
        const cityError = validateCity(city);

        setErrors({ name: nameError, phone: phoneError, email: emailError, city: cityError });

        if (nameError || phoneError || emailError || cityError) {
            setError("Por favor, corrige los errores en el formulario.");
        } else if (!terms) {
            setError("Debes aceptar terminos y condiciones");
        } else {
            const url = `${process.env.REACT_APP_URL_API_AIOP}api/medic/package/reserve`;
            setLoading(true);
            axios.post(url, {
                "total_value": pack?.price,
                "email": email,
                "medical_package_id": pack?.id,
                "full_name": name,
                "origin": "wellezy",
                "tel": Number(phone),
                "state": "reserved",
                "observations": observation,
                "terms": terms,
                "city": city,
                "type": "quote"
            })
                .then((res) => {
                    createLink();
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                    setError("Error en la solicitud habla con un asesor");
                });
        }
    };

    useEffect(() => {
        setTerms(false);
    }, []);

    useEffect(() => {
        if(terms){
            setError("");
        }
    }, [terms]);

    const createLink = () => {
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/place-to-pay`;
        axios.post(url, {
            "currency": pack?.currency,
            "type": "MedicalPackage",
            "localizator": pack?.id,
            "price": pack?.price,
            "url": `${getBaseUrl(window.location.href)}/TourismHealth/pay-state`
        })
            .then(res => {
                localStorage.setItem("pay-id-packages", res.data.requestId);
                window.location.href = res.data?.processUrl;
            })
            .catch(err => {
                console.log(err);
                setShowModalPay(false);
                setLoading(false);
                setError("Error al obtener el link de pago, comunicate con un asesor");
            });
    };

    function getBaseUrl(url) {
        const parsedUrl = new URL(url);
        const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
        return baseUrl;
    }

    return (
        <>
            <div className='form-pay-pack-health'>
                <legend>Llena con tus datos para continuar con el pago</legend>
                <div className={errors.name && 'validate-input-voucher'}>
                    <label htmlFor="">Nombre completo *</label>
                    <input
                        type='text'
                        className='input-name'
                        onChange={(e) => {
                            setName(e.target.value);
                            handleBlur("name", e.target.value);
                        }}
                        onBlur={(e) => handleBlur("name", e.target.value)}
                        value={name}
                    />
                    {errors.name && <p className="span-simple-error">{errors.name}</p>}
                </div>

                <div className={errors.phone && 'validate-input-voucher'}>
                    <label htmlFor="">Telefono *</label>
                    <input
                        type='text'
                        className='input-name'
                        onChange={(e) => {
                            setPhone(e.target.value);
                            handleBlur("phone", e.target.value);
                        }}
                        onBlur={(e) => handleBlur("phone", e.target.value)}
                        value={phone}
                    />
                    {errors.phone && <p className="span-simple-error">{errors.phone}</p>}
                </div>

                <div className={errors.email && 'validate-input-voucher'}>
                    <label htmlFor="">Correo *</label>
                    <input
                        type='text'
                        className='input-name'
                        onChange={(e) => {
                            setEmail(e.target.value);
                            handleBlur("email", e.target.value);
                        }}
                        onBlur={(e) => handleBlur("email", e.target.value)}
                        value={email}
                    />
                    {errors.email && <p className="span-simple-error">{errors.email}</p>}
                </div>

                <div className={errors.city && 'validate-input-voucher'}>
                    <label htmlFor="">Ciudad *</label>
                    <input
                        type='text'
                        className='input-name'
                        onChange={(e) => {
                            setCity(e.target.value);
                            handleBlur("city", e.target.value);
                        }}
                        onBlur={(e) => handleBlur("city", e.target.value)}
                        value={city}
                    />
                    {errors.city && <p className="span-simple-error">{errors.city}</p>}
                </div>

                <div>
                    <label htmlFor="">Observaciones</label>
                    <textarea rows="5" name="" id="" onChange={(e) => setObservation(e.target.value)}></textarea>
                </div>

                <div className='terms'>
                    <input
                        type="checkbox"
                        name="terms"
                        id="terms"
                        onClick={(e) => setTerms(!terms)}
                        value={terms}
                    />
                    <label htmlFor='terms'>Acepta terminos y condiciones</label>
                </div>
                {
                    error &&
                    <p className="span-simple-error">{error}</p>
                }
                <button onClick={handleFormReserve}>Ir a pagar</button>
            </div>
        </>
    );
};

export default FormPayHealth;