import { useState, useEffect } from "react";
import axios from "axios";
import { ApiCountryCodePhone } from "@/Api/ApiCountryCodePhone";

const CODES = ApiCountryCodePhone.map(country => country.phone_code);

const regex = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    name: /^[A-Za-z\s']+$/,
    password: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,16}$/,
    phone_public: /^\d{10,15}$/,
    prex_phone_public: /^\+\d{1,15}$/, // Expresión regular para validar el prefijo
};

const useUserUpdate = () => {
    const [formData, setFormData] = useState({
        id: "",
        birth: "",
        gener: "",
        current_password: "",
        name: "",
        email: "",
        prex_phone_public: "",
        phone_public: "",
        password: "",
        confirm_password: "", // Campo para confirmar la nueva contraseña
    });

    const [responseMessage, setResponseMessage] = useState("");
    const [responseStatus, setResponseStatus] = useState("");
    const [token, setToken] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); // Estado de carga
    const [messageType, setMessageType] = useState(""); // Para determinar el tipo de mensaje

    useEffect(() => {
        const savedData = localStorage.getItem("@authUserSession");

        if (savedData) {
            try {
                const parsedData = JSON.parse(savedData);
                if (parsedData.token && parsedData.user) {
                    setToken(parsedData.token);
                    setFormData({
                        id: parsedData.user.id || "",
                        birth: parsedData.user.birth || "",
                        gener: parsedData.user.gener || "",
                        current_password: "",
                        name: parsedData.user.name || "",
                        email: parsedData.user.email || "",
                        prex_phone_public: parsedData.user.prex_phone_public || "",
                        phone_public: parsedData.user.phone_public || "",
                        password: "",
                        confirm_password: "", // Campo para confirmar la nueva contraseña
                    });
                }
            } catch (error) {
                console.error("Error al parsear los datos de localStorage", error);
            }
        }
    }, []);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) newErrors.name = "El nombre no puede estar vacío";
        else if (!regex.name.test(formData.name)) newErrors.name = "El nombre solo puede contener letras y espacios";

        if (!formData.email.trim()) newErrors.email = "El email no puede estar vacío";
        else if (!regex.email.test(formData.email)) newErrors.email = "El email debe tener un formato válido";

        if (!formData.prex_phone_public.trim()) newErrors.prex_phone_public = "El prefijo telefónico no puede estar vacío";
        else if (!regex.prex_phone_public.test(formData.prex_phone_public)) newErrors.prex_phone_public = "El prefijo debe comenzar con + y contener entre 1 y 15 dígitos";

        if (!formData.phone_public.trim()) newErrors.phone_public = "El teléfono no puede estar vacío";
        else if (!regex.phone_public.test(formData.phone_public)) newErrors.phone_public = "El teléfono debe tener entre 10 y 15 dígitos";

        if (!formData.password.trim()) newErrors.password = "La contraseña no puede estar vacía";
        else if (!regex.password.test(formData.password)) newErrors.password = "La contraseña debe tener entre 8 y 16 caracteres, una mayúscula, un número y un símbolo especial";
        else if (formData.password !== formData.confirm_password) newErrors.confirm_password = "Las contraseñas no coinciden"; // Validación de contraseñas iguales

        if (!formData.current_password.trim()) newErrors.current_password = "Debes ingresar tu contraseña actual";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateField = (name, value) => {
        const newErrors = { ...errors };

        switch (name) {
            case "name":
                if (!value.trim()) newErrors.name = "El nombre no puede estar vacío";
                else if (!regex.name.test(value)) newErrors.name = "El nombre solo puede contener letras y espacios";
                else delete newErrors.name;
                break;
            case "email":
                if (!value.trim()) newErrors.email = "El email no puede estar vacío";
                else if (!regex.email.test(value)) newErrors.email = "El email debe tener un formato válido";
                else delete newErrors.email;
                break;
            case "prex_phone_public":
                if (!value.trim()) newErrors.prex_phone_public = "Debes seleccionar un prefijo telefónico";
                else if (!regex.prex_phone_public.test(value)) newErrors.prex_phone_public = "El prefijo debe comenzar con + y contener entre 1 y 15 dígitos";
                else delete newErrors.prex_phone_public;
                break;
            case "phone_public":
                if (!value.trim()) newErrors.phone_public = "El teléfono no puede estar vacío";
                else if (!regex.phone_public.test(value)) newErrors.phone_public = "El teléfono debe tener entre 10 y 15 dígitos";
                else delete newErrors.phone_public;
                break;
            case "password":
                if (!value.trim()) newErrors.password = "La contraseña no puede estar vacía";
                else if (!regex.password.test(value)) newErrors.password = "La contraseña debe tener entre 8 y 16 caracteres, una mayúscula, un número y un símbolo especial";
                else delete newErrors.password;
                break;
            case "confirm_password":
                if (value !== formData.password) newErrors.confirm_password = "Las contraseñas no coinciden";
                else delete newErrors.confirm_password;
                break;
            case "current_password":
                if (!value.trim()) newErrors.current_password = "Debes ingresar tu contraseña actual";
                else delete newErrors.current_password;
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        validateField(name, value);
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!token) {
            setResponseMessage("Error: Token no encontrado");
            setMessageType("error");
            return;
        }

        if (!validateForm()) {
            setResponseMessage("Por favor corrige los errores antes de enviar");
            setMessageType("warning");
            return;
        }

        setLoading(true); // Inicia el estado de carga

        try {
            const response = await axios.post(
                "https://staging.aiop.com.co/api/edit/user",
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            setResponseStatus(response);

            if (response.status === 200) {
                setResponseMessage("Usuario actualizado exitosamente");
                setMessageType("success");
                setErrors({}); // Limpia los errores si la actualización fue exitosa
            }

        } catch (error) {
            if (error.response && error.response.data) {
                // Manejo de errores de respuesta de la API
                if (error.response.data.errors) {
                    const apiErrors = error.response.data.errors;
                    const newErrors = {};

                    // Errores específicos para campos
                    if (apiErrors.current_password) {
                        newErrors.current_password = apiErrors.current_password;
                    }
                    if (apiErrors.password) {
                        newErrors.password = apiErrors.password;
                    }

                    setErrors(prevErrors => ({
                        ...prevErrors,
                        ...newErrors
                    }));
                }

                setResponseMessage("Error en la actualización: " + (error.response.data.errors.password || "Error desconocido"));
                setMessageType("error");
            } else {
                setResponseMessage("Error al actualizar usuario: " + (error.response?.data?.errors.password || "Error desconocido"));
                setMessageType("error");
            }
        } finally {
            setLoading(false);
        }
    };

    return {
        responseStatus,
        formData,
        errors,
        responseMessage,
        messageType, // Agrega messageType
        loading, // Agrega loading
        handleChange,
        handleBlur,
        handleSubmit,
    };
};

export default useUserUpdate;
