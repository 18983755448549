import React from "react";
import styles from "./styles.module.css";
import { IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";

const ModalAction = ({ open, setOpen }) => {
  const handleCloseModal = (e) => {
    if (e.target.classList.contains(styles.containModal)) {
      setOpen(false);
    }
  };

  return (
    <div
      className={`${styles.containModal} ${open ? styles.active : ""}`}
      onClick={handleCloseModal}
    >
      <div
        className={`${styles.contentModal} ${open ? styles.active : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={() => setOpen(false)}
          className={styles.contentModalButtonClose}
        >
          <IoClose size={20} />
        </button>

        <div className={styles.iconSuccess}>
          <FaCheck size={35} />
        </div>

        <div className={styles.contentText}>
          <h2>¡Paquete armado con éxito!</h2>
          <p>
            ¡Tu paquete ha sido armado con éxito! <br /> Nuestras asesoras se
            comunicarán contigo pronto.
          </p>
        </div>

        <Link className={styles.linkCreatePackage} to="/build-package">
          Crear nuevo paquete
        </Link>
      </div>
    </div>
  );
};

export default ModalAction;
