import { ColorBlack, ColorGreenPrimary, ColorGreyPrimary, ColorGreySecondary, ColorPrimary, ColorPrimaryLight, ColorRedPrimary, ColorSecondary, ColorTransparentModal, ColorWhite, YellosPrimary } from "@/pages/documentation/css/colors";

const styles = [
    {
        title: "Color primario",
        description: "Color primario de la pagina: #004274",
        component: <ColorPrimary />,
        typeCode: "css",
        code: `var(--bluePrimary)`
    },
    {
        title: "Color secundario",
        description: "Color secundario de la pagina: #104172",
        component: <ColorSecondary />,
        typeCode: "css",
        code: `var(--blueSecundary)`
    },
    {
        title: "Amarillo primario",
        description: "Color amarillo primario de la pagina: #ffd902",
        component: <YellosPrimary />,
        typeCode: "css",
        code: `var(--yellowPrimary)`
    },
    {
        title: "Primario claro",
        description: "Color primario claro de la pagina: #17aee6",
        component: <ColorPrimaryLight />,
        typeCode: "css",
        code: `var(--bluePrimary)`
    },
    {
        title: "Gris primario",
        description: "Color gris primario de la pagina: #d9d9d9",
        component: <ColorGreyPrimary />,
        typeCode: "css",
        code: `var(--greyPrimary)`
    },
    {
        title: "Gris secundario",
        description: "Color gris secundario de la pagina: #8d8d8d",
        component: <ColorGreySecondary />,
        typeCode: "css",
        code: `var(--greySecundary)`
    },
    {
        title: "Blanco",
        description: "Color blanco de la pagina: #ffffff",
        component: <ColorWhite />,
        typeCode: "css",
        code: `var(--white)`
    },
    {
        title: "Negro",
        description: "Color negro de la pagina: #000000",
        component: <ColorBlack />,
        typeCode: "css",
        code: `var(--black)`
    },
    {
        title: "Fondo transparente de modal",
        description: "Fondo transparente para modales de la pagina: #00000073",
        component: <ColorTransparentModal/>,
        typeCode: "css",
        code: `var(--transparentModal)`
    },
    {
        title: "Verde primario",
        description: "Color negro de la pagina: #50AE42",
        component: <ColorGreenPrimary />,
        typeCode: "css",
        code: `var(--greenPrimary)`
    },
    {
        title: "Rojo primario",
        description: "Color rojo de la pagina: #FF5151",
        component: <ColorRedPrimary />,
        typeCode: "css",
        code: `var(--redPrimary)`
    }
]

export default styles;