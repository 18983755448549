import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useTurismHealth = () => {
  const [data, setData] = useState(null);
  const [dataCopy, setDataCopy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // Página inicial de 1 en lugar de 0
  const [priceMin, setPriceMin] = useState(0)
  const [priceMax, setPriceMax] = useState(0)
  const [range, setRange] = useState([0, 0])
  const [menuHamburger, setMenuHamburger] = useState(false)
  const [city, setCity] = useState("")
  const [procedure, setProcedure] = useState("")
  const [speciality, setSpeciality] = useState("")


  function fetchTurismHealth(page = 1, city = "", subcategorie = "", category = "") {
    const url = `${process.env.REACT_APP_URL_API_AIOP}api/medic_packages`;
    setLoading(true);
    axios
      .get(url,{params:{
        city:city,
        subcategory: subcategorie,
        category: category
      }})
      .then((res) => {
        setLoading(false);
        setData(res.data);
        setDataCopy(res.data);
        let minPrice = Infinity;
        let maxPrice = -Infinity;
        res.data?.packs?.data.forEach(element => {
          if (element.currency === "COP") {
            if (element.price < minPrice) {
              minPrice = element.price
            }
            if (element.price > maxPrice) {
              maxPrice = element.price
            }
          }
        });
        setPriceMin(minPrice)
        setPriceMax(maxPrice)
        setRange([minPrice, maxPrice])
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

  }

  useEffect(() => {
    fetchTurismHealth();
  }, []);

  const nextHandler = (condition, page) => {

    fetchTurismHealth(condition ? page : Number(data?.packs.current_page) + 1, city, procedure, speciality);

  };

  const prevHandler = () => {
    if (currentPage > 1) {
      fetchTurismHealth(Number(data?.packs.current_page) - 1, city, procedure, speciality);
    }
  };

  const handleDoubleRangeChange = (event, newRange) => {
    setRange(newRange)
  };

  const handleDoubleRangeChangeCommitted = (event, newRange) => {
    filterPerPrice(Number(newRange[0]), Number(newRange[1]));
    setMenuHamburger(false)
  };

  const filterPerPrice = (one, two) => {
    if (one === 0 || two === 0) {
      
    } else {
      if (dataCopy?.packs?.data?.length > 0) {
        let filtrados = []
        if(city){
         filtrados = dataCopy?.packs?.data?.filter(item => {
            return item?.price >= one && item?.price <= two
          })
          
        }else{
          filtrados = dataCopy?.packs?.data?.filter(item => {
            return item?.price >= one && item?.price <= two
          })
        }
        
        setData(prevState => ({
          ...prevState,
          packs: {
            ...prevState.packs,
            data: filtrados
          }
        }))
      }
    }
  }


  const filterPerCity = (city) => {
    fetchTurismHealth(1,city,procedure,speciality)
    setRange([range[0], range[1]])
  }

  const filterPerProcedure = (procedure) => {
    fetchTurismHealth(1,city,procedure,speciality)
    setRange([range[0], range[1]])
  }

  const filterPerSpecialist = (speciality) => {
    fetchTurismHealth(1,city, "",speciality)
    setRange([range[0], range[1]])
  }

  const filterReset = () =>{
    fetchTurismHealth(1)
    setRange([range[0], range[1]])
  }

  return {
    data,
    loading,
    error,
    nextHandler,
    prevHandler,
    setCurrentPage,
    currentPage,
    priceMax,
    priceMin,
    handleDoubleRangeChange,
    range,
    handleDoubleRangeChangeCommitted,
    city,
    setCity,
    procedure,
    setProcedure,
    filterPerCity,
    setRange,
    setMenuHamburger,
    menuHamburger,
    filterPerProcedure,
    filterPerSpecialist,
    speciality,
    setSpeciality,
    filterReset
  };
};

export default useTurismHealth;
