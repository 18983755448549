import * as React from "react";
import styles from "./index.module.css";
import CodeHighlighterCopy from "../CodeHighlighterCopy";
import ContainerSeparations from "../ContainerSeparations/ContainerSepartaion";
import { Typography } from "@mui/material";
// import CodeEditor from "@/Components/CodeEditor";

const SectionDocumentation = ({
  title,
  description,
  component,
  typeCode,
  code,
}) => {
  const [currentCode, setCurrentCode] = React.useState(code);

  const handleCodeChange = (newCode) => {
    setCurrentCode(newCode);
  };

  return (
    <div>
      <section>
        <Typography variant="h2" gutterBottom fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>
        {component}
        <CodeHighlighterCopy codeString={code} typeCode={typeCode} />
        {/* <CodeEditor code={currentCode} onCodeChange={handleCodeChange} />  Feature futura */}
      </section>
      <ContainerSeparations height={40} />
    </div>
  );
};

const BodySectionDocumentation = ({ title, data, preview = <></> }) => {
  return (
    <div className={styles.documentationContainer}>
      <Typography variant="h1" gutterBottom fontWeight="bold">
        {title}
      </Typography>
      { preview }
      <ContainerSeparations height={40} />
      {data.map((input, index) => (
        <SectionDocumentation
          key={index}
          title={input.title}
          description={input.description}
          component={input.component}
          typeCode={input.typeCode}
          code={input.code}
        />
      ))}
    </div>
  );
};

export default BodySectionDocumentation;
