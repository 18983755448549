import React, { useState } from 'react'
import CarouselSevices from '../../share/components/CarouselServices/CarouselSevices'
import { useTranslation } from 'react-i18next'
import FlowPack from '../../share/components/flowPack/FlowPack'
import "./styles.css"
import { ArrowLeft, Happyemoji } from 'iconsax-react'
import DetailPay from './DetailPay/DetailPay'
import PayFormPackages from './PayFormPackages/PayFormPackages'
import EmailPackages from './EmailPackages/EmailPackages'
import PhonePackages from './PhonePackages/PhonePackages'
import DetailBuy from './DetailBuy/DetailBuy'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import ModalContainer from '../../share/components/modalContainer'
import RequiredSession from '../../share/components/requiredSession'
import usePassengerInformationForm from '../../../viewModels/flights/passenger.information.form.viewModel'
import PaymentCards from '../../profileUser/components/paymentCards'


const PackagesPay = () => {
  const [t] = useTranslation("global")
  const [paymentCardSelected, setPaymentCardSelected] = useState(null);
  const [travellers, setTravellers] = useState([])

  const {
    needLogin,
    goToScreen,
    hiddenModalSesion
  } = usePassengerInformationForm()
  const [accordionBuy, setAccordionBuy] = useState(false)

  const checkPassenger = (traveler)=>{
      if(traveler.name == ""){
        return "name";
      }else if(traveler.lastName == ""){
        return "lastName";
      }else if(traveler.country == ""){
        return "country";
      }else if(traveler.document == ""){
        return "document";
      }else if(traveler.typeDocument == ""){
        return "typeDocument";
      }else if(traveler.birthDate == "--"){
        return "birthDate";
      }else if(traveler.sex == ""){
        return "sex";
      }else{
        setTravellers([...travellers,traveler])
        return true;
      }
  }



  return (
    <div className='contain-packages-pay'>
      {/* <ModalContainer
        show={needLogin}
        hidden={hiddenModalSesion}
        sizeWidth={60}
        sizeHeight={50}
      >
        <RequiredSession navigation={goToScreen} />
      </ModalContainer> */}
      <p className='return-url'><ArrowLeft size={20} color='#004274'></ArrowLeft>{t("packages.packages_pay.back_page")}</p>
      <div className='layout-packages-pay'>
        <section className='main-packages-pay'>

          <h2 style={{ marginTop: "1rem", color: "rgb(50, 50, 50)" }}>{t("packages.packages_pay.no_much")}</h2>
          <div className='congratulations-selected'>

            <p>
              <Happyemoji size={20} color='#8FE3C6' />
              {t("packages.packages_pay.congrats")}            
            </p>
          </div>
          <PayFormPackages travellers={travellers} setTravellers={setTravellers} paidPackages={checkPassenger}/>
          <EmailPackages />
          <PhonePackages />
          <div className='contain-payment-cards-pack'>
            <PaymentCards selected={paymentCardSelected} selectItem={setPaymentCardSelected}/>
          </div>
          <button className='btn-pay-packages' >
          {t("packages.packages_pay.reserve")}
          </button>
        </section>
        <section className='aside-packages-pay'>
          <p className='title-aside-pay'>
          {t("packages.packages_pay.detail_pay")}
          </p>
          <DetailPay />
          <div className='accordion-pay-pack' onClick={() => {
            setAccordionBuy(!accordionBuy)
          }}>
            <section className='content-arrow-accordion'>
              <p className='title-aside-pay'>
              {t("packages.packages_pay.detail_buy")}
              </p>
              {
                accordionBuy === true ?
                  <SvgIcon color={"#004274"} name={iconList.IconCloseCircleOutLine} size={30} />
                  :

                  <SvgIcon color={"#004274"} name={iconList.IconArrowDown} size={30} />
              }

            </section>
            {
              accordionBuy &&
              <DetailBuy />
            }

          </div>

        </section>
      </div>
    </div>
  )
}

export default PackagesPay