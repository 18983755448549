import { createSlice } from "@reduxjs/toolkit";

export const dataStepzSlice = createSlice({
    name: 'dataStepz',
    initialState: {
        currentStep: 1,
        completedSteps: [],
        dataPackage: {},
        routes: {},
        lastSearch: {},
        proceduresData: [],
        especialtieAvliable: true,
    },
    reducers: {
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setCompletedSteps: (state, action) => {
            state.completedSteps = action.payload;
        },
        setDataPackage: (state, action) => {
            state.dataPackage = action.payload;
        },
        setRoutes: (state, action) => {
            state.routes = action.payload;
        },
        setLastSearch: (state, action) => {
            state.lastSearch = action.payload;
        },
        setProceduresData: (state, action) => {
            state.proceduresData = action.payload;
        },
        setEspecialtieAvliable: (state, action) => {
            state.especialtieAvliable = action.payload;
        }
    }
});

export const { 
    setCurrentStep, 
    setCompletedSteps, 
    setDataPackage, 
    setRoutes, 
    setLastSearch,
    setProceduresData,
    setEspecialtieAvliable
} = dataStepzSlice.actions;

export default dataStepzSlice.reducer;