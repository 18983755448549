import React, { useEffect, useRef, useState } from 'react';
import SvgIcon, { iconList } from '@/ui/views/share/components/iconSVG';
import { useDispatch, useSelector } from 'react-redux';
import { setTimeSelectedTransladeData } from '@/store/slices/dataEnginePackage/dataEnginePackageSlice';

const InputTimeTranslate = ({
    hoursSe,
    setHoursSe,
    setCalendarVerify,
    dateGoingSelected
}) => {
    // Variables
    const dispatch = useDispatch();

    const dateRef = useRef(null);

    const [modalDate, setModalDate] = useState(false);
    const [hours, setHours] = useState([]);

    const timeSelectedTransladeData = useSelector((state) => state.dataSearchEngine.timeSelectedTransladeData);

    function setHoursValidated(dateString) {
        const newHours = []; 
        
        const [month, day, year] = dateString.split('/');
        const formattedDate = `20${year}-${month}-${day}`;
        let date = new Date(formattedDate);
    
        if (isNaN(date.getTime())) {
            date = new Date();
        }
    
        date.setHours(0, 0, 0, 0);
    
        const now = new Date();
    
        for (let i = 0; i < 24; i++) {
            const hora = i.toString().padStart(2, "0");
        
            const dateAt00 = new Date(date);
            dateAt00.setHours(i, 0, 0, 0); 
        
            const dateAt30 = new Date(date);
            dateAt30.setHours(i, 30, 0, 0);
        
            if (dateAt00 > now) {
                newHours.push(`${hora}:00`);
            }
            if (dateAt30 > now) {
                newHours.push(`${hora}:30`);
            }
        }
    
        setHours(newHours);
    }

    // UseEffects
    useEffect(() => {
        setHoursSe(timeSelectedTransladeData);
    }, []);
    
    useEffect(() => { 
        setHoursValidated(dateGoingSelected);
    }, [dateGoingSelected]);

    useEffect(() => {
        dispatch(setTimeSelectedTransladeData(hoursSe));
    }, [hoursSe]);

    return(
        <div 
            className="searchBuildPackageContentInput"
            onClick={() => {
                setModalDate(!modalDate);
            }}
        >
            <div className="searchBuildPackageContentInputContent">
                <p>Hora</p>
                <input type="text" placeholder="Elige una hora" value={hoursSe} readOnly />
            </div>
            <SvgIcon
                name={iconList.IconReloj2}
                size={30}
                color={ "var(--bluePrimary)" }
            />
            {
                modalDate && (
                    <div className="ModalDateTransport dropdown-ciudad shadow content-hour-build-package" ref={dateRef}>
                        <div className="content-hours-date">
                            <h3>Hora</h3>
                            <ul>
                                {hours.map((item, key) => {
                                return (
                                    <li
                                    key={key}
                                    onClick={() => {
                                        setHoursSe(item);
                                        setCalendarVerify(false);
                                        localStorage.setItem(
                                        "hourTransport",
                                        item
                                        );
                                    }}
                                    className={
                                        hoursSe === item ? "selected" : ""
                                    }
                                    >
                                    {item}
                                    </li>
                                );
                                })}
                            </ul>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default InputTimeTranslate;