import React from "react";
import "./styles.css";
import {
  FaUserMd,
  FaRegSmile,
  FaUserNurse,
  FaRegEye,
  FaFemale,
  FaProcedures,
  FaRegHeart,
  FaRegHandPaper,
  FaFire,
  FaBrain,
  FaHeartbeat,
} from "react-icons/fa";

const Specialities = () => {
  const specialties = [
    { name: "Cirugía Estética Facial", icon: <FaUserMd size={20} /> },
    { name: "Cirugía de Rejuvenecimiento Facial", icon: <FaRegSmile size={20} /> },
    { name: "Cirugía de Nariz (Rinoplastia)", icon: <FaUserNurse size={20} /> },
    { name: "Cirugía de Párpados (Blefaroplastia)", icon: <FaRegEye size={20} /> },
    { name: "Cirugía de Mamas (Mamoplastia)", icon: <FaFemale size={20} /> },
    { name: "Cirugía Corporal (Liposucción)", icon: <FaProcedures size={20} /> },
    { name: "Cirugía de Abdomen (Abdominoplastia)", icon: <FaRegHeart size={20} /> },
    { name: "Cirugía Reconstructiva de Mano", icon: <FaRegHandPaper size={20} /> },
    { name: "Cirugía de Quemados", icon: <FaFire size={20} /> },
    { name: "Cirugía Craneofacial", icon: <FaBrain size={20} /> },
    { name: "Cirugía de Reconstrucción Mamaria", icon: <FaHeartbeat size={20} /> },
  ];
  return (
    <div className="content-specialties-turism-health">
      <h2>Nuestras Especialidades</h2>
      <div className="content-cards-specialidades">
        {specialties.map((specialist, index) => (
          <div className="card" key={index}>
            <div>
                {specialist.icon}
            </div>
            <span>{specialist.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Specialities;
