import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const UseMakeReservation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [nombreTomador, setNombreTomador] = useState("");
  const [response, setResponse] = useState({});
  const [emailTomador, setEmailTomador] = useState("");
  const [telTomador, setTelTomador] = useState("");
  const [paxes, setPaxes] = useState([]);
  const [step, setStep] = useState(1);
  const [paymentCardSelected, setPaymentCardSelected] = useState(null);
  const [nLocalizador, setNLocalizador] = useState(null);
  const [errorReservation, setErrorReservation] = useState(false);
  let cantAcomodationAdult = 0;
  let cantAcomodationChild = 0;
  let personsTotal = 0;
  const [loadingReservation, setLoadingReservation] = useState(false);
  const [loadingRespose, setLoadingResponse] = useState(false);
  const [reservation, setReservation] = useState({});
  const [searchStorage, setSearchStorage] = useState(
    JSON.parse(localStorage.getItem("storageLastSearch")) || []
  );
  
  const hotelUrl = searchStorage.filter(item => item.type === "hotel")[0]?.url;

  const body = {
    client: paxes[0]?.name + " " + paxes[0]?.lastname,
    email: paxes[0]?.email,
    tel: Number(paxes[0]?.tel),
    cancellation: "da",
    price: Number(state?.price),
    line: state?.line,
    arrival: state?.arrival,
    departure: state?.departure,
    hotel: state?.hotel,
    code: state?.code,
    country: "CO",
    country_client: state?.country_client,
    rooms: state?.rooms,
    paxes: paxes,
    origin: "wellezy",
    stars: state?.stars,
  };

  const functionNavigatePaceToPay = () => {
    navigate("/hotels/Payhotel", {
      state: {
        description: state?.hotel,
        name: paxes[0]?.name,
        lastName: paxes[0]?.lastname,
        document: paxes[0]?.doc,
        price: state?.price,
        nLocalizador: response?.data?.data?.parametros?.n_localizador,
        hotel: state?.hotel,
        tel: response.data?.reserve.cel,
        typeDoc: paxes[0]?.typeDoc,
        email: response.data?.reserve?.email,
        listReserve: state?.listReserve,
        arrival: state?.arrival,
        departure: state?.departure,
        address: state?.address,
        checkIn: state?.dataHot?.checkin,
        checkOut: state?.dataHot?.checkout,
        foto: state?.dataHab?.foto,
      },
    });
  }

  const createReservation = () => {
    if (paxes?.length < personsTotal) {
      setErrorReservation(true);
    } else {
      setLoadingReservation(true);
      setLoadingResponse(true);
      const url = `${process.env.REACT_APP_URL_API_FLY}restel/reserve`;
      axios
        .post(url, body)
        .then((res) => {
          setReservation(res.data?.reserve);
          setNLocalizador(res?.data?.data?.parametros?.n_localizador);
          setErrorReservation(false);
          setLoadingResponse(false);
          setResponse(res);
          setTimeout(() => {
            navigate(hotelUrl)
          }, 600000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  state?.listReserve?.map((item) => {
    let numeros = item?.acomodation?.split("-")?.map(Number);
    cantAcomodationAdult += numeros[0];
    cantAcomodationChild += numeros[1];
    personsTotal += numeros[0] + numeros[1];
  });

  return {
    emailTomador,
    setEmailTomador,
    telTomador,
    setTelTomador,
    paxes,
    setPaxes,
    step,
    setStep,
    cantAcomodationAdult,
    body,
    createReservation,
    nombreTomador,
    setNombreTomador,
    paymentCardSelected,
    setPaymentCardSelected,
    state,
    cantAcomodationChild,
    errorReservation,
    setErrorReservation,
    loadingReservation,
    setLoadingReservation,
    loadingRespose,
    reservation,
    functionNavigatePaceToPay,
  };
};

export default UseMakeReservation;
