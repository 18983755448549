import React from "react";
import ResultSearch from "../../ui/views/transport/resultSearch/ResultSearch.jsx";

const TransportResultsSearch = () => {
  return (
    <div>
      <ResultSearch />
    </div>
  );
};

export default TransportResultsSearch;
