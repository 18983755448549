import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "./styles.css";
import useFlightsSearch from "../../../viewModels/flights/flights.search.viewModel";
import FlightDetailResultsList from "../components/flightDetailResultsList";
import SearchFlights from "../search.flights.view";
import useSearchFlights from "../../../viewModels/flights/search.flights.viewModel";
import CarouselSevices from "../../../views/share/components/CarouselServices/CarouselSevices";
import Find from "../../../views/share/components/Find/Find";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import ReturnPage from "../../share/components/return/ReturnPage";
import AnyQuestions from "../../home/components/anyQuestion";
import { CiSearch } from "react-icons/ci";
import { set } from "date-fns";
import { useLocation } from "react-router-dom";

function Flights({ isWidget = false }) {
  const location = useLocation();
  const [t, i18n] = useTranslation("global");
  const [checkDate, setCheckDate] = useState(false);
  const [flightDefault, setFlightDefault] = useState(false);
  const {
    loading,
    nameOrigin,
    setNameOrigin,
    nameDestiny,
    setNameDestiny,
    getFlights,
    queryError,
    flightsList,
    airlinesList,
    allPassengersSelected,
    setAllPassengersSelected,
    isRoundTripB,
    dollarFlight,
    setDollarFlight,
  } = useFlightsSearch();

  const isIframe = false;

  const {
    cardRefPlace,
    cardRefPassenger,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    buttonDisable,
    searcherFlight,

    //ciudades
    //ida
    isCardRefOrigin,
    setIsCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,

    //retorno
    isCardRefDestination,
    setIsCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    //fechas
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    isCardRefDate,
    setIsCardRefDate,
    setDateGoingSelectedApi,
    setDateReturnSelectedApi,

    //pasajeros
    isManagingPassengers,
    setIsManagingPassengers,
    passengersSelecteds,
    getPassengersSelecteds,
    ellipsisText,
    setPassengersSelecteds,

    //Validation
    validationSearchDestiny,
    setValidationSearchDestiny,
    validationSearchDate,
    setDirectFlight,
    directFlight,
    // dollarFlight,
    // setDollarFlight,
  } = useSearchFlights(isIframe);

  const [isScrolled, setIsScrolled] = useState(false);
  const [showSearchScrolled, setShowSearchScrolled] = useState(false);

  const checkScroll = () => {
    if (window.scrollY > 400) {
      setIsScrolled(true);
      setShowSearchScrolled(true);
      setIsManagingPassengers(false);
      setIsCardRefDate(false);
    } else {
      setIsScrolled(false);
      setShowSearchScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  const routeStartsWithBuildPackageFlight = location.pathname.startsWith(
    "/build-package/flight"
  );

  return (
    <>
      {!routeStartsWithBuildPackageFlight && (
        <>
          <CarouselSevices current={t("carousel_services.flight")} />

          <ReturnPage />

          <section
            className={`${
              isScrolled ? "searchMenuScrolled active" : "searchMenuScrolled"
            } ${
              showSearchScrolled
                ? "searchMenuScrolled hidden"
                : "searchMenuScrolled "
            }`}
          >
            <div className={`${"searchMenuScrolledMaxWidth"}`}>
              <SearchFlights
                scrolled={isScrolled}
                accompanied={false}
                buttonDisable={buttonDisable}
                cardRefDate={cardRefDate}
                cardRefPassenger={cardRefPassenger}
                cardRefPlace={cardRefPlace}
                dateGoingSelected={dateGoingSelected}
                dateReturnSelected={dateReturnSelected}
                ellipsisText={ellipsisText}
                getPassengersSelecteds={getPassengersSelecteds}
                getSelectedDestination={getSelectedDestination}
                getSelectedOrigin={getSelectedOrigin}
                isCardRefDate={isCardRefDate}
                isCardRefDestination={isCardRefDestination}
                isCardRefOrigin={isCardRefOrigin}
                isManagingPassengers={isManagingPassengers}
                isRoundTrip={isRoundTrip}
                passengersSelecteds={passengersSelecteds}
                searcherFlight={searcherFlight}
                selectedDestination={selectedDestination}
                selectedOrigin={selectedOrigin}
                setDateGoingSelected={setDateGoingSelected}
                setDateGoingSelectedApi={setDateGoingSelectedApi}
                setDateReturnSelected={setDateReturnSelected}
                setDateReturnSelectedApi={setDateReturnSelectedApi}
                checkDate={checkDate}
                setCheckDate={setCheckDate}
                setFlightDefault={setFlightDefault}
                flightDefault={flightDefault}
                setIsCardRefDate={setIsCardRefDate}
                setIsCardRefDestination={setIsCardRefDestination}
                setIsCardRefOrigin={setIsCardRefOrigin}
                setIsManagingPassengers={setIsManagingPassengers}
                setIsRoundTrip={setIsRoundTrip}
                validationSearchDestiny={validationSearchDestiny}
                setValidationSearchDestiny={setValidationSearchDestiny}
                isScrolled={isScrolled}
                showSearchScrolled={showSearchScrolled}
                setPass={setPassengersSelecteds}
                validationSearchDate={validationSearchDate}
              />
            </div>

            {isScrolled && (
              <div
                style={{
                  fontSize: 12,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <button
                  className={`${
                    showSearchScrolled
                      ? "buttonArrowSearchScrolled active"
                      : "buttonArrowSearchScrolled"
                  }`}
                  onClick={() => setShowSearchScrolled(!showSearchScrolled)}
                >
                  <IoIosArrowDown size={22} />
                </button>

                {showSearchScrolled ? "Ver buscador" : "Ocultar"}
              </div>
            )}
          </section>
        </>
      )}

      <Grid container justifyContent="center">
        <div className="flightSelectedDetail">
          <FlightDetailResultsList
            allPassengersSelected={allPassengersSelected}
            queryError={queryError}
            isRoundTrip={isRoundTripB}
            loading={loading}
            flightsList={flightsList}
            hours={flightsList?.hour}
            nameOrigin={nameOrigin}
            nameDestiny={nameDestiny}
            airlinesList={airlinesList}
            searcherFlight={searcherFlight}
            setDirectFlight={setDirectFlight}
            directFlight={directFlight}
            dollarFlight={dollarFlight}
            setDollarFlight={setDollarFlight}
          />
        </div>
      </Grid>
      {!routeStartsWithBuildPackageFlight && (
        <AnyQuestions title={t("browse_home.have_doubt")} />
      )}
    </>
  );
}

export default Flights;
