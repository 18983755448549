import React, { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import InputCustom from "./InputCustom";
import { Record, RecordCircle } from "iconsax-react";
import taxi from "./Assets/taxi.svg";
import { IoSearchOutline } from "react-icons/io5";
import "./styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar as CalendarNew } from "react-date-range";
import * as locales from "react-date-range/dist/locale";

import { ArrowDown2 } from "iconsax-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ReactComponent as IconSearch } from "./Assets/svg/IconSearch.svg";
import { CircularProgress } from "@mui/material";
import TooltipContainer from "../../share/components/tooltipContainer";
import { GoAlertFill } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";
import { PiTaxiLight } from "react-icons/pi";
import { addMonths } from "date-fns";

const SearchTransport = ({
  adult,
  baby,
  setTypeT,
  typeT,
  ciudadDropdown,
  setCiudadDropdown,
  aeropuertoDropdown,
  setAeropuertoDropdown,
  formValidate,
  passengersCal,
  cityList,
  citySelected,
  setcitySelected,
  findOrigin,
  originList,
  originSelected,
  setOriginSelected,
  findDestiny,
  destinyList,
  destinySelected,
  setDestinySelected,
  destinyDropdown,
  setDestinyDropdown,
  findServices,
  selectedDate,
  handleDateChange,
  width,
  buscadorMenu,
  resetFilter,
  loader,
  setCityList,
  loadingCity,
  loadingDestiny,
  loadingOrigin,
  setCity,
  setOriginList,
  setDestinyList,
  typeView = false,
  cityVerify,
  setCityVerify,
  destinyVerify,
  setDestinyVerify,
  originVerify,
  setOriginVerify,
  hoursSe,
  setHoursSe,
  date,
  setDate,
  calendarVerify,
  setCalendarVerify,
}) => {
  const nameMapper = {
    ar: "Arabic",
    bg: "Bulgarian",
    ca: "Catalan",
    cs: "Czech",
    cy: "Welsh",
    da: "Danish",
    de: "German",
    el: "Greek",
    enGB: "English (United Kingdom)",
    enUS: "English (United States)",
    eo: "Esperanto",
    es: "Spanish",
    et: "Estonian",
    faIR: "Persian",
    fi: "Finnish",
    fil: "Filipino",
    fr: "French",
    hi: "Hindi",
    hr: "Croatian",
    hu: "Hungarian",
    hy: "Armenian",
    id: "Indonesian",
    is: "Icelandic",
    it: "Italian",
    ja: "Japanese",
    ka: "Georgian",
    ko: "Korean",
    lt: "Lithuanian",
    lv: "Latvian",
    mk: "Macedonian",
    nb: "Norwegian Bokmål",
    nl: "Dutch",
    pl: "Polish",
    pt: "Portuguese",
    ro: "Romanian",
    ru: "Russian",
    sk: "Slovak",
    sl: "Slovenian",
    sr: "Serbian",
    sv: "Swedish",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    vi: "Vietnamese",
    zhCN: "Chinese Simplified",
    zhTW: "Chinese Traditional",
  };

  const [hours, setHours] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  function setHoursValidated(date) {
    const newHours = []; // Creamos un array temporal para almacenar las horas válidas

    for (let i = 0; i < 24; i++) {
      const hora = i.toString().padStart(2, "0"); // Formato de 2 dígitos para la hora

      const dateAt00 = new Date(date); // Copia de la fecha pasada para la hora :00
      dateAt00.setHours(i, 0, 0, 0); // Establecer la hora en :00

      const dateAt30 = new Date(date); // Copia de la fecha pasada para la hora :30
      dateAt30.setHours(i, 30, 0, 0); // Establecer la hora en :30

      if (dateAt00 > date) {
        newHours.push(`${hora}:00`); // Agregar la hora :00 si es válida
      }
      if (dateAt30 > date) {
        newHours.push(`${hora}:30`); // Agregar la hora :30 si es válida
      }
    }

    setHours(newHours); // Actualizamos el estado con las nuevas horas válidas
  }

  useEffect(() => {
    console.log(currentDate);
    setHoursValidated(currentDate); // Llamamos la función cuando el componente se monta o cuando currentDate cambia
  }, [currentDate]);

  const [locale, setLocale] = useState("es");
  const localeOptions = Object.keys(locales)
    .map((key) => ({
      value: key,
      label: `${key} - ${nameMapper[key] || ""}`,
    }))
    .filter((item) => nameMapper[item.value]);

  const [t, i18n] = useTranslation("global");
  const containerRefe = useRef(null);
  const { pathname } = useLocation();
  const inputCityRef = useRef(null);
  const dateRef = useRef(null);
  const [focusCity, setFocusCity] = useState(false);
  const [originFocus, setOriginFocus] = useState(false);
  const [destinyFocus, setDestinyFocus] = useState(false);
  const [modalDate, setModalDate] = useState(false);

  const [modalPass, setModalPass] = useState(false);

  const handleClickOutside = (event) => {
    if (
      containerRefe.current &&
      !containerRefe.current.contains(event.target)
    ) {
      setModalPass(false); // Cambia el estado cuando se hace clic fuera del contenedor
    }
  };

  function formatFecha(fecha) {
    const dia = fecha.getDate().toString().padStart(2, "0"); // Obtener día y añadir ceros a la izquierda si es necesario
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Obtener mes (se le suma 1 porque getMonth() retorna 0-11)
    const año = fecha.getFullYear().toString().slice(-2); // Obtener últimos 2 dígitos del año

    return `${dia}/${mes}/${año}`;
  }

  const handleClickOutsideDate = (event) => {
    if (dateRef.current && !dateRef.current.contains(event.target)) {
      setModalDate(false); // Cambia el estado cuando se hace clic fuera del contenedor
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutsideDate);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutsideDate);
    };
  }, []);

  useEffect(() => {
    if (focusCity) {
      inputCityRef.current.focus();
    }
  }, [focusCity]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    function convertirFecha(fecha) {
      // Dividir la fecha en sus componentes
      const components = fecha.split("-");

      // Reorganizar los componentes para obtener el formato deseado
      const nuevaFecha = `${components[2]}-${components[1]}-${components[0]}`;

      return nuevaFecha;
    }
    function obtenerHora(fecha) {
      // Dividir la fecha en sus componentes
      const components = fecha.split("-");

      // Obtener la hora y los minutos
      const hora = components[3];
      const minutos = components[4];

      // Formatear la hora en "HH:MM"
      const horaFormateada = `${hora}:${minutos}`;

      return horaFormateada;
    }
    return (
      <div
        className="input-cat-date"
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          height: "13px",
          gap: "10px",
        }}
        ref={ref}
        onClick={onClick}
      >
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "9px",
            textWrap: "nowrap",
          }}
        >
          <span style={{ marginRight: "5px" }}>
            <SvgIcon
              name={iconList.IconCalendar2}
              size={13}
              color={"var(--bluePrimary)"}
            />
          </span>
          {convertirFecha(value)}
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "9px",
            textWrap: "nowrap",
          }}
        >
          <span style={{ marginRight: "5px" }}>
            <SvgIcon
              name={iconList.IconReloj2}
              size={13}
              color={"var(--bluePrimary)"}
            />
          </span>
          {obtenerHora(value)}
        </p>
      </div>
    );
  });

  const [isScrolled, setIsScrolled] = useState(false);
  const [showSearchScrolled, setShowSearchScrolled] = useState(false);

  const checkScroll = () => {
    if (window.scrollY > 400) {
      setIsScrolled(true);
      setShowSearchScrolled(true);
      setModalPass(false);
    } else {
      setIsScrolled(false);
      setShowSearchScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <div
      className={`${
        pathname === "/transport"
          ? "searchMenuTransScrolled"
          : `${
              isScrolled && pathname.includes("/transport/")
                ? "scrolledTrans"
                : ""
            }
         ${
           isScrolled
             ? "searchMenuTransScrolled active"
             : "searchMenuTransScrolled"
         } ${
              showSearchScrolled
                ? "searchMenuTransScrolled hidden"
                : "searchMenuTransScrolled "
            }`
      }`}
    >
      <div
        className={`searchMenuTransScrolledMaxWidth`}
        style={{ padding: pathname.includes("/transport") && 0 }}
      >
        <div className={`searchMenuTransScrolledMaxWidth`}>
          <div
            className="container_campoSearch_"
            style={{
              backgroundColor:
                pathname.includes("/transport/") && "var(--white)",
              padding: pathname.includes("/transport/") && "0",
            }}
          >
            <div
              className="contain-campos"
              style={{
                width: pathname.includes("/transport/") && "95%",
                padding: pathname.includes("/transport/") && "0",
              }}
            >
              <div
                className="check-title"
                style={{
                  flexDirection: typeView ? "column" : "",
                  alignItems: typeView ? "flex-start" : "",
                  gap: 80,
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <img src={taxi} alt="icon taxi" />
                  <div>
                    <h4>{t("transfers.title")}</h4>
                  </div>
                </div>

                <div className="content-radius-btns-transport">
                  <div className="contain-radius-btn">
                    <label htmlFor="airport">Ida aeropuerto</label>
                    <input
                      type="radio"
                      className="radio-button"
                      name="tripType"
                      id="airport"
                      value="ONLY_TO_AIRPORT"
                      onChange={(e) => setTypeT(e.target.value)}
                      defaultChecked
                    />
                  </div>
                  <div className="contain-radius-btn">
                    <label htmlFor="hotel">Ida hotel</label>
                    <input
                      type="radio"
                      className="radio-button"
                      name="tripType"
                      id="hotel"
                      value="ONLY_FROM_AIRPORT"
                      onChange={(e) => setTypeT(e.target.value)}
                    />
                  </div>
                  <div className="contain-radius-btn">
                    <label htmlFor="roundtrip">Ida y vuelta</label>
                    <input
                      type="radio"
                      className="radio-button"
                      name="tripType"
                      id="roundtrip"
                      value="ROUND_TRIP"
                      onChange={(e) => setTypeT(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="contain-form">
                <form
                  className="form-contain-inputs"
                  action=""
                  onSubmit={(e) => findServices(e)}
                >
                  <div
                    className={`content-column-a ${
                      typeView && "content-column-a-active"
                    }`}
                  >
                    <div className="flex-campo-t">
                      <div className="campo-t">
                        <div
                          className="input-pass"
                          style={{
                            borderColor: cityVerify && "var(--redPrimary)",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <label
                              htmlFor=""
                              style={{
                                color: cityVerify
                                  ? "var(--redPrimary)"
                                  : "var(--greySecundary)",
                                fontWeight: 400,
                              }}
                            >
                              {t("transfers.city")}
                            </label>
                            <div
                              className="input-cat"
                              style={{ background: "none" }}
                            >
                              {focusCity === false ? (
                                <p
                                  onClick={() => {
                                    setFocusCity(true);
                                    setOriginSelected("");
                                    setDestinySelected("");
                                    localStorage.removeItem("originTransport");
                                    localStorage.removeItem("destinyTransport");
                                  }}
                                  style={{
                                    textWrap: "nowrap",
                                    overflow: "hidden",
                                    marginRight: "28px",
                                    color: cityVerify && "var(--greySecundary)",
                                    fontWeight: 500,
                                  }}
                                >
                                  {citySelected.name
                                    ? citySelected?.name
                                    : citySelected}
                                </p>
                              ) : (
                                <div className="contend-input-loader">
                                  <input
                                    ref={inputCityRef}
                                    className="input-dropdown"
                                    onFocus={() => setFocusCity(true)}
                                    onBlur={() => setFocusCity(false)}
                                    onChange={(e) => {
                                      setCity(e.target.value);
                                    }}
                                    type="text"
                                    placeholder={t("transfers.city")}
                                  />
                                  {loadingCity && (
                                    <CircularProgress
                                      size={30}
                                      style={{
                                        right: "0",
                                        position: "absolute",
                                        top: "-17px",
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                            {!focusCity && (
                              <div
                                style={{
                                  position: "absolute",
                                  right: "8px",
                                  top: "13px",
                                }}
                              >
                                <SvgIcon
                                  name={iconList.IconPinMap2}
                                  color={"var(--bluePrimary)"}
                                  size={25}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {cityList?.length > 0 &&
                          !(isScrolled && showSearchScrolled) && (
                            <div className="dropdown-ciudad shadow">
                              <div className="header-list">
                                <p>Ciudades Encontradas {cityList.length}</p>
                              </div>

                              <ul className="city-result">
                                {cityList?.map((item) => {
                                  return (
                                    <li
                                      onClick={() => {
                                        setcitySelected(item);
                                        localStorage.setItem(
                                          "cityTransport",
                                          JSON.stringify(item)
                                        );
                                        setCityList([]);
                                        setCityVerify(false);
                                      }}
                                      key={item.id}
                                    >
                                      {item.name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                      </div>

                      <div className="campo-t">
                        <div
                          className="input-pass"
                          style={{
                            borderColor: originVerify && "var(--redPrimary)",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <label
                              htmlFor=""
                              style={{
                                color: originVerify
                                  ? "var(--redPrimary)"
                                  : "var(--greySecundary)",
                                fontWeight: 400,
                              }}
                            >
                              {t("transfers.origin")}
                            </label>
                            <div
                              className="input-cat"
                              style={{ background: originFocus ? "none" : "" }}
                            >
                              {originSelected !== "" ? (
                                <p
                                  onClick={() => {
                                    setOriginSelected("");
                                    localStorage.removeItem("originTransport");
                                  }}
                                  style={{
                                    textWrap: "nowrap",
                                    overflow: "hidden",
                                    marginRight: "20px",
                                    color: "var(--bluePrimary)",
                                    fontWeight: 500,
                                  }}
                                >
                                  {originSelected.name}
                                </p>
                              ) : (
                                <div
                                  onClick={() => {
                                    if (
                                      citySelected == "" ||
                                      citySelected == "Selecciona la ciudad"
                                    ) {
                                      setCityVerify(true);
                                    } else {
                                      findOrigin();
                                      setOriginFocus(true);
                                    }
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "97%",
                                  }}
                                >
                                  <p
                                    style={{
                                      textWrap: "nowrap",
                                      overflow: "hidden",
                                      marginRight: "20px",
                                      color:
                                        originVerify && "var(--greySecundary)",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {t("transfers.select_airport")}
                                  </p>
                                  {loadingOrigin && (
                                    <CircularProgress
                                      size={30}
                                      style={{
                                        right: "5px",
                                        position: "absolute",
                                        top: "11px",
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {originList?.length > 0 &&
                          !(isScrolled && showSearchScrolled) && (
                            <div className="dropdown-ciudad shadow">
                              <div className="header-list">
                                <p>Origenes Encontrados {originList?.length}</p>
                              </div>
                              <ul className="city-result">
                                {originList?.map((item) => (
                                  <li
                                    onClick={() => {
                                      setOriginSelected(item);
                                      setOriginFocus(false);
                                      setOriginList([]);
                                      setOriginVerify(false);
                                      localStorage.setItem(
                                        "originTransport",
                                        JSON.stringify(item)
                                      );
                                    }}
                                    key={item.id}
                                  >
                                    {item.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="flex-campo-t">
                      <div className="campo-t">
                        <div
                          className="input-pass"
                          style={{
                            borderColor: destinyVerify && "var(--redPrimary)",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <label
                              htmlFor=""
                              style={{
                                color: destinyVerify
                                  ? "var(--redPrimary)"
                                  : "var(--greySecundary)",
                                fontWeight: 400,
                              }}
                            >
                              {t("transfers.select_destination")}
                            </label>
                            <div
                              className="input-cat"
                              style={{ background: destinyFocus ? "none" : "" }}
                            >
                              {destinySelected !== "" ? (
                                <p
                                  onClick={() => {
                                    setDestinySelected("");
                                    localStorage.removeItem("destinyTransport");
                                  }}
                                  style={{
                                    textWrap: "nowrap",
                                    overflow: "hidden",
                                    marginRight: "20px",
                                    color: "var(--bluePrimary)",
                                    fontWeight: 600,
                                  }}
                                >
                                  {destinySelected.name}
                                </p>
                              ) : (
                                <div
                                  onClick={() => {
                                    if (originSelected == "") {
                                      setOriginVerify(true);
                                    } else {
                                      findDestiny();
                                      setDestinyFocus(true);
                                    }
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "97%",
                                  }}
                                >
                                  <p
                                    style={{
                                      color:
                                        destinyVerify && "var(--greySecundary)",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {t("transfers.select_destination")}
                                  </p>
                                  {loadingDestiny && (
                                    <CircularProgress
                                      size={30}
                                      style={{
                                        right: "5px",
                                        position: "absolute",
                                        top: "11px",
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {destinyList?.length > 0 &&
                          !(isScrolled && showSearchScrolled) && (
                            <div className="dropdown-ciudad shadow">
                              <div className="header-list">
                                <p>
                                  Destinos Encontrados {destinyList?.length}
                                </p>
                              </div>
                              <ul className="city-result">
                                {destinyList?.map((item) => {
                                  return (
                                    <li
                                      onClick={() => {
                                        setDestinySelected(item);
                                        setDestinyFocus(false);
                                        setDestinyList([]);
                                        setDestinyVerify(false);
                                        localStorage.setItem(
                                          "destinyTransport",
                                          JSON.stringify(item)
                                        );
                                      }}
                                      key={item.id}
                                    >
                                      {item.name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                      </div>

                      <div className="campo-t">
                        <div
                          onClick={() => {
                            setModalDate(true);
                          }}
                          className="input-pass"
                          style={{
                            overflow: "visible",
                            padding: ".3rem .4rem",
                            position: "relative",
                            borderColor: calendarVerify && "var(--redPrimary)",
                            height: "100%",
                          }}
                        >
                          <div style={{}}>
                            <label
                              htmlFor=""
                              style={{
                                color: calendarVerify
                                  ? "var(--redPrimary)"
                                  : "var(--greySecundary)",
                                fontWeight: 400,
                              }}
                            >
                              {t("transfers.select_date_time")}
                            </label>
                            <div className="content-date-transport">
                              <span
                                style={{
                                  color:
                                    calendarVerify && "var(--greySecundary)",
                                  fontWeight: 500,
                                  display: "flex",
                                  gap: 10,
                                  alignItems: "center",
                                }}
                              >
                                <SvgIcon
                                  name={iconList.IconCalendar2}
                                  size={15}
                                  color={
                                    calendarVerify
                                      ? "var(--redPrimary)"
                                      : "var(--bluePrimary)"
                                  }
                                />
                                {date ? formatFecha(date) : "dd/mm/aa"}
                              </span>
                              <span
                                style={{
                                  color:
                                    calendarVerify && "var(--greySecundary)",
                                  fontWeight: 500,
                                  display: "flex",
                                  gap: 10,
                                  alignItems: "center",
                                }}
                              >
                                <SvgIcon
                                  name={iconList.IconReloj2}
                                  size={15}
                                  color={
                                    calendarVerify
                                      ? "var(--redPrimary)"
                                      : "var(--bluePrimary)"
                                  }
                                />
                                {hoursSe ? hoursSe : "hh:mm"}
                              </span>
                            </div>
                          </div>
                          {modalDate && (
                            <div className="ModalDateTransport" ref={dateRef}>
                              <CalendarNew
                                minDate={new Date()}
                                maxDate={addMonths(new Date(), +12)}
                                locale={locales[locale]}
                                onChange={(date) => {
                                  setDate(date);
                                  localStorage.setItem("dateTransport", date);
                                  setCalendarVerify(false);
                                  const today = new Date();
                                  today.setHours(0, 0, 0, 0); // Resetea las horas, minutos, segundos y milisegundos

                                  const selectedDate = new Date(date);
                                  selectedDate.setHours(0, 0, 0, 0); // Resetea las horas, minutos, segundos y milisegundos

                                  if (
                                    selectedDate.getTime() === today.getTime()
                                  ) {
                                    // La fecha seleccionada es la misma que la de hoy
                                    setCurrentDate(new Date());
                                  } else {
                                    // La fecha seleccionada es diferente a la de hoy
                                    setCurrentDate(date); // Llama la función para actualizar todas las horas del día seleccionado
                                  }
                                }}
                                date={date ? date : new Date()} // Mostrar fecha de ida seleccionada
                              />
                              <div className="content-hours-date">
                                <h3>Hora</h3>
                                <ul>
                                  {hours.map((item, key) => {
                                    return (
                                      <li
                                        key={key}
                                        onClick={() => {
                                          setHoursSe(item);
                                          setCalendarVerify(false);
                                          localStorage.setItem(
                                            "hourTransport",
                                            item
                                          );
                                        }}
                                        className={
                                          hoursSe === item ? "selected" : ""
                                        }
                                      >
                                        {item}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex-campo-x">
                      <div className="campo-t" style={{ height: "100%" }}>
                        <div className="input-pass">
                          <div
                            onClick={() => {
                              setModalPass(!modalPass);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <label htmlFor="">Pasajeros</label>
                            <div className="content-pass">
                              {baby + adult}{" "}
                              {baby && adult > 1 ? "Pasajeros" : "Pasajero"}
                            </div>
                          </div>
                          <SvgIcon
                            color={"var(--bluePrimary)"}
                            name={iconList.IconPeoples}
                            size={25}
                          />
                        </div>
                        {modalPass && (
                          <TooltipContainer
                            show={modalPass}
                            hidden={setModalPass}
                            sizeWidth={100}
                            sizeHeight={80}
                          >
                            <div
                              className="modul-pass-transport"
                              ref={containerRefe}
                            >
                              <h2>Pasajeros</h2>
                              <div className="passengers-cont">
                                <h4>Adultos</h4>
                                <div className="control-pass">
                                  <span
                                    className="btn-res"
                                    onClick={(e) => passengersCal(e, "ad", -1)}
                                  >
                                    -
                                  </span>
                                  <div>{adult}</div>
                                  <span
                                    className="btn-sum"
                                    onClick={(e) => passengersCal(e, "ad", 1)}
                                  >
                                    +
                                  </span>
                                </div>
                              </div>
                              <div className="passengers-cont">
                                <h4>Niños</h4>
                                <div className="control-pass">
                                  <span
                                    className="btn-res"
                                    onClick={(e) => passengersCal(e, "bb", -1)}
                                  >
                                    -
                                  </span>
                                  <div>{baby}</div>
                                  <span
                                    className="btn-sum"
                                    onClick={(e) => passengersCal(e, "bb", 1)}
                                  >
                                    +
                                  </span>
                                </div>
                              </div>
                            </div>
                          </TooltipContainer>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`content-column-b ${
                      typeView && "content-column-b-active"
                    }`}
                  >
                    {pathname !== "/packages" && (
                      <button className="contain-btn-t" onClick={resetFilter}>
                        <IoSearchOutline size={26} />
                        {t("browse_home.search")}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isScrolled && pathname.includes("/transport/") && (
        <div
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <button
            className={`${
              showSearchScrolled
                ? "buttonArrowSearchScrolled active"
                : "buttonArrowSearchScrolled"
            }`}
            onClick={() => setShowSearchScrolled(!showSearchScrolled)}
          >
            <IoIosArrowDown size={22} />
          </button>

          {showSearchScrolled ? "Ver buscador" : "Ocultar"}
        </div>
      )}
    </div>
  );
};

export default SearchTransport;
