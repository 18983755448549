import React from "react";
import HotelResults from "../../ui/views/Hotel/HotelResult/HotelResults";

const HotelResultRoute = () => {
  return (
    <div>
      <HotelResults />
    </div>
  );
};

export default HotelResultRoute;
