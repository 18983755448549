import React from 'react'
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices'
import "./styles.css"
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Skeleton } from '@mui/material'
import SuccessPay from './successPay/SuccessPay'
import PendingPay from './pendingPay/PendingPay'
import RejectPay from './rejectPay/RejectPay'
import UsePayPackages from '../../../viewModels/packages/UsePayPackages';

const PayHealthPack = () => {

const {
  incompleteInformation,
  statePay,
  loading
}=UsePayPackages()

  return (
    <>
        <CarouselSevices />
        <div className='content-pack-health'>
        {
              loading ?
                <Card sx={{ Width: "100%", m: 2 }}>
                  <CardHeader
                    title={
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    }
                    subheader={
                      <Skeleton animation="wave" height={10} width="60%" />
                    }
                  />
                  <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
                  <CardContent>
                    <React.Fragment>
                      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>

                  </CardContent>
                </Card>
                :
                <>
                  {
                    statePay?.pay?.status === "APPROVED" &&
                    <SuccessPay value={statePay}/>
                  }
                  {
                    statePay?.pay?.status === "PENDING" &&
                    <PendingPay requestSuccess={statePay}/>
                  }
                  {
                    statePay?.pay?.status === "REJECTED" &&
                    <RejectPay requestSuccess={statePay}/>
                  }
                  {
                    incompleteInformation !== "" &&
                    <div className='err-hotel-form-person'>
                      <p style={{ color: "white", fontWeight: "bold" }}>{incompleteInformation}</p>
                    </div>
                  }
                </>
            }
        </div>
    </>
  )
}

export default PayHealthPack