import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeDetector = ({ children }) => {
  const location = useLocation();
  const prevLocationRef = useRef(location);

  useEffect(() => {
    const restrictedPaths = [
      '/build-package',
      '/build-package/specialist',
      '/build-package/specialist/:id',
      '/build-package/clinic',
      '/build-package/clinic/:id',
      '/build-package/flight/:id',
      '/build-package/hotel/:id',
      '/build-package/room/:id',
      '/build-package/transport/:id',
      '/build-package/additional',
      '/build-package/std'
    ];

    const wasInRestrictedPath = restrictedPaths.some(path => prevLocationRef.current.pathname.startsWith(path));
    const isInRestrictedPath = restrictedPaths.some(path => location.pathname.startsWith(path));

    if (wasInRestrictedPath && !isInRestrictedPath) {
      alert('Saliendo de una ruta restringida');
      // Aquí puedes agregar cualquier lógica adicional que necesites
    }

    // Actualizar la referencia de la ubicación anterior
    prevLocationRef.current = location;
  }, [location]);

  return <>{children}</>;
};

export default RouteChangeDetector;