import * as React from "react";

export function useValidationRequired(value, error, setError, required, email, touched, setMessage) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    React.useEffect(() => {
          if (value === '' && required && touched) {
            setError(true);
            setMessage('Este campo es obligatorio');
          }else if((!emailRegex.test(value) || /[\s]/.test(value)) && email && touched){
            setError(true);
            setMessage('El campo no es válido');
          } else {
            setError(false);
          }
      }, [value, required, setError]);

    return error;
}