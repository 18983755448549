import { useEffect } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../iconSVG";
import { IoClose } from "react-icons/io5";

export const sliceFrom = {
  up: { show: "modalContainerShowUp", hidden: "modalContainerHiddenUp" },
  down: { show: "modalContainerShowDown", hidden: "modalContainerHiddenDown" },
  left: { show: "modalContainerShowLeft", hidden: "modalContainerHiddenLeft" },
  right: {
    show: "modalContainerShowRight",
    hidden: "modalContainerHiddenRight",
  },
};

function TooltipContainer({
  show,
  hidden,
  sizeWidth = 80,
  sizeHeight = 60,
  children,
  hiddenButton = false,
  from = sliceFrom.right,
}) {
  return (
    <div
      className={`cardContainerTooltip ${show ? from?.show : from?.hidden}`}
      style={{
        width:`${sizeWidth}%`,
        position: 'absolute',
        left: 0,
        padding: 0
      }}
    >
      {/* {!hiddenButton && (
        <div className="tooltipContainerBtn" onClick={() => hidden(!show)}>
          <IoClose size={30} />
        </div>
      )} */}
      {children}
    </div>
  );
}

export default TooltipContainer;
