import SvgIcon, { iconList } from "../../../iconSVG";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const CardResultGlobal = ({ data, url }) => {
  const navigate = useNavigate();
  const stars = [];
  if (data?.stars) {
    for (let i = 0; i < Number(data?.stars); i++) {
      stars.push(
        <SvgIcon key={i} name={iconList.IconStar} color={"#FFD902"} size={20} />
      );
    }
  } else {
    for (let i = 0; i < 5; i++) {
      stars.push(
        <SvgIcon key={i} name={iconList.IconStar} color={"#FFD902"} size={20} />
      );
    }
  }

  const detailClinics = (clinic) => {
    navigate(`/Clinics/${clinic.name}`, {
      state: {
        name: clinic.name,
        address: clinic.address,
        id: clinic.id,
        urlImage: url,
        pathImage: clinic.image,
        subCategories: clinic.subcategories,
        stars: clinic.stars,
        description: clinic.description,
        city: clinic.city,
      },
    });
  };

  const detailSpecialist = (doctor) => {
    navigate(`/Specialists/${doctor.name}`, {
      state: {
        name: doctor.name,
        id: doctor.id,
        urlImage: url,
        pathImage: doctor.profile_photo_url,
        subCategories: doctor.subcategories,
        email: doctor.email,
        infoDoctor: doctor?.information_doctor,
        offices: doctor?.cities,
        images: doctor?.images,
      },
    });
  };

  return data.stars ? (
    <div className="card-global-search">
      <img src={`${url}${data?.image}`} alt="" className="img-card-global" />
      <div className="card-global-info">
        <div>
          <h3>{data?.name}</h3>
          <p className="dir-card-global">{data?.address}</p>
        </div>
        <div className="star-hotel-card">
          <span>{Number(data?.stars).toFixed(1)}</span>
          <div>{stars}</div>
        </div>

        <div className="card-global-categories">
          <div className="content-stars-card-global">
            <ul style={{ marginLeft: "15px" }}>
              {data?.subcategories.map((item1, key) => {
                return (
                  <li
                    key={key}
                    style={{
                      color: "rgb(134, 134, 134)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {data.subcategories.length > 1
                      ? `${item1.name}, `
                      : item1.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <button
            onClick={() => {
              detailClinics(data);
            }}
          >
            Ver más
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="card-global-search">
      <img
        className="img-card-global"
        src={`${data?.profile_photo_url}`}
        alt=""
      />
      <div className="card-global-info">
        <div>
          <h3>{data?.name}</h3>
          <p className="dir-card-global">{data?.cities[0].name}</p>
        </div>
        <div className="star-hotel-card">
          <span>
            {/* {data?.information_doctor?.stars?.toFixed(1)} */}
            5.0
          </span>
          <div>{stars}</div>
        </div>

        <div className="card-global-categories">
          <div className="content-stars-card-global">
            <ul style={{ marginLeft: "15px" }}>
              {data?.subcategories.map((item1, key) => {
                return (
                  <li
                    key={key}
                    style={{
                      color: "rgb(134, 134, 134)",
                      fontSize: "11px",
                      fontWeight: "400",
                    }}
                  >
                    {data?.subcategories?.length > 1
                      ? `${item1?.name}, `
                      : item1?.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <button
            onClick={() => {
              detailSpecialist(data);
            }}
          >
            Ver más
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardResultGlobal;
