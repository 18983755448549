import * as React from 'react';
import styles from "./index.module.css";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";

import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";

const CodeHighlighterCopy = ({ codeString, typeCode }) => {
    const [copied, setCopied] = React.useState(false);

    const handleCopy = React.useCallback(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }, []);

    return (
        <div className={styles.codeContainer}>
            <CopyToClipboard text={codeString} onCopy={handleCopy}>
                <Tooltip title={copied ? "Copiado!" : "Copiar"} arrow>
                    <button className={styles.copyButton}>
                        {copied ? <CheckIcon /> : <ContentCopyIcon />}
                        {copied ? "Copiado" : "Copiar"}
                    </button>
                </Tooltip>
            </CopyToClipboard>
            <SyntaxHighlighter language={ typeCode } style={darcula}>
                {codeString}
            </SyntaxHighlighter>
        </div>
    );
}

export default CodeHighlighterCopy;