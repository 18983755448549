import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import "react-slideshow-image/dist/styles.css";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { IoIosCloseCircle } from "react-icons/io";

const GalleryImg = ({ Images, setSlideShow, slideShow }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward
  const timeoutRef = useRef(null);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex + 1 === Images?.length) {
        setDirection(-1);
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0) {
        setDirection(1);
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };

  useEffect(() => {
    const startSlider = () => {
      timeoutRef.current = setTimeout(() => {
        if (direction === 1) {
          nextSlide();
        } else {
          prevSlide();
        }
      }, 3000);
    };

    startSlider();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [currentIndex, direction]);
  return (
    <div
      className={`${
        slideShow ? "modal-gallery-hotel" : "modal-gallery-hotel close"
      }`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setSlideShow(false);
        }
      }}
    >
      <div
        className={`${
          slideShow
            ? "modal-content-gallery-hotel"
            : "modal-content-gallery-hotel close"
        }`}
      >
        <button
          className="modal-card-detail-close"
          onClick={() => {
            setSlideShow(false);
          }}
        >
          <IoIosCloseCircle size={40} color="#fff" />
        </button>

        <div className="slider-hotel-detail">
          <div
            className="slider-hotel-wrapper-detail"
            style={{ marginLeft: `-${currentIndex * 100}%` }}
          >
            {Array.isArray(Images) &&
              Images.map((image, index) => (
                <div className="slide-hotel-detail" key={index}>
                  <img
                    src={image}
                    className="slide-hotel-detail-img"
                    alt={`Slide-hotel ${index}`}
                  />
                </div>
              ))}
          </div>
          <button className="left-hotel-arrow" onClick={prevSlide}>
            <IoIosArrowDropleftCircle size={40} />
          </button>
          <button className="right-hotel-arrow" onClick={nextSlide}>
            <IoIosArrowDroprightCircle size={40} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GalleryImg;
