import React, { useState, useRef, useEffect } from "react";
import "./styles.css";

const TimeRangeSlider = ({ min = 0, max = 86400, onChange, label, reset=false }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const [activeThumb, setActiveThumb] = useState(null);

  const rangeRef = useRef(null);

  useEffect(() => {
    if (rangeRef.current) {
      const minPercent = ((minVal - min) / (max - min)) * 100;
      const maxPercent = ((maxVal - min) / (max - min)) * 100;
      rangeRef.current.style.left = `${minPercent}%`;
      rangeRef.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, maxVal, min, max]);



  useEffect(() => {
    if (reset) {
      cleanFiltersRange()
    }
  }, [reset]);

  const handleMinChange = (e) => {
    const newValue = Math.max(parseInt(e.target.value), min);
    setActiveThumb('min');
    if (newValue > maxVal) {
      setMinVal(maxVal);
    } else {
      setMinVal(newValue);
    }
    onChange([newValue, maxVal]);
  };

  const handleMaxChange = (e) => {
    const newValue = Math.min(parseInt(e.target.value), max);
    setActiveThumb('max');
    if (newValue < minVal) {
      setMaxVal(minVal);
    } else {
      setMaxVal(newValue);
    }
    onChange([minVal, newValue]);
  };


  const cleanFiltersRange = () => {
    setMinVal(min);
    setMaxVal(max);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };

  return (
    <div className="rangeFilterSelect">
      <label>{label}</label>
      <div className="double-range-slider">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={handleMinChange}
          className={`thumb thumb--left ${activeThumb === 'min' ? 'active' : ''}`}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={handleMaxChange}
         className={`thumb thumb--right ${activeThumb === 'max' ? 'active' : ''}`}
        />

        <div className="sliderRange">
          <div className="slider__track"></div>
          <div ref={rangeRef} className="slider__range"></div>
        </div>

        <div className="slider__labels__time">
          <label className="slider__label">{formatTime(minVal)}</label>
          <label className="slider__label">{formatTime(maxVal)}</label>
        </div>
      </div>
    </div>
  );
};

export default TimeRangeSlider;
