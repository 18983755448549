import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import { FaShoppingCart } from "react-icons/fa";
import { BsBackpack2Fill } from "react-icons/bs";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import { currencyFormat } from "../../../../utils";

const BagComponent = ({
  index,
  setLocalTarifaLast,
  position,
  value,
  getChoose,
  isSelected,
  allPassengersSelected,
  sortedItems,
  typeOfCoin,
  toLowerCaset,
  detailsType,
  adultPrice,
  babyPrice,
  boyPrice,
  key1,
  arrowServices,
  setArrowServices,
  setValidateSelecteds,
  setSelectedValue,
  total,
  activeTrip,
  valueGoing,
  setValueGoing,
}) => {
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("100px");

  useEffect(() => {
    if (arrowServices === key1) {
      const scrollHeight = contentRef.current.scrollHeight;
      setMaxHeight(`${scrollHeight}px`);
    } else {
      setMaxHeight("100px");
    }
  }, [arrowServices, key1]);
  return (
    <div
      onClick={() => {
        getChoose(value, index);
        // setValidateSelecteds(true)
        // setSelectedValue(value)
        if (position === 0) {
          setLocalTarifaLast(index.oneWay);
        }

        if(!activeTrip){
          setValueGoing((adultPrice + babyPrice + boyPrice) - total);
        }

        if (arrowServices === key1) {
          setArrowServices(0);
        } else {
          setArrowServices(key1);
        }
      }}
      className={`upsellCardHeadListContaineOpt ${
        isSelected ? "selected" : ""
      }`}
    >
      <div
        className={`${
          isSelected
            ? "upsellCardHeadListContaineOptTitle active"
            : "upsellCardHeadListContaineOptTitle"
        }`}
      >
        <h3
          style={{
            textTransform: "capitalize",
            fontSize: "16px",
            textWrap: "nowrap",
            overflow: "hidden",
          }}
        >
          Tarifa{" "}
          {toLowerCaset(
            index?.[`${detailsType}`]?.freeFlowDescription[0]
              ? index?.[`${detailsType}`]?.freeFlowDescription[0]?.freeText
              : index?.[`${detailsType}`]?.freeFlowDescription?.freeText
          )}
        </h3>
        <p>Clase Económica</p>
      </div>

      <div className="upsellCardHeadListContaineOptSeparation">
        <div className="upsellCardHeadListContaineOptInfoFlight">
          <div className="upsellCardHeadListContaineOptPrice">
            {isSelected ? (
              <MdOutlineRadioButtonChecked size={25} />
            ) : (
              <MdRadioButtonUnchecked size={25} />
            )}
            <span>
              + ${" "}
              {
                !activeTrip ? currencyFormat((adultPrice + babyPrice + boyPrice) - total, typeOfCoin)
                  .total
              : currencyFormat(((adultPrice + babyPrice + boyPrice) - total) - valueGoing, typeOfCoin).total
              }
            </span>
          </div>
          <p style={{ fontSize: "12px" }}>
            {allPassengersSelected?.adult} Adulto
            {index.passengers[0]?.count > 1 && "s"}
          </p>
          {allPassengersSelected?.boy > 0 && (
            <p style={{ fontSize: "12px" }}>
              {allPassengersSelected?.boy} Niño
              {allPassengersSelected?.boy > 1 && "s"}
            </p>
          )}
          {allPassengersSelected?.baby > 0 && (
            <p style={{ fontSize: "12px" }}>
              {allPassengersSelected?.baby} Bebé
              {allPassengersSelected?.baby > 1 && "s"}
            </p>
          )}
          {allPassengersSelected?.seat > 0 && (
            <p style={{ fontSize: "12px" }}>
              {allPassengersSelected?.seat} Bebé con silla
            </p>
          )}
        </div>

        <div className="upsellCardHeadListContaineOptBags">
          <div
            className="upsellCardHeadListContaineOptBagsAll"
            style={{ margin: "1rem 0" }}
          >
            {sortedItems?.map((item) => {
              if (
                item?.isLaptop ||
                item?.isCarry ||
                item?.bagBig ||
                item?.extraBag ||
                item?.megaBig
              ) {
                return (
                  <div
                    key={item?.key}
                    className="upsellCardBodySectionContainerBag"
                  >
                    <div className="upsellCardBodySectionContainerBagContainer">
                      {item?.isLaptop ? (
                        <BsBackpack2Fill
                          size={30}
                          color={item?.bagAvailable ? "#104172" : "#BDBDBD"}
                        />
                      ) : (
                        <SvgIcon
                          name={item?.typeBag}
                          color={item?.bagAvailable ? "#104172" : "#BDBDBD"}
                          size={item?.megaBig ? 40 : 50}
                        />
                      )}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>

          <div
            ref={contentRef}
            className={`upsellCardHeadListContaineOptBagsInfo ${
              arrowServices === key1 ? "active" : ""
            }`}
            style={{ maxHeight: `${maxHeight}` }}
          >
            {index?.[`${detailsType}`]?.ocFeeInformation.map((item, key2) => {
              return (
                <span
                  className="upsellCardHeadListContaineOptBagsInfoItem"
                  key={key2}
                >
                  {item?.feeDescription?.dataInformation?.indicator ===
                  "INC" ? (
                    <IoMdCheckmark color="#104172" width={20} height={20} />
                  ) : item?.feeDescription?.dataInformation?.indicator ===
                    "CHA" ? (
                    <FaShoppingCart color="#104172" width={20} height={20} />
                  ) : (
                    <IoIosClose color="#104172" width={20} height={20} />
                  )}

                  <p>{toLowerCaset(item?.feeFreeFlowDescription?.freeText)}</p>
                </span>
              );
            })}
          </div>
          <button
            style={{
              cursor: "pointer",
              marginTop: "1rem",
            }}
            onClick={() => {
              if (arrowServices === key1) {
                setArrowServices(0);
              } else {
                setArrowServices(key1);
              }
            }}
          >
            {arrowServices === key1 ? (
              <IoIosArrowUp color="#104172" size={22} />
            ) : (
              <IoIosArrowDown color="#104172" size={22} />
            )}
          </button>
        </div>
      </div>
      <div
        className={`upsellCardHeadListContaineOptSelect ${
          isSelected ? "active" : ""
        }`}
      >
        {isSelected && <p>Esta es tu tarifa actual</p>}
      </div>
    </div>
  );
};

export default BagComponent;
