import React, { useState } from 'react'
import "./styles.css"
import { useTranslation } from 'react-i18next'
import { set } from 'date-fns'

const MotiveConsultation = ({setReason,setDescription,description, optionSelect, setOptionSelect}) => {

    const [camp, setCamp] = useState(false)
    

    const selectReason = (option)=>{
        if(option == "a"){
            setReason("Informacion sobre el precio")
            localStorage.setItem("BooReason", "Informacion sobre el precio")
        
        }else if(option == "b"){
            setReason("Informacion sobre el tratamiento")
            localStorage.setItem("BooReason", "Informacion sobre el tratamiento")
        }else if(option == "c"){
            setReason("Opciones de financiacion")
            localStorage.setItem("BooReason", "Opciones de financiacion")
        }else if(option == "d"){
            setReason("Otros")
            localStorage.setItem("BooReason", "Otros")
        }
    }

    const [t] = useTranslation("global")

  return (
    <div className='contain-motive-consultationT'>
        <h2>{t("specialist.booking_flow.which_reason")}</h2>
        <section className='contain-select-motive'>
            <div className='camp-checkbox-motive'>
                <input type="radio" name="motive" checked={optionSelect === "a" } che id="motive" onClick={()=>{
                    selectReason("a")
                    setOptionSelect("a")
                    localStorage.setItem("BooOption", "a")
                }}/>
                <label htmlFor="motive">{t("specialist.booking_flow.information_price")}</label>
            </div>
            <div className='camp-checkbox-motive'>
                <input type="radio" name="motive" checked={optionSelect === "b"} id="motive" onClick={()=>{
                    selectReason("b")
                    setOptionSelect("b")
                    localStorage.setItem("BooOption", "b")
                }}/>
                <label htmlFor="motive">{t("specialist.booking_flow.information_tratement")}</label>
            </div>
            <div className='camp-checkbox-motive'>
                <input type="radio" name="motive" checked={optionSelect === "c"} id="motive" onClick={()=>{
                    selectReason("c")
                    setOptionSelect("c")
                    localStorage.setItem("BooOption", "c")
                }}/>
                <label htmlFor="motive">{t("specialist.booking_flow.option_finantation")}</label>
            </div>
            <div className='camp-checkbox-motive'>
                <input type="radio" name="motive" checked={optionSelect === "d"} id="motive" onClick={()=>{
                    selectReason("d")
                    setOptionSelect("d")
                    localStorage.setItem("BooOption", "d")
                }}/>
                <label htmlFor="motive">{t("specialist.booking_flow.other")}</label>
            </div>
        </section>
      
            <section className='contain-describe-consultation'>
            <h3>{t("specialist.booking_flow.add_comment")}</h3>
            <textarea name="" id="" value={description}  placeholder={t("specialist.booking_flow.place_holder")} onChange={(e)=>{
                setDescription(e.target.value)
                localStorage.setItem("BooDescription", e.target.value)
                
            }}></textarea>
            </section>
        
        
    </div>
  )
}

export default MotiveConsultation