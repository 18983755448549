import * as React from "react";
import InputNew from "../../../ui/views/share/components/inputsNew/Input";

export default function TextInput() {
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState(false);

  return (
    <InputNew
        label={"text"}
        type="text"
        value={inputValue}
        setValue={setInputValue}
        error={error}
        setError={setError}
        fullWidth
        small={false}
        disabled={false}
        required={true}
        email={false}
        maxLength={50}
    />
  );
}
