import React, { useEffect, useState, useRef } from "react";
import styles from "./FlightsCarousel.module.css";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

const FlightsCarousel = ({
  cities,
  smoothScrollTo,
  getSelectedOrigin,
  getSelectedDestination,
  setIsCardRefDate,
  setIsRoundTrip,
  setCheckDate,
  setFlightDefault,
  citiSelected,
}) => {
  const [currentIndex, setCurrentIndex] = useState(cities.length); // Start at the first duplicated item
  const [visibleCards, setVisibleCards] = useState(4); // Default is 4 cards visible on desktop
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // State to track if it's mobile view
  const startXRef = useRef(null);
  const isSwipingRef = useRef(false);

  // Duplicate the cities array for infinite scrolling
  const duplicatedCities = [...cities, ...cities, ...cities];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setVisibleCards(1);
        setIsMobile(true);
      } else if (window.innerWidth < 767) {
        setVisibleCards(2);
        setIsMobile(true);
      } else if (window.innerWidth < 1024) {
        setVisibleCards(3);
        setIsMobile(true);
      } else {
        setVisibleCards(4);
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Reset index when changing visibleCards
    setCurrentIndex(cities.length);
  }, [visibleCards, cities.length]);

  useEffect(() => {
    // Update button states
    setIsPrevDisabled(currentIndex <= cities.length);
    setIsNextDisabled(currentIndex >= duplicatedCities.length - visibleCards);
  }, [currentIndex, duplicatedCities.length, visibleCards, cities.length]);

  const prevCard = () => {
    if (!isPrevDisabled) {
      setCurrentIndex((prevIndex) =>
        prevIndex <= cities.length
          ? duplicatedCities.length - visibleCards
          : prevIndex - 1
      );
    }
  };

  const nextCard = () => {
    if (!isNextDisabled) {
      setCurrentIndex((prevIndex) =>
        prevIndex >= duplicatedCities.length - visibleCards
          ? cities.length
          : prevIndex + 1
      );
    }
  };

  const handleTouchStart = (e) => {
    if (isMobile) {
      startXRef.current = e.touches[0].clientX;
      isSwipingRef.current = true;
    }
  };

  const handleTouchMove = (e) => {
    if (isMobile && isSwipingRef.current && startXRef.current) {
      const diffX = startXRef.current - e.touches[0].clientX;

      if (diffX > 50) {
        nextCard(); // Swipe left (next card)
        isSwipingRef.current = false;
      } else if (diffX < -50) {
        prevCard(); // Swipe right (previous card)
        isSwipingRef.current = false;
      }
    }
  };

  const handleTouchEnd = () => {
    if (isMobile) {
      startXRef.current = null;
      isSwipingRef.current = false;
    }
  };

  return (
    <div
      className={styles.carouselContainerFlights}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {isMobile && (
        <button
          onClick={prevCard}
          className={`${styles.carouselButton} ${styles.carouselButtonLeft} ${
            isPrevDisabled && styles.disabled
          }`}
          disabled={isPrevDisabled}
        >
          <MdOutlineKeyboardArrowLeft />
        </button>
      )}
      <div className={styles.carouselWrapper}>
        <div
          className={styles.carouselContentFlights}
          style={{
            transform: `translateX(-${(100 / visibleCards) * currentIndex}%)`,
          }}
        >
          {duplicatedCities.map((city, index) => (
            <div key={index} className={styles.cardFlightsDestac}>
              <div className={styles.cardFlightsDestacImg}>
                {city.img ? (
                  <img src={city.img} width={60} height={60} alt="Image" />
                ) : (
                  <div
                    style={{
                      width: 60,
                      height: 60,
                      backgroundColor: "var(--greyPrimary)",
                    }}
                  ></div>
                )}
              </div>
              <div className={styles.cardContentDestac}>
                <div>
                  <p>Disfruta en</p>
                  <h3>
                    {city.name
                      ? `Vuelo a ${city.name}`
                      : "Ciudad no disponible"}
                  </h3>
                </div>
                <h4>Ida y vuelta</h4>
                <p style={{ fontSize: "16px" }}>
                  Saliendo desde {city.nameAirport}
                </p>
                <div className={styles.cardContentInfo}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 10,
                      marginTop: 10,
                    }}
                  >
                    <span
                      onClick={() => {
                        smoothScrollTo(200, 400);
                        getSelectedOrigin(citiSelected);
                        getSelectedDestination(city);
                        setIsCardRefDate(true);
                        setIsRoundTrip(true);
                        setCheckDate(true);
                        setFlightDefault(true);
                      }}
                      className={styles.underlineEffect}
                      style={{ color: "var(--bluePrimary)", cursor: "pointer" }}
                    >
                      Ver más
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isMobile && (
        <button
          onClick={nextCard}
          className={`${styles.carouselButton} ${styles.carouselButtonRight} ${
            isNextDisabled && styles.disabled
          }`}
          disabled={isNextDisabled}
        >
          <MdOutlineKeyboardArrowRight />
        </button>
      )}
    </div>
  );
};

export default FlightsCarousel;
