import React from "react";
import AboutUsC from "../../ui/views/AboutUs/AboutUs";

const AboutUs = () => {
  return (
    <div>
      <AboutUsC />
    </div>
  );
};

export default AboutUs;
