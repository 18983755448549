import React, { useState } from 'react'
import secure2 from "../../../assets/svg/secure1.svg"
// import mok2 from "../../../assets/svg/mok2.png"
// import { Link } from 'react-router-dom'
// import { ReactComponent as SuraSecure } from './svg/SecureSura.svg'
// import MokSecure from './img/MokSecure.png'
import FormPlan from '../../../../Components/TerrawindFormPlan/FormPlan'
// import SuraFormQuote from '../../../../Components/SuraFormQuote/SuraFormQuote'
import "./styles.css"

const ButtonSecure = () => {
  const [isModalOpenTerrawind , setIsModalOpenTerrawind] = useState(false)
  // const [isModalOpenSura , setIsModalOpenSura] = useState(false)

  function closeModal(){
    setIsModalOpenTerrawind(false)
    // setIsModalOpenSura(false)
  }

  return (
    <div className='content-secures'>
        {/* <div className='sura-content hover-card'  onClick={() => setIsModalOpenSura(true)}>
            <SuraSecure className='sura-secure'/>
        </div> */}
        <div className='content-secure2 hover-card' onClick={ () => setIsModalOpenTerrawind(true) }>
              <img src={secure2} style={{ height: "100px" }} alt=""/>
        </div>
        {/* <div className='content-mok hover-card'>
            <img src={MokSecure} alt="" />
        </div> */}
        <FormPlan isModalOpen={isModalOpenTerrawind} id={"8021"} plan={"Terrawind"} closeModal={closeModal} />
        {/* <SuraFormQuote isModalOpen={isModalOpenSura} closeModal={closeModal}/> */}
    </div>
  )
}

export default ButtonSecure