import React, { useState } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import TextComponent from '../../../share/components/fonts/TextComponent';

function FlightPassengerManagement({ value, onChange, setIsManagingPassengers, refPass, isManagingPassengers, setPass }) {
    const [error, setError] = useState('');
    const { t } = useTranslation('global');

    const maxPassengers = 9;


    const errorMessages = {
        maxPassengers: t('fligth.error.max_passengers'),
        adultBabyRatio: t('fligth.error.adult_baby_ratio'),
        adultChildRatio: t('fligth.error.adult_child_ratio'),
    };


    const getPassengersSelecteds = (type, operation) => {
        if (operation) {
            localStorage.setItem(type, value[type] + 1);
        } else {
            localStorage.setItem(type, value[type] - 1);
        }
        if (type === 'adult') {
            if (operation) {
                setPass((prev) => {
                    return {
                        ...prev,
                        [type]: operation
                            ? value[type] + 1
                            : value[type] - 1,
                    };
                });
            } else {
                setPass({
                    adult: operation ? value.adult + 1 : value.adult - 1,
                    boy: 0,
                    baby: 0,
                    seat: 0
                });
            }

        } else {
            setPass((prev) => {
                return {
                    ...prev,
                    [type]: operation
                        ? value[type] + 1
                        : value[type] - 1,
                };
            });
        }


    };

    const validateAndSetError = (type, operationSuma) => {
        const { adult, boy, baby, seat } = value || {};
        const totalPassengers = adult + boy + baby + seat;
        if (operationSuma) {
            if (totalPassengers >= maxPassengers) {
                setError(errorMessages.maxPassengers);
                return false;
            }
            if (type === 'baby' && (adult * 2 <= boy + seat + baby || adult <= baby)) {
                setError(errorMessages.adultBabyRatio);
                return false;
            }
            if ((type === 'boy' || type === 'seat') && adult * 2 <= boy + seat + baby) {
                setError(errorMessages.adultChildRatio);
                return false;
            }
        } else {
            if ((type === 'adult' && adult === 1) || (type !== 'adult' && value[type] === 0)) {
                return false;
            }
        }

        setError('');
        return true;
    };

    const handleCounterChange = (type, operationSuma) => {
        if (validateAndSetError(type, operationSuma)) {
            getPassengersSelecteds(type, operationSuma)
        }
    };

    const renderPassengerGroup = (type, label, description) => (
        <div className='flightPassengerManagementGroup'>
            <div className='flightPassengerManagementGroupLeft'>
                <TextComponent className={"flightPassengerManagementGroupName"} size={"md2"} fontWeight={'bold'} children={label} />
                <TextComponent className={"flightPassengerManagementGroupDescriptionPassenger"} size={"sm"} fontWeight={'bold'} children={description} />
            </div>
            <div className='flightPassengerManagementGroupRigth'>
                <div className='flightPassengerManagementGroupRigthBtn' style={{ opacity: type === "adult" && value.adult === 1 ? "0.5" : type === "boy" && value.boy === 0 ? "0.5" : type === "baby" && value.baby === 0 ? "0.5" : type === "seat" && value.seat === 0 ? "0.5" : "", cursor: type === "adult" && value.adult === 1 ? "not-allowed" : type === "boy" && value.boy === 0 ? "not-allowed" : type === "baby" && value.baby === 0 ? "not-allowed" : type === "seat" && value.seat === 0 ? "not-allowed" : "pointer" }} onClick={() => handleCounterChange(type, false)}>
                    <p className='flightPassengerManagementGroupRigthBtnText'>-</p>
                </div>
                <TextComponent className={"flightPassengerManagementGroupRightValue"} size={"md2"} fontWeight={'bold'} children={value[type]} />
                <div className='flightPassengerManagementGroupRigthBtn' style={{ backgroundColor: error ? '#00427485' : '#004274' }} onClick={() => handleCounterChange(type, true)}>
                    <p className='flightPassengerManagementGroupRigthBtnText'>+</p>
                </div>
            </div>
        </div>
    );

    return (
        <div className='flightPassengerManagement' ref={refPass}>
            <TextComponent className={"flightPassengerManagementTitle"} size={"md"} fontWeight={'bold'} children={t('fligth.search_fligth.passengers')} />
            {renderPassengerGroup('adult', t('fligth.search_fligth.adults'), t('fligth.search_fligth.description_adults'))}
            {renderPassengerGroup('boy', t('fligth.search_fligth.childrens'), `2 ${t('fligth.search_fligth.to')} 11 ${t('fligth.search_fligth.years')}`)}
            {renderPassengerGroup('baby', t('fligth.search_fligth.babies'), `0 ${t('fligth.search_fligth.to')} 23 ${t('fligth.search_fligth.months')}`)}
            {renderPassengerGroup('seat', t('fligth.search_fligth.baby_seat'), `0 ${t('fligth.search_fligth.to')} 23 ${t('fligth.search_fligth.months')}`)}
            <TextComponent className={"flightPassengerManagementError"} size={"sm"} fontWeight={'normal'} children={error} />
        </div>
    );
}

export default FlightPassengerManagement;
