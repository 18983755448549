import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CarouselSevices from '../share/components/CarouselServices/CarouselSevices';
import "./styles.css"
import pqrs from "./assets/question.svg"
import girl from "./assets/girl.png"
import mail from "./assets/mail.svg"
import whassap from "./assets/whassap.svg"
import televentas from "./assets/televentas.svg"
import contact from "./assets/contact.svg"
import DisabledModal from '../share/components/disabledModal/disabledModal';
import ReturnPage from '../share/components/return/ReturnPage';

const Contact = () => {
  const [t] = useTranslation("global");

  const cards = [
    {
      title: "¿Necesitas ayuda?",
      img: girl,
      text: (
        <p>
          En Wellezy, nos importa que tengas la mejor experiencia posible. Si
          tienes preguntas o necesitas asistencia, puedes contactarnos a través
          de nuestros canales. <br />
          <br /> No dudes en comunicarte con nosotros. Nuestro equipo de
          atención al cliente está listo para ayudarte en todo lo que necesites.
        </p>
      ),
      type: "big",
    },
    {
      title: "Preguntas frecuentes",
      img: pqrs,
      text: <p>Puedes gestionar tus reservas desde Mis Viajes</p>,
      button: "Conoce más",
      url: "/pqrs",
      disable: true,
    },
    {
      title: "Televentas",
      img: televentas,
      text: (
        <p>
          Llama al número abajo para que nuestros agentes te asesoren con tu
          compra
          <br />
          <br />
          Estamos disponibles de lunes a domigo de 8:00 a las 18.00
        </p>
      ),
      button: "Conoce más",
      url: "#",
      disable: false,
    },
    {
      title: "WhatsApp",
      img: whassap,
      text: (
        <p>Estamos disponibles de lunes a domigo de 8:00 a las 18:00 horas</p>
      ),
      button: "Conoce más",
      url: "https://wa.link/uq1q0p",
      disable: true,
      linkExit: true,
    },
    {
      title: "Te contactamos",
      img: contact,
      text: <p>Selecciona el día y la hora que prefieras</p>,
      button: "Conoce más",
      url: "#",
      disable: false,
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  return (
    <>
      <CarouselSevices />
      <ReturnPage />
      <div className="contain-card-contact">
        {cards.map((card, index) => {
          const item = {
            route: card.url,
            disable: card.disable,
          };
          return (
            <>
              {card.type === "big" ? (
                <div className="card-big">
                  <div className="infor">
                    <h2>{card.title}</h2>

                    {card.text}
                  </div>
                  <img src={card.img} alt="" />
                </div>
              ) : (
                <div className="card-small">
                  <div className="heade">
                    <img src={card.img} alt="" />
                    <h2>{card.title}</h2>
                  </div>
                  <div className="core">{card.text}</div>
                  <button className="foo">
                    <DisabledModal
                      item={item}
                      active={item.disable}
                      color={"white"}
                      linkExit={card?.linkExit ? true : false}
                    >
                      {card.button}
                    </DisabledModal>
                  </button>
                </div>
              )}
            </>
          );
        })}
      </div>

      <div className="offerst-mail">
        <div className="cont-input">
          <img src={mail} alt="" />
          <div>
            <h2>Ofertas exclusivas en tu email</h2>
            <input type="text" placeholder="Ingresa tu email" />
            <p>
              Recibirás emails promocionales de Wellezy. para más información
              consulta las <b>políticas de privacidad</b>
            </p>
          </div>

          <button>
            <DisabledModal
              item={{ route: "#", disable: false }}
              active={false}
              color={"white"}
            >
              ¡Quiero recibirlas!
            </DisabledModal>
          </button>
        </div>
      </div>
    </>
  );
};

export default Contact;
