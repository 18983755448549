const routesBuildPackage = [
    {
        route: "/build-package"
    },
    {
        route: "/build-package/specialist"
    },
    {
        route: "/build-package/specialist/:id"
    },
    {
        route: "/build-package/clinic"
    },
    {
        route: "/build-package/clinic/:id"
    },
    {
        route: "/build-package/flight/:id"
    },
    {
        route: "/build-package/hotel/:id"
    },
    {
        route: "/build-package/transport/:id"
    }
];

export default routesBuildPackage;