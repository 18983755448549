// AnyQuestions.js

import React, { useState, useEffect } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import { useTranslation } from "react-i18next";
import TextComponent from "../../../share/components/fonts/TextComponent";

function AnyQuestions({ title }) {
  const [t] = useTranslation("global");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const items = [
    <div className="anyQuestionsOption" key="1">
      <div className="anyQuestionsOptionIcon">
        <SvgIcon name={iconList.IconPinMap} color={"#00AFE8"} size={60} />
      </div>
      <TextComponent
        className={"anyQuestionsOptionText"}
        children={t("browse_home.any_question.attention_points")}
        size={"min"}
        fontWeight="normal"
      />
    </div>,

    <div className="anyQuestionsOption" key="2">
      <div className="anyQuestionsOptionIcon">
        <SvgIcon name={iconList.IconChat} color={"#00AFE8"} size={70} />
      </div>
      <TextComponent
        className={"anyQuestionsOptionText"}
        children={t("browse_home.any_question.online_chat")}
        size={"min"}
        fontWeight="normal"
      />
    </div>,

    <div className="anyQuestionsOption" key="3">
      <div className="anyQuestionsOptionIcon">
        <SvgIcon name={iconList.IconPhoneRing} color={"#00AFE8"} size={80} />
      </div>
      <TextComponent
        className={"anyQuestionsOptionText"}
        children={t("browse_home.any_question.call_us")}
        size={"min"}
        fontWeight="normal"
      />
    </div>,

    <div className="anyQuestionsOption" key="4">
      <div className="anyQuestionsOptionIcon">
        <SvgIcon name={iconList.IconCall} color={"#00AFE8"} size={60} />
      </div>
      <TextComponent
        className={"anyQuestionsOptionText"}
        children={t("browse_home.any_question.we_call")}
        size={"min"}
        fontWeight="normal"
      />
    </div>,

    <div className="anyQuestionsOption" key="5">
      <div className="anyQuestionsOptionIcon">
        <SvgIcon name={iconList.IconMegaPhone} color={"#00AFE8"} size={60} />
      </div>
      <TextComponent
        className={"anyQuestionsOptionText"}
        children={t("browse_home.any_question.contact_us")}
        size={"min"}
        fontWeight="normal"
      />
    </div>,
  ];

  return (
    <section className="anyQuestionsContainer">
      <div className="anyQuestions">
        <TextComponent
          className={"anyQuestionsTitle"}
          children={title}
          size={"lg"}
          fontWeight="bold"
        />

        <div className="carousel-inner-max">
          <div className="carousel-inner">
            {isMobile ? (
              /* En móviles, duplicamos los elementos para la animación infinita */
              items.concat(items).map((item, index) => (
                <div className="carousel-item-footer" key={index}>
                  {item}
                </div>
              ))
            ) : (
              /* En escritorio, solo mostramos los elementos una vez */
              items.map((item, index) => (
                <div className="carousel-item-footer" key={index}>
                  {item}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AnyQuestions;
