import React from "react";
import "./styles.css";
import { global } from "../../../../global";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import TextComponent from "../../../share/components/fonts/TextComponent";

const { iconLuggageBlue } = global.img.icons;

function ModalWishToInclude({ show, close, continueBuy, chooseUpsell }) {
  const [t, i18n] = useTranslation("global");

  return (
    <div
      className={`${
        show
          ? "modalWishToIncludeContainer"
          : "modalWishToIncludeContainer close"
      }`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          close(false);
        }
      }}
    >
      <div
        className={`${
          show ? "modalWishToIncludeCard" : "modalWishToIncludeCard close"
        }`}
      >
        <div
          onClick={() => close(false)}
          className="modalWishToIncludeCardClose"
        >
          <IoClose size="30" color="#000" />
        </div>

        <div className="modalWishToIncludeCenter">
          <div className="modalWishToIncludeCardHead">
            <div className="modalWishToIncludeCardHeadIconWrap">
              <img
                src={iconLuggageBlue}
                alt="icon Luggage"
                className="modalWishToIncludeCardHeadIcon"
              />
            </div>
          </div>

          <div className="modalWishToIncludeCardBody">
            <TextComponent
              className={"modalWishToIncludeCardBodyTitle"}
              children={t("fligth.modal_to_include.include_luggage")}
              size={"lg"}
              fontWeight="bold"
            />
            <TextComponent
              className={"modalWishToIncludeCardBodysubTitle"}
              children={t("fligth.modal_to_include.select_fare_ajust")}
              size={"md"}
              fontWeight="bold"
            />
          </div>

          <div className="modalWishToIncludeCardFoot">
            <div
              className="modalWishToIncludeCardFootBtn"
              onClick={chooseUpsell}
            >
              <TextComponent
                className={"modalWishToIncludeCardFootBtnText"}
                children={t("fligth.modal_to_include.select_fare")}
                size={"md2"}
                fontWeight="bold"
              />
            </div>
            <div
              className="modalWishToIncludeCardFootBtn"
              onClick={() => continueBuy(false)}
            >
              <TextComponent
                className={"modalWishToIncludeCardFootBtnText"}
                children={t("fligth.search_fligth.continue")}
                size={"md2"}
                fontWeight="bold"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalWishToInclude;
