import React, { useState } from 'react'
import "./styles.css"
import CarouselSevices from '../share/components/CarouselServices/CarouselSevices'
import BestExperiences from './BestExperiences/BestExperiences'
import { useTranslation } from "react-i18next";
import BannerCarousel from './BannerCarousel/BannerCarousel'
import SearchTourismHealth from './searchTourismHealth/SearchTourismHealth'
import FormSuggestions from './formAndSuggestions/form/FormSuggestions'
import Specialities from './specialities/Specialities'
import CardRegisterNow from '../home/components/cardRegisterNow'
import NeedInformation from './needInformation/NeedInformation'
import question from "./needInformation/question.svg"
import earphones from "./needInformation/earphones.svg"
import NewsWellezy from "../../../Components/NewsWellezy/NewsWellezy"

import AnyQuestions from '../home/components/anyQuestion'

const TourismHealth = () => {
  const [t] = useTranslation("global");
  const [activeCard, setActiveCard] = useState(1);

  const btns = {
    need: {
      title: "¿Necesitas más Información?",
      textButton: "Conoce más",
      description: "Tienes dudas o preguntas sobre paquetes, vuelos, hoteles o algo relaionado con tu viaje da click aquí.",
      img: question,
      url:"/contact",
      active:true
    },
    agent: {
      title: "¡Compra con atención persoalizada!",
      textButton: "Conocer canales",
      description: "Tenemos varios canales de atención para que planifiques tu viaje con la asesoría de nuestros agentes expertos.",
      img: earphones,
      url:"https://api.whatsapp.com/send?phone=573160233389&text=Hola%20escribo%20desde%20la%20pagina%20de%20Wellezy%20quiero%20conocer%20mas%20sobre%20los%20paquetes%2C%20toda%20la%20info%20que%20me%20puedas%20ofrecer%20de%20turismo",
      active:true
    }
  }

  const cards = {
    card_1: {
      url: "/Specialists",
      typeUser: "serviceProvider",
      img: "https://uvn-brightspot.s3.amazonaws.com/assets/vixes/btg/curiosidades.batanga.com/files/6-cosas-que-ningun-doctor-puede-hacerte-3.jpg",
      title: "Especialistas",
      description: "Conoce el amplio catalogo de doctores en todas las especialidades de salud que Wellezy trae para ti",
      button: "VER MÁS",
      more_info: "LO MEJOR PARA TI",
      action: () => { },
    },

    card_2: {
      url: "/Clinics",
      typeUser: "customer",
      img: "https://anmdecolombia.org.co/wp-content/uploads/2022/08/hospital-cl%C3%ADnica.jpg",
      title: "Clinicas",
      description: "Conoce el amplio catalogo de clínicas en todas las especialidades de salud que Wellezy trae para ti",
      button: "VER MÁS",
      more_info: "LO MEJOR PARA TI",
      action: () => { },
    },
  };

  const handleClick = (type, setCard) => {
    setCard(type); // Cambia la tarjeta activa cuando se hace clic
  };




  return (
    <div className='content-tourism-health'>
      <CarouselSevices current={t("carousel_services.packages")} />
      <div className='layout-turismHealth'>
        <aside className='column-Atourism'>
          <SearchTourismHealth />
          <FormSuggestions />
        </aside>
        <article className='column-Btourism'>
          <BannerCarousel />
          <Specialities />
        </article>
      </div>
      <div className='layout-cards-tourism-health'>
        <div className="column-x">
          <CardRegisterNow
            backgroundColor={"var(--blueLight)"}
            backgroundColorDesactive={"var(--blueLightTransparent)"}
            colorButton={"var(--bluePrimary)"}
            icons={false}
            type={cards.card_1}
            disable={true}
            url={cards.card_1.url}
            active={activeCard === 1}
            onClick={() => handleClick(1, setActiveCard)}
            width={"100%"}
          />
        </div>
        <div className="column-x">
          <CardRegisterNow
            icons={false}
            backgroundColor={"var(--blueLight)"}
            backgroundColorDesactive={"var(--blueLightTransparent)"}
            colorButton={"var(--bluePrimary)"}
            type={cards.card_2}
            disable={true}
            url={cards.card_2.url}
            active={activeCard === 2}
            onClick={() => handleClick(2, setActiveCard)}
            width={"100%"}
          />
        </div>
      </div>
      <div className='layout-need-information-tourism-health'>
        <NeedInformation
          description={btns.need.description}
          img={btns.need.img}
          title={btns.need.title}
          textButton={btns.need.textButton}
          url={btns.need.url}
          active={btns.need.active}
          linkExit={false}
          color={"var(--blueLight)"}
          
        />
      </div>
      <BestExperiences />
      <div className='layout-need-information-tourism-health'>
        <NeedInformation
          description={btns.agent.description}
          img={btns.agent.img}
          title={btns.agent.title}
          textButton={btns.agent.textButton}
          active={true}
          url={"https://wa.link/uq1q0p"}
          linkExit={true}
          color={"var(--blueLight)"}
          
        />
      </div>

      {/* <OutstandingPlaces/> */}
      {/* <NewsTourism /> */}
      <NewsWellezy color={"#00AFE8"} />
      {/* <PackagesInterest/> */}
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  )
}

export default TourismHealth