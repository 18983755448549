const codes = [`
import * as React from 'react';
import ParagraphWellezy from '@/Components/ParagraphWellezy/ParagraphWellezy';

export function ParagraphWellezyPrimary() {
    return (
        <ParagraphWellezy size="large" color="black">
            Parrafo 1
        </ParagraphWellezy>
    );
}
`,
`
import * as React from 'react';
import ParagraphWellezy from '@/Components/ParagraphWellezy/ParagraphWellezy';

export function ParagraphWellezySecondary() {
    return (
        <ParagraphWellezy size="medium" color="black">
            Parrafo 1
        </ParagraphWellezy>
    );
}
`,
`
import * as React from 'react';
import ParagraphWellezy from '@/Components/ParagraphWellezy/ParagraphWellezy';

export function ParagraphWellezyTernary() {
    return (
        <ParagraphWellezy size="small" color="black">
            Parrafo 1
        </ParagraphWellezy>
    );
}
`
];

const dataTypographys = [
    {
        title: 'Parrafo 1',
        description: 'Este es el parrafo primario',
        component: '',
        typeCode: "jsx",
        code: codes[0]
    },
    {
        title: 'Parrafo 2',
        description: 'Este es el parrafo secundario',
        component: '',
        typeCode: "jsx",
        code: codes[1]
    },
    {
        title: 'Parrafo 3',
        description: 'Este es el parrafo terciario',
        component: '',
        typeCode: "jsx",
        code: codes[2],
    }
];

export default dataTypographys;