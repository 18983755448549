import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RiFilterFill } from "react-icons/ri";
import "./styles.css";
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineDollar } from "react-icons/ai";
import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";
import SearchBuildPackage from "../../components/SearchBuildPackage/SearchBuildPackage";
import Paginado from "../../../Hotel/HotelResult/Paginado/Paginado";
import useTurismHealth from "../../../../viewModels/turismHealth/useTurismHealth";
import DisabledModal from "@/ui/views/share/components/disabledModal/disabledModal";
import styles from "./styles.module.css"
import Filter from "./Filter/Filter";
import ModalContainer from "@/ui/views/share/components/modalContainer";
import Loading from "@/ui/views/share/components/loading";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import { HiMiniCalendarDays } from "react-icons/hi2";
import { BiBookContent } from "react-icons/bi";

const StepOne = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false)
  const [t] = useTranslation("global");

  const {
    data, 
    loading, 
    nextHandler, 
    prevHandler, 
    setCurrentPage ,
    priceMax,
    priceMin,
    handleDoubleRangeChange,
    range,
    handleDoubleRangeChangeCommitted,
    city,
    setCity,
    procedure,
    setProcedure,
    filterPerCity,
    setRange,
    setMenuHamburger,
    menuHamburger,
    filterPerProcedure,
    filterPerSpecialist,
    speciality,
    setSpeciality,
    filterReset
  } = useTurismHealth();

  const handleClickPack = (item, id) => {
    navigate(`/TourismHealth/DetailProcedure/${id}`, {
      state: {
        data: item,
      },
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>
      <SearchBuildPackage />

      {
        loading &&
        <Loading/>
      }

      <ModalContainer
        show={menuHamburger}
        setHidden={setMenuHamburger}
        hiddsetActiveChangeServiceen={setMenuHamburger}
        sizeHeight={52}
      >
       <Filter handleDoubleRangeChange={handleDoubleRangeChange} handleDoubleRangeChangeCommitted={handleDoubleRangeChangeCommitted} range={range} priceMax={priceMax} priceMin={priceMin} city={city} setCity={setCity} procedure={procedure} setProcedure={setProcedure} setModal={setMenuHamburger} filterPerCity={filterPerCity} setRange={setRange} filterPerProcedure={filterPerProcedure} filterPerSpecialist={filterPerSpecialist} speciality={speciality} setSpeciality={setSpeciality} filterReset={filterReset}/>
      </ModalContainer>

      <div className={styles.layout_specialist}>
        <div className={styles.filterBtn}>
          <button onClick={()=>setMenuHamburger(!menuHamburger)}>
            {" "}
            <RiFilterFill size={20} /> Filtros
          </button>
        </div>
        <div className={styles.filters}>
          <Filter handleDoubleRangeChange={handleDoubleRangeChange} handleDoubleRangeChangeCommitted={handleDoubleRangeChangeCommitted} range={range} priceMax={priceMax} priceMin={priceMin} city={city} setCity={setCity} procedure={procedure} setProcedure={setProcedure} setModal={setMenuHamburger} filterPerCity={filterPerCity} setRange={setRange} filterPerProcedure={filterPerProcedure} filterPerSpecialist={filterPerSpecialist} speciality={speciality} setSpeciality={setSpeciality} filterReset={filterReset}/>
        </div>
        <div className={styles.results}>
          <div className={styles.maxWidthBuildPackage}>
            <div className="maxWidthBuildPackageTitle">
              <h3>NUESTROS RECOMENDADOS PARA TI</h3>
              <p>
                Viaja por colombia con paquetes todo incluido y los mejores precios
                que trae Wellezy para ti{" "}
              </p>
            </div>
            <div className="grid-container">
              {data?.packs?.data?.length ? 
                data?.packs?.data.map((item, index) => (
                  <div className="grid-item" key={index}>
                    <div className="grid-item-content">
                      <div className="grid-item-content-img">
                        <img src={data?.url + "/" + item?.image} alt="Imagen" />
                      </div>
                      <div className="grid-item-info">
                        <div className="grid-item-info-text">
                          <h4 className="grid-item-info-title" title={item?.name}>
                            {item?.name}
                          </h4>
                          <div className="grid-item-icons">
                            <div className="grid-item-icon">
                              <IoLocationOutline size={25} color="#var(--blueLight)" />
                              {item.destination}
                            </div>
                            <div className="grid-item-icon">
                              <AiOutlineDollar size={25} color="#var(--blueLight)" />
                              Precio a partir de:{" "}
                              <span>
                                $
                                {new Intl.NumberFormat("es-CO", {
                                  currency: item?.currency,
                                  minimumFractionDigits:
                                    item?.currency !== "COP" ? 2 : 0,
                                  maximumFractionDigits:
                                    item?.currency !== "COP" ? 2 : 0,
                                }).format(item?.price)}{" "}
                                {item?.currency}
                              </span>
                            </div>

                            <div className="grid-item-icon">
                              <HiMiniCalendarDays size={25} color="#var(--blueLight)" />
                              Días del procedimiento:{" "}
                              <span>
                                {item.day}
                              </span>
                            </div>


                            <div className="grid-item-icon">
                              <BiBookContent size={25} color="#var(--blueLight)" />
                              Cantidad de unidades:{" "}
                              <span>
                                {item.availables_units}
                              </span>
                            </div>

                           
                            
                          </div>
                        </div>
                        <div
                          className="grid-item-info-buttons"
                          style={{
                            margin: "2px",
                          }}
                        >
                          <button
                            className="grid-item-info-button"
                            style={{
                              backgroundColor: "var(--blueLightTransparent)",
                            }}
                          >
                            <DisabledModal item={item}>
                              Conozca el proveedor
                            </DisabledModal>
                          </button>
                          <button
                            className="grid-item-info-button"
                            onClick={() => handleClickPack(item, item?.id)}
                          >
                            Ver paquete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              :
              <h4>No hay resultados</h4>
              }
            </div>

          </div>
        </div>
      </div>


      <Paginado
        currentPage={data?.packs.current_page}
        data={data?.packs?.data}
        totalPages={data?.packs?.last_page}
        nextHandler={nextHandler}
        prevHandler={prevHandler}
        pageRangeDisplayed={5}
        setCurrentPage={setCurrentPage}
      />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default StepOne;