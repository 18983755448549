import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDataPackage } from "@/store/slices/dataStepz/dataStepzSlice";

const useTrasnlateBuildPackage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
    const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
  
    const nextStep = (data) => {
        dispatch(setDataPackage({ ...dataPackage, trasnlate: data
        }));
        
        navigate('/');
    }

    return {
        dataPackage,
        setSpetVariableData,
        nextStep
    };

}

export default useTrasnlateBuildPackage;