import { useState, useEffect } from "react";

import CarouselSevices from "../../ui/views/share/components/CarouselServices/CarouselSevices";
import style from "./Assets/style.module.css";

import AnyQuestions from "../../ui/views/home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import { get, keys } from "../../ui/localStorage";
import AllCardTour from "../../ui/views/tours/CardTour/AllCardTour"
import Imagen from "./Assets/images/imagen.jpg";
import CustomSlider from "@/ui/views/Packages/DetailPackages/sliderImg/CustomSlider";
import banner from "./Assets/images/imagen2.png";
import { CiSearch } from "react-icons/ci";
import useTour from "../../ui/viewModels/tours/useTour";
import Paginado from "@/ui/views/tours/Paginado/Paginado";




function Tours() {
  const [t] = useTranslation("global");



  const {
    dataAlways,
    loading,
    nextHandler,
    prevHandler,
    services,
    urlImg,
    detailTour,
    setCurrentPage,
    findTour,
    setCity,
    setName,
    city,
    name,

  } = useTour();




  const photos = [
    {
      id: 1,
      img: banner,
    },
    {
      id: 2,
      img: banner,
    },
    {
      id: 3,
      img: banner,
    },
  ];

  return (
    <>
      <CarouselSevices current={t("carousel_services.tours")} />
      <div className={style.main_tours}>
        <div className={style.attraction_tour}>
          <div className={style.attraction_tour_header}>
            <h2>Turismo</h2>
            <p>Bienvenido a los mejores toures</p>
          </div>
          <img src={Imagen} alt="Imagen" />
          {/* <button className={style.attraction_tour_button}>Ver más</button> */}
        </div>
        <div className={style.carrousel_search}>
          <CustomSlider>
            {photos.map((image, index) => {
              return (
                <img
                  className={style.carrousel_search_image}
                  src={image.img}
                  key={index}
                  alt={`${index + 1}`}
                />
              );
            })}
          </CustomSlider>

          <div className={style.main_tours_form}>
            <div className={style.main_tours_form_header}>
              <h2>Turismo</h2>
              <p>Bienvenido a los mejores toures</p>
            </div>

            <div className={style.main_tours_inputs}>
              <input
                type="text"
                placeholder="¿Buscas algun destino en especial? "
              />
              <select name="tour" id="tour-select">
                <option value="a">Tour por la Ciudad</option>
                <option value="b">Excursión a la Montaña</option>
                <option value="c">Tour por el Río</option>
                <option value="d">Visita a la Playa</option>
                <option value="e">Recorrido por Museos</option>
                <option value="f">Safari en el Desierto</option>
                <option value="g">Tour de Viñedos</option>
              </select>

              <button className={style.main_tours_button_search}>
                <CiSearch size={25} /> Buscar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={style.results}>
        <AllCardTour
          data={dataAlways}
          urlImg={urlImg}
          loading={loading}
          detailTour={detailTour}
        />
      </div>
      <Paginado
        currentPage={services?.current_page}
        totalPages={services?.last_page}
        nextHandler={nextHandler}
        prevHandler={prevHandler}
        pageRangeDisplayed={5}
        setCurrentPage={setCurrentPage}
      />

      <div className={style.tours_main}>
        <AnyQuestions title={t("browse_home.have_doubt")} />
      </div>
    </>
  );
}

export default Tours;
