import React, { useEffect, useState } from "react";
import Find from "../../../share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../../../share/components/CarouselServices/CarouselSevices";
import UsePayHotel from "../../../../viewModels/hotels/UsePayHotel";
import PaymentCards from "../../../profileUser/components/paymentCards";
import DetailPayHotel from "./detailPayHotel/DetailPayHotel";
import connectMiles from "../../../../assets/logos/connectmiles.png";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import ModalContainer from "../../../share/components/modalContainer";
import Loading from "../../../share/components/loading";
import SuccessPay from "./successPay/SuccessPay";
import PendingPay from "./pendingPay/PendingPay";
import RejectPay from "./rejectPay/RejectPay";
import ReturnPage from "../../../share/components/return/ReturnPage";
import { MdCameraAlt } from "react-icons/md";
import GalleryImg from "../../DetailHotel/GalleryImg/GalleryImg";
import ModalCardDetail from "../../HotelResult/allResultsCards/components/ModalCardDetail";
import { formatToCOP } from "@/ui/utils";
import { GoCheckCircleFill } from "react-icons/go";
import { IoCloseCircle } from "react-icons/io5";
import { BiQuestionMark } from "react-icons/bi";
import { TbLoader2 } from "react-icons/tb";

const PayHotel = () => {
  const {
    paymentCardSelected,
    setPaymentCardSelected,
    state,
    dataPay,
    responseApi,
    setResponseApi,
    loadinPay,
    incompleteInformation,
    setIdUser,
    setEmail,
    goToLinkPay,
    statusTransaction,
    stateValue,
    dataEmitter,
    getStatePay,
  } = UsePayHotel();

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        statusTransaction?.pay?.status === "PENDING" ||
        statusTransaction?.pay?.status === "REJECTED"
      ) {
        window.location.reload();
      }
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const [hotelUrl, setHotelUrl] = useState("/hotel");

  const dataHotJSONRecovery = localStorage.getItem("dataHotJSON");

  // Convierte la cadena JSON de nuevo en un objeto JavaScript
  const dataHot = JSON.parse(dataHotJSONRecovery);
  console.log(dataHot);

  const [loadingImage, setLoadingImage] = useState(true);
  const [slideShow, setSlideShow] = useState(false);
  const [modalCardDetail, setModalDetail] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadingImage(false);
    }, 3000);
  }, []);

  function Image({ url }) {
    const onLoad = () => {
      setLoadingImage(false);
    };
    const onError = () => {
      setLoadingImage(true);
    };
    return (
      <img
        className="image-hotel-detail"
        src={url}
        onLoad={onLoad}
        onError={onError}
        alt="Hotel"
      />
    );
  }

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }
  console.log(statusTransaction);

  return (
    <div>
      <CarouselSevices current={servicesTravel[1].title} />
      <ReturnPage nav={hotelUrl} reload={true} />
      <div className="contain-hotel-pay">
        {loadinPay && <Loading size={90} />}
        {!loadingImage && (
          <GalleryImg
            Images={dataHot?.fotos?.foto}
            slideShow={slideShow}
            setSlideShow={setSlideShow}
          />
        )}
        <ModalCardDetail
          data={dataHot}
          total={formatearCantidad(stateValue?.price)}
          modalCardDetail={modalCardDetail}
          setModalDetail={setModalDetail}
          modalPay={true}
        />
        <div className="layout-hotel-pay">
          <section className="main-hotel-pay">
            <div className="main-hotel-title-pay">
              <h2>Estado de la transaccion</h2>
              <p>El estatus de tu pago actualmente</p>
            </div>

            <div className="contain-img-principal-button-hotel-pay">
              <div
                className="contain-img-principal-hotel-pay"
                onClick={() => {
                  setSlideShow(!slideShow);
                }}
              >
                <div className="section-img-big-pay">
                  <div className="section-img-1-pay">
                    {loadingImage ? (
                      <div className="img-hotel-skeleton-pay">
                        <MdCameraAlt size={40} color="var(--greyPrimary)" />
                      </div>
                    ) : (
                      <Image url={dataHot?.fotos?.foto[0]} />
                    )}
                  </div>
                </div>
                <div className="section-img-layout-for-pay">
                  <div className="section-img-layout-1-pay">
                    {loadingImage ? (
                      <div className="img-hotel-skeleton-pay">
                        <MdCameraAlt size={40} color="var(--greyPrimary)" />
                      </div>
                    ) : (
                      <Image url={dataHot?.fotos?.foto[2]} />
                    )}
                  </div>
                  <div className="section-img-layout-2-pay">
                    {loadingImage ? (
                      <div className="img-hotel-skeleton-pay">
                        <MdCameraAlt size={40} color="var(--greyPrimary)" />
                      </div>
                    ) : (
                      <Image url={dataHot?.fotos?.foto[3]} />
                    )}
                  </div>
                </div>
              </div>

              <button
                onClick={() => setModalDetail(true)}
                className="contain-img-principal-hotel-button-pay"
              >
                Ver información
              </button>
            </div>
          </section>

          <div className="content-state-pay-state">
            <div
              className={`content-state-pay ${
                statusTransaction?.pay?.status === "APPROVED"
                  ? "APPROVED"
                  : statusTransaction?.pay?.status === "PENDING"
                  ? "PENDING"
                  : statusTransaction?.pay?.status === "REJECTED"
                  ? "REJECTED"
                  : ""
              }`}
            >
              {statusTransaction?.pay?.status === "APPROVED" ? (
                <GoCheckCircleFill size={40} />
              ) : statusTransaction?.pay?.status === "PENDING" ? (
                <TbLoader2 size={40} />
              ) : statusTransaction?.pay?.status === "REJECTED" ? (
                <IoCloseCircle size={40} />
              ) : (
                <BiQuestionMark size={40} />
              )}
              <div>
                <div className="content-state-pay-message">
                  <h3>Estado:</h3>
                  <p>
                    {statusTransaction?.pay?.status === "APPROVED"
                      ? "Aprobada"
                      : statusTransaction?.pay?.status === "PENDING"
                      ? "Pendiente"
                      : statusTransaction?.pay?.status === "REJECTED"
                      ? "Denegada"
                      : "Cargando..."}
                  </p>
                </div>
                <p>
                  {statusTransaction?.pay?.status === "APPROVED"
                    ? "¡Pago realizado con exito!"
                    : statusTransaction?.pay?.status === "PENDING"
                    ? "¡Pago pendiente!"
                    : statusTransaction?.pay?.status === "REJECTED"
                    ? "¡No fue posible realizar el pago!"
                    : "Cargando..."}
                </p>
              </div>
            </div>

            <section className="aside-hotel-pay">
              <DetailPayHotel
                dataHot={dataHot}
                listReserve={stateValue?.listReserve}
                nom={stateValue?.description}
                checkIn={stateValue?.checkIn}
                checkOut={stateValue?.checkOut}
                img={stateValue?.foto}
                address={stateValue?.address}
                price={stateValue?.price}
              />
              <div className="pay-secure-hotel">
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconKey}
                  size={40}
                />
                <div>
                  <p>Pago Seguro</p>
                  <span>Los niveles más altos para los pagos con tarjetas</span>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="connect-miles">
          <img src={connectMiles} alt="connectMiles" />
          <p>
            By paying with your ConnectMiles credit card, you will earn more
            miles on you purchase
          </p>
        </div>
      </div>
    </div>
  );
};

export default PayHotel;
