import { useState, useEffect } from 'react';
import { CloseCircle } from 'iconsax-react';

import ItemReady from '../../UpsellSelectedFamiliesOneWay/Assets/Img/ItemReady.png'

import ActiveLuggageBigOn from '../../UpsellSelectedFamiliesOneWay/Assets/Img/ActiveLuggageBigOn.png';
import ActiveLuggageBigOff from '../../UpsellSelectedFamiliesOneWay/Assets/Img/ActiveLuggageBigOff.png';

import ActiveLuggageMediumOn from '../../UpsellSelectedFamiliesOneWay/Assets/Img/ActiveLuggageMediumOn.png';
import ActiveLuggageMediumOff from '../../UpsellSelectedFamiliesOneWay/Assets/Img/ActiveLuggageMediumOff.png';

import ActiveLuggageBasicOn from '../../UpsellSelectedFamiliesOneWay/Assets/Img/ActiveLuggageBasicOn.png';
import ActiveLuggageBasicOff from '../../UpsellSelectedFamiliesOneWay/Assets/Img/ActiveLuggageBasicOff.png';


import FamiliesButtonOrigin from '../../FamiliesButtonOrigin/FamiliesButtonOrigin';

const buildComponentKeys = {
  ida: { title: 'Tarifas Vuelta' },
  vuelta: { title: 'Tarifas Vuelta' },
}

const CardFamilies = (
  {
    isItOneWayType,
    isItRoundTrip,
    passengersSelected,
    itineraryGoingReturnConditional,
    value,
    change,
    lowestCostFamily
  }
) => {

  const buildComponent = buildComponentKeys[isItOneWayType ? 'ida' : 'vuelta'];
  const roundTripDetail = lowestCostFamily?.components[0];
  const bagCode = "BG";
  const included = "INC";
  const canBeIncluded = 'CHA';
  const carryOnHandBaggage = 'CARRY ON HAND BAGGAGE';

  const [bagsServices, setBagsServices] = useState([])
  const [additionalServices, setAdditionalServices] = useState([])


  const extractData = () => {
    const cleanDetail = [];
    const details = roundTripDetail?.details?.ocFeeInformation
    for (const d in details) {
      const detail = {
        serviceStatus: details[d].feeDescription?.dataInformation?.indicator,
        freeText: details[d].feeFreeFlowDescription?.freeText,
        serviceType: details[d].serviceDetails?.specialRequirementsInfo?.serviceType,
      }
      cleanDetail.push(detail)
    }

    const wordsKeyToBags = ['FIRST', 'SECOND', 'THIRD'];
    // const onlyBags = cleanDetail.filter( item => item?.serviceType === bagCode 
    //   // && (item.freeText.includes(wordsKeyToBags[0]) || item.freeText.includes(wordsKeyToBags[1]) || item.freeText.includes(wordsKeyToBags[2]))
    //   );
    //servicesINK
    //services
    // console.log(".. onlyBags: ", onlyBags)
    // {serviceStatus: 'CHA', freeText: 'FIRST EXCESS BAG', serviceType: 'BG'}
    // {serviceStatus: 'CHA', freeText: 'SECOND EXCESS BAG', serviceType: 'BG'} 
    // {serviceStatus: 'CHA', freeText: 'THIRD BAG 23KG', serviceType: 'BG'}



    const onlyBaggage = cleanDetail.filter(obj => obj.serviceType === bagCode && !obj.freeText.includes(carryOnHandBaggage))
    const otherServices = cleanDetail.filter(obj => obj.serviceType !== bagCode || (obj.serviceType === bagCode && obj.freeText.includes(carryOnHandBaggage)));
    setBagsServices(onlyBaggage);
    setAdditionalServices(otherServices);
  }

  useEffect(() => {
    extractData()
  }, [])

  const imagesBaggages = [
    { on: ActiveLuggageBasicOn, off: ActiveLuggageBasicOff },
    { on: ActiveLuggageMediumOn, off: ActiveLuggageMediumOff },
    { on: ActiveLuggageBigOn, off: ActiveLuggageBigOff }
  ];


  {/* <div className='containedLuggage-center'>
                  <div>
                    <div className='selectPlan-addLuggage_img'> <img src={ActiveLuggageBasicOn} alt="agregar equipaje" /></div>
                    <div><p>Bolso o mochila pequena</p></div>
                  </div>
                </div> */}

  {/* <div className='containedLuggage-center'>
                  <div>
                    <div className='selectPlan-addLuggage_img'> <img src={ActiveLuggageMediumOn} alt="agregar equipaje" /></div>
                    <div><p>Equipaje de mano 10kg</p></div>
                  </div>
                </div> */}

  {/* <div className='containedLuggage-center'>
                  <div>
                    <div className='selectPlan-addLuggage_img'> <img src={ActiveLuggageBigOn} alt="agregar equipaje" /></div>
                    <div><p>1 equipaje de bodeba 25kg</p></div>
                  </div>
                </div> */}





const textToLower = (text)=>{
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

  return (
    <div className='cardFamilies'>
      <div className='cardFamilies'>


        <div className='buttons-tittleBody'>
          <div style={{ paddingLeft: '20px', justifyContent: 'flex-start' }} className='tittleBody'>
            <p className='texFamilies-Strong'>{buildComponent?.title}</p>
          </div>
          <FamiliesButtonOrigin
            adult={passengersSelected.adult}
            child={passengersSelected.child}
            baby={passengersSelected.baby}
            itineraryGoingReturnConditional={itineraryGoingReturnConditional}
            value={value}
            change={change}
            isItOneWayType={isItOneWayType}
          />
        </div>



        <div className='tittle-cardFamilies'>
          <div className='contained-warningFamilies'>





            <div className='bodycard-containedBody-tittleAndInfoFamilies'>
              <div className='tittle-bodyCards-infoFamilies'>
                <p>Equipaje</p>
              </div>
              <div className='containedLuggage'  style={{marginTop:'-50px'}}>

                {
                  bagsServices !== [] && bagsServices.map((index, key) => {
                    const iconBaggage = index?.serviceStatus === included ? imagesBaggages[key].on : imagesBaggages[key].off;
                    return (
                      <div className='containedLuggage-center' key={key}>
                        <div>
                          <div className='selectPlan-addLuggage_img'> <img src={iconBaggage} alt="agregar equipaje" /></div>
                          <div><p style={{opacity: index?.serviceStatus === included ? 1 : 0.5,}}>{textToLower(index?.freeText)}</p></div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>



            <div className='bodycard-containedBody-tittleAndInfoFamilies' style={{marginTop:'-50px'}}>
              <div className='tittle-bodyCards-infoFamilies'>
                <p>Servicios Adicionales</p>
              </div>

              <div style={{ columnCount: 2, width: '100%'}}>
                {isItOneWayType && additionalServices !== [] && additionalServices.map((index, key) => {
                  if (index.serviceType !== canBeIncluded) {
                    return (
                      <div key={key} className='warningFamilies' style={{
                        opacity: index?.serviceStatus === included ? 1 : 0.5,
                        alignItems: "center",
                        height: '35px',
                        overflow: "hidden",
                      }}>
                        <div className='ItemReady_img'>
                          {
                            index?.serviceStatus === included
                              ? <img src={ItemReady} alt="ItemReady" />
                              : <CloseCircle size="25" color="#273746" variant="Outline" />
                          }
                        </div>
                        <div>
                          <p style={{ fontSize: '12px' }}>{
                          textToLower(index?.freeText)}</p>
                        </div>
                      </div>
                    )
                  }
                })}

                {isItRoundTrip && additionalServices !== [] && additionalServices.map((index, key) => {
                  return (
                    <div key={key} className='warningFamilies' style={{
                      opacity: index?.serviceStatus === included ? 1 : 0.5,
                      alignItems: "center",
                      height: '35px',
                      overflow: "hidden"
                    }}>
                      <div className='ItemReady_img'>
                        {
                          index?.serviceStatus === included
                            ? <img src={ItemReady} alt="ItemReady" />
                            : <CloseCircle size="25" color="#273746" variant="Outline" />
                        }
                      </div>
                      <div>
                        <p style={{ fontSize: '12px' }}>{textToLower(index?.freeText)}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          {/* <div className='detailSelectedSegments  details-tittle'>
            <p>Más detalles</p>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default CardFamilies;
