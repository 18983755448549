import React, { useEffect, useState } from "react";
import "./styles.css";
import HotelSearchEngine from "../../HotelSearchEngine/HotelSearchEngine";
import FilterAsideHotels from "./FilterAsideHotels/FilterAsideHotels";
import UseHotels from "../../../../viewModels/hotels/UseHotels";
import { FaFilter } from "react-icons/fa6";

const AsideHotelResult = ({
  setFirstRange,
  setSecondRange,
  activeFilterPrice,
  setActiveFilterPrice,
  erroFilter,
  setResetResult,
  resetResult,
  setSelectedStars,
  services,
}) => {
  const [filterHotelModal, setFilterHotelModal] = useState(false);
  return (
    <>
      <button className="modalButtonFilterHotel" onClick={() => setFilterHotelModal(true)}>
        <FaFilter size={20} /> Filtrar hoteles
      </button>
      {services && services.length > 0 && (
        <div
          className={`${
            filterHotelModal
              ? "contain-aside-hotel-result"
              : "contain-aside-hotel-result close"
          }`}
          onClick={(e) => {
            if (e.currentTarget === e.target) {
              setFilterHotelModal(false);
            }
          }}
        >
          <FilterAsideHotels
            widthContain={"100%"}
            setSecondRange={setSecondRange}
            activeFilterPrice={activeFilterPrice}
            errorFilter={erroFilter}
            setActiveFilterPrice={setActiveFilterPrice}
            setFirstRange={setFirstRange}
            setResetResult={setResetResult}
            resetResult={resetResult}
            setSelectedStars={setSelectedStars}
            filterHotelModal={filterHotelModal}
            setFilterHotelModal={setFilterHotelModal}
          />
        </div>
      )}
    </>
  );
};

export default AsideHotelResult;
