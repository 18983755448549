import { useState, useEffect, useRef } from "react";
import "./styles.css";
import CarouselSevices from "../share/components/CarouselServices/CarouselSevices";
import HotelSearchEngine from "./HotelSearchEngine/HotelSearchEngine";
import { useTranslation } from "react-i18next";
import { get, keys } from "../../localStorage";
import UseHotels from "../../viewModels/hotels/UseHotels";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";
import NeedInformation from "../tourismHealth/needInformation/NeedInformation";
import question from "./question.svg";
import earphones from "./earphones.svg";
import Card1 from "./assets/card-medellin.jpeg";
import Card2 from "./assets/card-cartagena.jpeg";
import Card3 from "./assets/card-barranquilla.jpg";
import Card4 from "./assets/card-cali.jpeg";
import LastSearch from "../share/components/lastSearch/LastSearch";
import CityCarousel from "./components/CityCarousel/CityCarousel";

const Hotel = () => {
  const [t] = useTranslation("global");
  const [isShowingFind, setIsShowingFind] = useState(false);
  const [defaultHotel, setDefaultHotel] = useState(false);

  const smoothScrollTo = (targetPosition, duration) => {
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  const {
    cardRef,
    openHotelSearch,
    paceholders,
    search,
    setSearch,
    destiny,
    dateEntry,
    setDateEntry,
    datesSelected,
    setDatesSelected,
    cardRooms,
    setCardRooms,
    roomsSelected,
    setRoomsSelected,
    quantityPersons,
    openHotelSearchFuct,
    destinationSelectionFunc,
    searchHotelSelected,
    findCountryClient,
    nacionalityDropdown,
    setNacionalityDropdown,
    dataNacionality,
    selectedNacionality,
    nacionality,
    codeNacionality,
    loading,
    loadingSearch,
    validationForm,
    errApi,
    searchHotelNewApi,
  } = UseHotels();

  async function isWidgetLS() {
    try {
      const response = await get(keys?.isWidget);
      setIsShowingFind(response?.enable);
    } catch (error) {
      console.log("Error in isWidgetLS(): ", error);
    }
  }

  useEffect(() => {
    isWidgetLS();
  }, []);

  const btns = {
    need: {
      title: "¿Necesitas más Información?",
      textButton: "Conoce más",
      description:
        "Tienes dudas o preguntas sobre paquetes, vuelos, hoteles o algo relacionado con tu viaje da click aquí.",
      img: question,
    },
    agent: {
      title: "Mi Agente Wellezy, ¡compra con atención personalizada!",
      textButton: "Conocer canales",
      description:
        "Tenemos varios canales de atención para que planifiques tu viaje con la asesoría de nuestros agentes expertos.",
      img: earphones,
    },
  };

  const active = false;
  const url = "/";
  const disable = false;
  const item = {
    route: url,
    disable: disable,
  };

  const cities = [
    {
      name: "Medellin",
      code: "16288",
      img: Card1,
    },
    {
      name: "Cartagena De Indias",
      code: "12962",
      img: Card2,
    },
    {
      name: "Barranquilla",
      code: "17366",
      img: Card3,
    },
    {
      name: "Cali",
      code: "16286",
      img: Card4,
    },
    {
      name: "Medellin",
      code: "16288",
      img: Card1,
    },
    {
      name: "Cartagena De Indias",
      code: "12962",
      img: Card2,
    },
    {
      name: "Barranquilla",
      code: "17366",
      img: Card3,
    },
    {
      name: "Cali",
      code: "16286",
      img: Card4,
    },
  ];

  return (
    <div>
      {/* {!isShowingFind && <Find />} */}
      <CarouselSevices current={t("carousel_services.hotel")} />
      <HotelSearchEngine
        titleSize={"30px"}
        iconTitleSize={"50"}
        containDateFlexDirection={"row"}
        containDateJustify={"space-between"}
        layoutCampoWidth={"47%"}
        containFinishFlexDirection={"row"}
        containFinishJustify={"space-between"}
        layoutCampoVWidth={"47%"}
        cardRef={cardRef}
        cardRooms={cardRooms}
        codeNacionality={codeNacionality}
        dataNacionality={dataNacionality}
        dateEntry={dateEntry}
        datesSelectedEntry={datesSelected.entryDate}
        datesSelectedExit={datesSelected.exitDate}
        destinationSelectionFunc={destinationSelectionFunc}
        destiny={destiny}
        errApi={errApi}
        findCountryClient={findCountryClient}
        loading={loading}
        loadingSearch={loadingSearch}
        nacionality={nacionality}
        nacionalityDropdown={nacionalityDropdown}
        openHotelSearch={openHotelSearch}
        openHotelSearchFuct={openHotelSearchFuct}
        paceholders={paceholders}
        quantityPersons={quantityPersons}
        roomsSelected={roomsSelected}
        search={search}
        searchHotelSelected={searchHotelSelected}
        selectedNacionality={selectedNacionality}
        setCardRooms={setCardRooms}
        setDateEntry={setDateEntry}
        setDatesSelected={setDatesSelected}
        setNacionalityDropdown={setNacionalityDropdown}
        setRoomsSelected={setRoomsSelected}
        setSearch={setSearch}
        validationForm={validationForm}
        searchHotelNewApi={searchHotelNewApi}
        defaultHotel={defaultHotel}
        setDefaultHotel={setDefaultHotel}
      />

      <section
        className="homeSectionDoubleCardWrap"
        style={{
          marginBottom: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: 20,
          marginTop: 40,
        }}
      >
        <div className="hotels-destacs">
          <h2>HOTELES DESTACADOS</h2>

          <p>En Wellezy te traemos los hoteles con los mejores precios</p>
        </div>

        <div className="cards-hotels-destacs">
          <CityCarousel
            cities={cities}
            smoothScrollTo={smoothScrollTo}
            selectedNacionality={selectedNacionality}
            destinationSelectionFunc={destinationSelectionFunc}
            setDefaultHotel={setDefaultHotel}
            setDateEntry={setDateEntry}
          />
        </div>
      </section>

      <LastSearch
        description={"Sigue con tus busquedas previas y no pares de viajar"}
        title={"TUS ÚLTIMAS BÚSQUEDAS"}
      />

      <div
        className="layout-need-information-tourism-health"
        style={{ marginBottom: 30, marginTop: 50 }}
      >
        <NeedInformation
          description={btns.need.description}
          img={btns.need.img}
          title={btns.need.title}
          textButton={btns.need.textButton}
          color={"#104172"}
          active={true}
          url={"/contact"}
        />
      </div>
      <header className="header-hotel">
        {/* <div className="contain-banner-hotel">
          <img className="banner-img" src={banner} alt="" />
        </div> */}
      </header>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Hotel;
