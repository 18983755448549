import axios from "axios";
import { useState } from "react";

const useStep = () => {

    const [loading, setLoading] = useState(false);

    async function placeToPay(){
        try{
            setLoading(true);

            const response = await axios.post(`${process.env.REACT_APP_URL_API_AIOP}api/place-to-pay`, {
                "currency":"COP",
                "type":"GuidedShopping",
                "localizator":"3",
                "price":10000,
                "url":"http://localhost:3000/"
            })

            window.location.href = response.data.processUrl;
        }catch(error){
            console.log(error)
        }finally{
            setLoading(false);
        }
    };

    return {
        placeToPay,
        loading
    };
}

export default useStep;