import React from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";

const CardPackages = ({ data }) => {
  function removeHtmlTags(inputText) {
    // Expresión regular para buscar etiquetas HTML
    const regex = /<[^>]+>/g;

    // Reemplazar las etiquetas HTML con una cadena vacía
    const cleanedText = inputText.replace(regex, "");

    return cleanedText;
  }

  const navigate = useNavigate();

  function generateUniqueId() {
    return "xxxxx-xxxx-4xxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const detailPackages = (
    name,
    id,
    image,
    stars,
    description,
    price,
    address
  ) => {
    navigate(`/packages/${generateUniqueId()}`, {
      state: {
        name: name,
        id: id,
        urlImage: "",
        pathImage: image,
        stars: stars,
        description: description,
        price: price,
        address: address,
      },
    });
  };

  return (
    <div className="card-traslado">
      <div
        className="layout-card-traslado content-img"
        style={{ padding: "0" }}
      >
        {/* <img src={`${'https://staging.aiop.com.co/storage/'}${data.image}`} alt={data.name} /> */}
        <img
          className="img-pack-results"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNKFR_B_ei0w1yS7xhfaA6RGLrpNoullRC8g&usqp=CAU"
          alt={data?.name}
        />
      </div>
      <div className="layout-card-traslado-second">
        <div className="content-info information-car">
          <h3>{data?.name}</h3>
          <p className="city-pack-result">{data?.city?.name}</p>
          <div className="content-information-p" style={{ margin: "0.5rem" }}>
            <p className="description-card-packs-results">
              {removeHtmlTags(data?.description)}
            </p>
          </div>
        </div>
        <div className=" price-card">
          <div className="price-content">
            <span>${(data?.price + data?.price * 0.2).toFixed(1)}</span>
            <h3 style={{ fontSize: "15px" }}>${data?.price.toFixed(1)} COP</h3>
          </div>
          <div className="content-reserva">
            <button>Reservar Ahora</button>
          </div>
          <div className="more-details">
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                detailPackages(
                  data?.name,
                  data?.name,
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNKFR_B_ei0w1yS7xhfaA6RGLrpNoullRC8g&usqp=CAU",
                  "4",
                  removeHtmlTags(data?.description),
                  data?.price,
                  data.city.name
                );
              }}
            >
              Más detalles...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPackages;
