import "./assets/styles.css";

import banner from "./assets/img/banner.jpg";
import leters from "./assets/img/letters.png";
import Find from "../../ui/views/share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../../ui/views/share/components/CarouselServices/CarouselSevices";
import personPhone2 from "../../ui/assets/pictures/girlFilgth.svg";
import personPhone from "../../ui/assets/pictures/menFligth.svg";
import SearchFlights from "../../ui/views/flights/search.flights.view";
import AnyQuestions from "../../ui/views/home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import useSearchFlights from "../../ui/viewModels/flights/search.flights.viewModel";
import CardRegisterNow from "../../ui/views/home/components/cardRegisterNow";
import { useEffect, useState } from "react";

import CardOne from "./assets/card-1.png";
import CardTwo from "./assets/card-2.png";

import CardPlaceOne from "./assets/img/card-place-1.png";
import CardPlaceTwo from "./assets/img/card-place-2.png";
import CardPlaceThirteen from "./assets/img/card-place-3.png";
import CardPlaceFour from "./assets/img/card-place-4.png";

import CardThird from "../../assets/images/home/cardPackageTurism3.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NeedInformation from "../../ui/views/tourismHealth/needInformation/NeedInformation";
import question from "./question.svg";
import earphones from "./earphones.svg";
import { Link } from "react-router-dom";
import DisabledModal from "../../ui/views/share/components/disabledModal/disabledModal";
import ReturnPage from "../../ui/views/share/components/return/ReturnPage";
import LastSearch from "../../ui/views/share/components/lastSearch/LastSearch";

import Card1 from "./assets/img/card-bogota.jpeg";
import Card2 from "./assets/img/card-medellin.jpeg";
import Card3 from "./assets/img/card-cali.jpeg";
import Card4 from "./assets/img/card-cartagena.jpeg";
import Card5 from "./assets/img/card-barranquilla.jpg";
import FlightsCarousel from "./components/FlightsCarousel/FlightsCarousel";

const FligthsNew = ({ isWidget = false }) => {
  const [t, i18n] = useTranslation("global");

  const isIframe = false;

  const [checkDate, setCheckDate] = useState(false);
  const [flightDefault, setFlightDefault] = useState(false);

  const cards = {
    card_1: {
      url: "/packages",
      typeUser: "serviceProvider",
      img: CardOne,
      title: "Vuelos en Colombia",
      description:
        "Aprovecha y disfruta de los mejores precios que trae Wellezy para ti",
      button: t("browse_home.cards_packages.card_1.button"),
      more_info: t("browse_home.cards_packages.card_1.more_info"),
      action: () => {},
    },

    card_2: {
      url: "/packagesType?type=colombia",
      typeUser: "customer",
      img: CardTwo,
      title: "Vuelos por el Mundo",
      description:
        "Aprovecha y disfruta de los mejores precios que trae Wellezy para ti",
      button: t("browse_home.cards_packages.card_2.button"),
      more_info: t("browse_home.cards_packages.card_2.more_info"),
      action: () => {},
    },
  };

  const cities = [
    {
      name: "Bogotá",
      img: Card1,
      nameAirport: "El Nuevo Dorado International",
      codeIataAirport: "BOG",
    },
    {
      name: "Medellín",
      img: Card2,
      nameAirport: "Aeropuerto internacional Jose Maria Cordova",
      codeIataAirport: "MDE",
    },
    {
      name: "Cali",
      img: Card3,
      nameAirport: "Alfonso B. Aragon",
      codeIataAirport: "CLO",
    },
    {
      name: "Cartagena",
      img: Card4,
      nameAirport: "Rafael Nunez",
      codeIataAirport: "CTG",
    },
    {
      name: "Barranquilla",
      img: Card5,
      nameAirport: "E Cortissoz",
      codeIataAirport: "BAQ",
    },
    {
      name: "Santa Marta",
      img: CardPlaceOne,
      nameAirport: "Simon Bolivar",
      codeIataAirport: "SMR",
    },
    {
      name: "San Andrés",
      img: CardPlaceOne,
      nameAirport: "San Andres Island",
      codeIataAirport: "ADZ",
    },
    {
      name: "Pereira",
      img: CardPlaceOne,
      nameAirport: "Matecana",
      codeIataAirport: "PEI",
    },
    {
      name: "Bucaramanga",
      img: CardPlaceOne,
      nameAirport: "Palo Negro",
      codeIataAirport: "BGA",
    },
    {
      name: "Leticia",
      img: CardPlaceOne,
      nameAirport: "Gen. A.V. Cobo",
      codeIataAirport: "LET",
    },
    {
      name: "Manizales",
      img: CardPlaceOne,
      nameAirport: "Santaguida",
      codeIataAirport: "MZL",
    },
    {
      name: "Villavicencio",
      img: CardPlaceOne,
      nameAirport: "La Vanguardia",
      codeIataAirport: "VVC",
    },
  ];

  const [citiSelected, setCitiSelected] = useState(cities[0]);

  const filteredCities = cities
    .filter((city) => city.name !== citiSelected.name)
    .slice(0, 4);

  const smoothScrollTo = (targetPosition, duration) => {
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  const [activeCard, setActiveCard] = useState(1); // Estado para la tarjeta activa

  const handleClick = (type) => {
    setActiveCard(type); // Cambia la tarjeta activa cuando se hace clic
  };

  const {
    cardRefPlace,
    cardRefPassenger,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    buttonDisable,
    searcherFlight,

    //ciudades
    //ida
    isCardRefOrigin,
    setIsCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,

    //retorno
    isCardRefDestination,
    setIsCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    //fechas
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    isCardRefDate,
    setIsCardRefDate,
    setDateGoingSelectedApi,
    setDateReturnSelectedApi,

    //pasajeros
    isManagingPassengers,
    setIsManagingPassengers,
    passengersSelecteds,
    getPassengersSelecteds,
    ellipsisText,
    setPassengersSelecteds,

    //Validation
    validationSearchDestiny,
    setValidationSearchDestiny,
    validationSearchDate,
  } = useSearchFlights(isIframe);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1354,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  useEffect(() => {
    const cardsSlides = document.querySelectorAll(
      ".homeSectionDoubleCardWrap .slick-slide"
    );
    const cardSlideTrack = document.querySelector(
      ".homeSectionDoubleCardWrap .slick-track"
    );
    const body = document.querySelector("body");
    cardsSlides.forEach((item, index) => {
      if (index + 1 === activeCard) {
        item.classList.add("active-slide");
        item.classList.remove("inactive-slide");
        if (activeCard === 3) {
          if (body.offsetWidth < 1100 && body.offsetWidth > 900) {
            cardSlideTrack.style.transform = "translate3d(-300px, 0px, 0px)";
          } else if (body.offsetWidth < 900 && body.offsetWidth > 700) {
            cardSlideTrack.style.transform = "translate3d(-200px, 0px, 0px)";
          }
        } else if (activeCard === 2) {
          if (body.offsetWidth < 1100 && body.offsetWidth > 900) {
            cardSlideTrack.style.transform = "translate3d(-200px, 0px, 0px)";
          } else if (body.offsetWidth < 900 && body.offsetWidth > 700) {
            cardSlideTrack.style.transform = "translate3d(-100px, 0px, 0px)";
          }
        } else {
          cardSlideTrack.style.transform = "translate3d(0px, 0px, 0px)";
        }
      } else {
        item.classList.add("inactive-slide");
        item.classList.remove("active-slide");
      }
    });
  }, [activeCard]);

  const btns = {
    need: {
      title: "¿Necesitas más Información?",
      textButton: "Conoce más",
      description:
        "Tienes dudas o preguntas sobre paquetes, vuelos, hoteles o algo relacionado con tu viaje da click aquí.",
      img: question,
    },
    agent: {
      title: "Mi Agente Wellezy, ¡compra con atención personalizada!",
      textButton: "Conocer canales",
      description:
        "Tenemos varios canales de atención para que planifiques tu viaje con la asesoría de nuestros agentes expertos.",
      img: earphones,
    },
  };

  const active = true;
  const url = "/";
  const disable = true;
  const item = {
    route: url,
    disable: disable,
  };

  return (
    <div>
      {/* {!isWidget && <Find />} */}
      <CarouselSevices current={t("carousel_services.fligth")} />
      <header className="header-fligths">
        <div className="content-search-fli">
          <SearchFlights
            accompanied={false}
            buttonDisable={buttonDisable}
            cardRefDate={cardRefDate}
            cardRefPassenger={cardRefPassenger}
            cardRefPlace={cardRefPlace}
            dateGoingSelected={dateGoingSelected}
            dateReturnSelected={dateReturnSelected}
            ellipsisText={ellipsisText}
            getPassengersSelecteds={getPassengersSelecteds}
            getSelectedDestination={getSelectedDestination}
            getSelectedOrigin={getSelectedOrigin}
            isCardRefDate={isCardRefDate}
            isCardRefDestination={isCardRefDestination}
            isCardRefOrigin={isCardRefOrigin}
            isManagingPassengers={isManagingPassengers}
            isRoundTrip={isRoundTrip}
            passengersSelecteds={passengersSelecteds}
            searcherFlight={searcherFlight}
            selectedDestination={selectedDestination}
            selectedOrigin={selectedOrigin}
            setDateGoingSelected={setDateGoingSelected}
            setDateGoingSelectedApi={setDateGoingSelectedApi}
            setDateReturnSelected={setDateReturnSelected}
            setDateReturnSelectedApi={setDateReturnSelectedApi}
            checkDate={checkDate}
            setCheckDate={setCheckDate}
            setFlightDefault={setFlightDefault}
            flightDefault={flightDefault}
            setIsCardRefDate={setIsCardRefDate}
            setIsCardRefDestination={setIsCardRefDestination}
            setIsCardRefOrigin={setIsCardRefOrigin}
            setIsManagingPassengers={setIsManagingPassengers}
            setIsRoundTrip={setIsRoundTrip}
            validationSearchDestiny={validationSearchDestiny}
            setValidationSearchDestiny={setValidationSearchDestiny}
            setPass={setPassengersSelecteds}
            validationSearchDate={validationSearchDate}
          />
        </div>
      </header>

      <section
        className="homeSectionDoubleCardWrap"
        style={{
          marginBottom: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: 20,
          marginTop: 40,
        }}
      >
        <div className="places-destacs">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <h2>LUGARES DESTACADOS DESDE</h2>
            <p>
              Viaja por el mundo, aquí te dejamos algunos de los lugares más
              llamativos para que empieces a viajar
            </p>
          </div>

          <div className="select-cities">
            <select
              onChange={(e) => setCitiSelected(cities[e.target.value])}
              name=""
              id=""
            >
              {cities.map((city, index) => (
                <option key={index} value={index}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <FlightsCarousel
          cities={filteredCities}
          smoothScrollTo={smoothScrollTo}
          selectedOrigin={selectedOrigin}
          getSelectedOrigin={getSelectedOrigin}
          citySelected={citiSelected}
          getSelectedDestination={getSelectedDestination}
          setIsCardRefDate={setIsCardRefDate}
          setIsRoundTrip={setIsRoundTrip}
          setCheckDate={setCheckDate}
          setFlightDefault={setFlightDefault}
          citiSelected={citiSelected}
        />
      </section>

      <LastSearch
        description={"Sigue con tus busquedas previas y no pares de viajar"}
        title={"TUS ÚLTIMAS BÚSQUEDAS"}
      />

      <div
        className="layout-need-information-tourism-health"
        style={{ margin: "1rem auto", marginBottom: 40, marginTop: "40px" }}
      >
        <NeedInformation
          description={btns.need.description}
          img={btns.need.img}
          title={btns.need.title}
          textButton={btns.need.textButton}
          color={"var(--blueSecundary)"}
          active={true}
          url={"/contact"}
        />
      </div>
      {/* <section className="option-traveling">
        <div className="card-option-traveling">
          <div className="card-option-traveling-img">
            <img className="img1" src={personPhone2} alt="imagen1" />
          </div>
          <div className="card-option-traveling-info">
            <h3>{t("fligth.cards.travel_colombia")}</h3>
            <p>{t("fligth.cards.text_colombia")}</p>
            <button>{t("fligth.cards.see_offers")}</button>
          </div>
        </div>
        <div className="card-option-traveling">
          <div className="card-option-traveling-img">
            <img className="img2" src={personPhone} alt="imagen" />
          </div>
          <div className="card-option-traveling-info">
            <h3>{t("fligth.cards.travel_world")}</h3>
            <p>{t("fligth.cards.text_world")}</p>
            <button>{t("fligth.cards.see_offers")}</button>
          </div>
        </div>
      </section> */}
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default FligthsNew;
