import React, { useEffect, useState } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG/index";
import { currencyFormat, formatToCOP } from "../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";

import { widgetsList } from "../../../../../infra/constants/app";

import { useTranslation } from "react-i18next";
import Loading from "../../../share/components/loading";
import { MdCameraAlt } from "react-icons/md";
import ModalCardDetail from "./components/ModalCardDetail";
import { IoLocationOutline } from "react-icons/io5";
import TextComponent from "../../../share/components/fonts/TextComponent";
import { PiCoffee, PiFireFill } from "react-icons/pi";
import Tooltip from "@/Components/Tooltip/Tooltip";

const CardHotelResult = ({
  data,
  dataRoom,
  detailReservation,
  persons,
  fen,
  fsa,
  rooms,
  country_client,
  currentPage,
  bestOffer
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [t] = useTranslation("global");
  const [dataHot, setDataHot] = useState([]);
  const [loadingViewRoom, setLoadingViewRoom] = useState(false);
  const stars = [];
  const [informationDetail, setInformationDetail] = useState([]);
  const routeStartsWithBuildPackageHotel = location.pathname.startsWith(
    "/build-package/hotel"
  );

  // Crear un objeto URL
  const match = pathname.match(/idHotel=(\d+)/);

  // Verificar si hubo una coincidencia y obtener el valor de idHotel
  const idHotel = match ? match[1] : "";

  for (let i = 0; i < Number(data?.cat); i++) {
    stars.push(
      <SvgIcon key={i} name={iconList.IconStar} color={"#FFD902"} size={20} />
    );
  }

  function formatterText(frase) {
    // Dividir la frase en palabras individuales
    let palabras = frase?.toLowerCase()?.split(" ");

    // Capitalizar la primera letra de cada palabra
    for (let i = 0; i < palabras?.length; i++) {
      palabras[i] =
        palabras[i]?.charAt(0)?.toUpperCase() + palabras[i]?.slice(1);
    }

    // Unir las palabras en una sola frase
    let resultado = palabras?.join(" ");

    return resultado;
  }


  const findRoomPrice = data?.res?.pax[0]?.hab?.reduce((minObjeto, objeto) => {
    if (
      objeto?.reg[0]["@attributes"]?.prr < minObjeto?.reg[0]["@attributes"]?.prr
    ) {
      return objeto;
    } else {
      return minObjeto;
    }
  });

  function sumPorcentage(num) {
    const numero = Number(num);
    const porcentaje = numero * 0.35;
    const resultado = numero + porcentaje;
    const resultadoRedondeado = Math.round(resultado * 100) / 100;
    return formatToCOP(resultadoRedondeado.toString());
  }

  function sumPorcentageV2(num) {
    const numero = Number(num);
    const porcentaje = numero * 0.35;
    const resultado = numero + porcentaje;
    const resultadoRedondeado = Math.round(resultado * 100) / 100;
    return resultadoRedondeado;
  }

  function calcularPorcentaje(valorActual, valorOriginal) {
    // Convertir valorActual a número usando parseFloat
    let newValorActual = parseFloat(valorActual);
    let newValorOriginal = parseFloat(valorOriginal);

    // Calcular el porcentaje de descuento
    const descuento = newValorOriginal - newValorActual;
    const porcentajeDescuento = (descuento / newValorOriginal) * 100;

    // Redondear el resultado y devolverlo como un número entero
    const porcentajeRedondeado = Math.round(porcentajeDescuento);

    return porcentajeRedondeado;
  }

  // const getInformation = () => {
  //   setLoadingViewRoom(true)
  //   if (idHotel === "") {
  //     console.log("id vacio")
  //   } else {
  //     axios.get("https://travelflight.pdtcomunicaciones.com/api/hotel-do/hotel-information", {
  //       params: {
  //         "hotel": data.Id
  //       }
  //     })
  //       .then(res => {
  //         console.log(res.data)
  //         setLoadingViewRoom(false)
  //         setInformationDetail(res.data.Hotel)

  //       })
  //       .catch(err => {
  //         console.log(err)
  //         setLoadingViewRoom(false)
  //       })
  //   }
  // }

  // const dataRequest = {
  //   "arrival": dataRoom?.arrival,
  //   "departure": dataRoom?.departure,
  //   "country": "CO",
  //   "countryClient": "CO",
  //   "hotels":
  //     [

  //       {

  //         "code": informationDetail?.Id

  //       }

  //     ],
  //   "rooms": dataRoom?.rooms
  // }

  const detailHotelView = () => {
    setLoadingViewRoom(true);
    axios
      .post(`${process.env.REACT_APP_URL_API_FLY}restel/information`, {
        hotel: data?.cod,
      })
      .then((res) => {
        setDataHot(res.data?.hotel.parametros.hotel);
        setLoadingViewRoom(false);
        const isWidget = widgetsList.find(
          (w) => w.path === window.location.pathname
        );
        let url = "";
        if (routeStartsWithBuildPackageHotel) {
          url = "/build-package/room/";
        } else if (isWidget) {
          url = `/widget/hotels/`;
        } else if (pathname.includes("packages")) {
          url = "/packages/hotel/";
        } else {
          url = "/hotel/";
        }

        navigate(`${url}${data.nom}`, {
          state: {
            dataHab: data,
            dataHot: res?.data?.hotel?.parametros?.hotel,
            persons: persons,
            fen: fen,
            fsa: fsa,
            rooms: rooms,
            country_client: country_client,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [loading, setLoading] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [loadingCurrentPage, setLoadingCurrentPage] = useState(false);

  useEffect(() => {
    if (data?.foto) {
      const img = new Image();
      img.src = data.foto;
      img.onload = () => {
        setLoading(false);
        setTimeout(() => {
          setShowImage(true);
        }, 500);
      };
      img.onerror = () => {
        setLoading(true);
        setShowImage(false);
      };
    } else {
      setLoading(true);
      setShowImage(false);
    }
  }, [data?.foto]);

  useEffect(() => {
    setLoadingCurrentPage(false);
    setTimeout(() => {
      setLoadingCurrentPage(true);
    }, 1000);
  }, [currentPage]);

  const [modalCardDetail, setModalDetail] = useState(false);

  return (
    <div className="card-result-hotel">
      {bestOffer && <div className="best-offer-label">
        <Tooltip content="Este hotel es más económico <br/> en relación a otros similares.">
          <div className="best-offer-label-content">

            <PiFireFill size={30} />

            <div>

              <h4>¡Oferta Recomendada!</h4>

              <p>Aprovecha nuestras tarifas especiales y vive una experiencia única</p>
            </div>


          </div>
        </Tooltip>
      </div>}
      <div className="contain-img-hotel-result">
        {loading || !data?.foto ? (
          <div className="img-hotel-skeleton">
            <MdCameraAlt size={40} color="#c8c8c8" />
          </div>
        ) : loadingCurrentPage ? (
          <img className="img-hotel-result" src={data?.foto} alt="" />
        ) : (
          <div className="img-hotel-skeleton">
            <MdCameraAlt size={40} color="#c8c8c8" />
          </div>
        )}
        <div className="service-room-include">
          {/* <SvgIcon name={iconList.IconFood} color={"#ffffff"} size={30} /> */}

          <PiCoffee size={18} />

          {findRoomPrice.reg[0]["@attributes"].cod}
        </div>
      </div>

      <div className="contain-info-result-hotels">
        <section className="contain-info-hotel-card">
          <TextComponent children={data?.nom} fontWeight="bold" size={"lg"} tag="h2" />
          <div>{stars}</div>
          {/* <div className="star-hotel-card">
            <span>{Number(data?.cat).toFixed(1)}</span>
            
          </div> */}
          <div className="card-hotel-habitacion">
            <TextComponent className={"dir-hotel-card"} size={"md2"}>
              <IoLocationOutline color={"#004274"} size={22} />
              {formatterText(data?.dir)}
            </TextComponent>

            <TextComponent size={"md2"}>
              <SvgIcon
                name={iconList.IconRestRoom}
                color={"#004274"}
                size={30}
              />
              {findRoomPrice["@attributes"].desc}
            </TextComponent>
          </div>
          <div className="contain-porcentage-desc">
            <TextComponent size={"md"} fontWeight="bold" tag="span">
              -
              {calcularPorcentaje(
                findRoomPrice.reg[0]["@attributes"].prr,
                sumPorcentageV2(findRoomPrice.reg[0]["@attributes"].prr)
              )}
              %
            </TextComponent>
          </div>
        </section>
        <section className="contain-price-hotel-card">
          <div>
            <div className="">
              <TextComponent className="price-descount-hotel-card" fontWeight="bold" size={"lg"}>
                {formatToCOP(findRoomPrice.reg[0]["@attributes"].prr)} COP
              </TextComponent>
              <TextComponent className="price-normal-hotel-card" size={"md"}>
                {sumPorcentage(findRoomPrice.reg[0]["@attributes"].prr)} COP
              </TextComponent>
            </div>

            <TextComponent className="taza-impuestos" size={"sm"}>{t("hotel.include_taxes")}</TextComponent>
          </div>

          <ModalCardDetail
            data={data}
            include={findRoomPrice.reg[0]["@attributes"].cod}
            more={findRoomPrice["@attributes"].desc}
            total={formatToCOP(findRoomPrice.reg[0]["@attributes"].prr)}
            modalCardDetail={modalCardDetail}
            setModalDetail={setModalDetail}
          />
          <div className="detail-button">
            <TextComponent onclick={{
              voidP: (parameters) => setModalDetail(parameters),
              parameters: true
            }} className="what-include" size={"md2"}>
              {t("hotel.what_include_price")}
            </TextComponent>

            <button
              className="button-detail-habitaciones"
              onClick={detailHotelView}
              style={{
                PointerEvent: loadingViewRoom ? "none" : "auto",
                opacity: loadingViewRoom ? 0.5 : 1,
              }}
            >
              {loadingViewRoom === true ? (
                <CircularProgress size={25} />
              ) : (
                t("hotel.see_habitation")
              )}
            </button>
          </div>
        </section>
      </div>
    </div>
    // <div className='card-result-hotel'>
    //   {
    //     loadingViewRoom ?
    //       <Loading size={100} />

    //       :
    //       <>
    //         <div className='contain-porcentage-desc'>
    //           <span>
    //             -35%
    //           </span>
    //         </div>
    //         <div className='contain-img-hotel-result'>
    //           <img className='img-hotel-result' src={informationDetail?.ThumbnailUrl} alt="" />
    //           <div className="service-room-include">
    //             <SvgIcon name={iconList.IconFood} color={'#ffffff'} size={30} />
    //             <span>{findRoomPrice?.reg[0]["@attributes"]?.cod}</span>
    //           </div>
    //         </div>

    //         <div className='contain-info-result-hotels'>
    //           <section className='contain-info-hotel-card'>

    //             <h2>{data?.Name}</h2>
    //             <p className='dir-hotel-card'>{formatterText(informationDetail?.Address?.Street)}</p>
    //             <div className='star-hotel-card'>
    //               <ul>
    //                 <li><span>Type:</span> {informationDetail?.AccommodationType}</li>
    //                 <li><span>Check-in:</span> {informationDetail?.CheckIn}</li>
    //                 <li><span>Check-out:</span> {informationDetail?.CheckOut}</li>
    //               </ul>
    //             </div>
    //             <div className='card-hotel-habitacion'>
    //               <p >
    //                 <SvgIcon name={iconList.IconRestRoom} color={'#004274'} size={30} />
    //                 {informationDetail?.TotalRooms} Total rooms
    //               </p>
    //             </div>
    //           </section>
    //           <section className='contain-price-hotel-card'>
    //             <div>
    //               <ul>
    //                 <li className='ellisisp-text'><span>Country:</span> {informationDetail?.Address?.Country?.Name}</li>
    //                 <li className='ellisisp-text'><span>City:</span> {informationDetail?.Address?.City?.Name}</li>
    //                 <li><span>Adults-only:</span> {informationDetail?.AdultOnly ? "Yes" : "No"}</li>
    //               </ul>

    //               <p className='short-description-hotel'>{informationDetail?.ShortDescription}</p>
    //             </div>
    //             <div className='button-detail-habitaciones'>
    //               <button onClick={() => {
    //                 detailReservation()
    //               }}>{
    //                   loadingViewRoom ?
    //                     <CircularProgress size={20} />

    //                     :

    //                     t("hotel.see_habitation")
    //                 }
    //               </button>
    //             </div>
    //           </section>
    //         </div>
    //       </>

    //   }

    // </div>
  );
};

export default CardHotelResult;
