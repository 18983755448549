import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';

const usePnrFlight = () => {

    const [errorReservation, setErrorReservation] = useState("")
    const [loadingPnr, setLoadingPnr] = useState(false)
    const [travellers, setTravellers] = useState([])
    const persons = []
    const childs = []
    const babys = []
    const [typeFlight, setTypeFlight] = useState("")
    const arrival = localStorage.getItem("iata-destiny")
    const departure = localStorage.getItem("iata-origin")
    const { state } = useLocation()
    const navigate = useNavigate();
    const [saveInfo, setSaveInfo] = useState(false)

    const volverPaginaAnterior = () => {
        navigate(-1);
    };

    const notify = (title, icon) =>
        toast(title, {
            icon: icon,
        });

    const cantPass = state?.prices.filter(item => item.type !== "INF")

    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad?.toLocaleString("es", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return cantidadFormateada;
    }


    const buildFree = (date, sex) => {
        // Separar la fecha en año, mes y día
        const [year, month, day] = date?.split('-');

        // Array con los nombres de los meses abreviados
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        // Obtener la abreviatura del mes según el número de mes
        const monthAbbr = months[parseInt(month) - 1];

        // Obtener los últimos dos dígitos del año
        const yearAbbr = year?.slice(-2);

        // Concatenar los componentes para formar el formato deseado
        const formattedDate = `----${day}${monthAbbr}${yearAbbr}-${sex}--`;

        return formattedDate;
    };

    const buildFech = (date) => {
        // Separar la fecha en año, mes y día
        const [year, month, day] = date?.split('-');

        // Array con los nombres de los meses abreviados
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        // Obtener la abreviatura del mes según el número de mes
        const monthAbbr = months[parseInt(month) - 1];

        // Obtener los últimos dos dígitos del año
        const yearAbbr = year?.slice(-2);

        // Concatenar los componentes para formar el formato deseado
        const formattedDate = `${day}${monthAbbr}${yearAbbr}`;

        return formattedDate;
    };

    const buildTravellers = () => {
        const travellersNew = []
        travellers.forEach((item, key) => {
            if (item?.email) {
                travellersNew.push({
                    number: key + 1,
                    free: buildFree(item?.birth, item?.sex),
                    name: item?.name,
                    lastName: item?.lastname,
                    passengerType: item?.adult,
                    type: item?.typeDoc,
                    document: item?.doc,
                    expiration: typeFlight === false ? "" : buildFech(item?.expiration),
                    birthDay: buildFech(item?.birth),
                    sex: item?.sex,
                    country: item?.country
                })

            } else if (item.child) {
               
                travellersNew.push({
                    number: key + 1,
                    free: buildFree(item?.birth, item?.sex),
                    birth: item?.birth,
                    name: item?.name,
                    lastName: item?.lastname,
                    passengerType: item?.child,
                    type: item?.typeDoc,
                    document: item?.doc,
                    expiration: typeFlight === false  ? "" : buildFech(item?.expiration),
                    birthDay: buildFech(item?.birth),
                    sex: item?.sex,
                    country: item?.country
                })
            } else if (item?.seat) {
              
                travellersNew.push({
                    number: key + 1,
                    free: buildFree(item?.birth, item?.sex),
                    name: item?.name,
                    lastName: item?.lastname,
                    passengerType: item?.seat,
                    birth: item?.birth,
                    offspring: item.offspring === "true" ? true : false,
                    type: item?.typeDoc,
                    document: item?.doc,
                    expiration: typeFlight === false ? "" : buildFech(item?.expiration),
                    birthDay: buildFech(item?.birth),
                    sex: item?.sex,
                    country: item?.country
                })
            } else if (item?.baby) {

                for (let index = 0; index < travellersNew.length; index++) {
                    // Verificar si es un adulto y no tiene un bebé asignado

                    if (travellersNew[index].passengerType === "ADT" && !travellersNew[index].baby) {

                        const newItem = {
                            number: travellersNew[index].number,
                            free: buildFree(item?.birth, item?.sex),
                            name: item?.name,
                            lastName: item?.lastname,
                            birth: item?.birth,
                            offspring: item?.offspring === "true" ? true : false,
                            type: item?.typeDoc,
                            document: item?.doc,
                            expiration: typeFlight === false ? "" : buildFech(item?.expiration),
                            birthDay: buildFech(item?.birth),
                            sex: item?.sex,
                            country: item?.country
                        };
                        // Asignar el bebé al pasajero y retornar
                        travellersNew[index].baby = newItem
                        break;
                    }
                }
            } else {
                travellersNew.push({
                    number: key + 1,
                    free: buildFree(item?.birth, item?.sex),
                    // typeDocument: item?.typeDoc,
                    name: item?.name,
                    lastName: item?.lastname,
                    passengerType: item?.adult,
                    type: item?.typeDoc,
                    document: item?.doc,
                    expiration: typeFlight === false ? "" : buildFech(item?.expiration),
                    birthDay: buildFech(item?.birth),
                    sex: item?.sex,
                    country: item?.country
                })
            }
        });
        return travellersNew;
    };





    const savePNRN = () => {
        setLoadingPnr(true)
        const url = `${process.env.REACT_APP_URL_API_FLY}savepnr`;
        if (!travellers[0]?.email || travellers.length < state?.passengersSelected?.adult + state?.passengersSelected?.boy + state?.passengersSelected?.seat || !travellers[0]?.tel) {
            notify("Datos incompletos para procesar la solicitud", "❌")
            setLoadingPnr(false)
        } else {
            const requestPNR = {
                "company": state?.company,
                "origin": "wellezy",
                "bag": state?.bag,
                "fare": state?.fare,
                "international": typeFlight,
                "tel": travellers[0]?.tel,
                "email": travellers[0]?.email?.replace("@", "//"),
                "emailTwo": travellers[0]?.email,
                "travellers": buildTravellers(),
                "itinerary": state?.itinerary

            }
            axios.post(url, requestPNR)
                .then(res => {

                    setLoadingPnr(false)
                    navigate("/flight/methodPay", {
                        state: {
                            requestPNR: requestPNR,
                            controlNumberPNR: res.data?.reserve?.control_number,
                            priceTotal: state?.priceTotal,
                            priceTaxes: state?.priceTaxes,
                            priceWithOutTaxes: state?.priceWithOutTaxes,
                            currency: state?.currency
                        }
                    })
                })
                .catch(err => {
                    setErrorReservation("err.response.data.error[0].text")
                    notify(err.response.data.error[0].text, "❌")
                    setLoadingPnr(false)
                })
        }
    }


    function checkTypeFlight() {
        setLoadingPnr(true)
        const url = `${process.env.REACT_APP_URL_API_FLY}airport/iata`;
        axios.post(url, {
            
            departure: departure,
            arrival: arrival
        })
            .then(res => {
                setLoadingPnr(false)
                setTypeFlight(res.data?.international)
            })
            .catch(err => {
                console.log(err)
                setLoadingPnr(false)
                notify("Error en la verificacion de vuelo", "❌")
            })
    }

    useEffect(() => {
        checkTypeFlight()
    }, [])


    return {
        savePNRN,
        formatearCantidad,
        volverPaginaAnterior,
        state,
        persons,
        childs,
        loadingPnr,
        babys,
        errorReservation,
        buildTravellers,
        travellers,
        setTravellers,
        typeFlight,
        saveInfo,
        setSaveInfo
    }
}

export default usePnrFlight