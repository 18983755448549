import React, { useEffect, useMemo, useRef } from "react";
import { useParticipant } from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import styles from "./styles.module.css";

function ParticipantView({ participantId }) {
  const micRef = useRef(null);
  
  const {
    webcamStream,
    micStream,
    webcamOn,
    micOn,
    isLocal,
    displayName,
  } = useParticipant(participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream([webcamStream.track]);
      return mediaStream;
    }
    return null;
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current && micOn && micStream) {
      const mediaStream = new MediaStream([micStream.track]);
      micRef.current.srcObject = mediaStream;
      micRef.current.play().catch(error => {
        console.error("Error playing mic stream:", error);
      });
    } else if (micRef.current) {
      micRef.current.srcObject = null;
    }
  }, [micStream, micOn]);

  const renderInitial = displayName?.charAt(0) || "";

  return (
    <div className={styles.participant}>
      <audio ref={micRef} autoPlay playsInline muted={isLocal} />

      {webcamOn ? (
          <ReactPlayer
          playsinline
          width={null}
          height={null}
          muted={isLocal}
          playing
          url={videoStream}
          onError={(err) => console.log("Video player error:", err)}
        />
   
      ) : (
        <div className={styles.participantAvatar}>
          <p className={styles.participantAvatarText}>{renderInitial}</p>
        </div>
      )}

      <div className={styles.participantInfo}>
        <p>{displayName}</p>
      </div>
    </div>
  );
}

export default ParticipantView;
