import React from 'react'
import "./styles.css"
import SvgIcon, { iconList } from '../../../share/components/iconSVG'


const RejectPay = ({requestSuccess}) => {

  
  function obtenerNumero(ticke) {

    const numero = ticke.split("/")[0];

    return numero;
  }
  function formatearFecha(fechaISO) {
    // Crear un objeto de fecha a partir de la cadena ISO
    const fecha = new Date(fechaISO);

    // Obtener los componentes de la fecha y hora
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear();
    const hora = fecha.getHours().toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');

    // Formatear la fecha y hora según el formato deseado
    const fechaFormateada = `${dia}-${mes}-${año} ${hora}:${minutos}`;

    return fechaFormateada;
  }

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return cantidadFormateada;
}

  return (
    <div className='content-pay-rejected-flight'>
      <h3 className='letter-green'><SvgIcon color={"rgb(141, 30, 30)"} name={iconList.IconCloseCircleOutLine} size={35} /> Compra Rechazada Intenta de nuevo</h3>
      <p style={{
        lineHeight:"20px"
      }}>
        Su transacción {requestSuccess?.pay?.request_id} fue rechazada  por la entidad financiera. En caso de tener algun problema, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a compras@wellezy.com
      </p>
      {/* <table className='table-info-tickets'>
        <tr>
          <th>#</th>
          <th>Ticket</th>
        </tr>
        {
          requestSuccess?.reserve?.tickets?.map((item, key) => {
            return (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{obtenerNumero(item?.et)}</td>
              </tr>
            )
          })
        }
      </table> */}
      <table className='table-info-tickets'>
        <tr>
          <th>Referencia</th>
          <th>Valor</th>
          <th>Dia y Hora</th>
          <th>Status</th>
        </tr>
        <tr>
          <td>{requestSuccess?.pay?.reference}</td>
          <td>{formatearCantidad(requestSuccess?.pay?.price)}</td>
          <td>{formatearFecha(requestSuccess?.pay?.date)}</td>
          <td>{requestSuccess?.pay?.status}</td>
        </tr>
      </table>
    </div>
  )
}

export default RejectPay