//logos
import wellezyLogo from "../assets/logos/wellezy-logo.svg";
import LatamLogo from "../assets/logos/LatamLogo.png";
import VivaLogo from "../assets/logos/VivaLogo.png";
import AviancaLogo from "../assets/logos/AviancaLogo.png";
import Alert from "../assets/logos/wellezy.png"; /* revisar */
import LogoBlue from "../assets/logos/wellezy.png"; /* revisar */
import AppStoreLogo from "../assets/logos/AppStoreLogo.png";
import GooglePlayLogo from "../assets/logos/GooglePlayLogo.png";
import IATALogo from "../assets/logos/IATALogo.png";
import PaypalLogo from "../assets/logos/PaypalLogo.png";
import VisaLogo from "../assets/logos/VisaLogo.png";
import MasterCardLogo from "../assets/logos/MasterCardLogo.png";

//iconos
import iconLuggage from "../assets/icons/ChooseLuggage.png";
import iconLuggageBlue from "../assets/icons/ChooseLuggageBlue.png";
import iconAirplane from "../assets/icons/Airplane.png";

//icons svg
import { ReactComponent as IconBagBig } from "../assets/iconsSVG/iconBagBigActive.svg";
import { ReactComponent as IconBagMedium } from "../assets/iconsSVG/iconBagMediumActive.svg";
import { ReactComponent as IconBagSmall } from "../assets/iconsSVG/iconBagSmallActive.svg";
import { ReactComponent as IconAddItem } from "../assets/iconsSVG/IconAddItem.svg";
import { ReactComponent as IconAmbulance } from "../assets/iconsSVG/IconAmbulance.svg";
import { ReactComponent as IconBag } from "../assets/iconsSVG/IconBag.svg";
import { ReactComponent as IconBeautiful } from "../assets/iconsSVG/IconBeautiful.svg";
import { ReactComponent as IconBeautySalon } from "../assets/iconsSVG/IconBeautySalon.svg";
import { ReactComponent as IconBus } from "../assets/iconsSVG/IconBus.svg";
import { ReactComponent as IconCalendar } from "../assets/iconsSVG/IconCalendar.svg";
import { ReactComponent as IconCall } from "../assets/iconsSVG/IconCall.svg";
import { ReactComponent as IconCallCenter } from "../assets/iconsSVG/IconCallCenter.svg";
import { ReactComponent as IconCartBuy } from "../assets/iconsSVG/IconCartBuy.svg";
import { ReactComponent as IconChat } from "../assets/iconsSVG/IconChat.svg";
import { ReactComponent as IconCheckBuy } from "../assets/iconsSVG/IconCheckBuy.svg";
import { ReactComponent as IconCheckSucces } from "../assets/iconsSVG/IconCheckSucces.svg";
import { ReactComponent as IconDealDone } from "../assets/iconsSVG/IconDealDone.svg";
import { ReactComponent as IconDoctor } from "../assets/iconsSVG/IconDoctor.svg";
import { ReactComponent as IconFilters } from "../assets/iconsSVG/IconFilters.svg";
import { ReactComponent as IconFood } from "../assets/iconsSVG/IconFood.svg";
import { ReactComponent as IconFrecuentQuestions } from "../assets/iconsSVG/IconFrequentQuestions.svg";
import { ReactComponent as IconHospital } from "../assets/iconsSVG/IconHospital.svg";
import { ReactComponent as IconHotel } from "../assets/iconsSVG/IconHotel.svg";
import { ReactComponent as IconListItem } from "../assets/iconsSVG/IconListItems.svg";
import { ReactComponent as IconListMedicaments } from "../assets/iconsSVG/iconListMedicaments.svg";
import { ReactComponent as IconMap } from "../assets/iconsSVG/IconMap.svg";
import { ReactComponent as IconMedicaments } from "../assets/iconsSVG/IconMedicaments.svg";
import { ReactComponent as IconMegaPhone } from "../assets/iconsSVG/IconMegaPhone.svg";
import { ReactComponent as IconNotice } from "../assets/iconsSVG/IconNotice.svg";
import { ReactComponent as IconNurse } from "../assets/iconsSVG/IconNurse.svg";
import { ReactComponent as IconPeoples } from "../assets/iconsSVG/IconPeoples.svg";
import { ReactComponent as IconPhoneRing } from "../assets/iconsSVG/IconPhoneRing.svg";
import { ReactComponent as IconPinMap } from "../assets/iconsSVG/IconPinMap.svg";
import { ReactComponent as IconPinMapBlank } from "../assets/iconsSVG/IconPinMapBlank.svg";
import { ReactComponent as IconHome } from "../assets/iconsSVG/IconHome.svg";
import { ReactComponent as IconPlane } from "../assets/iconsSVG/IconPlane.svg";
import { ReactComponent as IconPlane2 } from "../assets/iconsSVG/IconPlane2.svg";
import { ReactComponent as IconPrize } from "../assets/iconsSVG/IconPrize.svg";
import { ReactComponent as IconProfile } from "../assets/iconsSVG/IconProfile.svg";
import { ReactComponent as IconQuestions } from "../assets/iconsSVG/IconQuestions.svg";
import { ReactComponent as IconReloj } from "../assets/iconsSVG/IconReloj.svg";
import { ReactComponent as IconRentaCar } from "../assets/iconsSVG/IconRentaCar.svg";
import { ReactComponent as IconRestHouse } from "../assets/iconsSVG/IconRestHouse.svg";
import { ReactComponent as IconRestRoom } from "../assets/iconsSVG/IconRestRoom.svg";
import { ReactComponent as IconSearch } from "../assets/iconsSVG/IconSearch.svg";
import { ReactComponent as IconSecureTravel } from "../assets/iconsSVG/IconSecureTravel.svg";
import { ReactComponent as IconSell } from "../assets/iconsSVG/IconSell.svg";
import { ReactComponent as IconTaxi } from "../assets/iconsSVG/IconTaxi.svg";
import { ReactComponent as IconTravel } from "../assets/iconsSVG/IconTravel.svg";
import { ReactComponent as IconWhatsapp } from "../assets/iconsSVG/IconWhatsapp.svg";
import { ReactComponent as IconStar } from "../assets/iconsSVG/IconStar.svg";
import { ReactComponent as IconPencilOutLine } from "../assets/iconsSVG/IconPencilOutLine.svg";
import { ReactComponent as IconAlertOutLine } from "../assets/iconsSVG/IconAlertOutLine.svg";
import { ReactComponent as IconPlusCircleOutLine } from "../assets/iconsSVG/IconPlusCircleOutLine.svg";
import { ReactComponent as IconWalletOutLine } from "../assets/iconsSVG/IconWalletOutLine.svg";
import { ReactComponent as IconHeartOutLine } from "../assets/iconsSVG/IconHeartOutLine.svg";
import { ReactComponent as IconCoupon } from "../assets/iconsSVG/IconCoupon.svg";
import { ReactComponent as IconCalendarOutLine2 } from "../assets/iconsSVG/IconCalendarOutLine2.svg";
import { ReactComponent as IconOrders } from "../assets/iconsSVG/IconOrders.svg";
import { ReactComponent as IconTeleconsultationsOutLine } from "../assets/iconsSVG/IconTeleconsultationsOutLine.svg";
import { ReactComponent as IconDatafono } from "../assets/iconsSVG/IconDatafono.svg";
import { ReactComponent as IconInsurance } from "../assets/iconsSVG/IconInsurance.svg";
import { ReactComponent as IconShopSuppliesAndMore } from "../assets/iconsSVG/IconShopSuppliesAndMore.svg";
import { ReactComponent as IconUserCircleOutLine } from "../assets/iconsSVG/IconUserCircleOutLine.svg";
import { ReactComponent as IconTrashOutLine } from "../assets/iconsSVG/IconTrashOutLine.svg";
import { ReactComponent as IconCloseCircleOutLine } from "../assets/iconsSVG/IconCloseCircleOutLine.svg";
import { ReactComponent as IconEyeOn } from "../assets/iconsSVG/IconEyeOn.svg";
import { ReactComponent as IconEyeOff } from "../assets/iconsSVG/IconEyeOff.svg";
import { ReactComponent as IconPaperList } from "../assets/iconsSVG/IconPaperList.svg";
import { ReactComponent as IconArrowDown } from "../assets/iconsSVG/IconArrowDown.svg";
import { ReactComponent as IconPlus } from "../assets/iconsSVG/IconPlus.svg";
import { ReactComponent as IconGalleryPlay } from "../assets/iconsSVG/IconGalleryPlay.svg";
import { ReactComponent as IconAdult } from "../assets/iconsSVG/Iconadult.svg";
import { ReactComponent as IconBoy } from "../assets/iconsSVG/Iconboy.svg";
import { ReactComponent as IconBaby } from "../assets/iconsSVG/Iconbaby.svg";
import { ReactComponent as IconReloj2 } from "../assets/iconsSVG/iconReloj2.svg";
import { ReactComponent as IconCalendar2 } from "../assets/iconsSVG/iconCalendar2.svg";
import { ReactComponent as IconArrowDown2 } from "../assets/iconsSVG/arrowDown.svg";
import { ReactComponent as IconOperatorCall } from "../assets/iconsSVG/IconOperatorCall.svg";
import { ReactComponent as IconWeb } from "../assets/iconsSVG/IconWeb.svg";
import { ReactComponent as IconBariatica } from "../assets/iconsSVG/IconBariatica.svg";
import { ReactComponent as IconCancer } from "../assets/iconsSVG/IconCancer.svg";
import { ReactComponent as IconCelulas } from "../assets/iconsSVG/IconCelulas.svg";
import { ReactComponent as IconEnvejecimiento } from "../assets/iconsSVG/IconEnvejecimiento.svg";
import { ReactComponent as IconFertilidad } from "../assets/iconsSVG/IconFertilidad.svg";
import { ReactComponent as IconGinecologia } from "../assets/iconsSVG/IconGinecologia.svg";
import { ReactComponent as IconOdontologia } from "../assets/iconsSVG/IconOdontologia.svg";
import { ReactComponent as IconProtectEyes } from "../assets/iconsSVG/IconProtectEyes.svg";
import { ReactComponent as IconSurgeryEstectic } from "../assets/iconsSVG/IconSurgeryEstectic.svg";
import { ReactComponent as IconUrologia } from "../assets/iconsSVG/IconUrologia.svg";
import { ReactComponent as IconCalendar3 } from "../assets/iconsSVG/IconCalendar3.svg";
import { ReactComponent as IconPinMap2 } from "../assets/iconsSVG/IconPinMap2.svg";
import { ReactComponent as IconBander } from "../assets/iconsSVG/IconBander.svg";
import { ReactComponent as Ortopedia } from "../assets/iconsSVG/Ortopedia.svg";
import { ReactComponent as IconWhatsapp2 } from "../assets/iconsSVG/IconWhatsapp.svg";
import { ReactComponent as IconTwitter } from "../assets/iconsSVG/IconTwitter.svg";
import { ReactComponent as IconCadena } from "../assets/iconsSVG/IconCadena.svg";
import { ReactComponent as IconEmail } from "../assets/iconsSVG/IconEmail.svg";
import { ReactComponent as IconWorld } from "../assets/iconsSVG/IconWorld.svg";
import { ReactComponent as IconCoin } from "../assets/iconsSVG/IconCoin.svg";
import { ReactComponent as IconAddPerson } from "../assets/iconsSVG/IconAddPerson.svg";
import { ReactComponent as IconCertificate } from "../assets/iconsSVG/IconCertificate.svg";
import { ReactComponent as IconCardCheck } from "../assets/iconsSVG/IconCardCheck.svg";
import { ReactComponent as IconCardNormal } from "../assets/iconsSVG/IconCardNormal.svg";
import { ReactComponent as IconCardAdd } from "../assets/iconsSVG/IconCardAdd.svg";
import { ReactComponent as IconAddCards } from "../assets/iconsSVG/addCards.svg";
import { ReactComponent as IconArrowLarge } from "../assets/iconsSVG/IconArrowLarge.svg";
import { ReactComponent as IconKey } from "../assets/iconsSVG/IconKey.svg";
import { ReactComponent as IconTwoPerson } from "../assets/iconsSVG/IconTwoPerson.svg";
import { ReactComponent as IconCheck } from "../assets/iconsSVG/check.svg";
import { ReactComponent as IconCancel } from "../assets/iconsSVG/cancel.svg";
import { ReactComponent as IconInfo } from "../assets/iconsSVG/info.svg";
import { ReactComponent as IconExtraBag } from "../assets/iconsSVG/IconExtraBag.svg";
import { ReactComponent as IconMovimientos } from "../assets/iconsSVG/IconMovimientos.svg";
import { ReactComponent as IconPerfil } from "../assets/iconsSVG/IconPerfil.svg";
import { ReactComponent as IconNotificaciones } from "../assets/iconsSVG/IconNotificaciones.svg";
import { ReactComponent as IconTeleConsulta } from "../assets/iconsSVG/IconTeleConsulta.svg";
import { ReactComponent as IconCalendario } from "../assets/iconsSVG/IconCalendario.svg";
import { ReactComponent as IconCerrarSesion } from "../assets/iconsSVG/IconCerrarSesion.svg";
import { ReactComponent as IconXTwitter } from "../assets/iconsSVG/IconXTwitter.svg"

//pictures
import BannerTours from "../assets/pictures/BannerTours.png";
export const global = {
  colors: {
    primary: {
      blue: "#3b82f6",
      white: "#FFFFFF",
      gary: "#94a3b8",
      gray_light: "#f5f5f5",
      gary_dark: "#64748b",
      black: "#000000",
    },
  },
  fonts: {},

  img: {
    icons: {
      iconLuggage,
      iconLuggageBlue,
      iconAirplane,
    },

    iconsSVG: {
      IconBagBig,
      IconBagMedium,
      IconBagSmall,
      IconAddItem,
      IconAmbulance,
      IconBag,
      IconBeautiful,
      IconBeautySalon,
      IconBaby,
      IconAdult,
      IconBoy,
      IconBus,
      IconCalendar,
      IconCall,
      IconCallCenter,
      IconCartBuy,
      IconChat,
      IconCheckBuy,
      IconCheckSucces,
      IconDealDone,
      IconDoctor,
      IconFilters,
      IconFood,
      IconFrecuentQuestions,
      IconHospital,
      IconHotel,
      IconListItem,
      IconListMedicaments,
      IconMap,
      IconMedicaments,
      IconMegaPhone,
      IconNotice,
      IconNurse,
      IconPeoples,
      IconPhoneRing,
      IconPinMap,
      IconPinMapBlank,
      IconHome,
      IconPlane,
      IconPlane2,
      IconPrize,
      IconProfile,
      IconQuestions,
      IconReloj,
      IconRentaCar,
      IconRestHouse,
      IconRestRoom,
      IconSearch,
      IconSecureTravel,
      IconSell,
      IconTaxi,
      IconTravel,
      IconWhatsapp,
      IconStar,
      IconPaperList,
      IconArrowDown,
      IconPencilOutLine,
      IconAlertOutLine,
      IconPlusCircleOutLine,
      IconWalletOutLine,
      IconHeartOutLine,
      IconCoupon,
      IconCalendarOutLine2,
      IconOrders,
      IconTeleconsultationsOutLine,
      IconDatafono,
      IconInsurance,
      IconShopSuppliesAndMore,
      IconUserCircleOutLine,
      IconTrashOutLine,
      IconCloseCircleOutLine,
      IconEyeOn,
      IconEyeOff,
      IconPlus,
      IconGalleryPlay,
      IconCalendar2,
      IconReloj2,
      IconArrowDown2,
      IconOperatorCall,
      IconWeb,
      Ortopedia,
      IconUrologia,
      IconSurgeryEstectic,
      IconProtectEyes,
      IconOdontologia,
      IconGinecologia,
      IconFertilidad,
      IconEnvejecimiento,
      IconCelulas,
      IconCancer,
      IconBariatica,
      IconBander,
      IconPinMap2,
      IconCalendar3,
      IconCadena,
      IconWhatsapp2,
      IconTwitter,
      IconEmail,
      IconWorld,
      IconCertificate,
      IconCoin,
      IconAddPerson,
      IconCardAdd,
      IconCardCheck,
      IconCardNormal,
      IconAddCards,
      IconArrowLarge,
      IconKey,
      IconTwoPerson,
      IconCheck,
      IconCancel,
      IconInfo,
      IconExtraBag,
      IconMovimientos,
      IconPerfil,
      IconNotificaciones,
      IconTeleConsulta,
      IconCalendario,
      IconCerrarSesion,
      IconXTwitter
    },
    images: {},
    logos: {
      wellezyLogo,
      LatamLogo,
      VivaLogo,
      AviancaLogo,
      Alert,
      LogoBlue,
      AppStoreLogo,
      GooglePlayLogo,
      IATALogo,
      PaypalLogo,
      VisaLogo,
      MasterCardLogo,
    },
    pictures: {
      iconAirplane,
      BannerTours,
    },
  },
};
