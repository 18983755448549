import React from "react";
import "./ModalChange.css";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { formatToCOP } from "../../../../utils";

const ModalChange = ({ selectHab, show, setShow }) => {
  const [t] = useTranslation("global");

  return (
    <div
      className={`${show ? "ModalChange" : "ModalChange close"}`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setShow(false);
        }
      }}
    >
      <div
        className={`${
          show ? "ModalChangeContent" : "ModalChangeContent close"
        }`}
      >
        <button
          className="ModalChangeCloseButton"
          onClick={() => setShow(false)}
        >
          <MdClose size={23} color="#104172" />
        </button>

        <div className="content-modal-card-title">
          <h2>Detalle de Habitación</h2>

          <p style={{ color: "#2a2a2a", fontSize: 14 }}>
            Código de habitación:{" "}
            <span style={{ color: "#004274", fontWeight: 600 }}>
              {selectHab?.["@attributes"].cod}
            </span>
          </p>
        </div>
        <ul className="list-content-modal-card">
          <li>
            <span className="label-content-modal-card">Nombre completo: </span>
            <span className="value-content-modal-card">
              {selectHab?.reg?.[0]?.name}
            </span>
          </li>
          <li>
            <span className="label-content-modal-card">Personas: </span>
            <span className="value-content-modal-card">
              {selectHab?.acomodation}
            </span>
          </li>
          {/* <li>
            <span className="label-content-modal-card">Ciudad</span>
            <span className="value-content-modal-card">
              {data?.pob?.charAt(0).toUpperCase() +
                data?.pob?.slice(1).toLowerCase()}
            </span>
          </li> */}
          <li>
            <span className="label-content-modal-card">Incluye: </span>
            <span className="value-content-modal-card">
              {selectHab?.reg?.[0]["@attributes"]?.cod}
            </span>
          </li>

          {selectHab?.reg?.[0]["@attributes"]?.nr && (
            <li>
              <span className="label-content-modal-card">Además: </span>
              <span className="value-content-modal-card">
                {selectHab?.reg?.[0]["@attributes"]?.nr}
              </span>
            </li>
          )}
          <li>
            <span className="label-content-modal-card">
              {t("hotel.include_taxes")}
            </span>
          </li>
        </ul>

        <div className="price-content-modal-card">
          <span className="value-content-modal-card">Total:</span>
          <span style={{ color: "#004274 !important", fontSize: 18 }}>
            <b>{formatToCOP(selectHab?.reg?.[0]["@attributes"]?.prr)} COP</b>
          </span>
        </div>
        <p style={{ color: "#757575", paddingBottom: 10, fontSize: 14 }}>
          Podría haber cargos adicionales a pagar en el alojamiento. De ser así,
          te los informaremos en detalle antes de finalizar tu compra.
        </p>
      </div>
    </div>
  );
};

export default ModalChange;
