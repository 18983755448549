const typeOfCoin = { name: 'Peso colombiano', abbreviation: 'COP', simbol: '$' };

export function currencyFormat(numero, typeOfCoin = { name: 'Peso colombiano', abbreviation: 'COP', simbol: '$' }) {
  const numeroFormateado = numero?.toLocaleString("en");
  return {
    currency: typeOfCoin.abbreviation,
    value:`${numeroFormateado}`,
    total: `${numeroFormateado} ${typeOfCoin.abbreviation}`
  }
}


export function formatMoney(amount) {
  return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
  }).format(amount);
}

export function textToLowerCase(text) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function ellipsisText(text, maxCaracter) {
  let result = text.length > maxCaracter
      ? text.substring(0, maxCaracter) + '...'
      : text;
  return result;
}

export function formatCurrencyString(numberStr) {
  // Convertir la cadena a número
  const number = parseFloat(numberStr);

  // Formatear el número como moneda sin decimales
  const formattedNumber = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);

  return formattedNumber;
}


export function formatToCOP(numberString) {
  // Convertir la cadena a un número
  const number = Number(numberString.replace(/,/g, '')); // Eliminar cualquier coma si está presente
  
  // Verificar si el número es válido
  if (isNaN(number)) {
    throw new Error("Input is not a valid number");
  }
  
  // Formatear el número a pesos colombianos sin decimales
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
}
