import React, { useEffect } from "react";
import PaymentForm from "./PaymentForm.js";
import "./styles.css";

const AdminPaymentCards = ({ catchResponse, user, login }) => {
  useEffect(() => {
    if (user) {
      setTimeout(() => {
        PaymentForm(user, catchResponse, 2, login);
      }, 500);
    }
  }, []);

  return (
    <div id="payment_example_div">
      <p className="title-enter-your-method">Ingresa tu metodo de pago</p>
      <div id="tokenize_example"></div>
      <div id="response"></div>
      <button id="tokenize_btn" className="tok_btn">
        Save card
      </button>
      <button id="retry_btn" className="tok_btn" style={{ display: "none" }}>
        Save new card
      </button>
    </div>
  );
};
export default AdminPaymentCards;
