import PaymentGateway from './PaymentGateway.js';

const PaymentForm = (user, catchResponse, step,login) => {

  document.addEventListener('DOMContentLoaded', () => {
    const environment = 'stg';
  const application_code = "WELLEZYSAS-STG-CO-CLIENT"//'DV-WELLEZY-CO-CLIENT';
  const application_key = "r1RqEYbG1gUaAJi5skzIPR9Lts59TN"//'cXRo0W6RZL3ZKvg6LX8dnz2Z2Fts6D';
  const submitButton = document.querySelector('#tokenize_btn');
  const retryButton = document.querySelector('#retry_btn');
  const submitInitialText = submitButton?.textContent;

  
  const get_tokenize_data = () => {
    const data = {
      locale: 'en',
      user: {
        id: login === 1 ? user.id : user.tel ,
        email: user.email,
      },
      configuration: {
        default_country: 'COL',
      },
    };
    return data;
  };
    const notCompletedFormCallback = (message) => {
      document.getElementById('response').innerHTML = `Not completed form: ${message}, Please fill required data`;
      if(!submitButton){
        console.error('Botón de enviar no encontrado en el DOM');
      }else{
      submitButton.innerText = submitInitialText;
      submitButton.removeAttribute('disabled');
    }};

    const responseCallback = (response) => {
  
      catchResponse(response)

      document.getElementById('response').innerHTML = JSON.stringify(response);
      if(!submitButton || !retryButton){
        console.error('Botón de enviar o reintentar no encontrado en el DOM');
      }else{
        retryButton.style.display = 'block';
      submitButton.style.display = 'none';
      }
      
    };

    const pg_sdk = new PaymentGateway(environment, application_code, application_key);

    pg_sdk.generate_tokenize(get_tokenize_data(), '#tokenize_example', responseCallback, notCompletedFormCallback);

    submitButton.addEventListener('click', (event) => {

      document.getElementById('response').innerHTML = '';
      submitButton.innerText = 'Card Processing...';
      submitButton.setAttribute('disabled', 'disabled');
      pg_sdk.tokenize();
      event.preventDefault();
    });

    retryButton.addEventListener('click', (event) => {
      submitButton.innerText = submitInitialText;
      submitButton.removeAttribute('disabled');
      retryButton.style.display = 'none';
      submitButton.style.display = 'block';
      pg_sdk.generate_tokenize(get_tokenize_data(), '#tokenize_example', responseCallback, notCompletedFormCallback);
    });
  
  
  });

  
  







};

export default PaymentForm;