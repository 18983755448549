import React, { useState } from "react";
import "./styles.css";
import { IoIosArrowDown } from "react-icons/io";

const TermAndConditions = ({ questions, children }) => {
  const [sectionState, setSectionState] = useState(1);
  const [openIndex, setOpenIndex] = useState(1);

  const togglePopup = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div style={{ borderTop: "1px solid #cacaca", paddingTop: 50 }}>
      <div className="bar-terms-conditions">
        <h3
          onClick={() => setSectionState(1)}
          className={`animate-border ${sectionState === 1 ? "active" : ""}`}
          style={{
            cursor: "pointer",
            paddingBottom: 10,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Preguntas frecuentes
        </h3>
        <h3
          onClick={() => setSectionState(2)}
          className={`animate-border ${sectionState === 2 ? "active" : ""}`}
          style={{
            cursor: "pointer",
            paddingBottom: 10,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Términos y condiciones
        </h3>
      </div>
      {sectionState === 1 && (
        <div className="content-questions">
          {questions.map((info, key) => (
            <div key={key}>
              <h4
                style={{
                  cursor: "pointer",
                  fontSize: 14,
                  fontWeight: 500,
                  marginBottom: 5,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 10,
                }}
                onClick={() => togglePopup(key)}
              >
                {info?.question}
                <IoIosArrowDown
                  style={{
                    rotate: openIndex === key ? "180deg" : "0deg",
                    transition: "all 0.3s ease",
                  }}
                  size={20}
                />
              </h4>
              {openIndex === key && <p>{info?.info}</p>}
            </div>
          ))}
        </div>
      )}
      {sectionState == 2 && <div className="contain-t">{children}</div>}
    </div>
  );
};

export default TermAndConditions;
