import * as React from 'react';
import typographys from "@/data/documentation/typography/typography";
import BodySectionDocumentation from '@/Components/SectionDocumentation/SectionDocumentation';
import { 
    ParagraphWellezyPrimary, 
    ParagraphWellezySecondary, 
    ParagraphWellezyTernary, 
 } from "./typographys";

export default function Typography() {
    return (
        <>
            <BodySectionDocumentation title="Parrafos" data={typographys} preview={
                <>
                    <ParagraphWellezyPrimary />
                    <ParagraphWellezySecondary />
                    <ParagraphWellezyTernary />
                </>
            } />
        </>
    );
}