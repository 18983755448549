import CarouselSevices from "../share/components/CarouselServices/CarouselSevices";
import Find from "../share/components/Find/Find";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useTranslation } from "react-i18next";
import SearchPackages from "./SearchPackage/SearchPackages";
import banner from "../../assets/pictures/bannerPackages.png";
import packageAside from "../../assets/images/packages1.jpg";
import PackageSection from "../home/components/PackagesSection/PackageSection";
import arrow from "./assets/svg/arrowDown2.svg";
import axios from "axios";
import "./assets/styles.css";

import { useState, useEffect } from "react";
import { get, keys } from "../../localStorage";
import AnyQuestions from "../home/components/anyQuestion";
import SvgIcon, { iconList } from "../share/components/iconSVG";
import { Link, useNavigate } from "react-router-dom";
import CustomSlider from "./DetailPackages/sliderImg/CustomSlider";
import ReturnPage from "../share/components/return/ReturnPage";

const Packages = () => {
  const [t] = useTranslation("global");
  const [isShowingFind, setIsShowingFind] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataPackage, setDataPackage] = useState([]);
  //Contador
  let counter = 0;


  function removeHtmlTags(inputText) {
    // Expresión regular para buscar etiquetas HTML
    const regex = /<[^>]+>/g;
    // Reemplazar las etiquetas HTML con una cadena vacía
    const cleanedText = inputText.replace(regex, "");
    return cleanedText;
  }

  async function isWidgetLS() {
    try {
      const response = await get(keys?.isWidget);
      setIsShowingFind(response?.enable);
    } catch (error) {
      console.log("Error in isWidgetLS(): ", error);
    }
  }

  const navigate = useNavigate()

  const photos = [
    {
      id: 1,
      img: banner,
    },
    {
      id: 2,
      img: banner,
    },
    {
      id: 3,
      img: banner,
    },
  ];


    //Función para buscar servicios
    const findServices = () => {
      setLoading(true)
      if (counter === 0) {
          axios.get(`${process.env.REACT_APP_URL_API_AIOP}api/packages`, {
              params: {
                  "destination_type": selectValue,
                  "search": inputValue
              }
          })
              .then(res => {
                  counter += 1;
                  setDataPackage(res.data.packs);
                  setLoading(false);
              })
              .catch(error => {
                  counter += 1;
                  console.log(error);
                  setLoading(false);
              });
      }
  };

  useEffect(() => {
    findServices();
}, [])

  

  const redirectTo = (type) => {
    navigate(type === "colombia" ? "/packagesType?type=colombia" : "/packagesType?type=internacional")
  }

  return (
    <div>
      {!isShowingFind && <Find />}
      <CarouselSevices current={t("carousel_services.packages")} />
      <div className="content-packages-new">
        <div className="content-packages-colum-1">

          <aside>
            <img src={packageAside} className="img-pack-aside" alt="paquetes" />
            <div className="info">
              <div className="letter">
                <h2>
                  <span>Turismo</span>
                  en salud
                </h2>
                <p>
                  Viaja por tu salud o tu bienestar con todo incluido, todo con
                  Wellezy
                </p>
              </div>
              <div
                className="cardRegisterNowButtonContain"
                style={{
                  position: "relative",
                  padding: "0 4px",
                  bottom: "0px",
                }}
              >
                <Link
                  to={"/TourismHealth"}
                  style={{
                    backgroundColor: "#004274",
                    fontSize: "24px",
                    fontWeight: "900",
                    borderRadius:"0px",
                    width:"100%"
                  }}
                  className="cardRegisterNowButton"
                >
                  VER MÁS
                </Link>
                {/* <button
                  className="cardRegisterNowButtonLink"
                  style={{ width: "55%" }}
                >
                  <div
                    style={{ backgroundColor: "#004274" }}
                    className="cardRegisterNowClip"
                  ></div>
                  <div
                    style={{ backgroundColor: "#004274", borderLeft: "none" }}
                    className="cardRegisterNowClipBorder"
                  ></div>
                </button> */}
              </div>
            </div>
          </aside>
        </div>

        <div className="content-packages-colum-2">
          <article> 
            <CustomSlider>
              {photos.map((image, index) => {
                return (
                  <img
                    className="section-image"
                    src={image.img}
                    key={index}
                    // onClick={() => setSelectedImage(`${process.env.REACT_APP_URL_API_AIOP}storage/${image}`)}
                    alt={`${index + 1}`}
                    style={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                );
              })}
            </CustomSlider>
            <section className="content-btn-pack">
              <button onClick={()=>redirectTo("internacionales")}>
                <div>
                  <span>Paquetes Internacionales</span>
                </div>
              </button>
              <button onClick={()=>redirectTo("colombia")}>
                <div>
                  <span>Paquetes Colombia</span>
                </div>
              </button>
            </section>
            <section className="search-packages-turisticos">
              <h2>Paquetes Turisticos</h2>
              <p>
                Viaja por el mundo con los mejores precios que trae Wellezy para
                ti{" "}
              </p>
              <div className="ctn-inputs">
                <input
                  type="text"
                  placeholder="¿Buscas algun destino en especial?"
                  onChange={(event) => setInputValue(event.target.value)}
                />
                <div class="content-select">
                  <select
                    name=""
                    id=""
                    onChange={(event) => setSelectValue(event.target.value)}
                  >
                    <option value="">Todas</option>
                    <option value="international">Internacional</option>
                    <option value="national">Nacional</option>
                    <option value="regional">Regional</option>
                  </select>
                  <img src={arrow} alt="arrow" />
                </div>
                <button onClick={findServices}>
                  <SvgIcon
                    color={"white"}
                    size={"23"}
                    name={iconList.IconSearch}
                  />
                  Buscar
                </button>
              </div>
            </section>
          </article>
        </div>
      </div>

      <PackageSection
        inputValue={inputValue}
        selectValue={selectValue}
        seeker={false}
        dataPackage={dataPackage}
        loading={loading}
      />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Packages;
