import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { FaUserDoctor } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { RiHotelFill } from "react-icons/ri";
import AddIcon from "@mui/icons-material/Add";
import InputEspecialties from "./components/InputEspecialties/InputEspecialties";
import InputProcedures from "./components/InputProcedures/InputProcedures";
import useSearchBuildPackage from "@/Hooks/BuildPackage/useBuildPackageSearchEngine/UseBuildPackageSearchEngine";
import { setProceduresData } from "@/store/slices/dataStepz/dataStepzSlice";
import ClearIcon from "@mui/icons-material/Clear";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineArrowOutward } from "react-icons/md";
import { Link } from "react-router-dom";
import Tooltip from "@/Components/Tooltip/Tooltip";
import { IoMdHelpCircleOutline } from "react-icons/io";

const SpecialistClinic = ({ appointments, title }) => {
  const dispatch = useDispatch();

  const [showInputProcedure, setShowInputProcedure] = useState(false);

  const dateGoingSelectedData = useSelector(
    (state) => state.dataSearchEngine.dateGoingSelectedData
  );
  const procedureData = useSelector(
    (state) => state.dataSearchEngine.procedureData
  );
  const proceduresData = useSelector((state) => state.dataStepz.proceduresData);

  const btnAddProcedure = () => {
    setShowInputProcedure(true);
  };

  const deleteProcedure = (index) => () => {
    const newProceduresData = proceduresData.filter(
      (procedure, i) => i !== index
    );

    dispatch(setProceduresData(newProceduresData));
  };

  const {
    showSpecialties,
    setShowSpecialties,
    especialtie,
    setEspecialtie,
    checkSpeciality,
    setCheckSpeciality,
    idSpecialty,
    setIdSpecialty,
    showProcedures,
    setShowProcedures,
    procedure,
    setProcedure,
    checkProcedure,
    setCheckProcedure,
  } = useSearchBuildPackage();

  const saveProcedure = () => {
    if (procedure === "") {
      setCheckProcedure(true);
    }

    if (especialtie === "") {
      setCheckSpeciality(true);
    }

    if (procedure === "" || especialtie === "") {
      return;
    }

    if (
      proceduresData.includes(procedure) ||
      procedure === procedureData.name
    ) {
      toast("El procedimiento ya ha sido añadido", { icon: "❌" });
      return;
    }

    setShowInputProcedure(false);
    dispatch(setProceduresData([...proceduresData, procedure]));

    setProcedure("");
    setEspecialtie("");
    setIdSpecialty(null);
  };

  const [show, setShow] = useState(false);

  return (
    <div className={styles.cartItemsItem}>
      {!title ? null : <h3>1 y 2. Especialista y clínica</h3>}

      <div className={styles.cartItemsItemOne}>
        <div className={styles.itemAppointment}>
          <div className={styles.itemHeader}>
            <div className={styles.itemHeaderIconText}>
              <div className={styles.icon}>
                <FaUserDoctor />
              </div>
              <div className={styles.itemHeaderText}>
                <h4 className={styles.title}>{appointments.specialist.name}</h4>
                <p className={styles.shortDescription}>
                  Especialista en{" "}
                  {appointments.specialist.categories
                    .map((category) => category.name)
                    .join(", ")}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.itemDetails}>
            <p className={styles.longDescription}>
              {appointments.specialist.description}
            </p>
          </div>

          <div className={styles.itemLinks}>
            <p>
              Fecha <span>{dateGoingSelectedData}</span>
            </p>
            <p>
              <div className={styles.containerAddProcedure}>
                ¿Deseas cotizar otro procedimiento?{" "}
                <button
                  onClick={btnAddProcedure}
                  className={styles.btnAddProcedure}
                >
                  <AddIcon style={{ fontSize: "15px" }} />
                  Agregar procedimiento
                </button>
              </div>
              <Toaster />
              {showInputProcedure && (
                <div className={styles.containerInputsAddProcedure}>
                  <div className={styles.actionsInputs}>
                  <div>
                    <label htmlFor="especialties">Especialidad</label>
                    <br />
                    <InputEspecialties
                      showSpecialties={showSpecialties}
                      setShowSpecialties={setShowSpecialties}
                      especialtie={especialtie}
                      setEspecialtie={setEspecialtie}
                      checkSpeciality={checkSpeciality}
                      setCheckSpeciality={setCheckSpeciality}
                      setIdSpecialty={setIdSpecialty}
                    />
                  </div>
                  <div>
                    <label>Procedimiento</label>
                    <br />
                    <InputProcedures
                      showProcedures={showProcedures}
                      setShowProcedures={setShowProcedures}
                      procedure={procedure}
                      setProcedure={setProcedure}
                      checkProcedure={checkProcedure}
                      setCheckProcedure={setCheckProcedure}
                      idSpecialty={idSpecialty}
                    />
                  </div>
                  </div>
                  <div className={styles.actionsButtons}>
                  <button onClick={saveProcedure}>Guardar</button>
                  <button onClick={() => {
                    setShowInputProcedure(false)
                    setProcedure("")
                    setEspecialtie("")
                    setIdSpecialty(null)
                  }}>
                    Cancelar
                  </button>
                  </div>
                </div>
              )}
              <div className={styles.containerAllProcedures}>
                <span>{procedureData.name}</span>
                {Array.isArray(proceduresData) &&
                  proceduresData.length > 0 &&
                  proceduresData.map((procedure, index) => (
                    <div
                      className={styles.containerAddedProcedures}
                      key={index}
                    >
                      <span>{procedure}</span>
                      <button onClick={deleteProcedure(index)}>
                        <ClearIcon
                          style={{
                            fontSize: "15px",
                            color: "var(--bluePrimary)",
                          }}
                        />
                      </button>
                    </div>
                  ))}
              </div>
            </p>
          </div>
          <div className={styles.itemHeaderClinic}>
            <div className={styles.itemHeaderIconText}>
              <div className={styles.icon}>
                <RiHotelFill />
              </div>
              <div className={styles.itemHeaderText}>
                <h4 className={styles.title}>{appointments.clinic.name}</h4>
                <p className={styles.shortDescription}>
                  {appointments.clinic.address}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.itemDetails}>
            <p className={styles.longDescription}>
              {appointments.clinic.description}
            </p>
          </div>
        </div>

        <div className={styles.buttonsActions}>
          <Link to="/build-package/std" className={styles.linkSTD}>
            Confirmar procedimiento
            <MdOutlineArrowOutward size={20} />
          </Link>

          <Tooltip content="Indicaciones del STD" mobilePosition="bottom">
            <button onClick={() => setShow(true)} className={styles.helpSTD}>
              
              <IoMdHelpCircleOutline size={25} />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SpecialistClinic;
