import { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function useSearchFlights(isIframe) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const cardRefPlace = useRef();
  const cardRefPassenger = useRef();
  const cardRefDate = useRef();
  const [buttonDisable, setButtonDisable] = useState(true);
  const [searchStorage, setSearchStorage] = useState(JSON.parse(localStorage.getItem('storageLastSearch')) || [])

  const [directFlight, setDirectFlight] = useState(localStorage.getItem("directFlight") === null ? false : localStorage.getItem("directFlight") === "false" ? false : true);

  const [dollarFlight, setDollarFlight] = useState(localStorage.getItem("dollarFlight") === null ? "COP" : localStorage.getItem("dollarFlight"));


  const [isRoundTrip, setIsRoundTrip] = useState(() => {
    const saved = localStorage.getItem("isRoundTrip");
    return saved ? JSON.parse(saved) : false;
  });
  const [isCardRefOrigin, setIsCardRefOrigin] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState("o");
  const [isCardRefDestination, setIsCardRefDestination] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState("d");
  const [dateGoingSelected, setDateGoingSelected] = useState("");
  const [dateReturnSelected, setDateReturnSelected] = useState("");
  const [isCardRefDate, setIsCardRefDate] = useState(false);
  const [dateGoingSelectedApi, setDateGoingSelectedApi] = useState("");
  const [dateReturnSelectedApi, setDateReturnSelectedApi] = useState("");
  const [codeiataDestinity, setCodeiataDestinity] = useState("");
  const [codeIataOrigin, setCodeIataOrigin] = useState("");
  const [isManagingPassengers, setIsManagingPassengers] = useState(false);
  const [passengersSelecteds, setPassengersSelecteds] = useState({
    adult: localStorage.getItem("adult") === null ? 1 : Number(localStorage.getItem("adult")),
    boy: localStorage.getItem("boy") === null ? 0 : Number(localStorage.getItem("boy")),
    baby: localStorage.getItem("baby") === null ? 0 : Number(localStorage.getItem("baby")),
    seat: localStorage.getItem("seat") === null ? 0 : Number(localStorage.getItem("seat"))
  });
  const [validationSearchDestiny, setValidationSearchDestiny] = useState(true);
  const [validationSearchDate, setValidationSearchDate] = useState(false);

  useEffect(() => {
    localStorage.setItem("isRoundTrip", JSON.stringify(isRoundTrip));
  }, [isRoundTrip]);

  function getSelectedOrigin(value, build = false,type) {
    if (value == "o") {
      setSelectedOrigin("o");
      // localStorage.removeItem("select-origin");
      // localStorage.removeItem("iata-origin");
    } else {
      if(!build){
        const selected = `${value?.nameAirport} - ${value?.codeIataAirport}`;
      localStorage.setItem("select-origin", selected);
      localStorage.setItem("iata-origin", value?.codeIataAirport);
      setSelectedOrigin(selected);
      setCodeIataOrigin(value?.codeIataAirport);
      }else if(type == "airport"){
        const selected = `${value?.new_city
          .nameCity} - ${value?.codeIso2Country}`;
        localStorage.setItem("select-origin", selected);
        localStorage.setItem("iata-origin", value?.codeIataCity);
        setSelectedOrigin(selected);
        setCodeIataOrigin(value?.codeIataCity);
      }else{
        const selected = `${value?.nameCity} - ${value?.codeIso2Country}`;
        localStorage.setItem("select-origin", selected);
        localStorage.setItem("iata-origin", value?.codeIataCity);
        setSelectedOrigin(selected);
        setCodeIataOrigin(value?.codeIataCity);
      }
      
    }
  }

  const deleteItemsExpiration = () => {
    const date = new Date();
    const searchStorageFilter = searchStorage.filter((e) => {
      if (e?.type == 'flight') {
        const expiration = new Date(e?.departureDate);
        return expiration > date; // Aquí debes devolver el resultado de la comparación
      } else if (e?.type == 'hotel') {
        const expiration = new Date(e?.arrival);
        return expiration > date; // Aquí debes devolver el resultado de la comparación
      } else if (e?.type == 'transport') {
        const [year, month, day, hour, minute] = e.dateApi.split('-').map(Number);
        // Crear un objeto Date correctamente interpretado
        const expiration = new Date(year, month - 1, day, hour, minute);
        return expiration > date; // Devolver el resultado de la comparación
      }
    });
    setSearchStorage(searchStorageFilter);
    localStorage.setItem('storageLastSearch', JSON.stringify(searchStorageFilter))
  };

  function getSelectedDestination(value,build = false) {

    if (value == "d") {
      setSelectedDestination("d");
      // localStorage.removeItem("select-destiny");
      // localStorage.removeItem("iata-destiny");
    } else {
      if(!build){
        const selected = `${value?.nameAirport} - ${value?.codeIataAirport}`;
      localStorage.setItem("select-destiny", selected);
      localStorage.setItem("iata-destiny", value?.codeIataAirport);
      setSelectedDestination(selected);
      setCodeiataDestinity(value?.codeIataAirport);
      }else{
        const selected = `${value?.nameCity} - ${value?.codeIso2Country}`;
      localStorage.setItem("select-destiny", selected);
      localStorage.setItem("iata-destiny", value?.codeIataCity);
      setSelectedDestination(selected);
      setCodeiataDestinity(value?.codeIataCity);
      }
      
    }
  }

  const getPassengersSelecteds = (type, operation) => {
    if (operation) {
      localStorage.setItem(type, passengersSelecteds[type] + 1);
    } else {
      localStorage.setItem(type, passengersSelecteds[type] - 1);
    }
    if (type === 'adult') {
      setPassengersSelecteds({
        adult: operation ? passengersSelecteds.adult + 1 : passengersSelecteds.adult - 1,
        boy: 0,
        baby: 0,
        seat: 0
      });
    } else {
      setPassengersSelecteds((prev) => {
        return {
          ...prev,
          [type]: operation
            ? passengersSelecteds[type] + 1
            : passengersSelecteds[type] - 1,
        };
      });
    }


  };

  const showButtonSearch = () => {
    let isDisable = true;
    if (!isRoundTrip) {
      if (
        selectedOrigin !== "Origen vuelo" &&
        selectedDestination !== "Destino Vuelo" &&
        dateGoingSelected !== "Ida"
      ) {
        isDisable = false;
      }
    } else if (isDisable && dateReturnSelected !== "Vuelta") {
      isDisable = false;
    }
    setButtonDisable(isDisable);
  };

  function ellipsisText(text, maxCaracter) {
    let result = "";
    if (text.includes("-")) {
      const textArray = text.split("-");
      const newText = `${textArray[1]} - ${textArray[0]}`;
      result =
        newText.length > maxCaracter
          ? newText.substring(0, maxCaracter) + "..."
          : newText;
    } else {
      result = text;
    }
    return result;
  }
  //Url= /flight/&departure=2023-10-04T05:00:00.000Z&return=2023-09-28T05:00:00.000Z&iataD=MDE&iataO=BOG&destinationC=Jose%20Marie%20Cordova%20-%20MDE&originC=Eldorado%20-%20BOG&adult=1&boy=0&baby=0&isRoundTrip=true

  //return: fecha de retorno
  //departure: fecha de salida
  //iataD: codigo iata destino
  //iataO: codigos iata origen
  //destinationC: ciudad de destino
  //originC: ciudad de origen
  //adult: cantidad de adultos
  //baby: cantidad de bebes
  //isRoundTrip: indica si es ida y vuelta o solo ida, el valor es true o false
  //boy: cantidad de niños




  function searcherFlight(direct = "n", dollar = "n") {
    let url = "";
    const directFlightParam = direct !== "n" ? direct : directFlight;
    const dollarFlightParam = dollar !== "n" ? dollar : dollarFlight;

    setValidationSearchDestiny(false)
    setValidationSearchDate(true)
    if (selectedOrigin != "o" && selectedDestination != "d") {
      setValidationSearchDestiny(true)
      if (dateGoingSelected != "" && dateReturnSelected != "") {
        setValidationSearchDate(false)
        if (isIframe) {
          if (pathname === "/packages") {
            url = "/iframe/packages/flight";
          } else {
            url = "/iframe/flight";
          }
        } else if (pathname === "/packages") {
          url = "/packages/flight";
        } else {
          url = "/flight";
        }
        if (searchStorage.length < 4) {
          const data = {
            url: `${url}/&departure=${dateGoingSelectedApi}&return=${dateReturnSelectedApi}&iataD=${codeiataDestinity}&iataO=${codeIataOrigin}&destinationC=${selectedDestination}&originC=${selectedOrigin}&adult=${passengersSelecteds?.adult}&boy=${passengersSelecteds?.boy}&baby=${passengersSelecteds?.baby}&seat=${passengersSelecteds?.seat}&isRoundTrip=${isRoundTrip}&directFlight=${directFlightParam}&dollarFlight=${dollarFlightParam}&`,
            type: 'flight',
            departureDate: dateGoingSelectedApi,
            return: dateReturnSelectedApi,
            iataD: codeiataDestinity,
            iataO: codeIataOrigin,
            destinationC: selectedDestination,
            originC: selectedOrigin,
            adult: passengersSelecteds?.adult,
            boy: passengersSelecteds?.boy,
            baby: passengersSelecteds?.baby,
            seat: passengersSelecteds?.seat,
            isRoundTrip,
            directFlight: directFlightParam,
            dollarFlight: dollarFlightParam
          }


          let newArray = searchStorage.filter(item => item.type !== 'flight')
          localStorage.setItem('storageLastSearch', JSON.stringify([...newArray, data]))


        }
        pathname === "/packages"
          ? localStorage.setItem(
            "urlFligth",
            `${url}/&departure=${dateGoingSelectedApi}&return=${dateReturnSelectedApi}&iataD=${codeiataDestinity}&iataO=${codeIataOrigin}&destinationC=${selectedDestination}&originC=${selectedOrigin}&adult=${passengersSelecteds?.adult}&boy=${passengersSelecteds?.boy}&baby=${passengersSelecteds?.baby}&isRoundTrip=${isRoundTrip}&directFlight=${directFlight}&dollarFlight=${dollarFlight}&`
          )
          : navigate(
            `${url}/&departure=${dateGoingSelectedApi}&return=${dateReturnSelectedApi}&iataD=${codeiataDestinity}&iataO=${codeIataOrigin}&destinationC=${selectedDestination}&originC=${selectedOrigin}&adult=${passengersSelecteds?.adult}&boy=${passengersSelecteds?.boy}&baby=${passengersSelecteds?.baby}&seat=${passengersSelecteds?.seat}&isRoundTrip=${isRoundTrip}&directFlight=${directFlightParam}&dollarFlight=${dollarFlightParam}&`,
            {
              state: {
                dateReturnSelectedApi,
                dateGoingSelectedApi,
                codeiataDestinity,
                codeIataOrigin,
                buttonOriginFlight: selectedOrigin,
                buttonDestinityFlight: selectedDestination,
                passengersSelecteds,
                roundTrip: isRoundTrip,
                directFlight,
                dollarFlight
                // clasSelected, //no needed
                // dateGoingSelected, //no needed
                // dateSreturnSelected, //no needed
                // oneWay, //no needed
                // DestinityFlight//no needed
              },
            }
          );
        // window.location = window.location.href;
      }

    }
  }


  const builUrlFlight = () => {
    let url = "/build-package/flight";
    setValidationSearchDestiny(false)
    setValidationSearchDate(true)
    // validamos que destino y origen esten seleccionados
    if (selectedOrigin != "o" && selectedDestination != "d") {
      setValidationSearchDestiny(true)

      // si es ida no entra en esta pero si es ida y vuelta entra
      if(isRoundTrip){
        if (dateGoingSelected != "" && dateReturnSelected != "") {
        setValidationSearchDate(false)
          const data = {
            url: `${url}/&departure=${dateGoingSelectedApi}&return=${dateReturnSelectedApi}&iataD=${codeiataDestinity}&iataO=${codeIataOrigin}&destinationC=${selectedDestination}&originC=${selectedOrigin}&adult=${passengersSelecteds?.adult}&boy=${passengersSelecteds?.boy}&baby=${passengersSelecteds?.baby}&seat=${passengersSelecteds?.seat}&isRoundTrip=${isRoundTrip}&directFlight=${directFlight}&dollarFlight=${dollarFlight}&`,
            type: 'flight',
            departureDate: dateGoingSelectedApi,
            return: dateReturnSelectedApi,
            iataD: codeiataDestinity,
            iataO: codeIataOrigin,
            destinationC: selectedDestination,
            originC: selectedOrigin,
            adult: passengersSelecteds?.adult,
            boy: passengersSelecteds?.boy,
            baby: passengersSelecteds?.baby,
            seat: passengersSelecteds?.seat,
            isRoundTrip,
            directFlight: directFlight,
            dollarFlight: dollarFlight
          }
        // window.location = window.location.href;

        return data
        }
      }else{
        if (dateGoingSelected != "") {
          setValidationSearchDate(false)
            const data = {
              url: `${url}/&departure=${dateGoingSelectedApi}&return=${dateReturnSelectedApi}&iataD=${codeiataDestinity}&iataO=${codeIataOrigin}&destinationC=${selectedDestination}&originC=${selectedOrigin}&adult=${passengersSelecteds?.adult}&boy=${passengersSelecteds?.boy}&baby=${passengersSelecteds?.baby}&seat=${passengersSelecteds?.seat}&isRoundTrip=${isRoundTrip}&directFlight=${directFlight}&dollarFlight=${dollarFlight}&`,
              type: 'flight',
              departureDate: dateGoingSelectedApi,
              return: dateReturnSelectedApi,
              iataD: codeiataDestinity,
              iataO: codeIataOrigin,
              destinationC: selectedDestination,
              originC: selectedOrigin,
              adult: passengersSelecteds?.adult,
              boy: passengersSelecteds?.boy,
              baby: passengersSelecteds?.baby,
              seat: passengersSelecteds?.seat,
              isRoundTrip,
              directFlight: directFlight,
              dollarFlight: dollarFlight
            }
          // window.location = window.location.href;
  
          return data
          }
      }
      
      // console.log("datego + datere " + dateGoingSelected + " " + dateReturnSelected)
      return false;
    }
    // console.log("origin + destination " + selectedDestination + " " + selectedOrigin)
    return false;
  }

  useEffect(() => {
    showButtonSearch();
  }, [
    isRoundTrip,
    dateGoingSelected,
    dateReturnSelected,
    selectedOrigin,
    selectedDestination,
  ]);

  useEffect(() => {
    setIsCardRefOrigin(false);
    setIsCardRefDestination(false);
  }, [selectedOrigin, selectedDestination]);

  useEffect(() => {
    deleteItemsExpiration();
    setSelectedOrigin(
      localStorage.getItem("select-origin") === null
        ? "o"
        : localStorage.getItem("select-origin")
    );
    setCodeIataOrigin(
      localStorage.getItem("iata-origin") === null
        ? "o"
        : localStorage.getItem("iata-origin")
    );
    setSelectedDestination(
      localStorage.getItem("select-destiny") === null
        ? "d"
        : localStorage.getItem("select-destiny")
    );
    setCodeiataDestinity(
      localStorage.getItem("iata-destiny") === null
        ? "d"
        : localStorage.getItem("iata-destiny")
    );

    function handleClickOutside(event) {
      if (
        cardRefPlace.current &&
        !cardRefPlace.current.contains(event.target)
      ) {
        setIsCardRefOrigin(false);
        setIsCardRefDestination(false);
      }
      if (
        cardRefPassenger.current &&
        !cardRefPassenger.current.contains(event.target)
      ) {
        setIsManagingPassengers(false);
      }
      if (cardRefDate.current && !cardRefDate.current.contains(event.target)) {
        setIsCardRefDate(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return {
    cardRefPlace,
    cardRefPassenger,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    buttonDisable,
    searcherFlight,
    //ciudades
    //ida
    isCardRefOrigin,
    setIsCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,
    //retorno
    isCardRefDestination,
    setIsCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    //fechas
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    isCardRefDate,
    setIsCardRefDate,

    setDateGoingSelectedApi,
    setDateReturnSelectedApi,

    //pasajeros
    isManagingPassengers,
    setIsManagingPassengers,
    passengersSelecteds,
    getPassengersSelecteds,
    ellipsisText,
    setPassengersSelecteds,

    //Validation
    validationSearchDestiny,
    setValidationSearchDestiny,
    validationSearchDate,

    //Direct FLight
    setDirectFlight,
    directFlight,
    dollarFlight,
    setDollarFlight,


    builUrlFlight
  };
}
export default useSearchFlights;
