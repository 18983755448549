import React, { useState, useEffect } from "react";
import useUserUpdate from "@/ui/viewModels/session/update.user.viewModel";
import style from "./styles.module.css";
import SearchableInput from "@/ui/views/session/components/SearchableInput/SearchableInput";
import { ApiCountryCodePhone } from "@/Api/ApiCountryCodePhone";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FaSpinner } from "react-icons/fa6";

const UserUpdate = ({ show }) => {
    const { formData, errors, responseStatus, loading, responseMessage, messageType, handleChange, handleBlur, handleSubmit } = useUserUpdate();

    const [showPassword, setShowPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showResponseMessage, setShowResponseMessage] = useState(false);

    const CODES = ApiCountryCodePhone.map((item) => item.phone_code);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleCurrentPasswordVisibility = () => setShowCurrentPassword(!showCurrentPassword);

    useEffect(() => {
        if (responseStatus.status === 200 || messageType) {
            setShowResponseMessage(true);
            const timer = setTimeout(() => {
                setShowResponseMessage(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [responseStatus.status, messageType]);

    useEffect(() => {
        if (!show) {
            // Limpiar las contraseñas si `show` es `false`
            handleChange({ target: { name: 'password', value: '' } });
            handleChange({ target: { name: 'confirm_password', value: '' } });
            handleChange({ target: { name: 'current_password', value: '' } });
        }
    }, [show]);

    const isFormValid = () => {
        // Verifica si todos los campos están llenos y si las contraseñas coinciden
        return (
            formData.name &&
            formData.email &&
            formData.prex_phone_public &&
            formData.phone_public &&
            formData.current_password &&
            formData.password &&
            formData.confirm_password &&
            !errors.name &&
            !errors.email &&
            !errors.prex_phone_public &&
            !errors.phone_public &&
            !errors.current_password &&
            !errors.password &&
            !errors.confirm_password &&
            formData.password === formData.confirm_password
        );
    };

    return (
        <div className={style.formUpdateUser}>
            <h3>Actualiza tus datos</h3>
            <form onSubmit={handleSubmit} className={style.flexRowContainItemForm}>
                {/* Campos del formulario aquí */}

                <div className={style.flexRowContainItemInput}>
                    <label htmlFor="name" className={style.label}>Nombres</label>
                    <div className={`${style.input} ${errors.name ? style.error : ''}`}>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    {errors.name && <p className={style.messageInput}>{errors.name}</p>}
                </div>

                <div className={style.flexRowContainItemInput}>
                    <label htmlFor="email" className={style.label}>Email</label>
                    <div className={`${style.input} ${errors.email ? style.error : ''}`}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    {errors.email && <p className={style.messageInput}>{errors.email}</p>}
                </div>

                <div className={style.inputsFlex}>
                    <div className={style.flexRowContainItemInput}>
                        <label htmlFor="prex_phone_public" className={style.label}>Prefijo</label>
                        <SearchableInput
                            name="prex_phone_public"
                            options={CODES}
                            value={formData.prex_phone_public}
                            onChange={handleChange}
                            placeholder="Selecciona un prefijo"
                            onBlur={handleBlur}
                            type="text"
                            id="prex_phone_public"
                            inputErrors={errors.prex_phone_public}
                        />
                        {errors.prex_phone_public && <p className={style.messageInput}>{errors.prex_phone_public}</p>}
                    </div>

                    <div className={style.flexRowContainItemInput}>
                        <label htmlFor="phone_public" className={style.label}>Teléfono</label>
                        <div className={`${style.input} ${errors.phone_public ? style.error : ''}`}>
                            <input
                                type="text"
                                id="phone_public"
                                name="phone_public"
                                value={formData.phone_public}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                        {errors.phone_public && <p className={style.messageInput}>{errors.phone_public}</p>}
                    </div>
                </div>

                <div className={style.flexRowContainItemInput}>
                    <label htmlFor="current_password" className={style.label}>Contraseña Actual</label>
                    <div className={`${style.input} ${errors.current_password ? style.errorInput : ''}`}>
                        <input
                            type={showCurrentPassword ? "text" : "password"}
                            id="current_password"
                            name="current_password"
                            value={formData.current_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <button type="button" onClick={toggleCurrentPasswordVisibility} className={style.eyeIcon}>
                            {showCurrentPassword ? <FaEyeSlash size={20} color="var(--bluePrimary)" /> : <FaEye size={20} color="var(--bluePrimary)" />}
                        </button>
                    </div>
                    {errors.password && <p className={style.messageInput}>{errors.password}</p>}

                </div>

                <div className={style.flexRowContainItemInput}>
                    <label htmlFor="password" className={style.label}>Nueva Contraseña</label>
                    <div className={`${style.input} ${errors.password ? style.errorInput : ''}`}>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <button type="button" onClick={togglePasswordVisibility} className={style.eyeIcon}>
                            {showPassword ? <FaEyeSlash size={20} color="var(--bluePrimary)" /> : <FaEye size={20} color="var(--bluePrimary)" />}
                        </button>
                    </div>
                    {errors.current_password && <p className={style.messageInput}>{errors.current_password}</p>}
                </div>

                <div className={style.flexRowContainItemInput}>
                    <label htmlFor="confirm_password" className={style.label}>Confirmar Nueva Contraseña</label>
                    <div className={`${style.input} ${errors.confirm_password ? style.errorInput : ''}`}>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="confirm_password"
                            name="confirm_password"
                            value={formData.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <button type="button" onClick={togglePasswordVisibility} className={style.eyeIcon}>
                            {showPassword ? <FaEyeSlash size={20} color="var(--bluePrimary)" /> : <FaEye size={20} color="var(--bluePrimary)" />}
                        </button>
                    </div>
                    {errors.confirm_password && <p className={style.messageInput}>{errors.confirm_password}</p>}
                </div>

                {showResponseMessage && messageType === "success" && (
                    <p className={`${style.responseSave} ${style[messageType]}`}>
                        {responseMessage}
                    </p>
                )}

                <button
                    type="submit"
                    className={style.submitButton}
                    disabled={loading || !isFormValid()}
                >
                    {loading ? <FaSpinner size={20} className={style.spinner} /> : "Actualizar datos"}
                </button>
            </form>
        </div>
    );
};

export default UserUpdate;
